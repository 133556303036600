import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import { useSlate } from "slate-react";
import Modal from "~components/common/CustomModal/CustomModal";
import { insertVideo } from "../video";
import InsertVideoForm, {
  FormValues,
  InsertVideoFormProps,
} from "./InsertVideoForm";
import { VideoElement } from "../interface/slate.interface";

interface InsertVideoModalProps extends InsertVideoFormProps {
  isOpen: boolean;
}

export const videoModalState = atomWithReset<{
  isOpen: boolean;
  video: VideoElement;
}>({
  isOpen: false,
  video: null,
});

function InsertVideoModal(props: InsertVideoModalProps) {
  const { isOpen, close, onSubmit, afterSubmit, video } = props;

  return (
    <Modal isOpen={isOpen} close={close} small>
      {isOpen && (
        <InsertVideoForm
          close={close}
          onSubmit={onSubmit}
          video={video}
          afterSubmit={afterSubmit}
        />
      )}
    </Modal>
  );
}

export default function SlateInsertVideoModal() {
  const [modalState] = useAtom(videoModalState);
  const resetModalState = useResetAtom(videoModalState);

  const editor = useSlate();

  const close = () => {
    resetModalState();
  };

  const afterSubmit = close;

  const onSubmit = (formValues: FormValues) => {
    const { url } = formValues;

    const newVideo: VideoElement = {
      type: "video",
      children: [],
      url,
    };

    insertVideo(editor, newVideo);

    close();
  };

  return (
    <InsertVideoModal
      video={modalState.video}
      isOpen={modalState.isOpen}
      close={close}
      afterSubmit={afterSubmit}
      onSubmit={onSubmit}
    />
  );
}
