import { ToastAlert } from "flicket-ui";
import { toast, ToastOptions } from "react-toastify";
import { Icon } from "~components";
import React from "react";
import { IconName } from "~components/common/Icon/Icon";

type ToastType = "warning" | "error" | "success";

const icons: Record<string, IconName> = {
  success: "check-circle",
  warning: "warning",
  error: "error",
};

const showToast = (msg: string, type?: ToastType, options?: ToastOptions) =>
  toast(
    () => (
      <ToastAlert
        msg={msg}
        type={type}
        icon={
          <Icon
            icon={icons[type || "success"]}
            color={type || "success"}
            fontSize={"20px" as any}
            mr={2}
          />
        }
      />
    ),
    options
  );

export const showErrorToast = (msg: string) => showToast(msg, "error");

export default showToast;
