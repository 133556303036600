import { Flex, SystemProps, TransparentButton, system } from "flicket-ui";
import { Icon } from "../Icon";
import styled, { css } from "styled-components";
import { pick } from "@styled-system/props";
import ReactSlider from "react-slider";
import {
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
} from "@phosphor-icons/react";

const trackStyles = css`
  height: 3px;
  border-radius: 3px;
  background-color: ${(p) => p.theme.colors.N200};
`;

const thumbStyles = css`
  cursor: pointer;
  background-color: white;
  height: 22px;
  width: 22px;
  border-radius: 100%;
  box-shadow: ${(p) => p.theme.shadows.hover};
  transition: transform 0.1s ease-in-out;

  :focus {
    outline: 1px solid ${(p) => p.theme.colors.N300};
  }

  &:hover,
  &:active {
    transform: scale(1.1);
  }
`;

const ReactSliderStyled = styled(ReactSlider)<SystemProps>`
  display: flex;
  align-items: center;
  .slider__thumb {
    ${thumbStyles}
  }

  .slider__track {
    ${trackStyles}
  }

  .slider__track-0 {
    background-color: ${(p) => p.theme.colors.N800};
  }

  ${system}
`;

interface SliderProps extends SystemProps {
  className?: string;
  value: number;
  onChange: (value: number) => void;
}

export default function Slider(props: SliderProps) {
  const { className, value, onChange } = props;

  // 15 steps is the default when using page up/down
  // https://www.w3.org/WAI/ARIA/apg/patterns/slider/examples/slider-seek/
  const onZoomOut = () => {
    onChange?.(Math.max(value - 15, 0));
  };

  const onZoomIn = () => {
    onChange?.(Math.min(value + 15, 100));
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    <Flex className={className} {...pick(props)}>
      <TransparentButton onClick={onZoomOut} px={"6/4"}>
        <Icon
          icon={<MagnifyingGlassMinus size={24} weight="regular" />}
          color="N800"
        />
      </TransparentButton>
      <ReactSliderStyled
        flex={1}
        thumbClassName="slider__thumb"
        trackClassName="slider__track"
        value={value}
        min={0}
        max={100}
        onChange={(value) => {
          if (typeof value === "number") {
            onChange?.(value);
          }
        }}
      />

      <TransparentButton onClick={onZoomIn}>
        <Icon
          icon={<MagnifyingGlassPlus size={24} weight="regular" />}
          px={"6/4"}
          color="N800"
        />
      </TransparentButton>
    </Flex>
  );
}
