import { useState } from "react";
import useSWR from "swr";
import { ScoutsQuery } from "~graphql/sdk";

import { useSDK } from "~hooks";

type Params = {
  isActive: boolean;
};

export const useScouts = (initialParams: Params = { isActive: true }) => {
  const sdk = useSDK();
  const [params, setParams] = useState<Params>({
    ...initialParams,
  });

  const getScouts = async () => {
    return sdk
      .scouts({ input: { isActive: params.isActive } })
      .then((res) => res.scouts);
  };

  const { data: scouts, error, ...methods } = useSWR<
    ScoutsQuery["scouts"],
    Error
  >(["scouts", params?.isActive], getScouts, {
    shouldRetryOnError: false,
  });

  return {
    ...methods,
    error,
    scouts,
    setParams,
  };
};
