import { useState } from "react";

import {
  EventsListWithStatsQuery,
  EventWhereInput,
  OrderByDirection,
  Role,
} from "~graphql/sdk";
import { EventsListWithStatsDocument } from "~graphql/typed-document-nodes";
import { useUser, useOrganization } from "~hooks";
import { useQuery } from "~hooks/useQuery";

import { Box, Divider, Stack, Text } from "flicket-ui";

import { getUrl } from "~lib/helpers";
import { EventsParams, EventItem } from "./EventList.helpers";
import { EventListItemWithIntersectionLoader, Loader } from "./EventListItem";
import { KeyedMutator } from "swr";
import { useRouter } from "next/router";
import { useScreenSize } from "~hooks/useScreenSize";

export function EventList({
  events,
  isLoading,
  isValidating,
  limit = Infinity,
  mutate,
}: {
  events: EventItem[];
  isLoading: boolean;
  isValidating: boolean;
  limit?: number;
  mutate: KeyedMutator<EventsListWithStatsQuery>;
}) {
  const { organization } = useOrganization();

  const eventList = isFinite(limit) ? events?.slice(0, limit) : events;

  const noResults = !isValidating && !eventList?.length;

  const router = useRouter();
  const useHomepageLayout = router.pathname === "/";
  const { isTabletLandscapeUp } = useScreenSize();
  const useDivider = useHomepageLayout && isTabletLandscapeUp;

  return (
    <Box>
      <Stack gap={useDivider ? 0 : [2, 2, 3]} direction="vertical">
        {isLoading && new Array(3).fill(null).map((_, i) => <Loader key={i} />)}

        {eventList?.map((event) => (
          <>
            <EventListItemWithIntersectionLoader
              event={event}
              key={event.id}
              baseURL={getUrl(organization.slug)}
              mutate={mutate}
            />
            {useDivider && <Divider my={3} />}
          </>
        ))}
      </Stack>
      {noResults && <Text variant="header.XS">No results</Text>}
    </Box>
  );
}

const formatQueryParams = ({ orderBy, ...params }: EventsParams) => {
  return {
    where: {
      isActive: null,
      ...params,
    },
    orderBy: { startDate: orderBy },
  };
};

export const useEventsListFilter = (
  initialParams: EventWhereInput = {},
  options?: { adminAccessFilter: boolean }
) => {
  const { user } = useUser();
  const [params, setParams] = useState<EventsParams>({
    startDate: new Date().toString(),
    orderBy: OrderByDirection.Asc,
    ...initialParams,
  });

  function updateParams(params: EventsParams) {
    setParams({
      ...params,
    });
  }

  // If user is only scoped for certain events, we need to filter the events
  const eventAccessFilter = (
    eventsData: EventsListWithStatsQuery
  ): EventsListWithStatsQuery["events"]["edges"][number]["node"][] => {
    const events = eventsData?.events?.edges.map((event) => event.node);

    if (
      !options?.adminAccessFilter ||
      !user.roles.includes(Role.EventReporting)
    ) {
      return events;
    }

    return events?.filter((event) => user.accessEventIds.includes(event.id));
  };

  const { data: eventsData, error, ...methods } = useQuery(
    EventsListWithStatsDocument,
    formatQueryParams(params)
  );

  const events = eventAccessFilter(eventsData);

  return {
    ...methods,
    error,
    events,
    params,
    updateParams,
  };
};
