import React, { FC } from "react";

import { Status } from "~components";
import { SalesChannelGraphOutput } from "~graphql/sdk";
import { PieChart } from "../charts";
import {
  PointReportingFilterSource,
  SalesChannelGraphDocument,
} from "~graphql/typed-document-nodes";
import { formatReportingParams } from "../util";
import { ParamsProps } from "~features/useParams/hooks/useParams";
import { useQuery } from "~hooks/useQuery";
import { COLORS } from "~features/reports/reporting/components/dashboards/audience/audience.index";
import { getRandomColor } from "~lib";
import { GraphQLError } from "graphql";

export const SalesChannelChart: FC<{
  data: SalesChannelGraphOutput;
  error: GraphQLError;
  direction?: "horizontal" | "vertical";
}> = ({ data, error, direction }) => {
  const items = [
    {
      name: "online",
      label: "Online",
      value: data?.online,
    },
    {
      name: "backOffice",
      label: "Back office",
      value: data?.backOffice,
    },
    {
      name: "pos",
      label: "POS",
      value: data?.pos,
    },
  ]
    .sort((a, b) => b.value - a.value)
    .map((item, index) => ({
      ...item,
      color: COLORS[index] || getRandomColor(),
    }));

  return (
    <Status loading={!error && !data} error={error}>
      <PieChart
        direction={direction}
        data={items}
        label={`Transaction${data?.transactions === 1 ? "" : "s"}`}
        total={data?.transactions?.toLocaleString()}
      />
    </Status>
  );
};

export function ConnectedSalesChannelChart({
  params,
}: {
  params: ParamsProps;
}) {
  const formattedParams = formatReportingParams<
    ParamsProps,
    PointReportingFilterSource
  >(params);

  const { data: salesChannelGraph, error: errorSalesChannelGraph } = useQuery(
    params.ready ? SalesChannelGraphDocument : null,
    formattedParams
  );

  return (
    <SalesChannelChart
      data={salesChannelGraph?.salesChannelGraph}
      error={errorSalesChannelGraph}
    />
  );
}
