import { Stack, Text, formatPrice } from "flicket-ui";
import styled from "styled-components";
import { StatisticFragmentFragment } from "~graphql/typed-document-nodes";
import { StatisticTooltip } from "./StatisticTooltip";

const BorderedStack = styled(Stack)`
  border: 1px solid ${(p) => p.theme.colors.N300};
  break-inside: avoid;
`;

export function statisticValue(statistic: StatisticFragmentFragment): string {
  switch (statistic.__typename) {
    case "NumberStatistic": {
      return `${Intl.NumberFormat(undefined).format(
        statistic.numberValue ?? 0
      )}`;
    }
    case "MoneyStatistic": {
      return `${formatPrice(statistic.moneyValue)}`;
    }
    case "StringStatistic": {
      return `${statistic.stringValue}`;
    }
    case "PercentageStatistic": {
      return `${statistic.percentage}%`;
    }
    default: {
      return "";
    }
  }
}

type StatisticProps = Omit<
  StatisticFragmentFragment,
  "value" | "__typename"
> & {
  value: string;
};

export default function Statistic(props: StatisticProps) {
  const { label, value, tooltip } = props;

  return (
    <BorderedStack direction={"vertical"} padding={2}>
      <Stack gap={1}>
        <Text variant="header.M">{value}</Text>
        <StatisticTooltip tooltip={tooltip} />
      </Stack>
      <Text variant="regular">{label}</Text>
    </BorderedStack>
  );
}
