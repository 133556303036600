import { Flex } from "flicket-ui";
import styled from "styled-components";

export const NamingFieldsTag = styled(Flex).attrs({
  as: "span",
  my: "1/2",
  mr: 1,
  p: 1,
  fontSize: 2,
  fontWeight: "demiBold",
  flexShrink: 0,
  borderRadius: "full",
})``;
