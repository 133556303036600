import {
  PointReportingFilterSource,
  ReportingFilterSource,
  ReportingFinancialSalesBreakdownSource,
} from "~graphql/sdk";
import { ParamsProps } from "~features/useParams/hooks/useParams";

export type OmitTypename<T> = Omit<T, "__typename">;

export const formatReportingDates = (startDate: Date, endDate: Date) => ({
  startDate: startDate ? startDate.toISOString() : null,
  endDate: endDate ? endDate.toISOString() : null,
});

export const formatReportingParams = <
  T extends ParamsProps,
  TSource extends
    | ReportingFilterSource
    | ReportingFinancialSalesBreakdownSource
    | PointReportingFilterSource
>({
  dates,
  startDate,
  endDate,
  ready,
  ...rest
}: T) => ({
  source: rest.source as TSource,
  ...rest,
  ...formatReportingDates(startDate, endDate),
});
