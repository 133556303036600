import React, { createContext, ReactNode, useState } from "react";

export interface POSAuthContextProps {
  accessToken?: string;
  setAccessToken?: (val: string) => void;
}

const POSAuthContext = createContext<POSAuthContextProps>({});

const POSAuthProvider = ({ children }: { children: ReactNode }) => {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  return (
    <POSAuthContext.Provider value={{ accessToken, setAccessToken }}>
      {children}
    </POSAuthContext.Provider>
  );
};

export { POSAuthProvider, POSAuthContext };
