import React, { forwardRef, useContext } from "react";
import { SystemProps } from "flicket-ui";
import ReactSelect, { Props, Theme } from "react-select";
import styled, { DefaultTheme, ThemeContext } from "styled-components";
import { baseSelectStyles } from "./styles";

const StyledSelect = styled(ReactSelect)<
  SystemProps & { isInvalid?: boolean; styledTheme: DefaultTheme }
>(baseSelectStyles);

interface SelectProps extends Props {
  theme?: any;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const styledTheme = useContext(ThemeContext); // react-select and styled-components both need a theme so it needs to be renamed
  return (
    <StyledSelect
      inputRef={ref}
      className="react-select"
      classNamePrefix="react-select"
      styledTheme={styledTheme}
      theme={(t: Theme) => ({
        ...t,
        colors: {
          ...t.colors,
          primary25: styledTheme.colors.N100,
          primary50: styledTheme.colors.N100,
          primary: styledTheme.colors.N100,
        },
      })}
      {...props}
    />
  );
});

Select.displayName = "Select";

export { Select };
export default Select;
