import { Permission } from "~graphql/sdk";
import Error from "~pages/_error";
import useUser from "./useUser";
import { UserFragment } from "~context";

export const hasPermissions = (user: UserFragment) => (
  requiredPermissions: Permission | Permission[]
) => {
  if (Array.isArray(requiredPermissions)) {
    return requiredPermissions?.every((permission) =>
      user?.permissions.includes(permission)
    );
  }

  return user?.permissions.includes(requiredPermissions);
};

export const usePermissions = () => {
  const { user } = useUser();

  const _hasPermissions = hasPermissions(user);

  return { hasPermissions: _hasPermissions, Permission, Error };
};
