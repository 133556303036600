import { CSSProperties, FC, ReactNode } from "react";
import {
  FixedCropperRef,
  FixedCropperProps,
  CropperFade,
  CropperRef,
} from "react-advanced-cropper";
import {
  getAbsoluteZoom,
  getZoomFactor,
} from "advanced-cropper/extensions/absolute-zoom";
import "react-advanced-cropper/dist/style.css";
import Slider from "./Slider";
import { Flex } from "flicket-ui";

export type CustomCropperProps = FixedCropperProps;

export type CustomCropperRef = FixedCropperRef;

interface Props {
  cropper: CropperRef;
  loading: boolean;
  loaded: boolean;
  className?: string;
  style?: CSSProperties;
  extraFooterContent?: ReactNode;
}

const Wrapper: FC<Props> = ({
  cropper,
  children,
  loaded,
  className,
  extraFooterContent,
}) => {
  const state = cropper.getState();
  const settings = cropper.getSettings();

  // 0 - 1 with 0 being the minimum zoom and 1 being the maximum zoom
  // https://advanced-cropper.github.io/react-advanced-cropper/docs/tutorials/absolute-zoom/
  const absoluteZoom = getAbsoluteZoom(state, settings);

  const onZoom = (value: number, transitions?: boolean) => {
    if (cropper) {
      cropper.zoomImage(getZoomFactor(state, settings, value), {
        transitions: !!transitions,
      });
    }
  };

  return (
    <CropperFade visible={state && loaded} className={className}>
      <Flex overflow={"hidden"} minHeight={0} height="100%" px={3}>
        {children}
      </Flex>
      <Flex
        padding={3}
        justifyContent={"space-between"}
        width={"100%"}
        alignItems={["unset", "unset", "center"]}
        flexDirection={["column", "column", "row"]}
        style={{ gap: "24px" }}
        borderTop="1px solid"
        borderColor="N200"
      >
        <Flex width={"100%"}>
          <Slider
            flex={1}
            value={absoluteZoom * 100}
            onChange={(value) => {
              onZoom(value / 100);
            }}
          />
        </Flex>
        {extraFooterContent}
      </Flex>
    </CropperFade>
  );
};

export default Wrapper;
