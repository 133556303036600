/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Box, Text } from "flicket-ui";
import Select, { components, GroupType, OptionProps } from "react-select";
import { useTheme } from "styled-components";
import {
  allEvents,
  EventOptionType,
  groupEvents,
  isAllEvents,
} from "../../util";
import {
  CommonDisplay,
  defaultComponents,
  renderGroup,
  selectStyles,
} from "../common";
import { EventSearchItem, SearchItem } from "../../SearchItem";
import { Skeleton } from "~components";
import { formatEventDateRange } from "~lib/helpers/dates/formatDateRange";
import {
  InternalState,
  isEventSource,
  isSpecificEventSource,
  SourceSelection,
} from "../../interfaces";
import { useQuery } from "~hooks/useQuery";
import { EventInformationDocument } from "~graphql/typed-document-nodes";

interface EventSelectProps {
  internalState: InternalState;
  onChange: (option: SourceSelection) => void;
  modal?: boolean;
}

export const Option = (props: OptionProps<EventOptionType, false>) => {
  return (
    <components.Option {...props}>
      {isAllEvents(props.data) ? (
        <SearchItem active={props.isFocused}>All events</SearchItem>
      ) : (
        <EventSearchItem searchEvent={props.data} active={props.isFocused} />
      )}
    </components.Option>
  );
};

function Subtitle(props: InternalState) {
  const { small, searchIndexLoading, selectedSource } = props;

  const { data, isLoading } = useQuery(
    isSpecificEventSource(selectedSource) ? EventInformationDocument : null,
    isSpecificEventSource(selectedSource) && { eventId: selectedSource.eventId }
  );

  if (searchIndexLoading || isLoading) {
    return <Skeleton height={15} maxWidth={"400px"} mt={"6/4"} />;
  }

  if (!isSpecificEventSource(selectedSource) || !data?.eventInformation) {
    return null;
  }

  const eventDate = formatEventDateRange(data.eventInformation.dates);

  const location = data.eventInformation.location;

  return (
    <Box mt={1}>
      {eventDate && (
        <Text variant="small" as={small ? "span" : "div"}>
          {eventDate}
        </Text>
      )}
      {location && (
        <Text variant="small" as="span">
          {small ? ` • ${location}` : location}
        </Text>
      )}
    </Box>
  );
}

export function EventDisplay(props: InternalState) {
  const { selectedSource, searchableEvents, small } = props;

  const { data, isLoading } = useQuery(
    isSpecificEventSource(selectedSource) ? EventInformationDocument : null,
    isSpecificEventSource(selectedSource) && { eventId: selectedSource.eventId }
  );

  if (!isEventSource(selectedSource)) {
    return null;
  }

  let image = undefined;
  let title;

  if (selectedSource === "all-events") {
    image = null;
    title = allEvents.label;
  } else {
    const foundValue = searchableEvents?.find(
      (e) => e.id === selectedSource.eventId
    );

    title = data?.eventInformation?.title ?? foundValue?.title;
    image = data?.eventInformation?.logo ?? undefined;
  }

  return (
    <CommonDisplay
      small={small}
      image={image}
      title={title}
      subtitle={<Subtitle {...props} />}
      sourceLabel={"Events"}
      loading={isLoading}
    />
  );
}

export default function EventSelect(props: EventSelectProps) {
  const {
    internalState: { searchableEvents, hideAllEventsOption },
  } = props;
  const theme = useTheme();

  const options: (EventOptionType | GroupType<EventOptionType>)[] = [];

  if (!hideAllEventsOption) {
    options.push(allEvents);
  }

  options.push(...groupEvents(searchableEvents));

  const onChange = (option: EventOptionType) => {
    const selectedSource = isAllEvents(option)
      ? "all-events"
      : { eventId: option.id, title: option.title };

    props.onChange(selectedSource);
  };

  return (
    <Select
      menuIsOpen
      autoFocus
      options={options}
      getOptionValue={(option: EventOptionType) => {
        return option.id;
      }}
      getOptionLabel={(option: EventOptionType) => {
        return isAllEvents(option) ? option.label : option.title;
      }}
      formatGroupLabel={renderGroup}
      onChange={onChange}
      components={{ ...defaultComponents, Option }}
      styles={selectStyles(theme)}
      placeholder="Search events"
    />
  );
}
