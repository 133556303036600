import { Status } from "~components";
import { ReportStatistics } from "~features/reports/reporting/components/dashboards/report.statistics";
import { useQuery } from "~hooks/useQuery";
import {
  MembershipAudienceReportOverviewDocument,
  StatisticFragmentFragment,
} from "~graphql/typed-document-nodes";
import { MembershipReportParams } from "~features/reports/reporting/types";

export const MembershipAudienceStatistics = ({
  params,
}: MembershipReportParams) => {
  const { data, error, isLoading } = useQuery(
    MembershipAudienceReportOverviewDocument,
    params
  );
  if (isLoading || error) {
    return <Status error={error} loading={isLoading} />;
  }

  const formatData = Object.values(
    data.customerReportingOverview
  ) as StatisticFragmentFragment[];

  return <ReportStatistics title={"Audience insights"} data={formatData} />;
};
