import React, { FC } from "react";

import { PieChart } from "../charts";
import { getRandomColor, notEmptyPredicate } from "~lib";
import { GoogleAnalyticsReportQuery } from "~graphql/typed-document-nodes";

const deviceMapping: Record<string, string> = {
  mobile: "Mobile",
  desktop: "Desktop",
  tablet: "Tablet",
};

export const DeviceBreakdown: FC<{
  data: GoogleAnalyticsReportQuery["googleAnalyticsReport"];
  colors: string[];
}> = ({ data, colors }) => {
  const existingDeviceTypesLength = Object.keys(deviceMapping).length;

  const other = {
    label: "Other",
    value: 0,
    color: colors[existingDeviceTypesLength] || getRandomColor(),
  };

  const chartData = data
    .map((data, index) => {
      const otherDeviceType = !deviceMapping[data.range];
      if (otherDeviceType) {
        other.value += data.value;
        return null;
      }
      return {
        label: deviceMapping[data.range],
        value: data.value,
        color: colors[index] || getRandomColor(),
      };
    })
    .filter(notEmptyPredicate);

  if (other.value > 0) {
    chartData.push(other);
  }

  return <PieChart data={chartData} direction="vertical" />;
};
