import { useEffect, useState } from "react";
import uniqid from "uniqid";

export const useFormArray = <
  T extends { createdAt?: string; id: string } = any
>(
  initialData: T[] = []
) => {
  const [fields, setFields] = useState<T[]>([]);
  const [dirtyFields, setDirtyFields] = useState<number[]>([]);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (initialData) {
      const unsavedData = fields?.filter((f) => !f.createdAt);
      setFields([...initialData, ...unsavedData]);
    }
  }, [initialData]);

  useEffect(() => {
    if (dirtyFields.length && !isDirty) setIsDirty(true);
    if (!dirtyFields.length && isDirty) setIsDirty(false);
  }, [dirtyFields]);

  const append = () => setFields([...fields, { id: uniqid() } as any]);

  const remove = (index: number) =>
    setFields(fields?.filter((_, i) => i !== index));

  const update = (index: number, updatedField: T) =>
    fields.map((f, i) => (i === index ? updatedField : f));

  const updateDirtyState = (isFormDirty: boolean, index: number) => {
    if (isFormDirty && dirtyFields.indexOf(index) === -1) {
      setDirtyFields([...dirtyFields, index]);
    }

    if (!isFormDirty && dirtyFields.indexOf(index) !== -1) {
      setDirtyFields(dirtyFields?.filter((i) => i !== index));
    }
  };

  return {
    fields,
    append,
    remove,
    update,
    updateDirtyState,
    setFields,
    dirtyFields,
    isDirty,
  };
};
