import { useState } from "react";

import useSWR from "swr";
import { useSDK } from "~hooks/useSDK";
interface Input {
  eventId: string;
}

export const usePOSReports = ({ eventId }: Input) => {
  const sdk = useSDK();
  const [terminalId, setTerminalId] = useState<string>(undefined);
  const [sessionId, setSessionId] = useState<string>(undefined);
  const [tab, setTab] = useState<"sales" | "finances">("sales");

  const getPosSales = async (
    _: string,
    eventId: string,
    posId: string | undefined,
    sessionId: string | undefined
  ) =>
    sdk
      .posSalesOverview({ eventId, posId, sessionId })
      .then((result) => result.posSalesOverview);

  const getPosFinancials = async (
    _: string,
    eventId: string,
    posId: string | undefined,
    sessionId: string | undefined
  ) =>
    sdk
      .posFinancialOverview({ eventId, posId, sessionId })
      .then((result) => result.posFinancialOverview);

  const getFloatOfChange = async (
    _: string,
    eventId: string,
    posId: string,
    sessionId: string | undefined
  ) =>
    sdk
      .posFloatOfChange({ eventId, posId, sessionId })
      .then((result) => result.posFloatOfChange);

  const getSessions = async (_: string, terminalId: string) =>
    sdk.posSessions({ posId: terminalId }).then((result) => result.posSessions);

  const posSales = useSWR(
    eventId ? [`${tab}-pos-sales`, eventId, terminalId, sessionId] : null,
    getPosSales
  );
  const posFinancials = useSWR(
    eventId ? [`${tab}-pos-financials`, eventId, terminalId, sessionId] : null,
    getPosFinancials
  );
  const floatOfChange = useSWR(
    eventId && terminalId
      ? [`${tab}-pos-float-of-change`, eventId, terminalId, sessionId]
      : null,
    getFloatOfChange
  );
  const sessions = useSWR(
    terminalId ? [`${tab}-pos-sessions`, terminalId] : null,
    getSessions
  );

  return {
    posSales,
    posFinancials,
    floatOfChange,
    sessions,
    sessionId,
    setTerminalId,
    setSessionId,
    setTab,
    swrTab: tab,
  };
};
