import React, { useMemo } from "react";

import {
  Box,
  Stack,
  Text,
  Flex,
  Divider,
  PrimaryButton,
  SecondaryButton,
  TransparentButton,
} from "flicket-ui";
import { orderBy } from "lodash";
import { useRouter } from "next/router";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { Modal, Icon, Select } from "~components";
import { useOrganization, useSDK } from "~hooks";
import { showToast, handlePromise, getError } from "~lib";
import { OrderQuery, ShippingOption } from "~graphql/sdk";
import { CustomerSearch } from "./CustomerSearch";
import { ListItem } from "./ListItem";
import { useGetMultibuy } from "./PriceDifference";

const shippingTypes = {
  eTicket: ShippingOption.Eticket,
  printedTicketByCourier: ShippingOption.PrintedTicketByCourier,
  printedTicketByMail: ShippingOption.PrintedTicketByMail,
  printedTicketByCourierRural: ShippingOption.PrintedTicketByCourierRural,
  printedTicketAtVenue: ShippingOption.PrintedTicketAtVenue,
};
const shippingLabels = {
  eTicket: "E-Ticket",
  printedTicketByMail: "Printed ticket by mail",
  printedTicketByCourier: "Printed ticket by courier",
  printedTicketByCourierRural: "Printed ticket by courier - rural",
  printedTicketAtVenue: "Pickup at venue",
};

type LineItem = OrderQuery["order"]["lineItems"]["edges"][number]["node"];

type TransferModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  order: OrderQuery["order"];
};

type ModalFooterProps = {
  onCancel: () => void;
  isBusy: boolean;
  isDisabled: boolean;
  handleSubmit: any;
  transferCount: number;
};

type FormProps = {
  user: string;
  delivery: ShippingOption;
  orderItems: LineItem[];
  transferItems: LineItem[];
};

const ModalFooter = ({
  onCancel,
  isDisabled,
  isBusy,
  transferCount,
  handleSubmit,
}: ModalFooterProps) => (
  <Flex justifyContent="flex-end" mb={"-56px" as any}>
    <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
    <PrimaryButton
      onClick={handleSubmit}
      ml={1}
      disabled={isDisabled}
      isLoading={isBusy}
    >
      Transfer {transferCount > 0 ? `(${transferCount})` : null}
    </PrimaryButton>
  </Flex>
);

export const TransferModal = ({
  isOpen,
  setIsOpen,
  order,
}: TransferModalProps) => {
  const sdk = useSDK();
  const router = useRouter();
  const { organization } = useOrganization();

  const shippingOptions = useMemo(
    () =>
      Object.keys((order?.event ?? order?.membership)?.shippingOptions ?? {})
        ?.filter((opt) => !!shippingTypes?.[opt])
        .map((opt) => ({
          label: organization?.shippingLabels?.[opt] ?? shippingLabels?.[opt],
          value: shippingTypes?.[opt],
        })),
    [order?.id]
  );

  /** should always map this to make sure the order is preserved */
  const sortedLineItems = useMemo(
    () =>
      orderBy(
        order?.lineItems?.edges?.map(({ node }) => node),
        ["type", "name"],
        ["desc", "asc"]
      ),
    [order?.id]
  );

  const { formState, handleSubmit, control, reset } = useForm<FormProps>({
    defaultValues: {
      user: undefined,
      delivery: shippingOptions?.[0]?.value,
      orderItems: sortedLineItems,
      transferItems: [],
    },
  });

  const {
    fields: orderFields,
    remove: removeOrder,
    append: appendOrder,
  } = useFieldArray<LineItem>({
    name: "orderItems",
    control,
  });
  const {
    fields: transferFields,
    remove: removeTransfer,
    append: appendTransfer,
  } = useFieldArray<LineItem>({
    name: "transferItems",
    control,
  });

  const { transferPromotions, orderPromotions } = useGetMultibuy(
    order,
    orderFields,
    transferFields
  );

  const swapOrderItem = (lineItem: LineItem, index: number) => {
    if (orderFields[index]?.id === lineItem?.id) {
      removeOrder(index);
      appendTransfer(lineItem);
    } else {
      removeTransfer(index);
      appendOrder(lineItem);
    }
  };

  const resetState = () => {
    setIsOpen(false);
    reset();
  };

  const onSubmit = async (data: FormProps) => {
    const payload = {
      userId: data?.user,
      deliveryMethod: data?.delivery,
      lineItemIds: transferFields.map(({ id }) => id),
      curMultiBuyId:
        orderFields?.length > 1
          ? orderPromotions?.[0]?.promotion?.id
          : undefined,
      newMultiBuyId:
        transferFields?.length > 1
          ? transferPromotions?.[0]?.promotion?.id
          : undefined,
    };

    const [error, result] = await handlePromise(async () =>
      sdk.splitOrder({ id: order?.id, input: payload })
    );

    if (error || !result?.splitHoldOrder?.id) {
      showToast(getError(error, "graphQL"), "error");
      return;
    }

    resetState();

    await router.push(
      "/orders/[orderId]",
      `/orders/${result?.splitHoldOrder?.id}`
    );

    return;
  };

  return (
    <Modal header="Create new order" isOpen={isOpen} close={resetState}>
      <Box as="form" id="transfer-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="user"
          control={control}
          render={({ onChange }) => (
            <CustomerSearch
              handleChange={onChange}
              defaultOptions={
                (order?.user && [
                  { label: order?.user?.fullName, value: order?.user?.id },
                ]) ||
                []
              }
            />
          )}
        />

        <Controller
          name="delivery"
          control={control}
          defaultValue={shippingOptions?.[0]?.value}
          render={({ onChange }) => (
            <Select
              label="Select delivery method"
              onChange={onChange}
              defaultValue={shippingOptions?.[0]?.value}
              options={shippingOptions}
            />
          )}
        />

        <Divider my={2} />

        <Stack space={4}>
          <Box flexBasis={"calc(50% - 16px)"}>
            <Flex justifyContent="space-between" mb={2}>
              <Text fontWeight="extraBold" fontSize={3}>
                Order items
              </Text>
            </Flex>
            <Stack flexDir="column" space={2}>
              {sortedLineItems?.map((lineItem) => {
                const fieldLineItem = orderFields?.find(
                  (fLineItem) => fLineItem?.id === lineItem?.id
                );

                if (!fieldLineItem) {
                  return null;
                }

                const fieldIndex = orderFields.findIndex(
                  (fLineItem) => fLineItem?.id === lineItem?.id
                );

                return (
                  <Controller
                    name={`transferItems[${fieldIndex}]`}
                    key={`transferItems[${fieldIndex}]`}
                    control={control}
                    render={() => (
                      <ListItem
                        key={fieldLineItem?.id}
                        {...(fieldLineItem as LineItem)}
                        onClick={() =>
                          swapOrderItem(fieldLineItem as LineItem, fieldIndex)
                        }
                        isDisabled={orderFields?.length === 1}
                        icon="arrow-right"
                      />
                    )}
                  />
                );
              })}
            </Stack>
          </Box>

          {transferFields?.length > 0 ? (
            <Box flexBasis="calc(50% - 16px)">
              <Flex justifyContent="space-between" mb={2} alignItems="baseline">
                <Text fontWeight="extraBold" fontSize={3}>
                  Items to transfer
                </Text>
                {transferFields?.length > 0 && (
                  <TransparentButton onClick={() => reset()}>
                    <Icon icon="remove" />
                  </TransparentButton>
                )}
              </Flex>
              <Stack flexDir="column" space={2}>
                {sortedLineItems.map((lineItem) => {
                  const fieldLineItem = transferFields.find(
                    (fLineItem) => fLineItem?.id === lineItem?.id
                  );
                  if (!fieldLineItem) {
                    return null;
                  }

                  const fieldIndex = transferFields.findIndex(
                    (fLineItem) => fLineItem?.id === lineItem?.id
                  );

                  return (
                    <Controller
                      name={`transferItems[${fieldIndex}]`}
                      key={`transferItems[${fieldIndex}]`}
                      control={control}
                      render={() => (
                        <ListItem
                          key={fieldLineItem?.id}
                          {...(fieldLineItem as LineItem)}
                          onClick={() =>
                            swapOrderItem(fieldLineItem as LineItem, fieldIndex)
                          }
                          isDisabled={false}
                        />
                      )}
                    />
                  );
                })}
              </Stack>
            </Box>
          ) : null}
        </Stack>

        {/*transferFields?.length > 0 ? (
          <>
            <Divider mt={4} mb={2} />
            <PriceDifference
              order={order}
              originalItems={sortedLineItems}
              orderItems={orderFields as LineItem[]}
              transferItems={transferFields as LineItem[]}
            />
          </>
        ) : null*/}

        <Divider mt={2} mb={4} />

        <ModalFooter
          isBusy={formState.isSubmitting}
          isDisabled={transferFields?.length === 0}
          transferCount={transferFields?.length}
          onCancel={resetState}
          handleSubmit={handleSubmit(onSubmit)}
        />
      </Box>
    </Modal>
  );
};
