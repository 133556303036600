import { ArrowDown, ArrowUp } from "@phosphor-icons/react";
import { Editor, Element, Node, NodeEntry, Path, Transforms } from "slate";
import { useSlate } from "slate-react";
import { Icon } from "~components/common/Icon";
import { Text } from "flicket-ui";
import { Button } from "../components";

export const moveNodeUp = (editor: Editor, nodeEntry: NodeEntry<Node>) => {
  const [, path] = nodeEntry;

  if (path[path.length - 1] !== 0) {
    const previousPath = Path.previous(path);

    Transforms.moveNodes(editor, {
      at: path,
      to: previousPath,
    });
  }
};

export const moveNodeDown = (editor: Editor, nodeEntry: NodeEntry<Node>) => {
  const { children } = editor;
  const [, path] = nodeEntry;

  const nextPath = Path.next(path);

  if (nextPath[0] != children.length) {
    Transforms.moveNodes(editor, {
      to: nextPath,
    });
  }
};

function useSelectedElements() {
  const editor = useSlate();

  const elements = Editor.nodes(editor, {
    at: editor.selection,
    match: (n) => {
      return Element.isElement(n);
    },
  });
  return elements;
}

export function UpDownPopoverButtons() {
  const editor = useSlate();
  const elements = useSelectedElements();

  const [selectedNode] = elements;

  return (
    <>
      <Button
        height={"32px"}
        width={"32px"}
        justifyContent={"center"}
        onClick={() => {
          moveNodeUp(editor, selectedNode);
        }}
      >
        <Icon icon={<ArrowUp weight="bold" />} />
      </Button>
      <Button
        height={"32px"}
        width={"32px"}
        justifyContent={"center"}
        onClick={() => {
          moveNodeDown(editor, selectedNode);
        }}
      >
        <Icon icon={<ArrowDown weight="bold" />} />
      </Button>
    </>
  );
}

export function UpToolbarButton() {
  const editor = useSlate();
  const [selectedNode] = useSelectedElements();

  const onClick = () => {
    moveNodeUp(editor, selectedNode);
  };

  return (
    <Button onClick={onClick}>
      <Icon icon={<ArrowUp />} mr={"1/2"} />
      <Text variant="regular">Move up</Text>
    </Button>
  );
}

export function DownToolbarButton() {
  const editor = useSlate();
  const [selectedNode] = useSelectedElements();

  const onClick = () => {
    moveNodeDown(editor, selectedNode);
  };

  return (
    <Button onClick={onClick}>
      <Icon icon={<ArrowDown />} mr={"1/2"} />
      <Text variant="regular">Move down</Text>
    </Button>
  );
}
