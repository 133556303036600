import { Flex, system } from "flicket-ui";
import styled from "styled-components";

export const Container = styled(Flex).attrs({
  m: "0 auto",
  maxWidth: 1196 + 80 * 2,
  width: 1,
})<{ $wide?: boolean }>`
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: ${(p) => p.theme.breakpoints.xs}) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.sm}) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    ${(p) =>
      !p.$wide &&
      `
        padding-left: 80px;
        padding-right: 80px;
      `}
  }

  ${system}
`;
