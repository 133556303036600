import useLayoutContext from "~features/reports/reporting/hooks/useLayoutContext";
import { PRIMARY_NAVIGATION_KEYS } from "~features/reports/reporting/navigation/primary.config";
import { EventAudienceIndex } from "~features/reports/reporting/components/dashboards/audience/event/audience.index";
import { MembershipAudienceIndex } from "~features/reports/reporting/components/dashboards/audience/membership/audience.index";
import { REPORT_COLOURS } from "~components/reports/common/reportColours";
import { useReportParams } from "~features/reports/reporting/hooks/useReportingOverview";
import { SeasonAudienceIndex } from "./season/audience.index";
import { OverallAudienceIndex } from "./overall/audience.index";

export const COLORS = [
  REPORT_COLOURS.teal,
  REPORT_COLOURS.tangerine,
  REPORT_COLOURS.pink,
  REPORT_COLOURS.navy,
  REPORT_COLOURS.blue,
  REPORT_COLOURS.cyan,
  REPORT_COLOURS.purple,
];

export default function AudienceIndex() {
  const context = useLayoutContext();
  const params = useReportParams();

  switch (context.type) {
    case PRIMARY_NAVIGATION_KEYS.EVENTS:
      return <EventAudienceIndex params={params} />;
    case PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS:
      return <MembershipAudienceIndex params={params} />;
    case PRIMARY_NAVIGATION_KEYS.SEASONS:
      return <SeasonAudienceIndex params={params} />;
    case PRIMARY_NAVIGATION_KEYS.OVERALL:
      return <OverallAudienceIndex params={params} />;
    default:
      return <></>;
  }
}
