import { Editor, Element, Range } from "slate";
import { Unremovable } from "./interface/slate.interface";

const isUnremovableElementBefore = (editor: Editor) => {
  const { selection } = editor;
  const before = Editor.before(editor, selection);
  if (!before) return false;
  const [beforeNode] = Editor.nodes(editor, {
    at: before,
    match: (n) =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      (n as Unremovable).isRemovable === false,
  });
  return !!beforeNode;
};

const isUnremovableElementAfter = (editor: Editor) => {
  const { selection } = editor;
  const after = Editor.after(editor, selection);
  if (!after) return false;
  const [beforeNode] = Editor.nodes(editor, {
    at: after,
    match: (n) =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      (n as Unremovable).isRemovable === false,
  });
  return !!beforeNode;
};

const isUnremovableVoidElementSelected = (editor: Editor) => {
  const { selection } = editor;
  const [selectedNode] = Editor.nodes(editor, {
    at: selection,
    match: (n) =>
      Editor.isVoid(editor, n as Element) &&
      (n as Unremovable).isRemovable === false,
  });
  return !!selectedNode;
};

export const withPreventDeletion = (editor: Editor) => {
  const { deleteBackward, deleteForward, deleteFragment } = editor;

  editor.deleteBackward = (unit) => {
    if (
      isUnremovableElementBefore(editor) ||
      isUnremovableVoidElementSelected(editor)
    ) {
      return;
    }
    deleteBackward(unit);
  };

  editor.deleteForward = (unit) => {
    if (
      isUnremovableElementAfter(editor) ||
      isUnremovableVoidElementSelected(editor)
    ) {
      return;
    }

    deleteForward(unit);
  };

  editor.deleteFragment = () => {
    if (editor.selection && !Range.isCollapsed(editor.selection)) {
      const [selectedNodes] = Editor.nodes(editor, {
        at: editor.selection,
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          (n as Unremovable).isRemovable === false,
      });

      if (selectedNodes) {
        return;
      }
    }

    deleteFragment();
  };

  return editor;
};
