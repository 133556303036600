import { Editor, Element, Range, Transforms } from "slate";
import { showToast } from "~lib";

export const insertImportantInfo = (editor: Editor, importantInfo: unknown) => {
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }

  if (typeof importantInfo !== "string") {
    showToast("Important info is empty", "error");
    return;
  }

  const parsedImportantInfo = JSON.parse(importantInfo);

  if (Element.isElementList(parsedImportantInfo)) {
    Transforms.insertNodes(editor, parsedImportantInfo, {
      at: blurSelection,
    });
  } else {
    showToast("Invalid important info format", "error");
  }
};
