import { get, intersection, map } from "lodash";
import React, { createContext, useReducer, ReactNode } from "react";
import track from "~features/analytics/track";
import { MeQuery, MyLoggedInOrganizationsQuery, Role } from "~graphql/sdk";

export type UserFragment = MeQuery["me"];
type LoggedInOrgs = MyLoggedInOrganizationsQuery["myLoggedInOrganizations"];
type UserActions =
  | { type: UserActionTypes.LOGOUT }
  | { type: UserActionTypes.LOGIN; user: UserFragment }
  | { type: UserActionTypes.AUTHENTICATE_TWO_FACTOR }
  | {
      type: UserActionTypes.LOGGED_IN_ORGS;
      loggedInOrganizations?: LoggedInOrgs;
    }
  | { type: UserActionTypes.UNAUTHENTICATE_USER };
interface UserState {
  user: UserFragment;
  isLoggedIn: boolean;
  hasLoaded: boolean;
  isTwoFactorAuthenticated: boolean;
  loggedInOrganizations: LoggedInOrgs | undefined;
}

export type UserRoles = Record<keyof typeof Role, boolean>;
interface UserContextProps extends UserState {
  dispatch: (input: UserActions) => void;
  isAuth: boolean;
  isSalesOutlet: boolean;
  isEventManager: boolean;
  isSubpromoter: boolean;
  isAdmin: boolean;
  userRoles: UserRoles;
}

export enum UserActionTypes {
  LOGIN = "login",
  LOGOUT = "logout",
  AUTHENTICATE_TWO_FACTOR = "two-factor",
  LOGGED_IN_ORGS = "logged-in-orgs",
  UNAUTHENTICATE_USER = "unauthenticate-user",
}

interface Props {
  children: ReactNode;
}

const getIsTwoFactorAuthenticatedInLocalStorage = () => {
  return !!localStorage.getItem("isTwoFactorAuthenticated");
};

const setIsTwoFactorAuthenticatedInLocalStorage = () => {
  localStorage.setItem("isTwoFactorAuthenticated", "true");
};

const removeIsTwoFactorAuthenticatedInLocalStorage = () => {
  localStorage.removeItem("isTwoFactorAuthenticated");
};

const initialState: UserState = {
  isLoggedIn: false,
  user: undefined,
  hasLoaded: false,
  isTwoFactorAuthenticated: false,
  loggedInOrganizations: undefined,
};

const unauthenticatedUserState: UserState = {
  user: undefined,
  isLoggedIn: false,
  hasLoaded: true,
  isTwoFactorAuthenticated: false,
  loggedInOrganizations: undefined,
};

export const hasRoles = (
  userRoles: UserFragment["roles"],
  roles: UserFragment["roles"]
) => Boolean(get(intersection(userRoles ?? [], roles), "length"));

function reducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.UNAUTHENTICATE_USER:
      removeIsTwoFactorAuthenticatedInLocalStorage();
      return {
        ...state,
        ...unauthenticatedUserState,
      };
    case UserActionTypes.LOGOUT:
      removeIsTwoFactorAuthenticatedInLocalStorage();
      track.logout();

      return {
        ...state,
        ...unauthenticatedUserState,
      };
    case UserActionTypes.LOGIN:
      track.login(action.user);

      return {
        ...state,
        user: action.user,
        isLoggedIn: true,
        hasLoaded: true,
        isTwoFactorAuthenticated: getIsTwoFactorAuthenticatedInLocalStorage(),
      };
    case UserActionTypes.AUTHENTICATE_TWO_FACTOR:
      setIsTwoFactorAuthenticatedInLocalStorage();
      return {
        ...state,
        isTwoFactorAuthenticated: true,
      };
    case UserActionTypes.LOGGED_IN_ORGS:
      return {
        ...state,
        loggedInOrganizations: action.loggedInOrganizations,
      };
    default:
  }
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

const UserProvider = ({ children }: Props) => {
  const [
    {
      user,
      isLoggedIn,
      hasLoaded,
      isTwoFactorAuthenticated,
      loggedInOrganizations: slugs,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const isAuth = !!user;

  const isAdmin = hasRoles(user?.roles, [Role.Admin, Role.Superadmin]);

  return (
    <UserContext.Provider
      value={{
        user,
        isAuth,
        dispatch,
        isLoggedIn,
        hasLoaded,
        isTwoFactorAuthenticated,
        loggedInOrganizations: slugs,
        isSalesOutlet: hasRoles(user?.roles, [Role.SalesOutlet]) && !isAdmin,
        isEventManager: hasRoles(user?.roles, [Role.EventManager]) && !isAdmin,
        isAdmin,
        isSubpromoter: hasRoles(user?.roles, [Role.SubPromoter]) && !isAdmin,
        userRoles: generateUserRoles(user?.roles, Role),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };

export function generateUserRoles(
  userRoles: UserFragment["roles"] = [],
  allRoles: typeof Role
) {
  return (Object.keys(allRoles) as (keyof typeof Role)[]).reduce((acc, key) => {
    const role = Role[key];
    if (!["Superadmin", "Admin"].includes(key)) {
      acc[key] =
        hasRoles(userRoles, [role]) &&
        !hasRoles(userRoles, [Role.Admin, Role.Superadmin]);
    } else {
      acc[key] = hasRoles(userRoles, [role]);
    }
    return acc;
  }, {}) as UserContextProps["userRoles"];
}
