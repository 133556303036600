import axios from "axios";
import { Flex, Text } from "flicket-ui";
import objectFitImages from "object-fit-images";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SWRConfig } from "swr";
import { setLocale } from "yup";

import { Providers, SuperAdminTopBar } from "~components";
import { apiUrl, domainAllowsSessionFeatureFlags } from "~config";
import { sdk } from "~lib";
import { getError, handlePromise } from "~lib/helpers";
import CSSreset from "~styles/CSSreset";
import i18next from "i18next";
import { NextPage, NextPageContext } from "next";
import { AppProps } from "next/app";
import { initReactI18next } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css/swiper.css";
import { serverSideUserAgent } from "~config/serverSideUserAgent";
import { useThirdpartyScripts } from "~features/analytics/useThirdpartyScripts";
import { CurrentOrganizationOutput, I18nOutput } from "~graphql/sdk";
import { i18n as i18nTimezone } from "~lib/i18n";
import "../../public/static/fonts/stylesheet.css";
import FeatureFlagAdminPanel from "~components/FeatureFlagAdminPanel/FeatureFlagAdminPanel";

axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;

setLocale({
  mixed: {
    required: "This field is required",
    notType: "Invalid value",
  },
  string: {
    email: "Invalid email address",
  },
});

toast.configure({
  position: "bottom-right",
  autoClose: 4000,
  closeButton: false,
  hideProgressBar: true,
});

type AppPropsCommon = {
  organization?: CurrentOrganizationOutput;
  i18n?: I18nOutput;
  organizationError?: string;
};
export interface AppPageProps extends AppProps {
  props: AppPropsCommon;
}

const setUpI18n = (
  organization?: CurrentOrganizationOutput,
  i18n?: I18nOutput
) => {
  if (organization?.timezone && i18n?.data) {
    i18nTimezone.timezone = organization.timezone ?? i18nTimezone.timezone;
    i18nTimezone.locale = organization.locale ?? i18nTimezone.locale;
    if (!i18next.isInitialized) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      void i18next.use(initReactI18next).init({
        lng: organization.defaultI18nLanguage,
        fallbackLng: "en_default",
        debug: process.env.NODE_ENV !== "production",
        resources: i18n.data,
      });
    }
  }
};

const App = ({ Component, pageProps, props }: AppPageProps) => {
  // The organization is fetched on the server and sent to the client via rendered HTML and serialized data
  // for rehydration. When this component frst runs on the client we need to store the value for subsequent client side transitions.
  const [organization, setOrganization] = useState<CurrentOrganizationOutput>(
    props?.organization
  );

  useThirdpartyScripts();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    objectFitImages();
  }, []);

  let content;

  if (props?.organizationError) {
    content = (
      <Flex minH="100vh" variant="center" flexDir="column">
        <Text variant="title.M">Error loading organization</Text>
        <Text variant="title.XS">{props?.organizationError}</Text>
      </Flex>
    );
  } else {
    setUpI18n(organization, props?.i18n);
    content = <Component {...pageProps} {...props} />;
  }

  return (
    <Providers organization={organization} setOrganization={setOrganization}>
      <SWRConfig
        value={{
          fetcher: async (url, params) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-return
            axios(url, { params }).then((res) => res.data),
          revalidateOnFocus: false,
        }}
      >
        <CSSreset />
        <SuperAdminTopBar />

        {/* Debugging panel for feature flag toggling */}
        {domainAllowsSessionFeatureFlags && <FeatureFlagAdminPanel />}

        {content}
      </SWRConfig>
    </Providers>
  );
};

App.getInitialProps = async ({
  Component,
  ctx,
}: {
  Component: NextPage;
  ctx: NextPageContext;
}) => {
  const { req } = ctx;

  // Check if we are running on the server
  // https://github.com/vercel/next.js/issues/2946#issuecomment-329235139
  const isServer = !!req;

  const props: AppPropsCommon = {};

  if (isServer) {
    const hostHeader = req?.headers
      ? `https://${
          (req.headers["x-forwarded-host"] || req.headers.host) as string
        }`
      : "";

    try {
      const { currentOrganization } = await sdk({
        host: hostHeader,
        key: "user-agent",
        value: serverSideUserAgent,
      }).currentOrganization();
      props.organization = currentOrganization as CurrentOrganizationOutput;
      if (props.organization) {
        const [, getI18nData] = await handlePromise(async () =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
          sdk({
            host: hostHeader,
            key: "user-agent",
            value: serverSideUserAgent,
          }).getI18n()
        );
        props.i18n = getI18nData.getI18n;
      }
    } catch (error) {
      props.organizationError = getError(error, "graphQL");
    }
  }
  if (Component.getInitialProps) {
    const cmpProps = await Component.getInitialProps(ctx);
    Object.assign(props, cmpProps);
  }
  return { props };
};

export default App;
