import { Stack, useScreenSize } from "flicket-ui";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { FilterConnectedDateRangeSelect } from "~features/reports/components/ReportOptionSelects";
import { DesktopExtraFilters } from "./ExtraFilters";

export const reportingFiltersAtom = atom<boolean>(false);

export const ReportingFilters = () => {
  const screenSizes = useScreenSize();
  const isMobile = screenSizes.isTabletPortraitDown;
  const [, setShowFilters] = useAtom(reportingFiltersAtom);

  useEffect(() => {
    setShowFilters(true);
    return () => setShowFilters(false);
  }, []);

  return (
    <Stack gap={1}>
      <FilterConnectedDateRangeSelect
        maxWidth={isMobile ? "100%" : 380}
        height={"48px"}
      />

      {!isMobile && <DesktopExtraFilters />}
    </Stack>
  );
};
