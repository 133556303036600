import { Flex, Stack, SystemProps, Text } from "flicket-ui";
import React, { ReactNode } from "react";
import { Card } from "~components/reports";
import { pick } from "@styled-system/props";

export const ReportingCard = ({
  title,
  TopRightElement,
  children,
  overflow = "hidden",
  titleWrapperProps,
  ...props
}: SystemProps & {
  title: string | JSX.Element;
  overflow?: "hidden" | "visible" | "-moz-initial" | "inherit";
  TopRightElement?: JSX.Element;
  children: ReactNode;
  titleWrapperProps?: SystemProps;
}) => {
  return (
    <Card
      display="block"
      overflow={overflow}
      title={
        <Flex
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
          flexWrap={"wrap"}
          {...titleWrapperProps}
        >
          <Text variant={"header.M"}>{title}</Text>

          <Stack
            justifyContent="space-between"
            gap="6/4"
            marginLeft={"auto" as any}
          >
            {TopRightElement}
          </Stack>
        </Flex>
      }
      pr={{ _: 2, md: 4 }}
      position="relative"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      {...pick(props)}
    >
      {children}
    </Card>
  );
};
