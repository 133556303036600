import { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { Box } from "flicket-ui";
import isUrl from "is-url";
import { Editor, Range, Transforms } from "slate";
import { RenderElementProps, useSlate } from "slate-react";
import styled from "styled-components";
import { Icon } from "~components";
import { Button } from "./components";
import { linkAndButtonModalAtom } from "./InsertModal";
import { addProtocolToURL } from "~lib/helpers/addProtocolToURL";
import { Link } from "@phosphor-icons/react";
import { LinkElement, SuggestedLinkType } from "./interface/slate.interface";
import { isLinkElement } from "./interface/slate.predicates";
import { LinkPopover } from "./Popover/LinkPopover";
import { usePopover } from "./Popover/usePopover";
import { createPortal } from "react-dom";
import { StyledPopover } from "./Popover/Popover";
import { useAtom } from "jotai";

export const withLinks = (editor: Editor) => {
  const { insertData, insertText, isInline, isVoid } = editor;
  let blurSelection = editor.blurSelection;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, {
        url: text,
        at: blurSelection,
        content: text,
      });
    } else {
      insertText(text);
    }
  };

  editor.isVoid = (element) => {
    return element.type === "link" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, {
        url: text,
        at: blurSelection,
        content: text,
      });
    } else {
      insertData(data);
    }
  };

  return editor;
};

const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, { match: (n) => isLinkElement(n) });
  return !!link;
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, { match: (n) => isLinkElement(n) });
};

export const removeLink = (editor: Editor) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }
};

type WrapLinkOptions = {
  url?: string;
  at: Range;
  content: string;
  suggestedLink?: SuggestedLinkType;
  eventId?: string;
  releaseId?: string;
  membershipId?: string;
};

export const wrapLink = (editor: Editor, options: WrapLinkOptions) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const {
    at,
    content,
    suggestedLink,
    url,
    eventId,
    releaseId,
    membershipId,
  } = options;
  let protocolURL = url;

  const isCollapsed = Range.isCollapsed(at);

  if (url) {
    protocolURL = addProtocolToURL(url);
  }

  const link: LinkElement = {
    type: "link",
    url: protocolURL,
    content,
    suggestedLink,
    eventId,
    releaseId,
    membershipId,
    children: [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link, { at });
  } else {
    Transforms.wrapNodes(editor, link, { split: true, at });
    Transforms.collapse(editor, { edge: "end" });
  }
};

// this is used by the non-email editor
export const LinkButton = () => {
  const editor = useSlate();
  const [_, setLinkAndButtonModalState] = useAtom(linkAndButtonModalAtom);

  return (
    <>
      <Button
        w="30px"
        h="30px"
        active={isLinkActive(editor)}
        onClick={(event) => {
          event.preventDefault();

          let selectedText = "";

          const selection = editor.selection;
          if (selection) {
            const isCollapsed = Range.isCollapsed(selection);
            if (!isCollapsed) {
              selectedText = Editor.string(editor, selection);
            }
          }

          setLinkAndButtonModalState({
            isOpen: true,
            allowTogglingLinkOrButton: false,
            defaultValues: {
              displayText: selectedText,
            },
          });
        }}
      >
        <Icon icon={<Link />} />
      </Button>
    </>
  );
};

const StyledLink = styled.a<{ href: string; color: string; fontSize: string }>`
  position: relative;
  color: ${(p) => p.color} !important;
  text-decoration: underline;
  font-size: ${(p) => p.fontSize} !important;
`;

const POPOVER_HEIGHT = 45;
const MAX_LINK_WIDTH = 200;

export const LinkItem: FC<
  Pick<RenderElementProps, "attributes"> & {
    style?: CSSProperties;
    isSMS?: boolean;
    element: LinkElement;
  }
> = ({ attributes, children, style, isSMS, element }) => {
  const { content, url, variant, useLinkAsDisplayText } = element;
  const [, setLinkAndButtonModalState] = useAtom(linkAndButtonModalAtom);

  const { isPopoverOpen, popoverRef, selectedNode } = usePopover(
    "link",
    useLinkAsDisplayText
  );

  if (variant === "secondary") {
    style.color = "#666666";
    style.fontSize = "14px";
  }

  // need to attach the popover to the editor to make it clickable😥
  // https://gitlab.com/flicket-sporting/flicket-admin-panel/-/merge_requests/2819
  const containerRef = useRef<HTMLDivElement>(null);
  const closestEditor = containerRef.current?.closest<HTMLDivElement>(
    ".editor-wrapper"
  );
  const editorPage = containerRef.current?.closest<HTMLDivElement>(
    "[data-slate-editor=true]"
  );

  const popoverTop = containerRef.current?.offsetTop + POPOVER_HEIGHT;

  // 6 is the approximate width of the a character
  const urlWidth = url ? Math.min(url.length * 6, MAX_LINK_WIDTH) : 0;

  const popoverLeft =
    containerRef.current?.offsetLeft +
    containerRef.current?.clientWidth / 2 -
    // 110 is the width of the popover except url
    (110 + urlWidth) / 2 +
    editorPage?.offsetLeft -
    closestEditor?.offsetLeft;

  return (
    <Box
      style={style}
      position={"relative"}
      display="inline-flex"
      justifyContent={"center"}
      as="span"
      ref={containerRef}
    >
      <StyledLink
        {...attributes}
        href={url}
        contentEditable={true}
        color={style.color}
        fontSize={style.fontSize as string}
        suppressContentEditableWarning={true}
      >
        {content}
        {children}
      </StyledLink>

      {isPopoverOpen &&
        createPortal(
          <StyledPopover
            ref={popoverRef}
            top={`${popoverTop}px`}
            left={`${popoverLeft}px`}
            bottom="unset"
            transform="unset"
          >
            <LinkPopover
              url={url}
              onEdit={() => {
                setLinkAndButtonModalState({
                  isOpen: true,
                  element,
                });
              }}
              displayRemoveButton={
                selectedNode ? selectedNode[0]?.isRemovable !== false : true
              }
              displayUpDownButtons={!isSMS}
            />
          </StyledPopover>,
          closestEditor
        )}
    </Box>
  );
};
