import { useRouter } from "next/router";
import { Grid } from "~components";
import { NAV_ITEMS } from "~components/common/Navbar";
import { CustomerLocationsWithFilters } from "~components/reports/charts/CustomerLocationByFilters";
import { ParamsProps } from "~features/useParams/hooks/useParams";
import {
  PointReportingFilterSource,
  ReportingFilterSource,
} from "~graphql/sdk";
import {
  SalesChannelGraphDocument,
  SalesOverviewDocument,
} from "~graphql/typed-document-nodes";
import { useOrganization, usePermissions, useUser } from "~hooks";
import { useQuery } from "~hooks/useQuery";
import { Card } from "../Card";
import { formatReportingParams } from "../util";
import { SalesChannelChart } from "./SalesChannelChart";
import { ConnectedSalesChart } from "./SalesChart";
import { SalesOverview } from "./SalesOverview";

type SalesProps = {
  params: ParamsProps;
};

export const Sales = ({ params }: SalesProps) => {
  const { hasPermissions, Permission } = usePermissions();
  const { user } = useUser();
  const router = useRouter();
  const { hasFeature } = useOrganization();

  const formattedParams = formatReportingParams<
    ParamsProps,
    PointReportingFilterSource
  >(params);

  const { data: salesOverview, error: errorOverview } = useQuery(
    params.ready ? SalesOverviewDocument : null,
    formattedParams
  );

  const { data: salesChannelGraph, error: errorSalesChannelGraph } = useQuery(
    params.ready ? SalesChannelGraphDocument : null,
    formattedParams
  );

  if (!hasPermissions(Permission.ReportingSales)) {
    const firstRoute = NAV_ITEMS({ hasPermissions, user, hasFeature })[0];
    void router.replace(firstRoute.url);
    return null;
  }

  return (
    <>
      <SalesOverview
        data={salesOverview?.salesOverview}
        eventId={
          params.source === ReportingFilterSource.Event && params?.sourceId
        }
        error={errorOverview}
        mb={4}
        mt={4}
      />

      <ConnectedSalesChart params={params} />

      <Grid
        width={1}
        gridTemplateColumns={{
          _: "repeat(auto-fill, minmax(300px, 1fr))",
          sm: "repeat(auto-fill, minmax(400px, 1fr))",
        }}
      >
        <CustomerLocationsWithFilters {...params} />
        <Card title="Sales channel">
          <SalesChannelChart
            data={salesChannelGraph?.salesChannelGraph}
            error={errorSalesChannelGraph}
          />
        </Card>
      </Grid>
    </>
  );
};

export default Sales;
