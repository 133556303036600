import { ChangeEvent } from "react";

export const parseNumber = (onChange: (val: any) => void) => (
  e: ChangeEvent<HTMLInputElement>
) => onChange(parseInt(e?.target?.value?.replace(/[^\d.]/g, "") || String(0)));

const HUNDRED_PERCENT = 100;

export const convertToFixedPercentage = (value: number, decimal = 0) => {
  if (value < 0) {
    return `0%`;
  }
  return `${Math.min(value * HUNDRED_PERCENT, HUNDRED_PERCENT).toFixed(
    decimal
  )}%`;
};
