import { Check } from "@phosphor-icons/react";
import { CSSProperties } from "react";
import { components, OptionProps } from "react-select";
import { Icon, Select, SelectProps } from "~components";
import { SearchItem } from "./SourceSwitcher/SearchItem";

type Option = {
  label: string;
  value: string;
};

const Option = (props: OptionProps<Option, false>) => {
  return (
    <components.Option {...props}>
      <SearchItem
        active={props.isSelected || props.isFocused}
        postContent={props.isSelected && <Icon icon={<Check />} />}
        paddingX={"6/4"}
      >
        {props.data.label}
      </SearchItem>
    </components.Option>
  );
};

export const SelectWithSelectState = ({
  components,
  selectStyles,
  ...props
}: SelectProps) => {
  return (
    <Select
      components={{ Option, ...components }}
      selectStyles={{
        option: (): CSSProperties => ({}),
        ...selectStyles,
      }}
      {...props}
    />
  );
};
