/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  EventPromoCodeType,
  MembershipPromoCodeType,
  Order,
  OrderQuery,
} from "~graphql/sdk";

export const calculateDiscount = (
  promoCode: OrderQuery["order"]["promoCode"],
  lineItems: Order["lineItems"]["edges"][number]["node"][],
  activePromotions: any[] = []
) => {
  let discount = undefined;
  let discountItems = undefined;

  if (!promoCode) {
    return discount;
  }

  if (
    (promoCode?.__typename === "EventPromoCode" &&
      promoCode?.eventType === EventPromoCodeType.Event) ||
    (promoCode?.__typename === "MembershipPromoCode" &&
      promoCode?.membershipType === MembershipPromoCodeType.Membership)
  ) {
    discountItems = lineItems?.filter(
      ({ id, ticketType, membershipType }) =>
        (ticketType?.id || membershipType?.id) &&
        !activePromotions.find(
          ({ id: promoId, type }) => promoId === id && type === "getting"
        )
    );
  } else if (
    (promoCode?.__typename === "EventPromoCode" &&
      promoCode?.eventType === EventPromoCodeType.Addons) ||
    (promoCode?.__typename === "MembershipPromoCode" &&
      promoCode?.membershipType === MembershipPromoCodeType.Addons)
  ) {
    discountItems = lineItems?.filter(
      ({ id, eventAddon, membershipAddon }) =>
        (eventAddon?.id || membershipAddon?.id) &&
        !activePromotions.find(
          ({ id: promoId, type }) => promoId === id && type === "getting"
        )
    );
  } else {
    discountItems = lineItems?.filter(
      ({ id }) =>
        !activePromotions.find(
          ({ id: promoId, type }) => promoId === id && type === "getting"
        )
    );
  }

  /** @note should filter out the multiBuy promotions as they show as free */
  discount = discountItems.reduce(
    (acc, curr) => acc + (curr.originalPrice - curr.price) * curr.quantity,
    0
  );

  return discount;
};
