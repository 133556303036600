import { addDays, endOfDay, startOfDay, subDays } from "date-fns";
import {
  OrderChannel,
  PointReportingFilterSource,
  ReportingFilterSource,
  ReportingFinancialSalesBreakdownSource,
} from "~graphql/sdk";
import { OrderStatus } from "~graphql/typed-document-nodes";

const mapEnumToOptions = (obj: Record<string, string>) =>
  Object.values(obj).map((value) => ({ label: value, value }));

export const OPTION_ALL = "all";

export const CHANNEL_OPTIONS = [
  { label: "All channels", value: OPTION_ALL },
  { label: "Online", value: OrderChannel.Online },
  { label: "Back office", value: OrderChannel.BackOffice },
  { label: "POS", value: OrderChannel.Pos },
];

const OPTION_ALL_NULL_VALUE = { label: "All channels", value: null };
export const CHANNEL_OPTIONS_NULL_ALL = [...CHANNEL_OPTIONS];
CHANNEL_OPTIONS_NULL_ALL[0] = OPTION_ALL_NULL_VALUE;

export const SOURCE_LIST = {
  ...ReportingFilterSource,
  ...ReportingFinancialSalesBreakdownSource,
  ...PointReportingFilterSource,
};

export const today = new Date();
today.setHours(0, 0, 0, 0);

export const DEFAULT_DATES = {
  startDate: startOfDay(subDays(today, 31)),
  endDate: endOfDay(addDays(today, 1)),
};

export const ORDER_STATUS_OPTIONS = [
  ...mapEnumToOptions(OrderStatus),
  { label: "Refunded", value: "refunded" },
  { label: "Partially Refunded", value: "partialRefunded" },
];

export const ORDER_TYPES = [
  { label: "All", value: null },
  { label: "Comp", value: true },
  { label: "Standard", value: false },
];
