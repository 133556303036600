import { FC } from "react";
import { Flex, SystemProps, Text } from "flicket-ui";
import { Status } from "~components";
import { SalesOverviewQuery } from "~graphql/sdk";
import { Card, OmitTypename } from "../";
import { IconCardGrid, OverviewItem } from "../IconCardGrid";
import { GraphQLError } from "graphql";
import PostEventReportDownload from "~features/reports/postevent/hooks/usePostEventReportDownload";

type SalesOverviewData = OmitTypename<SalesOverviewQuery["salesOverview"]>;

interface Props {
  data: SalesOverviewData;
  eventId?: string;
  error: GraphQLError;
}

export const overviewItems: OverviewItem<SalesOverviewData>[] = [
  { title: "Tickets issued", icon: "my-tickets", key: "ticketsIssued" },
  { title: "Total tickets sold", icon: "my-tickets", key: "ticketsSold" },
  { title: "Total comps issued", icon: "my-tickets", key: "ticketsComp" },
  {
    title: "Ticket protection tickets",
    icon: "add-cart",
    key: "ticketCoverTicketsIssued",
  },
  {
    title: "Ticket protection memberships",
    icon: "add-cart",
    key: "ticketCoverMembershipsIssued",
  },
  { title: "Memberships issued", icon: "membership", key: "membershipsIssued" },
  {
    title: "Memberships renewed",
    icon: "membership",
    key: "membershipsRenewed",
  },
  {
    title: "Tickets resold",
    icon: "membership",
    key: "ticketsTransferred",
  },
  { title: "Add-ons issued", icon: "add-cart", key: "addonsIssued" },
  { title: "Orders", icon: "orders", key: "orders" },
  {
    title: "Ticket protection orders",
    icon: "add-cart",
    key: "ticketCoverOrdersSold",
  },
  { title: "Cart abandonment", icon: "cart", key: "cartAbandonment" },
  { title: "Cart recovered", icon: "cart", key: "cartRecovered" },
  {
    title: "Returning customers",
    icon: "customer",
    key: "returningCustomers",
  },
  { title: "Packages sold", icon: "redeem", key: "packagesSold" },
  {
    title: "Points distributed",
    icon: "redeem",
    key: "pointsDistributed",
    description: "Number of points sold via packages.",
  },
  {
    title: "Part paid tickets",
    icon: "my-tickets",
    key: "ticketsPartPaid",
  },
  {
    title: "Part paid memberships",
    icon: "my-tickets",
    key: "membershipsPartPaid",
  },
  {
    title: "Part paid orders",
    icon: "orders",
    key: "ordersPartPaid",
  },
];

export const SalesOverview: FC<Props & SystemProps> = ({
  data,
  eventId,
  error,
  ...props
}) => {
  return (
    <>
      <Card
        title={
          <Flex
            justifyContent={"space-between"}
            width={"100%"}
            pr={{ _: "6/4", md: 0 }}
            flexDirection={{ _: "column", md: "row" }}
          >
            <Text variant={"header.M"} mb={{ _: 3, md: 0 }}>
              Sales overview
            </Text>
            <PostEventReportDownload eventId={eventId} />
          </Flex>
        }
        pr={{ _: 0, md: 4 }}
        position="relative"
        {...props}
      >
        <Status loading={!error && !data} error={error}>
          <IconCardGrid
            variant="sales"
            items={overviewItems}
            data={data}
            getDataValue={(data, key) => data[key].toLocaleString()}
            hideZeroValues={true}
          />
        </Status>
      </Card>
    </>
  );
};

export default SalesOverview;
