import { Flex, system, SystemProps } from "flicket-ui";
import styled, { css } from "styled-components";

export interface DividerProps extends SystemProps {
  /** @default horizontal */
  direction?: "vertical" | "horizontal";
}

export const Divider = styled(Flex)<DividerProps>`
  background: ${(p) => p.theme.colors.N200};

  ${(p) =>
    p.direction === "vertical"
      ? css`
          margin: 0 24px;
          width: 1px;
          height: 24px;
        `
      : css`
          margin: 24px 0;
          width: 100%;
          height: 1px;
        `}

  ${system}
`;
