import { Flex, SystemProps } from "flicket-ui";
import React from "react";
import { HistoryFragment } from "~graphql/sdk";

interface AvatarProps extends SystemProps {
  user: HistoryFragment["user"];
}

export const Avatar = ({ user, ...props }: AvatarProps) => (
  <Flex
    borderRadius="full"
    alignItems="center"
    justifyContent="center"
    color="white"
    bg="N500"
    fontSize={4}
    width={48}
    height={48}
    mr={2}
    flexShrink={0}
    fontWeight="extraBold"
    {...props}
  >
    {user?.firstName?.substring(0, 1)}
    {user?.lastName?.substring(0, 1)}
  </Flex>
);
