import { SystemProps, TertiaryButton } from "flicket-ui";
import { useState } from "react";
import { ToastType } from "react-toastify";
import CustomModal from "~components/common/CustomModal";
import { apiUrl } from "~config";
import { useOrganization, useUser } from "~hooks";
import { downloadFile, showToast } from "~lib";
import {
  FormValues,
  PostEventReportFilterForm,
} from "../components/PostEventReportFilterForm";

export type DownloadButtonProps = {
  onClick: () => void;
  isLoading: boolean;
};

export const downloadPostEventReport = async (
  orgId: string,
  eventId: string,
  values: FormValues,
  onLoading?: () => void,
  onDone?: () => void
) => {
  try {
    const { dates, locationLevel } = values;

    const query = new URLSearchParams({
      locationFilterTypes: locationLevel,
      startDate: new Date(dates[0]).toISOString(),
      endDate: new Date(dates[1]).toISOString(),
    }).toString();

    if (process.env.NODE_ENV === "development") {
      window.open(`/reports/event/${eventId}/overview?${query}`);
    }

    onLoading?.();

    const url = `${apiUrl}/export/post-event-report/${eventId}?flicket-org-id=${orgId}&${query}`;

    const res = await fetch(url, {
      credentials: "include",
      method: "GET",
    });

    if (!res.ok) {
      throw new Error(`HTTP status code: ${res.status}`);
    }

    const blob = await res.blob();

    downloadFile(
      window.URL.createObjectURL(blob),
      `event-report-${Date.now()}.pdf`
    );
  } catch (err) {
    showToast("Failed to generate this report", ToastType.ERROR);
  } finally {
    onDone?.();
  }
};

export default function PostEventReportDownload(props: {
  eventId?: string;
  downloadButton?: (props: DownloadButtonProps) => JSX.Element;
  modalMaxWidth?: SystemProps["maxWidth"];
}) {
  const { eventId, downloadButton, modalMaxWidth: modalWidth } = props;
  const { organization } = useOrganization();
  const { isAdmin } = useUser();
  const [loading, setLoading] = useState(false);
  const showPostEventReportButton = eventId && isAdmin;

  const [isOpen, setIsOpen] = useState(false);

  const openDownloadModal = () => setIsOpen(true);

  const DefaultDownloadButton = ({
    onClick,
    isLoading,
  }: DownloadButtonProps) => (
    <TertiaryButton color="N700" onClick={onClick} isLoading={isLoading}>
      Download event report
    </TertiaryButton>
  );

  const DownloadButton = downloadButton ?? DefaultDownloadButton;

  return !showPostEventReportButton ? null : (
    <>
      <DownloadButton isLoading={loading} onClick={openDownloadModal} />
      <CustomModal
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        dialogContentProps={{
          maxWidth: modalWidth ?? { _: "100%", sm: "370px" },
        }}
        overflowY={"visible"}
      >
        <PostEventReportFilterForm
          eventId={eventId}
          _onSubmit={async (values) =>
            downloadPostEventReport(
              organization.id,
              eventId,
              values,
              () => setLoading(true),
              () => {
                setIsOpen(false);
                setLoading(false);
              }
            )
          }
        />
      </CustomModal>
    </>
  );
}
