import React from 'react';
import { Flex, SystemProps, system } from 'flicket-ui';

import styled from 'styled-components';

import { Icon } from '~components';

export const ToggleWrapper = styled.input.attrs({ as: 'label' })<SystemProps>`
  position: relative;
  font-size: ${(p) => p.theme.fontSizes[2]};
  font-weight: ${(p) => p.theme.fontWeights.extraBold};
  flex-shrink: 0;
  line-height: ${(p) => p.theme.lineHeights.normal};
  border-radius: ${(p) => p.theme.radii.sm};
  display: flex;
  align-items: 'center';
  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.07),
    0px 1.6711px 12.1155px rgba(0, 0, 0, 0.0503198),
    0px 0.893452px 6.47753px rgba(0, 0, 0, 0.0417275),
    0px 0.500862px 3.63125px rgba(0, 0, 0, 0.035),
    0px 0.266004px 1.92853px rgba(0, 0, 0, 0.0282725),
    0px 0.11069px 0.802504px rgba(0, 0, 0, 0.0196802);

  input {
    appearance: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  span {
    width: 100%;
    height: 100%;
    padding: 12px 16px;
    border-radius: ${(p) => p.theme.radii.sm};
    transition: all 0.2s ease;

    color: ${(p) => p.theme.colors.N600};
    user-select: none;
    .checked-icon {
      display: none;
    }
  }

  input:checked + span {
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.N600};

    .checked-icon {
      display: inline-flex;
    }
  }

  ${system}
`;

export const ToggleLabel = ({ children }) => {
  return (
    <Flex as="span" variant="center">
      <Icon
        className="checked-icon"
        icon="check"
        fontSize={1}
        color="white"
        mr="1/2"
      />
      {children}
    </Flex>
  );
};
