import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import { useSlate } from "slate-react";
import Modal from "~components/common/CustomModal/CustomModal";
import InsertMerchandiseForm, {
  InsertMerchandiseFormProps,
} from "./InsertMerchandiseForm";
import { MerchandiseElement, SelectOption } from "../interface/slate.interface";
import {
  editMerchandise,
  insertMerchandise,
} from "~components/common/RichText/Merchandise";

interface InsertMerchandiseModalProps extends InsertMerchandiseFormProps {
  isOpen: boolean;
}

export const merchandiseModalState = atomWithReset<{
  isOpen: boolean;
  merchandise: MerchandiseElement;
}>({
  isOpen: false,
  merchandise: null,
});

function InsertMerchandiseModal(props: InsertMerchandiseModalProps) {
  const { isOpen, close, onSubmit, onUpdate, afterSubmit, merchandise } = props;

  return (
    <Modal isOpen={isOpen} close={close} small overflowY={"visible"}>
      {isOpen && (
        <InsertMerchandiseForm
          close={close}
          onSubmit={onSubmit}
          afterSubmit={afterSubmit}
          merchandise={merchandise}
          onUpdate={onUpdate}
        />
      )}
    </Modal>
  );
}

export default function SlateInsertMerchandiseModal() {
  const [modalState] = useAtom(merchandiseModalState);
  const resetModalState = useResetAtom(merchandiseModalState);

  const editor = useSlate();

  const close = () => {
    resetModalState();
  };

  const afterSubmit = close;

  const onSubmit = (values: SelectOption[]) => {
    const newMerchandise: MerchandiseElement = {
      type: "merchandise",
      children: [],
      items: values,
    };

    insertMerchandise(editor, newMerchandise);
    close();
  };

  const onUpdate = (values: SelectOption[]) => {
    const newMerchandise: MerchandiseElement = {
      type: "merchandise",
      children: [],
      items: values,
    };

    editMerchandise(editor, newMerchandise);
    close();
  };

  return (
    <InsertMerchandiseModal
      merchandise={modalState.merchandise}
      isOpen={modalState.isOpen}
      close={close}
      afterSubmit={afterSubmit}
      onSubmit={onSubmit}
      onUpdate={onUpdate}
    />
  );
}
