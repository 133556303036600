import { useQuery } from "~hooks/useQuery";
import { InsightsHeardAboutUsBreakDownDocument } from "~graphql/typed-document-nodes";
import { ReportingCard } from "~features/reports/reporting/components/dashboards/ReportingCard";
import { Box, Stack, SystemProps, Text } from "flicket-ui";
import React from "react";
import styled from "styled-components";
import { List } from "~components/common/List/List";
import { useScreenSize } from "~hooks/useScreenSize";
import { PercentBar } from "~components/common/PercentBar";
import { REPORT_COLOURS } from "~components/reports/common/reportColours";
import { AudienceGoogleButton } from "~features/reports/reporting/components/dashboards/audience/audience.google";
import {
  BaseReportParams,
  narrowPointReportingFilterSource,
} from "~features/reports/reporting/types";

export const StyleList = styled(List)`
  li:first-child:before {
    background: none;
  }
`;

const Grid = styled(Box)<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 2fr 6fr 2fr;
  grid-template-rows: 1fr;
  grid-gap: ${(props) => (props.isMobile ? "4px" : "10px")};
  justify-content: space-between;
`;

export const AudienceHeard = ({ params }: BaseReportParams) => {
  const isMobile = useScreenSize().isPhonePortrait;

  const { data, error, isLoading } = useQuery(
    InsightsHeardAboutUsBreakDownDocument,
    {
      ...params,
      source: narrowPointReportingFilterSource(params.source),
    }
  );
  if (isLoading || error || data?.insightsHeardAboutUsBreakDown.length === 0) {
    return <></>;
  }

  const total = data?.insightsHeardAboutUsBreakDown.reduce(
    (acc, item) => acc + item.count,
    0
  );

  return (
    <ReportingCard
      title={"Heard about you"}
      TopRightElement={<AudienceGoogleButton />}
    >
      <Stack direction={"vertical"}>
        {data.insightsHeardAboutUsBreakDown.map((item) => (
          <StyleList key={item.heardAboutUs}>
            <List.BaseItem key={`heard-${item.heardAboutUs}`}>
              <Grid isMobile={isMobile}>
                <Text textAlign={"start"} gridColumn={1}>
                  {item.heardAboutUs}
                </Text>
                <PercentBar
                  minHeight={"24px"}
                  maxHeight={"24px"}
                  gridColumn={2}
                  percentage={(item.count / total) * 100}
                  barColour={
                    REPORT_COLOURS.teal as SystemProps["backgroundColor"]
                  }
                  borderRadius={"none"}
                />
                <Text textAlign={"end"} gridColumn={3}>
                  {item.count}
                </Text>
              </Grid>
            </List.BaseItem>
          </StyleList>
        ))}
      </Stack>
    </ReportingCard>
  );
};
