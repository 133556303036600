import { useEffect } from "react";
import { hotjar } from "react-hotjar";

interface UseThirdpartyScriptsProps {
  gtmId?: string; // Goggle tag manager Id
  pixelId?: string; // Facebook pixel Id
}

const ENABLE_TRACKING_SCRIPTS = process.env.NODE_ENV === "production";
const HOTJAR_ID = process.env.HOTJAR_PRODUCTION_ID;

export const useThirdpartyScripts = ({
  gtmId,
  pixelId,
}: UseThirdpartyScriptsProps = {}) => {
  useEffect(() => {
    if (ENABLE_TRACKING_SCRIPTS && HOTJAR_ID && typeof window !== "undefined") {
      hotjar.initialize((HOTJAR_ID as unknown) as number, 6);
    }
  }, []);

  useEffect(() => {
    if (ENABLE_TRACKING_SCRIPTS && typeof window !== "undefined") {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
          j: any = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-MSK3NHN");
    }
  }, []);
};
