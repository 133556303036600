import { Stack } from "flicket-ui";
import { useRef, useState } from "react";
import { ShareFat } from "@phosphor-icons/react";
import { useOnClickOutside } from "usehooks-ts";
import { FilterDropdownBox } from "./FilterDropdownBox";
import { AppliedFilter } from "./AppliedFilter";
import useFilterContext, {
  FilterActionTypes,
} from "~features/reports/reporting/hooks/useFiltersContext";
import { useReleaseFilterState } from "~features/reports/reporting/hooks/useReleaseOptions";

export const ReleaseFilter = () => {
  const { dispatch, getFilterShow } = useFilterContext();
  if (!getFilterShow("releaseId")) return null;

  const {
    isLoading,
    releaseGroupedOptions,
    currentReleaseName,
    releaseIdState,
    releaseOptions,
  } = useReleaseFilterState();

  const ref = useRef<HTMLElement>(null);
  // automatically open the dropdown if no release is selected
  const [isOpen, setIsOpen] = useState(releaseIdState ? false : true);

  const handleCancelFilter = () =>
    dispatch({ type: FilterActionTypes.CLEAR_FILTER, filter: "releaseId" });

  useOnClickOutside(ref, () => {
    setIsOpen(false);
    // remove the filter if no release is selected
    if (!releaseIdState) {
      handleCancelFilter();
    }
  });

  const onSelectChange = (releaseId: string) => {
    dispatch({
      type: FilterActionTypes.SET_FILTERS,
      filters: {
        releaseId,
      },
    });
    setIsOpen(false);
  };

  const openDropdownBox = () => setIsOpen(true);

  return (
    <Stack direction={"vertical"} position={"relative"}>
      <AppliedFilter
        icon={<ShareFat color="N800" />}
        onClick={openDropdownBox}
        textContent={currentReleaseName ? currentReleaseName : "Release"}
        removeFilter={handleCancelFilter}
        showRemoveIcon={releaseIdState !== undefined}
      />

      {isOpen && (
        <FilterDropdownBox
          isLoading={isLoading}
          options={releaseGroupedOptions}
          ref={ref}
          title="Sale release"
          onSelectChange={onSelectChange}
          value={
            releaseOptions.find((option) => option.value === releaseIdState) ??
            releaseOptions[0]
          }
          placeholder={"Select releases"}
        />
      )}
    </Stack>
  );
};
