import React, { FC } from "react";

import { theme, formatPrice } from "flicket-ui";
import { formatDate } from "~lib/helpers";

import { BarChart } from "../charts";
import { GraphDateItem } from "~graphql/sdk";

export const financialGraphOptions = {
  y: {
    tickFormatter: (val) => `$${val.toFixed(2).toLocaleString()}`,
  },
  x: {
    tickFormatter: (val) => {
      const date = new Date(val);
      date.setHours(0, 0, 0, 0);
      return formatDate(date, "d MMM yyyy");
    },
  },
  tooltip: {
    formatter: (val) => formatPrice(val),
  },
};

type FinancialsChartProps = {
  data: GraphDateItem[];
  colors?: {
    totalOrderGross: string;
    totalOrderBookingFees: string;
    totalTransactionFees: string;
    totalDeliveryFees: string;
  };
};

export const FinancialsChart: FC<FinancialsChartProps> = ({ data, colors }) => {
  const bars = [
    {
      name: "Gross sales",
      dataKey: "totalOrderGross",
      fill: colors?.totalOrderGross ?? theme.colors.S300,
    },
    {
      name: "Service fees",
      dataKey: "totalOrderBookingFees",
      fill: colors?.totalOrderBookingFees ?? theme.colors.S200,
    },
    {
      name: "Transaction costs",
      dataKey: "totalTransactionFees",
      fill: colors?.totalTransactionFees ?? theme.colors.P300,
    },
    {
      name: "Delivery costs",
      dataKey: "totalDeliveryFees",
      fill: colors?.totalDeliveryFees ?? theme.colors.P200,
    },
  ];

  // Transform from [{ key: grossSales, value: 100}] -> { date: date, grossSales: 100, netSales: 100 }
  const formatGraphData = (dates: GraphDateItem[]) =>
    dates?.map(({ date, items }) => {
      const obj = { date };
      for (const item of items) {
        obj[item.key] = item.value ?? 0;
      }
      return obj;
    });

  return (
    <BarChart
      data={formatGraphData(data)}
      options={financialGraphOptions}
      items={bars}
    />
  );
};
