import { PencilSimple, Trash } from "@phosphor-icons/react";
import { Stack, Text } from "flicket-ui";
import { useAtom } from "jotai";
import { FC } from "react";
import { Editor } from "slate";
import { RenderElementProps, useSlate } from "slate-react";
import styled from "styled-components";
import { Icon } from "~components";
import { useOrganization } from "~hooks";
import { textColourFromLuminance } from "~lib/helpers/colours";
import { Button } from "./components";
import { linkAndButtonModalAtom } from "./InsertModal";
import { ButtonElement } from "./interface/slate.interface";
import { LinkPopover } from "./Popover/LinkPopover";
import { StyledPopover } from "./Popover/Popover";
import { usePopover } from "./Popover/usePopover";

export const withButton = (editor: Editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "button" ? true : isVoid(element);
  };

  return editor;
};

interface ButtonToolbarButtonProps {
  buttonElement: ButtonElement;
}

export function EditButtonToolbarButton(props: ButtonToolbarButtonProps) {
  const { buttonElement } = props;
  const [, setLinkAndButtonModalState] = useAtom(linkAndButtonModalAtom);

  return (
    <>
      <Button
        onClick={() => {
          setLinkAndButtonModalState({
            element: buttonElement,
            isOpen: true,
            allowTogglingLinkOrButton: false,
          });
        }}
      >
        <Icon icon={<PencilSimple />} mr={"1/2"} />
        <Text variant="regular">Edit</Text>
      </Button>
    </>
  );
}

export function DeleteButtonToolbarButton(props: ButtonToolbarButtonProps) {
  const editor = useSlate();

  return (
    <Button
      onClick={() => {
        editor.deleteBackward("block");
      }}
    >
      <Icon icon={<Trash />} mr={"1/2"} />
      <Text variant="regular">Delete</Text>
    </Button>
  );
}

const StyledLink = styled.a<{
  $backgroundColor: string;
  $primaryColor: string;
  $border: string;
  $isPopoverOpen: boolean;
}>`
  &&& {
    text-decoration: none;
    font-weight: bold;
    border-radius: 3px;
    background-color: ${(p) => p.$backgroundColor};
    padding: 12px 18px;
    border: ${(p) => p.$border};
    display: inline-block;
    box-shadow: ${(p) =>
      p.$isPopoverOpen ? `0 0 0 2px ${p.theme.colors.N800}` : "none"};
    color: ${(p) => {
      if (p.$backgroundColor) {
        return textColourFromLuminance(p.$backgroundColor);
      }

      return p.$primaryColor;
    }};
  }
`;

export const RichTextInsertButton: FC<
  Pick<RenderElementProps, "attributes"> & {
    element: ButtonElement;
    color: string;
  }
> = ({ attributes, children, color, element }) => {
  const {
    content,
    suggestedLink,
    eventId,
    releaseId,
    membershipId,
    variant,
    align,
  } = element;
  let { url } = element;
  if (suggestedLink) {
    url = undefined;
  }
  const [, setLinkAndButtonModalState] = useAtom(linkAndButtonModalAtom);
  const { organization } = useOrganization();
  const primaryColor = organization?.branding?.primaryColor;
  const { isPopoverOpen, popoverRef, selectedNode } = usePopover("button");

  let backgroundColor = color;
  let border = "none";
  if (variant === "secondary") {
    backgroundColor = "#ffffff";
    border = "1px solid #cccccc";
  }

  let justifyContent = "center";
  if (align === "left") {
    justifyContent = "flex-start";
  } else if (align === "right") {
    justifyContent = "flex-end";
  }

  return (
    // added contentEditable={false} and userSelect="none" to prevent the "Cannot resolve a Slate point from DOM point" error
    <div
      contentEditable={false}
      style={{
        userSelect: "none",
      }}
    >
      <Stack position={"relative"} justifyContent={justifyContent}>
        <StyledLink
          {...attributes}
          $backgroundColor={backgroundColor}
          $primaryColor={primaryColor}
          $isPopoverOpen={isPopoverOpen}
          $border={border}
        >
          {children}
          {content}
        </StyledLink>
        {isPopoverOpen && (
          <StyledPopover ref={popoverRef}>
            <LinkPopover
              url={url}
              onEdit={() => {
                setLinkAndButtonModalState({
                  element,
                  isOpen: true,
                  allowTogglingLinkOrButton: true,
                });
              }}
              displayRemoveButton={
                selectedNode ? selectedNode[0]?.isRemovable !== false : true
              }
            />
          </StyledPopover>
        )}
      </Stack>
    </div>
  );
};
