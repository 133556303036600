import {
  Box,
  Divider,
  Flex,
  formatPrice,
  Price,
  SecondaryButton,
  Text,
} from "flicket-ui";
import { orderBy } from "lodash";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import { Modal } from "~components";
import CustomModal from "~components/common/CustomModal";
import {
  getPointsDisplayLabel,
  getPointsQuantityFromOrder,
} from "~features/points/helpers";
import {
  DiscountType,
  FeeType,
  GetReferralCampaignByOrderIdQuery,
  GetReferralRewardByOrderIdQuery,
  LineItem,
  OrderQuery,
  OrderStatus,
  OrderType,
  ShippingOption,
  Ticket,
  TicketStatus,
  TransferFeeType,
  VenueSeating,
} from "~graphql/sdk";
import { TransferredMembershipTicketsForOrderDocument } from "~graphql/typed-document-nodes";
import {
  useNonSeatedMultiBuy,
  useOrganization,
  usePermissions,
  useSeatedMultibuy,
} from "~hooks";
import { ActivePromotion, SeatWithPromotion } from "~hooks/multibuy/types";
import { useQuery } from "~hooks/useQuery";
import { calculateDiscount } from "~lib/helpers/finance";
import { ChangeSeatsModalContent } from "./ChangeSeatsModalContent";
import { EditModalContent } from "./edit/EditModalContent";
import { getMultibuyInfo } from "./getMultibuyInfo";
import { OrderItem } from "./OrderItem";
import RefundModalContent from "./refund/RefundModalContent";
import { TicketCover } from "./TicketCover";
import { TicketFees } from "./TicketFees";
import { TransferModal } from "./transfer/Modal";

const Title = styled(Text).attrs({
  fontSize: 3,
  fontWeight: "extraBold",
})``;

const formatDeliveryMethod = (deliveryMethod: ShippingOption) => {
  switch (deliveryMethod) {
    case ShippingOption.Eticket:
      return "E-ticket";
    case ShippingOption.PrintedTicketAtVenue:
      return "Printed ticket at venue";
    case ShippingOption.PrintedTicketByCourier:
      return "Printed ticket by courier";
    case ShippingOption.PrintedTicketByCourierRural:
      return "Printed ticket by courier - rural";
    case ShippingOption.PrintedTicketByMail:
      return "Printed ticket by mail";
    default:
      return "-";
  }
};

export const formatSeatLocation = (
  seatZone: Ticket["seatZone"],
  seatSection: Ticket["seatSection"],
  seatLabel: Ticket["seatLabel"]
) => {
  const split = seatLabel?.split("-");
  let label = "";

  if (split?.length > 2) {
    label = `Row ${split[1].trim()}, Seat ${split[2].trim()}`;
  } else if (split?.length === 2) {
    label = `Seat ${split[1].trim()}`;
  }

  return `${seatZone}${seatSection ? `, ${seatSection}` : ""}${
    label ? `, ${label}` : ""
  }`;
};

const shouldShowChangeSeats = (
  tickets: OrderQuery["order"]["tickets"],
  isNonSeated: boolean,
  order: OrderQuery["order"]
) =>
  order.status !== OrderStatus.Hold &&
  !!tickets?.length &&
  tickets.some(({ status }) => status === TicketStatus.Active) &&
  !isNonSeated;

const shouldShowRefundSeats = (order: OrderQuery["order"]) =>
  order.refundedAmount <= order.total;

export type TransactionFee = {
  transactionFeeType?: FeeType;
  transactionFee?: number;
  transactionFeeType2?: FeeType;
  transactionFee2?: number;
};

export const transactionFeeDescription = ({
  transactionFee,
  transactionFee2,
  transactionFeeType2,
  transactionFeeType,
}: TransactionFee) => {
  if (transactionFee2 && transactionFeeType2) {
    if (transactionFee) {
      if (transactionFeeType === FeeType.Percentage) {
        return ` (${transactionFee}% + $${transactionFee2.toFixed(2)})`;
      } else if (transactionFeeType === FeeType.FlatRate) {
        return ` (${transactionFee2}% + $${transactionFee.toFixed(2)})`;
      }
    } else if (transactionFeeType2 === FeeType.Percentage) {
      return ` (${transactionFee2}%)`;
    }
  }
  return transactionFeeType === FeeType.Percentage && transactionFee !== 0
    ? ` (${transactionFee}%)`
    : ``;
};

interface Props {
  order: OrderQuery["order"];
  mutate: KeyedMutator<OrderQuery["order"]>;
  referralCampaign?: GetReferralCampaignByOrderIdQuery["getReferralCampaignByOrderId"];
  referralRewards?: GetReferralRewardByOrderIdQuery["getReferralRewardByOrderId"];
}

export const OrderDetails: FC<Props> = ({
  order,
  mutate,
  referralCampaign,
  referralRewards,
}) => {
  const { organization } = useOrganization();
  const { hasPermissions, Permission } = usePermissions();
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showChangeSeatsModal, setShowChangeSeatsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const { t: translate } = useTranslation();
  const { hasFeature } = useOrganization();

  //should be pulled into a central way of displaying what sort of reward someone has gotten
  //needs to cover points when implemented
  const referralDetails = referralRewards
    ? `Referral discount of ${referralCampaign?.referralOwnerRewardQuantity?.toFixed(
        2
      )}%`
    : undefined;

  const isNonSeated =
    (order.event
      ? order.event?.venue?.seating
      : order.membership
      ? order.membership?.venue?.seating
      : undefined) === VenueSeating.NonSeated;

  const isPointsOrder = order.orderType === OrderType.PointPurchase;
  const numMembershipEvents = order.membership?.events?.length;

  const lineItems = useMemo(
    () => order.lineItems?.edges?.map(({ node }) => node),
    [order.id, order.lineItems?.edges, order.total]
  );

  const { activePromotions: seatedPromotions } = useSeatedMultibuy(
    order.event?.multiBuyPromotions || order.membership?.multiBuyPromotions,
    lineItems
  );
  const { activePromotions: promotions } = useNonSeatedMultiBuy(
    order.event?.multiBuyPromotions || order.membership?.multiBuyPromotions,
    lineItems
  );

  let seating: VenueSeating;
  if (order.event) {
    seating = order.event.venue?.seating;
  } else if (order.membership) {
    seating = order.membership.venue?.seating;
  }

  const activePromotions: (SeatWithPromotion | ActivePromotion)[] =
    seating === VenueSeating.NonSeated ? promotions : seatedPromotions;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const discountAmount = calculateDiscount(
    order.promoCode,
    lineItems as LineItem[], // TODO really need to fix this stuff...
    activePromotions
  );

  const { data: transferredMembershipTicketsData } = useQuery(
    TransferredMembershipTicketsForOrderDocument,
    {
      id: order.id,
    }
  );

  const tickets = order.tickets?.filter(
    ({ status }) => status === TicketStatus.Active
  );

  const transferredTickets = order.tickets?.filter(
    (t) => t.status === TicketStatus.Transferred
  );

  const transferredTicketsCount =
    (transferredTickets?.length ?? 0) +
    (transferredMembershipTicketsData?.transferredMembershipTicketsForOrder
      ?.length ?? 0);

  const totalSellerTransferFees = (() =>
    (transferredTickets?.reduce(
      (acc, t) =>
        acc +
        (t.transferFee?.type === TransferFeeType.Seller
          ? t.transferFee?.fee ?? 0
          : 0),
      0
    ) ?? 0) +
    (transferredMembershipTicketsData?.transferredMembershipTicketsForOrder?.reduce(
      (acc, t) =>
        acc +
        (t.transferFee?.type === TransferFeeType.Seller
          ? t.transferFee?.fee ?? 0
          : 0),
      0
    ) ?? 0))();

  const countReleasedTickets = (item: typeof lineItems[0]) =>
    order?.tickets?.filter(
      (ticket) =>
        ticket.lineItem.id === item?.id &&
        ![
          TicketStatus.Active,
          TicketStatus.Transferred,
          TicketStatus.TransferPending,
        ].includes(ticket.status)
    ).length;

  const countTransferredTickets = (item: typeof lineItems[0]) =>
    (order?.tickets?.filter(
      (ticket) =>
        ticket.lineItem.id === item?.id &&
        ticket.status === TicketStatus.Transferred
    ).length ?? 0) +
    (transferredMembershipTicketsData?.transferredMembershipTicketsForOrder?.filter(
      (t) => t.lineItem?.id === item?.id
    ).length ?? 0);

  const countTransferPendingTickets = (item: typeof lineItems[0]) =>
    order?.tickets?.filter(
      (ticket) =>
        ticket.lineItem.id === item?.id &&
        ticket.status === TicketStatus.TransferPending
    ).length;

  const countActiveTickets = (order: OrderQuery["order"]) =>
    order?.tickets?.filter((ticket) =>
      [TicketStatus.Active, TicketStatus.TransferPending].includes(
        ticket.status
      )
    ).length;

  return (
    <>
      <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
        <Title>Product</Title>
        <Title>Qty</Title>
        <Title>Tax</Title>
        <Title>Total</Title>

        {orderBy(lineItems, ["type", "name"], ["desc", "asc"]).map((item) => {
          const multiBuy = activePromotions.find((promo) => {
            if ("id" in promo) {
              return promo.id === item.id;
            }

            if ("getType" in promo) {
              return (
                (item?.ticketType?.id || item?.membershipType?.id) ===
                promo.getType.id
              );
            }

            return;
          });

          const {
            MultibuyPromoText,
            hasAppliedMultibuy,
            giftedQty,
            multibuyDiscountedPrice,
          } = getMultibuyInfo(multiBuy, item);

          return (
            <React.Fragment key={item.id}>
              <OrderItem
                title={`${item.name} ${
                  item.seatZone
                    ? " - " +
                      formatSeatLocation(
                        item.seatZone,
                        item.seatSection,
                        item.seatLabel
                      )
                    : ""
                }`}
                promo={MultibuyPromoText}
                numReleasedTickets={countReleasedTickets(item)}
                numTransferredTickets={countTransferredTickets(item)}
                numTransferPendingTickets={countTransferPendingTickets(item)}
                numMembershipEvents={numMembershipEvents}
                referral={referralDetails}
              />
              <Text>{item.quantity?.toLocaleString()}</Text>
              <Text>{order.taxRate > 0 ? `${order.taxRate * 100}%` : " "}</Text>
              <Flex flexDir="column">
                {hasAppliedMultibuy ? (
                  giftedQty === item.quantity &&
                  multiBuy.promotion.price === 0 ? (
                    <Text>Free</Text>
                  ) : (
                    <>
                      <Text>
                        {formatPrice(item.originalPrice * item.quantity)}
                      </Text>
                      <Price
                        fontSize={1}
                        lineHeight="normal"
                        price={-multibuyDiscountedPrice}
                        color="success"
                      />
                    </>
                  )
                ) : (
                  <>
                    <Text>
                      {formatPrice(item.originalPrice * item.quantity)}
                    </Text>
                    {item.originalPrice !== item.price && (
                      <Price
                        fontSize={1}
                        lineHeight="normal"
                        price={
                          -((item.originalPrice - item.price) * item.quantity)
                        }
                        color="success"
                      />
                    )}
                  </>
                )}
              </Flex>
            </React.Fragment>
          );
        })}
      </Box>

      <Divider my={2} />

      <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
        <Text>
          Total number of{" "}
          {isPointsOrder
            ? getPointsDisplayLabel(organization.point?.name)
            : order.event?.title
            ? `tickets`
            : `memberships`}
        </Text>
        <Box gridColumn="2">
          <Text whiteSpace="nowrap">
            {isPointsOrder
              ? getPointsQuantityFromOrder(order)
              : countActiveTickets(order)}
          </Text>
        </Box>
      </Box>

      <Divider my={2} />

      {order.promoCode && (
        <>
          <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
            <Box>
              <Text>Promo code applied</Text>
              <Text
                fontSize={1}
                lineHeight="normal"
                fontWeight="demiBold"
                color="N600"
              >
                {`"${order.promoCode.code}" ${
                  order.promoCode.discountType === DiscountType.Fixed ? "$" : ""
                }${order.promoCode.discountAmount.toFixed(2)}${
                  order.promoCode.discountType === DiscountType.Percentage
                    ? "%"
                    : ""
                } discount code`}
              </Text>
            </Box>
            <Box display="flex" alignItems="center" gridColumn="4">
              <Price ml={"-8px" as any} price={-discountAmount} />
            </Box>
          </Box>
          <Divider my={2} />
        </>
      )}

      <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
        <Text>
          Delivery costs{" "}
          {order.deliveryMethod
            ? `(${formatDeliveryMethod(order.deliveryMethod)})`
            : null}
        </Text>
        <OrderItem />
        <Box gridColumn="4">
          <Text whiteSpace="nowrap">
            {!order.deliveryFee
              ? order.deliveryFee === 0
                ? "Free shipping"
                : "-"
              : `${formatPrice(order.deliveryFee)}`}
          </Text>
        </Box>
      </Box>

      <Divider my={2} />

      {!isPointsOrder && <TicketFees lineItems={lineItems} />}

      {order.orderTicketCover && (
        <TicketCover orderTicketCover={order.orderTicketCover} />
      )}

      {!isPointsOrder && (
        <>
          {" "}
          <Box
            display="grid"
            gridTemplateColumns="9fr 2fr 2fr 1fr"
            gridGap={3}
            my={1}
          >
            <Text>Order Handling Fee</Text>
            <OrderItem />

            <Box gridColumn="4">
              <Text>{formatPrice(order.bookingFeeAmount)}</Text>
            </Box>

            {order?.orderPlan?.billingDetails?.totalFeeAmount > 0 && (
              <>
                <Text>Payment Plan Fee</Text>
                <OrderItem />
                <Box gridColumn="4">
                  <Text>
                    {formatPrice(
                      Number(order.orderPlan.billingDetails.totalFeeAmount ?? 0)
                    )}
                  </Text>
                </Box>
              </>
            )}

            <Text>Transaction fee {transactionFeeDescription(order)}</Text>
            <OrderItem />
            <Box gridColumn="4">
              <Text>{formatPrice(order.transactionFeeAmount || 0)}</Text>
            </Box>

            {!!order.transferFee && order.transferFee > 0 && (
              <>
                <Text>Ticket transfer fee</Text>
                <OrderItem />
                <Box gridColumn="4">
                  <Text>{formatPrice(order.transferFee)}</Text>
                </Box>
              </>
            )}
          </Box>
          <Divider my={2} />
        </>
      )}

      {order.usedCredits > 0 && (
        <>
          <Box display="grid" gridTemplateColumns="13fr 1fr">
            <Text>Account credits</Text>
            <Flex alignItems="center" justifyContent="flex-start">
              <Price price={-order.usedCredits} />
            </Flex>
          </Box>
          <Divider my={2} />
        </>
      )}

      {order.usedPoints > 0 && (
        <>
          <Box display="grid" gridTemplateColumns="13fr 1fr">
            <Text>Points used</Text>
            <Flex alignItems="center" justifyContent="flex-start">
              <Price price={-order.usedPoints} />
            </Flex>
          </Box>
          <Divider my={2} />
        </>
      )}

      <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
        {[
          OrderStatus.Completed,
          OrderStatus.Paid,
          OrderStatus.Hold,
          OrderStatus.PartPaid,
        ].includes(order.status) && (
          <Flex alignItems="center">
            <>
              {shouldShowChangeSeats(tickets, isNonSeated, order) && (
                <SecondaryButton
                  type="button"
                  onClick={() => setShowChangeSeatsModal(true)}
                >
                  Change seats
                </SecondaryButton>
              )}

              {!isPointsOrder &&
                hasPermissions(Permission.OrderRefund) &&
                shouldShowRefundSeats(order) && (
                  <SecondaryButton
                    fontSize={2}
                    type="button"
                    onClick={() => setShowRefundModal(true)}
                    ml={
                      shouldShowChangeSeats(tickets, isNonSeated, order) ? 2 : 0
                    }
                  >
                    Refund / Release seats
                  </SecondaryButton>
                )}

              {hasPermissions(Permission.OrderCreate) &&
                order.status === OrderStatus.Hold && (
                  <SecondaryButton
                    fontSize={2}
                    type="button"
                    onClick={() => setShowEditModal(true)}
                    ml={
                      hasPermissions(Permission.OrderRefund) &&
                      shouldShowRefundSeats(order)
                        ? 2
                        : 0
                    }
                  >
                    Edit
                  </SecondaryButton>
                )}

              {hasPermissions(Permission.OrderCreate) &&
                order.status === OrderStatus.Hold &&
                order.lineItems?.edges?.length > 1 && (
                  <SecondaryButton
                    fontSize={2}
                    type="button"
                    onClick={() => setShowTransferModal(true)}
                    ml={
                      hasPermissions(Permission.OrderCreate) &&
                      order.status === OrderStatus.Hold
                        ? 2
                        : 0
                    }
                  >
                    Split
                  </SecondaryButton>
                )}
            </>
          </Flex>
        )}

        <Box gridColumn="2 / span 2">
          <Title mb={2}>
            {order.taxRate > 0
              ? translate("order_details_sub_total_tax", {
                  taxRate: order.taxRate * 100,
                })
              : translate("customer_general_all_relevant_tax_rate_label")}
          </Title>

          <Title>Order total</Title>
        </Box>
        <Box>
          <Text mb={2}>{formatPrice(order.subtotal)}</Text>
          <Text>{formatPrice(order.total)}</Text>
        </Box>
      </Box>

      <Divider my={2} />

      {order.refundedAmount > 0 && (
        <>
          <Box display="grid" gridTemplateColumns="13fr 1fr" gridGap={3}>
            <Flex flexDir={"column"}>
              <Text>Refunded amount</Text>
              {transferredTicketsCount > 0 && (
                <Text
                  fontSize={1}
                  fontWeight="demiBold"
                  lineHeight="high"
                  color="N600"
                >
                  Includes ticket transfer fee of{" "}
                  {formatPrice(totalSellerTransferFees)}
                </Text>
              )}
            </Flex>
            <Flex alignItems="center" justifyContent="flex-start">
              <Price price={-order.refundedAmount} />
            </Flex>
          </Box>
          <Divider my={2} />
          <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
            <Box gridColumn="2">
              <Title>Adjusted total</Title>
            </Box>
            <Box gridColumn="4">
              <Text>
                {formatPrice(order.total - (order.refundedAmount ?? 0))}
              </Text>
            </Box>
          </Box>
          <Divider my={2} />
        </>
      )}

      <Modal
        isOpen={showChangeSeatsModal}
        close={() => setShowChangeSeatsModal(false)}
        header="Change Seats"
      >
        <ChangeSeatsModalContent
          order={order}
          orgSlug={organization?.slug}
          close={() => setShowChangeSeatsModal(false)}
        />
      </Modal>

      <CustomModal
        isOpen={showRefundModal}
        close={() => setShowRefundModal(false)}
      >
        <RefundModalContent
          order={order}
          onRefundOrTicketRelease={async () => {
            await mutate();
          }}
        />
      </CustomModal>

      <Modal
        isOpen={showEditModal}
        close={() => setShowEditModal(false)}
        header="Edit order"
      >
        <EditModalContent
          order={order}
          mutate={mutate}
          setIsOpen={setShowEditModal}
        />
      </Modal>

      <TransferModal
        order={order}
        isOpen={showTransferModal}
        setIsOpen={setShowTransferModal}
      />
    </>
  );
};
