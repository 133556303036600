import { ReactNode, useState } from "react";
import { Flex, SystemProps, Text } from "flicket-ui";
import styled from "styled-components";
import { Icon, IconProps } from "../Icon/Icon";
import { pick } from "@styled-system/props";
import ReactTooltip from "react-tooltip";

const TabWrapper = styled(Flex)`
  position: relative;
  width: 100%;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: ${(p) => p.theme.colors.N200};
  }
`;

export const Tab = styled(Flex).attrs({
  as: "span",
  mr: 3,
  py: 2,
  px: "1/2",
  alignItems: "center",
  lineHeight: "100%",
})<{
  active: boolean;
  isFirst: boolean;
  isLast: boolean;
}>`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: ${(p) => p.theme.fontSizes[3]};

  user-select: none;
  cursor: pointer;

  color: ${(p) => p.theme.colors.N600};
  position: relative;

  ${(p) =>
    p.active &&
    `
      color: ${p.theme.colors.N800};
      font-weight: ${p.theme.fontWeights.demiBold};
  `};

  &:after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 0;

    height: 3px;
    width: 100%;

    background: linear-gradient(
      to left,
      ${(p) => p.theme.colors.P300},
      ${(p) => p.theme.colors.P300}
    );
    background-size: ${(p) => (p.active ? "100% 100%" : "0% 100%")};
    background-position: 50% 50%;
    background-repeat: no-repeat;

    transition: all 0.2s ease-in-out;
  }
`;

export interface TabItem {
  name: string;
  icon?: IconProps["icon"];
  iconTooltip?: string;
  content: ReactNode;
  key?: string;
  disabled?: boolean;
  [x: string]: any;
}

interface FolderTabsProps {
  activeIndex?: number;
  initialActiveIndex?: number;
  items: TabItem[];
  onTabChange?: (index: number) => any;
  tabContentWrapperProps?: SystemProps;
  tabWrapperProps?: SystemProps;
}

export const FolderTabs = ({
  items,
  activeIndex: activeIndexProp,
  initialActiveIndex,
  onTabChange,
  tabContentWrapperProps = {},
  tabWrapperProps = {},
}: FolderTabsProps) => {
  const [activeIndexState, setActiveIndex] = useState(initialActiveIndex || 0);
  const activeIndex =
    activeIndexProp !== undefined ? activeIndexProp : activeIndexState;

  const handleTabChange = (index: number) => {
    if (onTabChange) onTabChange(index);
    setActiveIndex(index);
  };

  return (
    <>
      <TabWrapper
        flexWrap={"wrap"}
        mb={3}
        css={tabWrapperProps?.css}
        {...pick(tabWrapperProps)}
      >
        {items.map((tab, index) => (
          <Tab
            key={tab.name}
            onClick={() => {
              !tab.disabled && handleTabChange(index);
            }}
            active={activeIndex === index}
            isFirst={index === 0}
            isLast={index === items.length}
          >
            <Text
              as="span"
              // variant="extraBold.M"
              color={tab.disabled ? "N300" : ("inherit" as any)}
            >
              {tab.name}
            </Text>

            {tab.icon && (
              <>
                <Icon
                  icon={tab.icon}
                  ml={2}
                  color={tab.icon === "check-circle" ? "success" : "warning"}
                  fontSize={3}
                  data-tip={tab.iconTooltip ? tab.iconTooltip : undefined}
                />
                {tab.iconTooltip && (
                  <ReactTooltip
                    effect="solid"
                    textColor="white"
                    borderColor="black"
                    backgroundColor="black"
                    multiline={true}
                  />
                )}
              </>
            )}
          </Tab>
        ))}
      </TabWrapper>

      <Flex
        bg="white"
        borderRadius={"12px" as any}
        width={1}
        flexDir="column"
        p={3}
        zIndex={1}
        style={{ transition: "all .2s ease" }}
        flex={1}
        {...pick(tabContentWrapperProps)}
      >
        {!items[activeIndex].disabled && items[activeIndex].content}
      </Flex>
    </>
  );
};

interface FolderTabsKeyProps {
  activeKey?: string;
  initialActiveKey?: string;
  items: TabItem[];
  onTabChange?: (key: string) => unknown;
  tabContentWrapperProps?: SystemProps;
  tabWrapperProps?: SystemProps;
  onTabNotFound?: () => void;
}

export const FolderTabsKey = ({
  items,
  activeKey: activeKeyProp,
  initialActiveKey,
  onTabChange,
  tabContentWrapperProps = {},
  tabWrapperProps = {},
  onTabNotFound,
}: FolderTabsKeyProps) => {
  const [activeKeyState, setActiveKey] = useState(
    initialActiveKey || items[0].key
  );
  let activeKey = activeKeyProp !== undefined ? activeKeyProp : activeKeyState;

  let activeItem = items.find((item) => item.key === activeKey);

  if (!activeItem) {
    activeItem = items[0];
    activeKey = items[0].key;
    onTabNotFound();
  }

  const handleTabChange = (key: string) => {
    if (onTabChange) onTabChange(key);
    setActiveKey(key);
  };

  return (
    <>
      <TabWrapper
        flexWrap={"wrap"}
        mb={3}
        css={tabWrapperProps?.css}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        {...pick(tabWrapperProps)}
      >
        {items.map((tab, index) => (
          <Tab
            key={tab.name}
            onClick={() => {
              !tab.disabled && handleTabChange(tab.key);
            }}
            active={activeKey === tab.key}
            isFirst={index === 0}
            isLast={index === items.length}
          >
            <Text as="span" color={tab.disabled ? "N300" : ("inherit" as any)}>
              {tab.name}
            </Text>

            {tab.icon && (
              <>
                <Icon
                  icon={tab.icon}
                  ml={2}
                  color={tab.icon === "check-circle" ? "success" : "warning"}
                  fontSize={3}
                  data-tip={tab.iconTooltip ? tab.iconTooltip : undefined}
                />
                {tab.iconTooltip && (
                  <ReactTooltip
                    effect="solid"
                    textColor="white"
                    borderColor="black"
                    backgroundColor="black"
                    multiline={true}
                  />
                )}
              </>
            )}
          </Tab>
        ))}
      </TabWrapper>

      <Flex
        bg="white"
        borderRadius={"12px" as any}
        width={1}
        flexDir="column"
        p={3}
        zIndex={1}
        style={{ transition: "all .2s ease" }}
        flex={1}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        {...pick(tabContentWrapperProps)}
      >
        {!activeItem.disabled && activeItem.content}
      </Flex>
    </>
  );
};

export const getValidTabIcon = (isValid: boolean) =>
  isValid ? "check-circle" : "error";

export const validateTabAndGetIcon: <T extends (...args) => boolean>(
  func: T
) => (...func: Parameters<T>) => ReturnType<typeof getValidTabIcon> = (
  func
) => (...params) => getValidTabIcon(func(...params));
