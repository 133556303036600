import React, { FC, useState } from "react";

import { Flex, SystemProps, theme } from "flicket-ui";
import { camelCase, capitalize, flatten, startCase, uniq } from "lodash";

import { GraphOutput } from "~graphql/sdk";
import { formatDate, getRandomColor } from "~lib/helpers";

import { BarChart } from "../charts";
import { Card } from "../Card";
import { Status } from "~components";
import { useQuery } from "~hooks/useQuery";
import {
  GraphFilterType,
  PointReportingFilterSource,
  SalesGraphDocument,
} from "~graphql/typed-document-nodes";
import { formatReportingParams } from "../util";
import { ParamsProps } from "~features/useParams/hooks/useParams";
import { REPORT_COLOURS } from "~components/reports/common/reportColours";

export const SalesChart: FC<
  {
    data: GraphOutput;
    dateFormat?: string;
    error: any;
    leftMargin?: number;
  } & SystemProps
> = ({ data, dateFormat = "d MMM yyyy", error, leftMargin, ...props }) => {
  const options = {
    x: {
      tickFormatter: (val) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const date = new Date(val);
        date.setHours(0, 0, 0, 0);
        return formatDate(date, dateFormat);
      },
    },
  };

  const COLORS = [
    REPORT_COLOURS.teal,
    REPORT_COLOURS.navy,
    REPORT_COLOURS.pink,
    REPORT_COLOURS.tangerine,
  ];

  const formattedBars = uniq(
    flatten(
      data?.dates
        ?.filter((item) => !!item.items?.length)
        .map((item) => item.items)
    ).map((item) => item.name)
  ).map((name, index) => ({
    name,
    dataKey: camelCase(name),
    fill:
      camelCase(name) === "addOn"
        ? theme.colors.S300
        : COLORS[index] || getRandomColor(),
    stackId: camelCase(name) === "addOn" ? "b" : "a",
  }));

  const formattedGraphData = data?.dates?.map(({ date, items }) => {
    const obj = {};

    items.forEach((item) => (obj[camelCase(item.name)] = item.value));

    return { date, ...obj };
  });

  return formattedBars?.length ? (
    <BarChart
      data={formattedGraphData}
      options={options}
      items={formattedBars}
      leftMargin={leftMargin}
    />
  ) : (
    <Flex p={5} width={1} variant="center">
      No results
    </Flex>
  );
};

export function ConnectedSalesChart({ params }: { params: ParamsProps }) {
  const [type, setType] = useState<GraphFilterType>(GraphFilterType.Overall);
  const formattedParams = (formatReportingParams<
    ParamsProps,
    PointReportingFilterSource
  >(params) as unknown) as any;

  const { data: graphData, error: errorGraph } = useQuery(
    params.ready ? SalesGraphDocument : null,
    { ...formattedParams, type }
  );

  const allOptions = Object.values(GraphFilterType).map((val) => ({
    label: capitalize(startCase(val)),
    onClick: () => setType(val),
  }));

  let options: {
    label: string;
    onClick: () => void;
  }[];

  if (params.source === PointReportingFilterSource.Package) {
    options = allOptions.filter((option) => option.label === "Overall");
  } else {
    options = allOptions;
  }

  const dropdownOptions = {
    options,
    title: capitalize(startCase(type)),
  };

  return (
    <Card title="Sales" mb={4} dropdown={dropdownOptions}>
      <Status
        loading={!errorGraph && !graphData?.salesGraph}
        error={errorGraph}
      >
        <SalesChart data={graphData?.salesGraph} error={errorGraph} />
      </Status>
    </Card>
  );
}
