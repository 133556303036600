import React from "react";
import { CustomerLocationsWithFilters } from "~components/reports/charts/CustomerLocationByFilters";
import useFilterContext from "~features/reports/reporting/hooks/useFiltersContext";
import { BaseReportParams } from "~features/reports/reporting/types";

export const AudienceLocation = ({ params }: BaseReportParams) => {
  const { dateRange } = useFilterContext();

  const formatParams = {
    ...params,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  };

  return (
    <CustomerLocationsWithFilters
      title={"Location"}
      ready={true}
      {...formatParams}
      listProps={{
        height: "fit-content",
        maxHeight: "600px",
      }}
    />
  );
};
