import { Card } from "~components/reports";
import {
  PointReportingFilterSource,
  TimeOfDayBreakdownDocument,
} from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";
import { REPORT_COLOURS } from "~components/reports/common/reportColours";
import { TimeOfDayBreakdown } from "~components/reports/insights/TimeOfDayBreakdown";
import {
  EventReportParams,
  narrowPointReportingFilterSource,
} from "~features/reports/reporting/types";

export const AudienceSales = ({ params }: EventReportParams) => {
  const { data, error, isLoading } = useQuery(
    params.source === PointReportingFilterSource.Event &&
      TimeOfDayBreakdownDocument,
    {
      ...params,
      source: narrowPointReportingFilterSource(params.source),
    }
  );
  const hasAgeData = data?.timeOfDayBreakdown.some((item) => item.value > 0);
  if (
    isLoading ||
    error ||
    data?.timeOfDayBreakdown.length === 0 ||
    !hasAgeData
  )
    return <></>;
  return (
    <Card title="Sales by time of day">
      <TimeOfDayBreakdown
        fontSize={11}
        colour={REPORT_COLOURS.teal}
        data={data?.timeOfDayBreakdown}
      />
    </Card>
  );
};
