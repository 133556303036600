import React from "react";
import { Text, Flex, Stack, Box } from "flicket-ui";
import { NamingFieldsOverviewProps } from ".";
import { useNamingFieldOptions } from "./hooks/useNamingFieldOptions";
import { FieldLabel } from "~features/generalAdmissionEvent/components/FormSectionHelpers";
import { useOrganization } from "~hooks";
import { OrganizationFeatures } from "~lib/features";
import Link from "next/link";
import { Pill } from "../Pill";

export const NamingFieldsOverview = ({
  fields,
  onClick,
  hideTitle = false,
  isMembership = false,
}: NamingFieldsOverviewProps) => {
  const { namingFieldOptions } = useNamingFieldOptions(fields);
  const { hasFeature } = useOrganization();

  if (!hasFeature(OrganizationFeatures.OldNamingFields) && !isMembership) {
    return (
      <Stack gap={1} direction="vertical">
        <Text color="N600" fontSize={3} fontWeight="extraBold">
          Customer details
        </Text>
        <Text variant="small" as={Box}>
          Ask each ticket holder for the following.{" "}
          <Text as="span" textDecoration={"underline"} cursor="pointer">
            <Link
              href={{
                pathname: window.location.pathname,
                query: { tab: "customerDetails" },
              }}
            >
              Edit
            </Link>
          </Text>
        </Text>
        <Stack gap={1} flexWrap="wrap">
          {fields?.map(({ field, label }) => (
            <Pill
              padding={"4px 12px" as any}
              fontSize={"16px" as any}
              labelProps={{
                fontSize: "16px" as any,
                color: "N800",
              }}
              key={field}
            >
              {label}
            </Pill>
          ))}
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      {!hideTitle && (
        <Text color="N600" fontSize={3} fontWeight="extraBold" mb="1/2">
          Customer details
        </Text>
      )}
      <Flex width={1} alignItems="center">
        <Stack gap={1} flexWrap="wrap">
          {fields
            ?.filter((f) => f.isRequired)
            .map(({ field }) => (
              <FieldLabel key={field}>
                {
                  Object.entries(namingFieldOptions).find(
                    ([, { field: f }]) => f === field
                  )?.[0]
                }
                *
              </FieldLabel>
            ))}

          {fields
            ?.filter((f) => !f.isRequired)
            .map(({ field }) => (
              <FieldLabel key={field}>
                {
                  Object.entries(namingFieldOptions).find(
                    ([, { field: f }]) => f === field
                  )?.[0]
                }
              </FieldLabel>
            ))}
          <Text
            color="P300"
            fontSize={3}
            fontWeight="medium"
            textDecoration="underline"
            cursor="pointer"
            onClick={onClick}
            lineHeight={"40px" as any}
          >
            Edit
          </Text>
        </Stack>
      </Flex>
    </>
  );
};
