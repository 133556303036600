import React from 'react';
import { Text } from 'flicket-ui';
import { useTable } from 'react-table';
import { Status } from '~components';

export const SummaryTotalsTable = ({ items }) => {

    const data = React.useMemo(() =>
    items,
    []
   )

   const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: 'Sales by ticket type',
        align: 'left',
        hideHeader: true,
        columns: [
          {
            Header: 'Event',
            accessor: 'events',
            collapse: true,
          },
          {
            Header: 'Zone',
            accessor: 'zones',
            collapse: true,
          },
          {
            Header: 'Type',
            accessor: 'ticketTypes',
            collapse: true,

          },
          {
            Header: 'Total Issued',
            accessor: 'ticketsIssued',
            collapse: true,

          },
        ],
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
   } = useTable({ columns, data, })


  return (
        <Status error={!items} loading={!items}>
            {items?.length === 0 ? 
            (
            <Text fontSize={6} lineHeight="normal" fontWeight="heavy" color="N600"
                    textAlign="center"
                  >
                    No data available
                  </Text>
            ):(
            <div className="tableWrap">   
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => 
                        (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => 
                            (
                            <th {...column.getHeaderProps(
                                {
                                    style: {width: '4000px', textAlign:"left", paddingRight: "0.8rem"}

                                   }
                            )}>{column.render("Header")}
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => 
                        {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps({ style: {} })}
                            style={{
                              background: i % 2 && "#FAFAFC",
                            }}
                          >
                            {row.cells.map((cell) => {
                              {
                                row.values.events == null
                                  ? (row.values.events = "Membership")
                                  : row.values.events;
                              }
                              return (
                                <td
                                  {...cell.getCellProps({
                                    style: {
                                      paddingRight: "3.0rem",
                                      paddingBottom: "0.5rem",
                                      paddingTop: "0.5rem",
                                    },
                                  })}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                        })}
                    </tbody>
                </table> 
            </div>
            )}
            </Status>
  )

};


export default SummaryTotalsTable;