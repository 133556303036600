import { SystemProps, system } from "flicket-ui";
import styled from "styled-components";

export const Button = styled.button<SystemProps>`
  padding: 12px 16px;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: ${(p) => p.theme.radii.sm};
  font-size: ${(p) => p.theme.fontSizes[2]};
  font-weight: ${(p) => p.theme.fontWeights.extraBold};
  color: ${(p) => p.theme.colors.N700};
  flex-shrink: 0;
  line-height: ${(p) => p.theme.lineHeights.normal};
  display: flex;
  align-items: center;
  transition: all 0.1s ease-out;
  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.07),
    0px 1.6711px 12.1155px rgba(0, 0, 0, 0.0503198),
    0px 0.893452px 6.47753px rgba(0, 0, 0, 0.0417275),
    0px 0.500862px 3.63125px rgba(0, 0, 0, 0.035),
    0px 0.266004px 1.92853px rgba(0, 0, 0, 0.0282725),
    0px 0.11069px 0.802504px rgba(0, 0, 0, 0.0196802);

  &:disabled {
    background-color: ${(p) => p.theme.colors.N200};
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${(p) => p.theme.colors.N100};
  }

  &:active {
    transition: none;
    background-color: ${(p) => p.theme.colors.N200};
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.035),
      0px 1.6711px 12.1155px rgba(0, 0, 0, 0.025),
      0px 0.893452px 6.47753px rgba(0, 0, 0, 0.02),
      0px 0.500862px 3.63125px rgba(0, 0, 0, 0.015),
      0px 0.266004px 1.92853px rgba(0, 0, 0, 0.014),
      0px 0.11069px 0.802504px rgba(0, 0, 0, 0.009);
  }

  ${system}
`;
