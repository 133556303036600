import React from "react";

import { Flex, Stack, Text } from "flicket-ui";
import { Cell, Pie, PieChart as Chart } from "recharts";
import { LegendColor } from "./Legend";
import styled, { useTheme } from "styled-components";
import { useScreenSize } from "~hooks/useScreenSize";

interface Data {
  label: string;
  color: string;
  value: number;
}
interface PieChartProps {
  label?: React.ReactNode | null;
  total?: number | string | null;
  data: Data[];
  direction?: "vertical" | "horizontal";
}

const calcTotal = (data: Data[]) => {
  return data.reduce((total, current) => (total += current.value), 0);
};

const Wrapper = styled(Stack)`
  @media print {
    flex-direction: row;
  }
`;

export const PieChart = ({
  data,
  label = null,
  total = null,
  direction = "horizontal",
}: PieChartProps) => {
  const { colors } = useTheme();
  const dataTotal = calcTotal(data);
  const isMobile = useScreenSize().isPhonePortrait;

  return (
    <Wrapper direction={isMobile ? "vertical" : direction} gap={2}>
      <Flex justifyContent={"center"} position="relative">
        <Chart width={230} height={230}>
          <Pie
            cx={110}
            cy="50%"
            data={[{ value: 100, name: "test" }]}
            innerRadius={70}
            outerRadius={110}
            animationDuration={0}
            labelLine={false}
            dataKey="value"
            stroke={""}
          >
            <Cell fill={colors.N100} />
          </Pie>

          <Pie
            cx={110}
            cy="50%"
            data={data}
            innerRadius={70}
            outerRadius={110}
            labelLine={false}
            dataKey="value"
            stroke={""}
          >
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </Chart>
        {(label || total) && (
          <Flex
            left={0}
            top={85}
            right={0}
            position="absolute"
            flexDirection="column"
            variant="center"
          >
            {total && (
              <Text as="span" fontSize={4} fontWeight="extraBold" color="N800">
                {total.toLocaleString()}
              </Text>
            )}

            {label && (
              <Text as="span" fontWeight="extraBold" color="N800">
                {label}
              </Text>
            )}
          </Flex>
        )}
      </Flex>

      <Stack
        flexWrap="wrap"
        justifyContent={{ _: "center", sm: "center" }}
        gap={2}
        direction={`${direction === "vertical" ? "horizontal" : "vertical"}`}
      >
        {data?.map((item, index) => {
          const percentage =
            dataTotal > 0
              ? +(Number(item.value / dataTotal) * 100).toFixed(0)
              : 0;
          return (
            <Flex alignItems="center" key={index}>
              <LegendColor bg={item.color as any} />
              <Text
                variant="regular"
                ml="3/4"
              >{`${percentage}% ${item.label}`}</Text>
            </Flex>
          );
        })}
      </Stack>
    </Wrapper>
  );
};
