import { Flex } from "flicket-ui";
import styled from "styled-components";

export const Tag = styled(Flex).attrs({
  as: "span",
  px: "6/4" as any,
  py: 1,
  fontSize: 1,
  textTransform: "uppercase",
  borderRadius: "full",
})<{ active?: boolean }>`
  background-color: ${(p) => p.theme.colors[p.active ? "success50" : "N600"]};
  color: ${(p) => p.theme.colors[p.active ? "N800" : "white"]};
  border: 1px solid ${(p) => p.theme.colors[p.active ? "success" : "N600"]};
`;
