import {
  EventDate,
  SearchEventFilter,
  SearchIndexQueryVariables,
} from "~graphql/typed-document-nodes";

export type SpecificEventSourceSelection = {
  eventId: string;
};

export type EventSourceSelection = SpecificEventSourceSelection | "all-events";

export type SpecificMembershipSourceSelection = {
  membershipId: string;
};

export type MembershipSourceSelection =
  | SpecificMembershipSourceSelection
  | "all-memberships";

export type SpecificSeasonSourceSelection = {
  seasonId: string;
};

export type SeasonSourceSelection =
  | SpecificSeasonSourceSelection
  | "all-seasons";

export type OverallSourceSelection = "overall";

export type SpecificPackageSourceSelection = {
  packageId: string;
};

export type PackageSourceSelection =
  | SpecificPackageSourceSelection
  | "all-packages";

export type SourceSelection =
  | EventSourceSelection
  | MembershipSourceSelection
  | SeasonSourceSelection
  | "default"
  | OverallSourceSelection
  | PackageSourceSelection;

export type TabType = "event" | "membership" | "season" | "overall" | "package";

export interface SearchableData {
  searchableEvents: SearchEvent[];
  searchableMemberships: SearchMembership[];
  searchableSeasons: SearchSeason[];
  searchablePackages: SearchPackage[];
}

export interface SourceSwitcherProps {
  initialValue?: SourceSelection;
  placeholder?: string;
  value?: SourceSelection;
  small?: boolean;
  defaultSelectedTab?: TabType;
  displayOverallTab?: boolean;
  displaySeasonsTab?: boolean;
  displayEventsTab?: boolean;
  displayMembershipsTab?: boolean;
  displayPackagesTab?: boolean;
  searchEventFilter?: SearchEventFilter;
  onChange?: (option: SourceSelection, searchData: SearchableData) => void;

  hideAllEventsOption?: boolean;
  hideAllMembershipsOption?: boolean;

  // Bit of a hack prop to render additional elements to the seasons display
  renderSeasonPostSubtitle?: () => JSX.Element;
}

export interface InternalState extends SourceSwitcherProps {
  selectedSource: SourceSelection;
  searchIndexLoading?: boolean;
  searchableEvents: SearchEvent[];
  searchableMemberships: SearchMembership[];
  searchableSeasons: SearchSeason[];
  searchablePackages: SearchPackage[];
}

export interface SearchEvent {
  id: string;
  title: string;
  dates: Omit<EventDate, "id">[];
}

export interface SearchMembership {
  id: string;
  name: string;
  startDate: string;
}

export interface SearchSeason {
  id: string;
  name: string;
  startDate: string;
}

export interface SearchPackage {
  id: string;
  name: string;
}

export function isEventSource(
  sourceSelection: SourceSelection
): sourceSelection is EventSourceSelection {
  if (typeof sourceSelection === "string") {
    return sourceSelection === "all-events";
  }
  return sourceSelection && "eventId" in sourceSelection;
}

export function isSpecificEventSource(
  sourceSelection: SourceSelection
): sourceSelection is SpecificEventSourceSelection {
  return isEventSource(sourceSelection) && sourceSelection !== "all-events";
}

export function isMembershipSource(
  sourceSelection: SourceSelection
): sourceSelection is MembershipSourceSelection {
  if (typeof sourceSelection === "string") {
    return sourceSelection === "all-memberships";
  }
  return sourceSelection && "membershipId" in sourceSelection;
}

export function isSpecificMembershipSource(
  sourceSelection: SourceSelection
): sourceSelection is SpecificMembershipSourceSelection {
  return (
    isMembershipSource(sourceSelection) && sourceSelection !== "all-memberships"
  );
}

export function isSeasonSource(
  sourceSelection: SourceSelection
): sourceSelection is SeasonSourceSelection {
  if (typeof sourceSelection === "string") {
    return sourceSelection === "all-seasons";
  }
  return sourceSelection && "seasonId" in sourceSelection;
}

export function isSpecificSeasonSource(
  sourceSelection: SourceSelection
): sourceSelection is SpecificSeasonSourceSelection {
  return isSeasonSource(sourceSelection) && sourceSelection !== "all-seasons";
}

export function isOverallSource(
  sourceSelection: SourceSelection
): sourceSelection is OverallSourceSelection {
  return sourceSelection === "overall";
}

export function isPackageSource(
  sourceSelection: SourceSelection
): sourceSelection is PackageSourceSelection {
  if (typeof sourceSelection === "string") {
    return sourceSelection === "all-packages";
  }
  return sourceSelection && "packageId" in sourceSelection;
}
