import React, { ChangeEvent, useRef, useState } from "react";

import { Stack, Flex, PrimaryButton, SecondaryButton } from "flicket-ui";
import { orderBy } from "lodash";
import { stringify } from "qs";

import { LineItemType, OrderQuery, TicketStatus } from "~graphql/sdk";
import { getUrl } from "~lib/helpers";
import { Checkbox } from "~components";

type ChangeSeatsModalContentProps = {
  order: OrderQuery["order"];
  orgSlug: string;
  close: () => void;
};

const onRedirect = (url: string) => {
  const linkEl = document.createElement("a");
  linkEl.setAttribute("href", url);
  linkEl.setAttribute("target", "_blank");
  linkEl.setAttribute("rel", "noopener noreferrer");

  linkEl.click();
  linkEl.remove();
};

export const ChangeSeatsModalContent = ({
  order,
  orgSlug,
  close,
}: ChangeSeatsModalContentProps) => {
  const linkButtonRef = useRef(null);
  const [ids, setIds] = useState(
    orderBy(order?.tickets, ["lineItem.type", "lineItem.name"], ["desc", "asc"])
      ?.filter(
        (s) =>
          s.status === TicketStatus.Active &&
          s.lineItem.type !== LineItemType.Addon
      )
      .map(({ id }) => id)
  );

  const handleRedirect = () => {
    const params = stringify({
      customer: order?.user?.id,
      type: "CHANGE_SEATS",
    });

    const urlSuffix = order?.event
      ? `events/${order?.event?.id}/reservation`
      : `memberships/${order?.membership?.id}/reservation`;

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    onRedirect(`${getUrl(orgSlug)}/${urlSuffix}?ownedTickets=${ids}&${params}`);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    linkButtonRef?.current?.blur();
  };

  return (
    <>
      <Stack flexDir="column" space={2} pb={9}>
        {orderBy(
          order?.tickets,
          ["lineItem.type", "lineItem.name"],
          ["desc", "asc"]
        )
          ?.filter(
            (s) =>
              s.status === TicketStatus.Active &&
              s.lineItem.type !== LineItemType.Addon
          )
          .map((ticket) => (
            <Flex flex={1} justifyContent="space-between" key={ticket.id}>
              <Checkbox
                label={`${ticket?.lineItem.name} | (#${ticket?.ticketNumber})`}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  e.target.checked
                    ? setIds([...ids, ticket.id])
                    : setIds([...(ids?.filter((id) => id !== ticket.id) ?? [])])
                }
                checked={ids.includes(ticket.id)}
                cursor="pointer"
                disabled={ticket.status !== TicketStatus.Active}
              />
            </Flex>
          ))}
      </Stack>
      <Flex justifyContent="flex-end">
        <SecondaryButton onClick={close}>Cancel</SecondaryButton>
        <PrimaryButton ref={linkButtonRef} onClick={handleRedirect} ml={2}>
          Change seats
        </PrimaryButton>
      </Flex>
    </>
  );
};
