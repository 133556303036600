import { ScreenSizeProvider, theme as baseTheme } from "flicket-ui";
import { lighten, rgba } from "polished";
import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import {
  AnalyticsProvider,
  BaseProvider,
  FormStateProvider,
  UserProvider,
} from "~context";
import { AlertManagerProvider } from "~context/AlertManager";
import { POSAuthProvider } from "~context/POSAuthContext";
import { ReCaptchaProvider } from "~context/RecaptchaContext";
import { CurrentOrganizationOutput } from "~graphql/sdk";

interface ProvidersProps {
  organization?: CurrentOrganizationOutput | undefined;
  setOrganization?: (org: any) => void;
}

export const appTheme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    P300: "#F06F05",
    P200: "#f06f0550",
    P100: "#f06f0525",
    error: "#DC3009",
    error25: "#fdf5f3",
    error50: "#DC3009",
    error100: "#DC3009",
    N800: "#1A1A1A",
    N700: "#404040",
    N600: "#666666",
    N500: "#999999",
    N400: "#B2B2B2",
    N300: "#CCCCCC",
    N200: "#E5E5E5",
    N100: "#F2F2F2",
    /** 3% black */
    hoverBackground3: rgba(0, 0, 0, 0.03),
    /** 6% black */
    hoverBackground6: rgba(0, 0, 0, 0.06),
    success: "#3ba937",
    success25: lighten(0.4, "#3ba937"),
    success50: lighten(0.1, "#3ba937"),
  },
  fonts: {
    ...baseTheme.fonts,
    body: "Figtree, sans-serif",
    heading: "Figtree, sans-serif",
  },
};

export const Providers: FC<ProvidersProps> = ({
  children,
  setOrganization,
  organization,
}) => {
  const recaptchaKey = process.env.RECAPTCHA_SITE_KEY;

  return (
    <BaseProvider organization={organization} setOrganization={setOrganization}>
      <AnalyticsProvider>
        <ThemeProvider theme={appTheme}>
          <ScreenSizeProvider>
            <AlertManagerProvider>
              <ReCaptchaProvider reCaptchaKey={recaptchaKey}>
                <UserProvider>
                  <FormStateProvider>
                    <POSAuthProvider>{children}</POSAuthProvider>
                  </FormStateProvider>
                </UserProvider>
              </ReCaptchaProvider>
            </AlertManagerProvider>
          </ScreenSizeProvider>
        </ThemeProvider>
      </AnalyticsProvider>
    </BaseProvider>
  );
};
