import { LockSimple } from "@phosphor-icons/react";
import { ReactNode } from "react";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import styled from "styled-components";
import { FieldElement, FieldTextVariables } from "./interface/slate.interface";
import { EN_DASH } from "~lib/constants/enDash";
import { Icon } from "../Icon";
import { topOfDocumentPoint } from "./util";
import useEmailDataContext from "~features/emailCustomisation/hooks/useEmailDataContext";

const customFieldsOptions: Record<FieldTextVariables, string> = {
  [FieldTextVariables.FIRST_NAME]: "First name",
  [FieldTextVariables.LAST_NAME]: "Last name",
  [FieldTextVariables.EVENT_NAME]: "Event name",
  [FieldTextVariables.MEMBERSHIP_NAME]: "Membership name",
  [FieldTextVariables.ITEMS_PURCHASED]: "Items purchased",
  [FieldTextVariables.ORDER_NUMBER_EVENT_NAME]: `Order number ${EN_DASH} Event name`,
  [FieldTextVariables.ORDER_NUMBER_MEMBERSHIP_NAME]: `Order number ${EN_DASH} Membership name`,
  [FieldTextVariables.ORDER_NUMBER_PACKAGE_NAME]: `Order number ${EN_DASH} Package name`,
  [FieldTextVariables.CUSTOMER_NAME]: "Customer name",
  [FieldTextVariables.TICKETS_TO_SEND]: "Tickets to send",
  [FieldTextVariables.PACKAGE_NAME]: "Package name",
  [FieldTextVariables.POINT_NAME]: "Point name",
  [FieldTextVariables.COMP_ITEM]: "Comp items",
};

export const withFields = (editor: Editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "field" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "field" ? true : isVoid(element);
  };

  return editor;
};

export const insertField = (editor: Editor, content: FieldTextVariables) => {
  let blurSelection = editor.blurSelection;
  if (!blurSelection) {
    blurSelection = {
      anchor: topOfDocumentPoint,
      focus: topOfDocumentPoint,
    };
  }
  const field: FieldElement = {
    type: "field",
    content,
    children: [{ text: "" }],
  };
  Transforms.insertNodes(editor, field, {
    at: blurSelection,
  });

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

// Background is implement as pseudo element to not affect the line height
export const StyledCustomFieldElement = styled.span`
  display: inline-block;
  position: relative;
  mix-blend-mode: multiply;
  padding: 0 4px;

  :after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    background: ${(p) => p.theme.colors.N100};
    border-radius: 4px;
    z-index: -1;
    border: 1px solid ${(p) => p.theme.colors.N300};
    transform: translate(-50%, -50%);
    width: calc(100%);
    height: calc(100% + 2px);
  }
`;

export const CustomFieldElement = ({
  content,
  children,
  isRemovable,
}: {
  content: string;
  children: ReactNode;
  isRemovable?: false;
}) => {
  const context = useEmailDataContext();
  if (context?.[content]) return context[content];

  return (
    <StyledCustomFieldElement contentEditable={false}>
      {children}
      {customFieldsOptions[content]}
      {isRemovable === false && (
        <Icon
          icon={<LockSimple />}
          position="relative"
          top="3px"
          ml={1}
          // need this to remove a console error: <div> cannot appear as a descendant of <p>.
          as="span"
        />
      )}
    </StyledCustomFieldElement>
  );
};
