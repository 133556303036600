import { Flex } from "flicket-ui";
import styled from "styled-components";

export const StickyHeader = styled(Flex)`
  position: sticky;
  top: 0;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  min-height: 84px;

  &.stuck {
    transition: background 0.12s ease-out;
    background: white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.13),
      0px 9px 39px rgba(0, 0, 0, 0.13);
  }
`;
