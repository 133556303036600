import { Editor, Transforms, Range } from "slate";
import { DividerElement } from "./interface/slate.interface";
import { EmptyParagraph } from "./util";

export const insertDivider = (editor: Editor) => {
  let blurSelection = editor.blurSelection;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }
  const newRange: Range = {
    anchor: {
      offset: 0,
      path: [blurSelection?.anchor.path[0] + 1, 0],
    },
    focus: {
      offset: 0,
      path: [blurSelection?.focus.path[0] + 1, 0],
    },
  };
  const divider: DividerElement = { type: "divider", children: [{ text: "" }] };
  Transforms.insertNodes(editor, divider, { at: blurSelection });
  Transforms.insertNodes(editor, EmptyParagraph, { at: newRange });
};

export const withDivider = (editor: Editor) => {
  const { isVoid } = editor;
  editor.isVoid = (element) => {
    return element.type === "divider" ? true : isVoid(element);
  };
  return editor;
};
