// Adapted from https://github.com/sindresorhus/hex-rgb/blob/main/index.js

const hexCharacters = "a-f\\d";
const match3or4Hex = `#?[${hexCharacters}]{3}[${hexCharacters}]?`;
const match6or8Hex = `#?[${hexCharacters}]{6}([${hexCharacters}]{2})?`;
const nonHexChars = new RegExp(`[^#${hexCharacters}]`, "gi");
const validHexSize = new RegExp(`^${match3or4Hex}$|^${match6or8Hex}$`, "i");

export function luminance(hex: string): number {
  if (nonHexChars.test(hex) || !validHexSize.test(hex)) {
    throw new TypeError("Expected a valid hex string");
  }

  hex = hex.replace(/^#/, "");

  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  if (hex.length === 8) {
    hex = hex.slice(0, 6);
  }

  const rgb = parseInt(hex, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  return (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
}

export function textColourFromLuminance(
  hex: string,
  dark = "#000000",
  light = "#ffffff"
): string {
  return luminance(hex) > 0.5 ? dark : light;
}
