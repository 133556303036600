import { ButtonBase, system } from "flicket-ui";
import { ComponentProps } from "react";
import styled from "styled-components";

type ButtonBaseProps = ComponentProps<typeof ButtonBase>;

export type WhiteButtonProps = ButtonBaseProps & {
  hasBadge?: boolean;
};

export const WhiteButton = styled(ButtonBase)<WhiteButtonProps>`
  border: 1px solid
    ${(p) => (p.hasBadge ? p.theme.colors.N800 : p.theme.colors.N200)};
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.N800};
  padding-right: 20px;
  padding-left: 20px;

  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: 700;
  line-height: ${(p) => p.theme.fontSizes[4]};
  letter-spacing: -0.165px;

  &:hover:enabled,
  &:focus:enabled {
    background: ${(p) => p.theme.colors.N100};
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    width: 100%;

    font-size: ${(p) => p.theme.fontSizes[4]};
    line-height: ${(p) => p.theme.lineHeights.normal};
  }

  ${system}
`;
