import React, { FC } from "react";

import { theme } from "flicket-ui";

import { formatDate } from "~lib";
import { BarChart } from "../charts";
import { addMinutes } from "date-fns";
import { useIsMobile } from "~hooks";
import { ScanVisitorsOverTimeQuery } from "~graphql/sdk";

const getDateFormat = (val: number) =>
  `${formatDate(new Date(val), "d LLL p")} - ${formatDate(
    addMinutes(new Date(val), 5),
    "p"
  )}`;

const formatGraphData = (
  data: ScanVisitorsOverTimeQuery["scanVisitorsOverTime"]
) => {
  return data?.map(({ start, value }) => {
    return {
      date: new Date(start).getTime(),
      scanned: value,
    };
  });
};

export const ScansChart: FC<{
  data: ScanVisitorsOverTimeQuery["scanVisitorsOverTime"];
}> = ({ data }) => {
  const isMobile = useIsMobile();

  const options = {
    y: {
      tickFormatter: (val) => val.toLocaleString(),
      labelFormatter: (val) => val.toLocaleString(),
    },
    x: {
      tickFormatter: (val: string | number) =>
        formatDate(new Date(val), "d LLL p"),
      labelFormatter: (val: string) => val,
      hide: false,
      interval: "preserveStartEnd",
      angle: 0,
      type: "number",
      scale: "time",
      minTickGap: isMobile ? 5 : 50,
      domain: ["dataMin", "dataMax"],
    },
    tooltip: {
      formatter: (val) => val,
      labelFormatter: (val: string | number) => getDateFormat(val as number),
    },
  };

  const bars = [
    {
      name: "Scanned",
      dataKey: "scanned",
      fill: theme.colors.S300,
    },
  ];

  return (
    <BarChart
      data={formatGraphData(data)}
      options={options}
      items={bars}
      hideLegend
    />
  );
};
