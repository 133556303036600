import useSWR from "swr";
import { useSDK } from "~hooks";
import { IntegrationWhereInput } from "../sdk";

export const useIntegrations = (
  input?: IntegrationWhereInput,
  full = false
) => {
  const sdk = useSDK();

  const fetcher = async () =>
    full
      ? sdk.fullIntegrations({ input }).then(({ integrations }) => integrations)
      : sdk.integrations({ input }).then(({ integrations }) => integrations);

  const { data: integrations, ...rest } = useSWR(
    ["integrations", full],
    fetcher
  );

  return {
    ...rest,
    integrations,
  };
};
