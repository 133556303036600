import { FC, useContext, useEffect, useState } from "react";
import {
  PrimaryButton,
  Link,
  Text,
  Flex,
  Input,
  Stack,
  Box,
  TertiaryButton,
} from "flicket-ui";
import { showToast } from "~lib";
import { Printer } from "@flicket/utils";
import { BaseContext } from "~context";
import CustomModal from "~components/common/CustomModal";
import { StatusLabel } from "~components/common/StatusLabel";
import capitalize from "lodash/capitalize";
import { apiUrl } from "~config";

const printer = new Printer(apiUrl);
interface PrintModalProps {
  isOpen: boolean;
  close: () => void;
}

export const PrintModal: FC<PrintModalProps> = ({ isOpen, close }) => {
  return (
    <CustomModal isOpen={isOpen} close={close}>
      <CustomModal.Header> Printer configuration</CustomModal.Header>

      <ModalContent close={close} />
    </CustomModal>
  );
};

function ModalContent({ close }: { close: () => void }) {
  const { organization } = useContext(BaseContext);
  const [labelShift, setLabelShiftState] = useState("0");
  const [labelTop, setLabelTopState] = useState("0");
  const [printerSoftwareStatus, setPrinterSoftwareStatus] = useState<
    "idle" | "connecting" | "connected"
  >(printer.isConnected ? "connected" : "connecting");

  useEffect(() => {
    if (printerSoftwareStatus === "connecting") {
      void connectPrinterSoftware();
    }

    const { labelShift, labelTop } = printer.getPrinterConfig(organization.id);
    setLabelShiftState(String(labelShift));
    setLabelTopState(String(labelTop));
  }, []);

  const setLabelShift = (value: string) => {
    setLabelShiftState(value);
  };

  const setLabelTop = (value: string) => {
    setLabelTopState(value);
  };

  const saveSettings = () => {
    printer.setPrinterConfig(
      {
        labelShift,
        labelTop,
      },
      organization.id
    );

    showToast("Printer settings saved.");
    close();
  };

  async function connectPrinterSoftware() {
    setPrinterSoftwareStatus("connecting");
    const res = await printer.connectToSoftware();
    setPrinterSoftwareStatus(res ? "connected" : "idle");
  }

  async function disconnectPrinterSoftware() {
    setPrinterSoftwareStatus(
      (await printer.disconnectPrinterSoftware()) ? "idle" : "connected"
    );
  }

  return (
    <>
      <CustomModal.Content>
        <Text variant="header.S">Setup</Text>
        <Text variant="regular">
          You will need to install and run the QZ Tray printing software.
          <br />
          <Link
            color="P300"
            href="https://github.com/qzind/tray/releases/download/v2.2.3/qz-tray-2.2.3-x86_64.exe"
            // rel="noreferrer noopener"
            target="_blank"
          >
            Windows
          </Link>
          <br />
          <Link
            color="P300"
            href="https://github.com/qzind/tray/releases/download/v2.2.3/qz-tray-2.2.3-x86_64.pkg"
            // rel="noreferrer noopener"
            target="_blank"
          >
            Mac OS (Intel)
          </Link>
          <br />
          <Link
            color="P300"
            href="https://github.com/qzind/tray/releases/download/v2.2.3/qz-tray-2.2.3-arm64.pkg"
            // rel="noreferrer noopener"
            target="_blank"
          >
            Mac OS (Apple Silicon)
          </Link>
        </Text>

        <Text variant="header.S" mt={3}>
          Printer software connection (QZ Tray)
        </Text>

        <Box>
          <StatusLabel
            status={printerSoftwareStatus === "connected" ? "success" : "error"}
            mb={1}
          >
            {capitalize(
              printerSoftwareStatus === "idle"
                ? "Not connected"
                : printerSoftwareStatus
            )}
          </StatusLabel>
        </Box>

        <TertiaryButton
          disabled={printerSoftwareStatus === "connecting"}
          onClick={() => {
            void (printerSoftwareStatus === "connected"
              ? disconnectPrinterSoftware()
              : connectPrinterSoftware());
          }}
        >
          {printerSoftwareStatus === "connected" ? "Disconnect" : "Connect"}
        </TertiaryButton>

        <Text variant="header.S" mt={3}>
          Optional position adjustments
        </Text>
        <Stack direction="horizontal" gap={2} mt={1}>
          <Input
            label="Horizontal shift"
            value={labelShift}
            type="number"
            onChange={(e) => {
              setLabelShift(e.currentTarget.value);
            }}
          />
          <Input
            label="Vertical shift"
            value={labelTop}
            type="number"
            onChange={(e) => {
              setLabelTop(e.currentTarget.value);
            }}
          />
        </Stack>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Flex justifyContent="flex-end" alignItems="center">
          <PrimaryButton fontSize={2} onClick={saveSettings}>
            Save settings
          </PrimaryButton>
        </Flex>
      </CustomModal.Footer>
    </>
  );
}
