import { useQuery } from "~hooks/useQuery";
import {
  GoogleAnalyticsReportDocument,
  GoogleAnalyticsReportingFilterInput,
} from "~graphql/typed-document-nodes";
import { ReportingCard } from "~features/reports/reporting/components/dashboards/ReportingCard";
import { Box, Stack, SystemProps, Text } from "flicket-ui";
import React from "react";
import styled from "styled-components";
import { List } from "~components/common/List/List";
import { useScreenSize } from "~hooks/useScreenSize";
import { PercentBar } from "~components/common/PercentBar";
import { REPORT_COLOURS } from "~components/reports/common/reportColours";
import { AudienceGoogleButton } from "~features/reports/reporting/components/dashboards/audience/audience.google";

export const StyleList = styled(List)`
  li:first-child:before {
    background: none;
  }
`;

const trafficSourceMapping: Record<string, string> = {
  referral: "Referral links",
  "(none)": "Direct traffic",
  cpc: "Paid ads",
  organic: "Organic search",
  paid: "Paid ads",
  social: "Social media",
  email: "Email",
  display: "Display ads",
  "paid-social": "Paid social media",
  video: "Video ads",
  search: "Search engine",
  QR: "QR code",
};

const Grid = styled(Box)<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 2fr 6fr 2fr;
  grid-template-rows: 1fr;
  grid-gap: ${(props) => (props.isMobile ? "4px" : "10px")};
  justify-content: space-between;
`;

export const AudienceTraffic = ({
  params,
}: {
  params: GoogleAnalyticsReportingFilterInput;
}) => {
  const isMobile = useScreenSize().isPhonePortrait;

  const { data, error, isLoading } = useQuery(GoogleAnalyticsReportDocument, {
    input: params,
  });
  const hasAgeData = data?.googleAnalyticsReport.some((item) => item.value > 0);
  if (data?.googleAnalyticsReport.length === 0 || !hasAgeData) return null;

  if (isLoading || error) {
    return <></>;
  }

  const chartData: { label: string; value: number }[] = [];
  let otherValue = 0;
  let total = 0;
  data?.googleAnalyticsReport.forEach((data) => {
    const label = trafficSourceMapping[data.range] || "Other";

    total += data.value;
    if (label === "Other") {
      otherValue += data.value;
    } else {
      chartData.push({
        label: label,
        value: data.value,
      });
    }
  });

  if (otherValue > 0) {
    chartData.push({
      label: "Other",
      value: otherValue,
    });
  }

  return (
    <ReportingCard
      title={"Traffic channels"}
      TopRightElement={<AudienceGoogleButton />}
    >
      <Stack direction={"vertical"}>
        {chartData.map((item) => (
          <StyleList key={item.label}>
            <List.BaseItem key={`channel-${item.label}`}>
              <Grid isMobile={isMobile}>
                <Text textAlign={"start"} gridColumn={1}>
                  {item.label}
                </Text>
                <PercentBar
                  minHeight={"24px"}
                  maxHeight={"24px"}
                  gridColumn={2}
                  percentage={(item.value / total) * 100}
                  barColour={
                    REPORT_COLOURS.teal as SystemProps["backgroundColor"]
                  }
                  borderRadius={"none"}
                />
                <Text textAlign={"end"} gridColumn={3}>
                  {item.value}
                </Text>
              </Grid>
            </List.BaseItem>
          </StyleList>
        ))}
      </Stack>
    </ReportingCard>
  );
};
