import React from "react";
import { useRouter } from "next/router";
import { NAV_ITEMS } from "~components/common/Navbar";
import {
  EventQuery,
  MembershipQuery,
  ReportingFilterSource,
  ScoutCampaignOverviewOutput,
  ScoutCommunicationQuery,
} from "~graphql/sdk";
import { useOrganization, usePermissions, useUser } from "~hooks";

import { ScoutCampignOverview } from "./ScoutCampaignOverview";
import { capitalize } from "lodash";

interface ScoutParamsProps {
  scoutCampaignOverview: ScoutCampaignOverviewOutput;
  errorScoutCampaignOverview: Error;
  scoutCommunication: ScoutCommunicationQuery["scoutCommunication"];
  event: EventQuery["event"];
  membership: MembershipQuery["membership"];
  releaseName: string;
}

export const CampaignSummary = ({
  scoutCampaignOverview,
  errorScoutCampaignOverview,
  scoutCommunication,
  event,
  membership,
  releaseName,
}: ScoutParamsProps) => {
  const { hasPermissions, Permission } = usePermissions();
  const { user } = useUser();
  const router = useRouter();
  const { hasFeature } = useOrganization();

  if (!hasPermissions(Permission.ReportingSales)) {
    const firstRoute = NAV_ITEMS({ hasPermissions, user, hasFeature })[0];
    void router.replace(firstRoute.url);
    return null;
  }

  const getTitle = (
    scoutCommunication: ScoutCommunicationQuery["scoutCommunication"],
    event: EventQuery["event"],
    membership: MembershipQuery["membership"]
  ): string => {
    if (scoutCommunication.trackSource === ReportingFilterSource.Overall) {
      return capitalize(ReportingFilterSource.Overall);
    } else if (scoutCommunication.trackSource === ReportingFilterSource.Event) {
      if (event) {
        return event.title;
      } else {
        return capitalize(ReportingFilterSource.Event);
      }
    } else if (
      scoutCommunication.trackSource === ReportingFilterSource.Membership
    ) {
      if (membership) {
        return membership.name;
      } else {
        return capitalize(ReportingFilterSource.Membership);
      }
    } else {
      return "";
    }
  };

  return (
    <ScoutCampignOverview
      data={scoutCampaignOverview}
      error={errorScoutCampaignOverview}
      scoutCommunication={scoutCommunication}
      title={getTitle(scoutCommunication, event, membership)}
      releaseName={releaseName}
      mb={4}
    />
  );
};

export default CampaignSummary;
