import { formatPrice } from "flicket-ui";
import { Status } from "~components";
import { Card, OmitTypename } from "~components/reports";
import { FinancialOverviewQuery } from "~graphql/sdk";
import { IconCardGrid, OverviewItem } from "../IconCardGrid";
import { GraphQLError } from "graphql";

type FinancialOverviewData = OmitTypename<
  FinancialOverviewQuery["financialOverview"]
>;

interface Props {
  data: FinancialOverviewData;
  error: GraphQLError;
  isPoint?: boolean;
  title?: string;
}

const overviewItems = (
  isPoint: boolean
): OverviewItem<FinancialOverviewData>[] => [
  ...((isPoint
    ? [
        {
          title: "Gross volume",
          icon: "trending_up",
          key: "netSales",
          description:
            "Volume of transactions after refunds and credits have been deducted.",
        },
      ]
    : [
        {
          title: "Total sales",
          icon: "trending_up",
          key: "netSales",
          description:
            "Revenue from sales after points, credits, ticket resale<br />refunds and general refunds have been deducted.",
        },
      ]) as OverviewItem<FinancialOverviewData>[]),
  ...((isPoint
    ? []
    : [
        {
          title: "Resale fees",
          icon: "trending_up",
          key: "resaleFees",
          description: "Total buyer resale fees plus total seller resale fees.",
        },
      ]) as OverviewItem<FinancialOverviewData>[]),
  ...((isPoint
    ? []
    : [
        { title: "Discounts", icon: "my-tickets", key: "discounts" },
      ]) as OverviewItem<FinancialOverviewData>[]),
  { title: "Average per order", icon: "credit_card", key: "avgOrderAmount" },
  { title: "Average order size", icon: "bars", key: "avgOrderItemsCount" },
  {
    title: "Resale margin",
    icon: "trending_up",
    key: "resaleMargin",
    description:
      "Revenue generated from the margin between the resale price and original ticket price.",
  },
  {
    title: "Ticket protection order value",
    icon: "my-tickets",
    key: "ticketCoverOrderTotal",
  },
  {
    title: "Ticket protection cost",
    icon: "my-tickets",
    key: "ticketCoverCost",
  },
  {
    title: "Ticket protection margin",
    icon: "my-tickets",
    key: "ticketCoverMargin",
  },
  {
    title: "Part paid order revenue",
    icon: "credit_card",
    key: "planTotalPaid",
    description: "Total revenue of payments collected from part paid orders.",
  },
  {
    title: "Outstanding payments",
    icon: "credit_card",
    key: "planTotalOutstanding",
  },
];

export const FinancialOverview = ({
  data,
  error,
  isPoint = false,
  title = "Financial overview",
}: Props) => (
  <Card title={title} mb={4} mt={4} pr={{ _: 0, md: 4 }} position="relative">
    <Status loading={!error && !data} error={error}>
      <IconCardGrid
        variant="financial"
        items={overviewItems(isPoint)}
        data={data}
        getDataValue={(data, key) =>
          key === "avgOrderItemsCount"
            ? data?.[key].toLocaleString()
            : formatPrice(data?.[key])
        }
        hideZeroValues={true}
      />
    </Status>
  </Card>
);

export default FinancialOverview;
