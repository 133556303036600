import { uploadsFolder } from "~config";
import { ExtendedFile } from "~graphql/sdk";

export const getImage = (
  fileOrUrl: ExtendedFile | string,
  fallback: string = undefined
): string => {
  if (typeof fileOrUrl === "string") {
    return fileOrUrl;
  }

  return fileOrUrl
    ? `${uploadsFolder}/${fileOrUrl?.directory}/${fileOrUrl?.fileName}`
    : fallback ?? "/static/no-image.jpg";
};
