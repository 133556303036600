export const REPORT_COLOURS = {
  cyan: "#64c2fc",
  teal: "#00A89F",
  tangerine: "#FA9869",
  pink: "#E56289",
  purple: "#9645AA",
  blue: "#4085EC",
  navy: "#09458C",
  mango: "#FFC857",
};
