import { SeasonReportParams } from "~features/reports/reporting/types";
import { PointReportingFilterSource } from "~graphql/typed-document-nodes";
import { EventAudienceIndex } from "../event/audience.index";
import { MembershipAudienceIndex } from "../membership/audience.index";

export const SeasonAudienceIndex = ({ params }: SeasonReportParams) => {
  switch (params.source) {
    case PointReportingFilterSource.Event:
      return <EventAudienceIndex params={params} />;
    case PointReportingFilterSource.Membership:
      return <MembershipAudienceIndex params={params} />;
  }
};
