import {
  FormEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { addYears } from "date-fns";
import { InputWrapper, SystemProps } from "flicket-ui";
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";

import { Icon } from "~components";
import Styles from "./styles";
import { IconProps } from "../Icon/Icon";
import HoverIcon from "~features/generalAdmissionEvent/components/common/HoverIcon/HoverIcon";
import { CalendarBlank, CaretDown, X } from "@phosphor-icons/react";
import { FormatType, ZonedDate } from "@flicket/utils";
import { i18n } from "~lib/i18n";
import { localToUtc, utcToLocal } from "~lib";

const now = new Date();

const defaultOptions = {
  date: {
    dateFormat: "d - m - Y",
    minDate: now,
    maxDate: addYears(now, 2),
  },
  time: {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H : i",
    time_24hr: true,
    minuteIncrement: 1,
  },
  dateTime: {
    dateFormat: "d - m - Y   H : i",
    minDate: now,
    maxDate: addYears(now, 2),
    enableTime: true,
    time_24hr: true,
    minuteIncrement: 1,
  },
};

export interface IDatePicker extends SystemProps {
  label?: ReactNode;
  error?: string;
  type?: keyof typeof defaultOptions;
  onChange?: (dateString: string | string[] | null) => void;
  name: string;
  options?: DateTimePickerProps["options"];
  value?: (Date | string) | (Date | string)[];
  defaultValue?: string | string[];
  disabled?: boolean;
  placeholder?: string;
  icon?: IconProps["icon"];
  iconLeft?: boolean;
  allowClear?: boolean;
  iconRight?: boolean;
  timezone: string;
  locale: string;
  showCaretDownIcon?: boolean;
}

export const DatePicker = ({
  name,
  onChange,
  label,
  type = "date",
  error,
  options,
  value,
  disabled,
  placeholder,
  iconLeft = true,
  showCaretDownIcon = false,
  icon = <CalendarBlank />,
  allowClear = false,
  css,
  timezone = i18n.timezone,
  locale = i18n.locale,
  ...props
}: IDatePicker) => {
  const flatPickrRef = useRef<Flatpickr>(null);
  const [todayInTz, setTodayInTz] = useState<string>();

  useEffect(() => {
    if (timezone) {
      const todayInTz = utcToLocal(new Date(), timezone).toDateString();
      setTodayInTz(todayInTz);
    }
  }, [timezone]);

  const valueInLocalTimezone: string | string[] = useMemo(() => {
    if (value) {
      if (Array.isArray(value)) {
        return value.map((v: Date | string) => {
          return utcToLocal(v, timezone).toISOString();
        });
      } else {
        return utcToLocal(value, timezone).toISOString();
      }
    }
  }, [value, timezone]);

  const showClearButton = allowClear && !!value && !disabled;

  return (
    <>
      <InputWrapper label={label} name={name} error={error} flex="1" {...props}>
        <Styles
          $isValid={!error}
          $iconLeft={iconLeft}
          $showClearButton={showClearButton}
          $css={css}
        >
          <Flatpickr
            name={name}
            ref={flatPickrRef}
            placeholder={placeholder}
            options={{
              static: true,
              ...defaultOptions[type],
              // This function allows us to override how the day displays
              // inside the calendar. Here we are making sure that "today"
              // is displayed in the supplied timezone.
              onDayCreate: function (
                dObj,
                dStr,
                fp,
                dayElement: HTMLElement & { dateObj: Date }
              ) {
                const date = utcToLocal(dayElement.dateObj, timezone);
                if (date.toDateString() === todayInTz) {
                  dayElement.classList.add("tz-today");
                  dayElement.setAttribute("aria-current", "date");
                }
              },
              // Format date is responsible for the format of the date shown in the input field
              formatDate: (date) =>
                ZonedDate.format(date, type.toLowerCase() as FormatType, {
                  timeZone: ZonedDate.utils.getLocalTimezone(),
                  locale: locale ?? i18n.locale,
                }),
              ...options,
            }}
            className={`type-${type}`}
            data-testid="date-picker"
            onChange={(val) =>
              onChange(
                val.length > 1
                  ? val.map((date) => localToUtc(date, timezone).toISOString())
                  : val?.[0]
                  ? localToUtc(val[0], timezone).toISOString()
                  : undefined
              )
            }
            value={valueInLocalTimezone}
            disabled={disabled}
            type="text"
          />

          {showCaretDownIcon && (
            <CaretDown
              style={{
                position: "absolute",
                right: "10px",
                top: "14px",
                pointerEvents: "none",
              }}
            />
          )}
          {showClearButton && (
            <HoverIcon
              position={"absolute"}
              top={"13px"}
              right={"10px" as any}
              icon={<X />}
              title="Clear date"
              onClick={(e: FormEvent) => {
                e.preventDefault();
                onChange(undefined);
              }}
            />
          )}
          <Icon icon={icon} fontSize={5} className="icon" />
        </Styles>
      </InputWrapper>
    </>
  );
};
