import useSWR from "swr";
import { useSDK } from "~hooks";

export interface MailchimpList {
  id: string;
  name: string;
}

export interface MailchimpTemplate {
  id: number;
  name: string;
}

export const useEmail = () => {
  const sdk = useSDK();

  const { data: templates } = useSWR("mailchimpTemplates", async () =>
    sdk
      .mailchimpTemplates()
      .then((res) => res.mailchimpTemplates as MailchimpTemplate[])
  );

  const { data: lists } = useSWR("mailchimpLists", async () =>
    sdk.mailchimpLists().then((res) => res.mailchimpLists as MailchimpList[])
  );

  return {
    templates,
    lists,
  };
};
