import { Stack } from "flicket-ui";
import { useRef, useState } from "react";
import { ShareNetwork } from "@phosphor-icons/react";
import { useOnClickOutside } from "usehooks-ts";
import { AppliedFilter } from "./AppliedFilter";
import useFilterContext, {
  FilterActionTypes,
} from "~features/reports/reporting/hooks/useFiltersContext";
import { useChannelOptions } from "~features/reports/reporting/hooks/useChannelOptions";
import { FilterDropdownBox } from "./FilterDropdownBox";

export const ChannelFilter = () => {
  const { dispatch, getFilterShow } = useFilterContext();
  if (!getFilterShow("channel")) return null;

  const {
    channelLabel,
    channelGroupedOptions,
    channelState,
    channelOptions,
  } = useChannelOptions();

  const ref = useRef<HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(channelState ? false : true);

  const handleCancelFilter = () =>
    dispatch({ type: FilterActionTypes.CLEAR_FILTER, filter: "channel" });

  useOnClickOutside(ref, () => {
    setIsOpen(false);

    if (!channelState) {
      handleCancelFilter();
    }
  });

  const openSelect = () => setIsOpen(true);

  const onSelectChange = (channel: string) => {
    dispatch({
      type: FilterActionTypes.SET_FILTERS,
      filters: {
        channel,
      },
    });
    setIsOpen(false);
  };

  return (
    <Stack direction={"vertical"} position={"relative"}>
      <AppliedFilter
        icon={<ShareNetwork color="N800" />}
        onClick={openSelect}
        textContent={channelLabel ? channelLabel : "Channel"}
        removeFilter={handleCancelFilter}
        showRemoveIcon={channelLabel !== undefined}
      />
      {isOpen && (
        <FilterDropdownBox
          options={channelGroupedOptions}
          ref={ref}
          title="Channel used to purchase"
          onSelectChange={onSelectChange}
          value={
            channelOptions.find((option) => option.value === channelState) ??
            channelOptions[0]
          }
          placeholder={"Select channels"}
        />
      )}
    </Stack>
  );
};
