/* eslint-disable react/prop-types */
import { Box, Flex, Text } from "flicket-ui";
import { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";
import { FolderTabs, Skeleton } from "~components";
import CustomModal from "~components/common/CustomModal";
import { useScreenSize } from "~hooks/useScreenSize";
import EventSelect, { EventDisplay } from "./Select/EventSelect/EventSelect";
import MembershipSelect, {
  MembershipDisplay,
} from "../SourceSwitcher/Select/MembershipSelect/MembershipSelect";
import { AnimatePresence, motion } from "framer-motion";
import SeasonSelect, {
  SeasonDisplay,
} from "./Select/SeasonSelect/SeasonSelect";
import { OverallDisplay } from "../SourceSwitcher/Select/OverallDisplay";
import {
  InternalState,
  isEventSource,
  isMembershipSource,
  isOverallSource,
  isPackageSource,
  isSeasonSource,
  isSpecificEventSource,
  SearchableData,
  SearchEvent,
  SearchMembership,
  SearchPackage,
  SearchSeason,
  SourceSelection,
  SourceSwitcherProps,
  TabType,
} from "../SourceSwitcher/interfaces";
import { useOrganization } from "~hooks";
import { SearchIndexDocument } from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";
import { OrganizationFeatures } from "~lib/features";
import PackageSelect, {
  PackageDisplay,
} from "../SourceSwitcher/Select/PackageSelect";

const DropdownButton = styled(Flex).attrs({ as: "button" })<{
  $isOpen: boolean;
  $modal: boolean;
}>`
  --focus-color: ${(p) => p.theme.colors.N800};

  border-radius: ${(p) => p.theme.radii.sm};
  background: ${(p) => (p.$isOpen ? `white` : `rgba(255, 255, 255, 0.5)`)};
  border: 1px solid ${(p) => p.theme.colors.N200};
  padding: ${(p) => (p.$modal ? 10 : p.theme.space[2])}px;
  justify-content: space-between;
  align-items: center;
  gap: ${(p) => p.theme.space[1]}px;
  width: 100%;

  &&:focus-visible {
    outline: 2px solid var(--focus-color);
    z-index: 3;
  }

  :hover {
    background: white;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.sm}) {
    padding: ${(p) => (p.$modal ? 10 : p.theme.space[3])}px;
  }
`;

const DropdownWrapper = styled(Flex)`
  flex-direction: column;
  position: absolute;
  background: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.shadows.button};
  width: 100%;
  border-radius: ${(p) => p.theme.radii.sm};

  top: ${(p) => p.theme.space["1/4"]}px;
  z-index: 10;
`;

interface WrapperProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const MobileWrapper = forwardRef<HTMLElement, WrapperProps>(
  (props, ref) => {
    const { children, isOpen, setIsOpen } = props;
    return (
      <CustomModal isOpen={isOpen} close={() => setIsOpen(false)} ref={ref}>
        <CustomModal.Header>Select event</CustomModal.Header>
        <CustomModal.Content>{children}</CustomModal.Content>
      </CustomModal>
    );
  }
);

MobileWrapper.displayName = "MobileWrapper";

export const DesktopWrapper = forwardRef<HTMLElement, WrapperProps>(
  (props, ref) => {
    const { children, isOpen } = props;

    return (
      <AnimatePresence>
        {isOpen && (
          <DropdownWrapper ref={ref} position="absolute" background="white">
            <motion.div
              initial={{
                height: 0,
                opacity: 0,
                overflow: "hidden",
              }}
              animate={{
                height: "auto",
                opacity: 1,
                transitionEnd: {
                  overflow: "visible",
                },
              }}
              transition={{ duration: 0.2 }}
              exit={{
                height: 0,
                opacity: 0,
                overflow: "hidden",
                transition: {
                  duration: 0.15,
                },
              }}
            >
              {/*
              height auto can't be used with padding, it will cause the animation jump in the end
              so we need to move padding to a child element
              check `height: "auto" is jumping` section in this link
              https://github.com/framer/motion/blob/main/.github/ISSUE_TEMPLATE/bug_report.md
              */}
              <Box padding={2}>{children}</Box>
            </motion.div>
          </DropdownWrapper>
        )}
      </AnimatePresence>
    );
  }
);

DesktopWrapper.displayName = "DesktopWrapper";

export interface DropdownContentProps {
  setIsOpen: (isOpen: boolean) => void;
}

interface SourceSwitcherDropdownProps {
  buttonContent: React.ReactNode;
  dropdownContent: (props: DropdownContentProps) => React.ReactNode;
  zIndex?: number;
  small?: boolean;
}

export const SourceSwitcherDropdown = (props: SourceSwitcherDropdownProps) => {
  const { buttonContent, dropdownContent } = props;

  const dropdownRef = useRef<HTMLElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  const screenSizes = useScreenSize();
  const isMobile = screenSizes.isTabletPortraitDown;
  const Wrapper = isMobile ? MobileWrapper : DesktopWrapper;

  return (
    <Box
      position="relative"
      zIndex={100}
      ref={!isMobile ? dropdownRef : undefined}
    >
      <DropdownButton
        onClick={() => setIsOpen(!isOpen)}
        $isOpen={isOpen}
        $modal={props.small ?? false}
      >
        {buttonContent}
      </DropdownButton>

      <Box position={"absolute"} width={"100%"}>
        <Wrapper
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          ref={isMobile ? dropdownRef : undefined}
        >
          {dropdownContent({ setIsOpen })}
        </Wrapper>
      </Box>
    </Box>
  );
};

/**
 * The source selector component lets you choose from events, memberships, seasons or overall.
 * You can also select "all-events", "all-memberships" or "all-seasons".
 */
export default function SourceSwitcher(
  props: SourceSwitcherProps & {
    searchData?: SearchableData & { isLoading: boolean };
  }
) {
  const {
    initialValue,
    displayOverallTab = true,
    displayEventsTab = true,
    displayMembershipsTab = true,
    displaySeasonsTab = true,
    displayPackagesTab = true,
    small = false,
    value: controlledValue,
    onChange,
    searchData,
    searchEventFilter,
    placeholder = "Select...",
  } = props;

  const { data, isLoading } = useQuery(
    searchData ? null : SearchIndexDocument,
    {
      includeEvents: displayEventsTab,
      includeMemberships: displayMembershipsTab,
      includeSeasons: displaySeasonsTab,
      includePackages: displayPackagesTab,
      ...(searchEventFilter ? { eventFilter: searchEventFilter } : {}),
    }
  );
  const { hasFeature } = useOrganization();

  let searchableEvents: SearchEvent[] = [];
  let searchableMemberships: SearchMembership[] = [];
  let searchableSeasons: SearchSeason[] = [];
  let searchablePackages: SearchPackage[] = [];

  let searchIndexLoading;

  if (searchData) {
    searchableEvents = searchData.searchableEvents ?? [];
    searchableMemberships = searchData.searchableMemberships ?? [];
    searchableSeasons = searchData.searchableSeasons ?? [];
    searchablePackages = searchData.searchablePackages ?? [];

    searchIndexLoading = searchData.isLoading;
  } else {
    searchableEvents = data?.reportingSearchIndex?.searchableEvents ?? [];
    searchableMemberships =
      data?.reportingSearchIndex?.searchableMemberships ?? [];
    searchableSeasons = data?.reportingSearchIndex?.searchableSeasons ?? [];
    searchablePackages = data?.reportingSearchIndex?.searchablePackages ?? [];

    searchIndexLoading = isLoading;
  }

  const [selectedSource, setSelectedSource] = useState<SourceSelection>(
    initialValue
  );

  const isControlled = controlledValue !== undefined;
  const value = isControlled ? controlledValue : selectedSource;

  const _onChange = (option: SourceSelection) => {
    setSelectedSource(option);

    onChange(option, {
      searchableEvents,
      searchableMemberships,
      searchableSeasons,
      searchablePackages,
    });
  };

  const sourceTabs: {
    key: TabType;
    name: string;
    content: (props: any) => JSX.Element;
  }[] = [
    ...(displayEventsTab
      ? [
          {
            key: "event" as TabType,
            name: "Events",
            content: ({ setIsOpen }: DropdownContentProps) => (
              <EventSelect
                internalState={internalState}
                onChange={(option) => {
                  _onChange(option);
                  setIsOpen(false);
                }}
              />
            ),
          },
        ]
      : []),
    ...(displayMembershipsTab
      ? [
          {
            key: "membership" as TabType,
            name: "Memberships",
            content: ({ setIsOpen }: DropdownContentProps) => (
              <MembershipSelect
                internalState={internalState}
                onChange={(option) => {
                  _onChange(option);
                  setIsOpen(false);
                }}
              />
            ),
          },
        ]
      : []),
    ...(displaySeasonsTab
      ? [
          {
            key: "season" as TabType,
            name: "Seasons",
            content: ({ setIsOpen }: DropdownContentProps) => (
              <SeasonSelect
                internalState={internalState}
                onChange={(option) => {
                  _onChange(option);
                  setIsOpen(false);
                }}
              />
            ),
          },
        ]
      : []),
    ...(displayPackagesTab && hasFeature(OrganizationFeatures.Points)
      ? [
          {
            key: "package" as TabType,
            name: "Packages",
            content: ({ setIsOpen }: DropdownContentProps) => (
              <PackageSelect
                internalState={internalState}
                onChange={(option) => {
                  _onChange(option);
                  setIsOpen(false);
                }}
              />
            ),
          },
        ]
      : []),
    ...(displayOverallTab
      ? [
          {
            key: "overall" as TabType,
            name: "Overall",
            content: () => <></>,
          },
        ]
      : []),
  ];

  const overallIndex = sourceTabs.findIndex((item) => item.key === "overall");

  const [activeIndex, setActiveIndex] = useState(0);

  const internalState: InternalState = {
    ...props,
    searchIndexLoading,
    searchableEvents,
    searchableMemberships,
    searchableSeasons,
    searchablePackages,
    selectedSource: value,
  };
  useEffect(() => {
    if (isControlled) {
      return;
    }
    if (!searchIndexLoading) {
      if (initialValue) {
        if (isSpecificEventSource(initialValue)) {
          _onChange({
            eventId: initialValue.eventId,
          });
        }
      } else {
        if (searchableEvents.length > 0) {
          _onChange({
            eventId: searchableEvents[0].id,
          });
        } else if (searchableMemberships.length > 0) {
          _onChange({
            membershipId: searchableMemberships[0].id,
          });
        } else if (searchableSeasons.length > 0) {
          _onChange({
            seasonId: searchableSeasons[0].id,
          });
        } else if (searchablePackages.length > 0) {
          _onChange({
            packageId: searchablePackages[0].id,
          });
        }
      }
    }
  }, [searchIndexLoading]);

  let ButtonContent: JSX.Element | null = (
    <Text variant="regular" color="N500">
      {placeholder}
    </Text>
  );

  if (isEventSource(value)) {
    ButtonContent = <EventDisplay {...internalState} />;
  }

  if (isMembershipSource(value)) {
    ButtonContent = <MembershipDisplay {...internalState} />;
  }

  if (isSeasonSource(value)) {
    ButtonContent = <SeasonDisplay {...internalState} />;
  }

  if (isOverallSource(value)) {
    ButtonContent = <OverallDisplay {...internalState} />;
  }

  if (isPackageSource(value)) {
    ButtonContent = <PackageDisplay {...internalState} />;
  }

  return (
    <SourceSwitcherDropdown
      buttonContent={ButtonContent}
      small={small}
      dropdownContent={(props) => {
        if (searchIndexLoading) {
          return (
            <Box>
              <Text>Loading...</Text>
              <Skeleton />
            </Box>
          );
        }

        return (
          <Box position={"relative"}>
            {sourceTabs.length > 1 ? (
              <FolderTabs
                activeIndex={activeIndex >= 0 ? activeIndex : 0}
                onTabChange={(index: number) => {
                  if (index === overallIndex) {
                    setActiveIndex(0);
                    _onChange("overall");
                    props.setIsOpen(false);
                  } else {
                    setActiveIndex(index);
                  }
                }}
                items={sourceTabs.map((item) => ({
                  ...item,
                  content: item.content(props),
                }))}
                tabContentWrapperProps={{
                  padding: 0,
                  borderRadius: "none",
                  background: "none",
                }}
              />
            ) : null}

            {sourceTabs.length === 1 ? sourceTabs[0].content(props) : null}
          </Box>
        );
      }}
    />
  );
}
