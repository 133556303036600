import { pick } from "@styled-system/props";
import { Box, Stack, system, SystemProps, Text } from "flicket-ui";
import React, { ComponentProps } from "react";
import styled from "styled-components";
import { useScreenSize } from "~hooks/useScreenSize";
import { PercentBar } from "../PercentBar";

const DesktopGrid = styled(Box)`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 8fr minmax(110px, 1fr) minmax(130px, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  ${system}
`;

const DesktopTitle = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export type ReportingCategoryDetails = {
  title: string;
  chartProps?: ComponentProps<typeof PercentBar>;
  descriptionItems?: string[];
  displayValue?: string | JSX.Element;
  displayValueSubtitle?: string;
  showDisplayValue?: boolean;
  showDisplayValueSubtitle?: boolean;
};

export type ReportingCategoryProps = ReportingCategoryDetails & SystemProps;

const DesktopItem = ({
  title,
  descriptionItems,
  chartProps,
  displayValue,
  displayValueSubtitle,
  showDisplayValue = true,
  showDisplayValueSubtitle = true,
  ...props
}: ReportingCategoryProps) => {
  const _displayValue = (() => {
    if (!displayValue || !showDisplayValue) return null;
    if (typeof displayValue === "string")
      return (
        <Text variant={"header.XS"} textAlign={"end"}>
          {displayValue}
        </Text>
      );
    return displayValue;
  })();

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    <DesktopGrid gridGap={3} width={"100%"} {...pick(props)}>
      <Stack gridColumn={1} direction={"vertical"}>
        <DesktopTitle variant={"header.S"}>{title}</DesktopTitle>
        {descriptionItems && (
          <Text fontSize={2} color={"N600"}>
            {descriptionItems.join(" • ")}
          </Text>
        )}
      </Stack>
      {chartProps && <PercentBar gridColumn={2} {...chartProps} />}
      <Stack direction={"vertical"} gridColumn={3}>
        {_displayValue}
        {displayValueSubtitle && showDisplayValueSubtitle && (
          <Text fontSize={2} color={"N600"} textAlign={"right"}>
            {displayValueSubtitle}
          </Text>
        )}
      </Stack>
    </DesktopGrid>
  );
};

const MobileItem = ({
  title,
  descriptionItems,
  displayValue,
  displayValueSubtitle,
  chartProps,
  showDisplayValue = true,
  showDisplayValueSubtitle = true,
  ...props
}: ReportingCategoryProps) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    <Stack {...pick(props)} gap={1}>
      <Stack direction={"vertical"} flex={1}>
        <Text variant={"header.S"}>{title}</Text>
        {descriptionItems && (
          <Text fontSize={2} color={"N600"} flex={2} gridColumn={1}>
            {descriptionItems.join(" • ")}
          </Text>
        )}
      </Stack>

      <Stack
        direction={"vertical"}
        flex={"0 0 auto"}
        maxW={"180px"}
        minWidth={0}
      >
        {displayValue && showDisplayValue && (
          <Text variant={"header.XS"} textAlign={"end"}>
            {displayValue}
          </Text>
        )}

        {displayValueSubtitle && showDisplayValueSubtitle && (
          <Text fontSize={2} color={"N600"} textAlign={"end"}>
            {displayValueSubtitle}
          </Text>
        )}
        <Box width="90px" alignSelf={"end"}>
          {chartProps && (
            <PercentBar
              minHeight={"3px"}
              maxHeight={"3px"}
              marginTop={1}
              flex={1}
              {...chartProps}
            />
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default function ReportingCategory(props: ReportingCategoryProps) {
  const isMobile = useScreenSize().isTabletPortraitDown;
  return isMobile ? <MobileItem {...props} /> : <DesktopItem {...props} />;
}
