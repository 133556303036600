import * as yup from "yup";

export const richTextStyleOptions = {
  backgroundColour: "Background colour",
  bodyColour: "Body colour",
  headingColour: "Headings",
  paragraphColour: "Paragraph text",
  linkColour: "Link text",
  buttonColour: "Button colour",
} as const;

export interface RichTextStyles
  extends Record<keyof typeof richTextStyleOptions, string> {
  backgroundColour: string;
  bodyColour: string;
  headingColour: string;
  paragraphColour: string;
  linkColour: string;
  buttonColour: string;
}

export type StylesChangeFn = (styles: RichTextStyles) => void;

const hexValueSchema = yup
  .string()
  .required()
  .matches(
    /^#([0-9a-f]{3}|[0-9a-f]{6})$/i,
    "This field must be a valid hex colour"
  );

export const richTextStylesSchema = yup.object().shape({
  backgroundColour: hexValueSchema,
  bodyColour: hexValueSchema,
  headingColour: hexValueSchema,
  paragraphColour: hexValueSchema,
  linkColour: hexValueSchema,
  buttonColour: hexValueSchema,
});
