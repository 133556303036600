import useSWR from "swr";
import { useSDK } from "~hooks";

export const useAutomation = (id: string) => {
  const sdk = useSDK();

  const getAutomation = async () => {
    return await sdk.automation({ id }).then((res) => res.automation);
  };
  const { data: automation, error, mutate, ...methods } = useSWR(
    id ? ["automation", id] : null,
    getAutomation,
    {
      shouldRetryOnError: false,
    }
  );

  return {
    ...methods,
    error,
    automation,
    mutate,
  };
};
