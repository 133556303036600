import React, { useEffect, useState } from "react";
import { Box, Flex, Stack, Text } from "flicket-ui";
import { formatOrgDate } from "~lib/helpers";
import { Avatar } from "./Avatar";
import { HistoryFragment } from "~graphql/sdk";
import { capitalize } from "lodash";

interface HistoryItemProps {
  item: HistoryFragment;
  showHeader?: boolean;
  condensed?: boolean;
}

export const HistoryItem = ({ item, condensed = false }: HistoryItemProps) => {
  const [header, setHeader] = useState("");

  useEffect(() => {
    if (item.refName === "order" && item.order) {
      setHeader(`Order Number: ${item.order.orderNumber}`);
    } else if (item.refName === "scout-user" && item.scoutCommunication) {
      setHeader(`Scout Name: ${item.scoutCommunication.name}`);
    } else if (item.refName === "scan" && item.ticket.ticketNumber) {
      setHeader(`Ticket Number: ${item.ticket.ticketNumber}`);
    }
  }, [item.refName, item.order, item.scoutCommunication]);

  return (
    <Box
      borderRadius="sm"
      bg={"#F3F3F8" as any}
      p={2}
      my={condensed ? undefined : 1}
    >
      <React.Fragment>
        <Stack
          gap={2}
          direction={{ _: "vertical", md: "horizontal" }}
          alignItems={{ _: "unset", md: "flex-start" }}
          justifyContent="space-between"
        >
          <Stack gap={2} direction="horizontal" alignItems="flex-start">
            {!condensed && (
              <Flex justifySelf="center">
                <Avatar
                  user={item.user}
                  bg={"#D3682C" as any}
                  mr={0}
                  mt={"1/2"}
                />
              </Flex>
            )}
            <Stack direction="vertical">
              {!condensed ? (
                <>
                  <Text variant="header.XS">
                    {`Type: ${
                      item.refName === "scout-user"
                        ? "Scout"
                        : capitalize(item.refName)
                    }`}
                  </Text>
                  <Text variant="header.XS">{header}</Text>
                </>
              ) : null}
              <Text variant="regular">{item.description}</Text>
            </Stack>
          </Stack>
          <Text variant="regular" minW={"166px" as any} textAlign="right">
            {formatOrgDate(item.createdAt, "datetime")}
          </Text>
        </Stack>
      </React.Fragment>
    </Box>
  );
};
