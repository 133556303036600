import { useQuery } from "~hooks/useQuery";

import React, { FC, useState } from "react";

import {
  Box,
  Flex,
  formatPrice,
  Stack,
  SystemProps,
  Text,
  useScreenSize,
} from "flicket-ui";
import orderBy from "lodash/orderBy";

import { Icon, Select, Status } from "~components";
import {
  LocationOutputV2,
  Permission,
  PointReportingFilterSource,
  ReportingFilterSource,
} from "~graphql/sdk";

import {
  CustomerLocationsByFilterDocument,
  LocationFilterTypes,
} from "~graphql/typed-document-nodes";
import { ParamsProps } from "~features/useParams/hooks/useParams";
import { formatReportingParams } from "~components/reports";
import { useOrganization, usePermissions } from "~hooks";
import { PercentBar } from "~components/common/PercentBar";
import { getLocationFiltersForCountry } from "~lib/i18n";
import { REPORT_COLOURS } from "~components/reports/common/reportColours";
import { ReportingCard } from "~features/reports/reporting/components/dashboards/ReportingCard";
import { DownloadSimple } from "@phosphor-icons/react";
import { apiUrl } from "~config";
import axios from "axios";
import { showToast } from "~lib";

export enum LocationReportingFilterValueType {
  ORDER_AMOUNT = "orderAmount",
  TICKET_VALUE = "ticketValue",
  MEMBERSHIP_VALUE = "membershipValue",
  ORDER_COUNT = "orderCount",
  TICKET_SOLD_COUNT = "ticketSoldCount",
  AUDIENCE_VIEWS = "userCount",
  MEMBERSHIP_SOLD_COUNT = "membershipSoldCount",
}

type CustomerLocationsProps = ParamsProps &
  SystemProps & {
    title?: string;
    defaultVariant?: LocationReportingFilterValueType;
    listProps?: SystemProps;
    displayDownloadButton?: boolean;
  };

const valuesFiltersDefault = [
  { label: "Orders", value: LocationReportingFilterValueType.ORDER_COUNT },
  {
    label: "Order value",
    value: LocationReportingFilterValueType.ORDER_AMOUNT,
  },
  {
    label: "Ticket value",
    value: LocationReportingFilterValueType.TICKET_VALUE,
  },
  {
    label: "Membership value",
    value: LocationReportingFilterValueType.MEMBERSHIP_VALUE,
  },
  {
    label: "Tickets sold",
    value: LocationReportingFilterValueType.TICKET_SOLD_COUNT,
  },
  {
    label: "Audience views",
    value: LocationReportingFilterValueType.AUDIENCE_VIEWS,
  },
  {
    label: "Memberships sold",
    value: LocationReportingFilterValueType.MEMBERSHIP_SOLD_COUNT,
  },
];

const DownloadButton = ({
  filters,
  data,
}: {
  data: LocationOutputV2[];
  filters: {
    filter: string;
    type: string;
    startDate?: Date;
    endDate?: Date;
  };
}) => {
  const { isTabletPortraitDown } = useScreenSize();
  const { organization } = useOrganization();
  const locationFilters = getLocationFiltersForCountry(organization);

  const onClick = () => {
    const selectType = valuesFiltersDefault.find(
      (item) => item.value === filters.type
    ).label;

    const period = `${filters.startDate
      .toISOString()
      .slice(0, 10)} to ${filters.endDate.toISOString().slice(0, 10)}`;
    const postData = {
      label: `${
        locationFilters.find((item) => item.value === filters.filter).label
      }`,
      value: selectType,
    };

    const formatData = data.map((item) => {
      return { label: item.label, value: item[filters.type] ?? 0 };
    });
    const fileName = `customer-locations-${period}`;
    axios
      .post(
        `${apiUrl}/export/reporting/export-csv`,
        {
          columns: postData,
          data: formatData,
          filename: "report",
        },
        {
          responseType: "arraybuffer",
          params: {
            "flicket-org-id": organization.id,
          },
        }
      )
      .then((response) => {
        // Create a blob URL from the response data
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger a click event to download the file
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the blob URL
        window.URL.revokeObjectURL(url);
      })
      .catch(() => showToast("Error downloading CSV"));
  };
  return (
    <Stack gap={1}>
      {isTabletPortraitDown && (
        <Icon icon={<DownloadSimple />} fontSize={4} color="N800" />
      )}
      <Text
        variant="regular"
        textDecoration={"underline"}
        cursor="pointer"
        onClick={onClick}
      >
        Download
      </Text>
    </Stack>
  );
};

export const CustomerLocationsWithFilters: FC<CustomerLocationsProps> = ({
  ready,
  title,
  defaultVariant = LocationReportingFilterValueType.ORDER_COUNT,
  listProps,
  displayDownloadButton = true,
  ...props
}) => {
  const { hasPermissions } = usePermissions();
  const omittedFilters = [];

  if (
    props.source === PointReportingFilterSource.Membership ||
    props.source === PointReportingFilterSource.Event
  ) {
    omittedFilters.push(
      props.source === PointReportingFilterSource.Membership
        ? LocationReportingFilterValueType.TICKET_SOLD_COUNT
        : LocationReportingFilterValueType.MEMBERSHIP_SOLD_COUNT
    );

    if (!hasPermissions(Permission.ReportingFaceValue)) {
      omittedFilters.push(
        props.source === PointReportingFilterSource.Membership
          ? LocationReportingFilterValueType.TICKET_VALUE
          : LocationReportingFilterValueType.MEMBERSHIP_VALUE
      );
    }
  }

  if (!hasPermissions(Permission.ReportingFinancial)) {
    omittedFilters.push(LocationReportingFilterValueType.ORDER_AMOUNT);
  }

  // since we dont show tickets for memberships
  const valuesFilters = valuesFiltersDefault.filter(
    (item) => !omittedFilters.includes(item.value)
  );

  const { organization } = useOrganization();
  const locationFilters = getLocationFiltersForCountry(organization);
  const [
    locationFilterSelection,
    setLocationFilterSelection,
  ] = useState<LocationFilterTypes>(locationFilters[0].value);

  const [
    filterValueSelection,
    setFilterValueSelection,
  ] = useState<LocationReportingFilterValueType>(defaultVariant);
  const {
    data: customerLocationsData,
    error: errorCustomerLocations,
    isLoading,
  } = useQuery(ready && CustomerLocationsByFilterDocument, {
    filter: locationFilterSelection,
    ...formatReportingParams<ParamsProps, ReportingFilterSource>(props),
  });

  const data = customerLocationsData?.customerLocationsByFilter;

  // dont show this widget if there is no data, currently this will only show for nz, aus, usa
  if (!isLoading && (!data || data.length === 0)) {
    return null;
  }

  const sortCriteriaMap = {
    [LocationReportingFilterValueType.ORDER_COUNT]: "orderCount",
    [LocationReportingFilterValueType.ORDER_AMOUNT]: "orderAmount",
    [LocationReportingFilterValueType.TICKET_VALUE]: "faceValueTicketRevenue",
    [LocationReportingFilterValueType.MEMBERSHIP_VALUE]:
      "faceValueTicketRevenue",
    [LocationReportingFilterValueType.TICKET_SOLD_COUNT]: "ticketSoldCount",
    [LocationReportingFilterValueType.MEMBERSHIP_SOLD_COUNT]:
      "membershipSoldCount",
    [LocationReportingFilterValueType.AUDIENCE_VIEWS]: "userCount",
  };

  const orderedData = (() => {
    const sortCriteria = sortCriteriaMap[filterValueSelection];
    if (sortCriteria) {
      return orderBy(data, [sortCriteria], ["desc"]);
    }
    return data;
  })();

  const renderItem = (item: LocationOutputV2) => {
    const renderMap = {
      [LocationReportingFilterValueType.ORDER_COUNT]: () => (
        <>{item.orderCount?.toLocaleString() ?? 0}</>
      ),
      [LocationReportingFilterValueType.ORDER_AMOUNT]: () => (
        <> {formatPrice(item.orderAmount)}</>
      ),
      [LocationReportingFilterValueType.TICKET_VALUE]: () => (
        <> {formatPrice(item.faceValueTicketRevenue)}</>
      ),
      [LocationReportingFilterValueType.TICKET_SOLD_COUNT]: () => (
        <>{item.ticketSoldCount?.toLocaleString() ?? 0}</>
      ),
      [LocationReportingFilterValueType.MEMBERSHIP_SOLD_COUNT]: () => (
        <>{item.membershipSoldCount?.toLocaleString() ?? 0}</>
      ),
      [LocationReportingFilterValueType.AUDIENCE_VIEWS]: () => (
        <>{item.userCount?.toLocaleString() ?? 0}</>
      ),
    };

    return renderMap[filterValueSelection]?.() ?? null;
  };

  const renderPercentage = (item: LocationOutputV2) => {
    const percentageMap = {
      [LocationReportingFilterValueType.ORDER_COUNT]: item.orderCountPercentage,
      [LocationReportingFilterValueType.ORDER_AMOUNT]:
        item.orderAmountPercentage,
      [LocationReportingFilterValueType.TICKET_VALUE]:
        item.faceValueTicketRevenuePercentage,
      [LocationReportingFilterValueType.TICKET_SOLD_COUNT]:
        item.ticketSoldPercentage,
      [LocationReportingFilterValueType.MEMBERSHIP_SOLD_COUNT]:
        item.membershipSoldPercentage,
      [LocationReportingFilterValueType.AUDIENCE_VIEWS]:
        item.userCountPercentage,
    };

    const percentage = percentageMap[filterValueSelection] ?? 0;

    return (
      <PercentBar
        minHeight={"3px"}
        maxHeight={"3px"}
        marginBottom={1}
        barColour={REPORT_COLOURS.teal as SystemProps["backgroundColor"]}
        flex={1}
        percentage={percentage}
      />
    );
  };

  return (
    <ReportingCard
      title={title ?? "Customer locations"}
      {...props}
      overflow={"inherit"}
      TopRightElement={
        displayDownloadButton ? (
          <DownloadButton
            filters={{
              filter: locationFilterSelection,
              type: filterValueSelection,
              startDate: props.startDate,
              endDate: props.endDate,
            }}
            data={orderedData}
          />
        ) : null
      }
    >
      <Status loading={isLoading} error={errorCustomerLocations}>
        <Flex gridGap={2} mb={2}>
          <Select
            options={locationFilters}
            defaultValue={locationFilterSelection}
            onChange={(val) => {
              setLocationFilterSelection(val as LocationFilterTypes);
            }}
          ></Select>
          <Select
            options={valuesFilters}
            defaultValue={filterValueSelection}
            onChange={(val) => {
              setFilterValueSelection(val as LocationReportingFilterValueType);
            }}
          ></Select>
        </Flex>
        <Box as="ol" height={230} overflowY="auto" width={1} {...listProps}>
          {orderedData?.map((item, i) => (
            <Box mr={2} key={item.label}>
              <Flex
                as="li"
                borderRadius="xs"
                py="6/4"
                listStyleType="decimal"
                flex={2}
              >
                <Flex as="span" flex={1} mr={4}>
                  {i + 1}. {item.label}
                </Flex>
                <Flex as="span" justifyContent="flex-end">
                  {renderItem(item)}
                </Flex>
              </Flex>
              <Flex pl={1}>{renderPercentage(item)}</Flex>
            </Box>
          ))}
        </Box>
        {orderedData.length === 0 ? (
          <Box
            height={"auto"}
            verticalAlign={"middle"}
            width={"100%"}
            textAlign={"center"}
          >
            <Text mt={4} mb={4} fontSize={4}>
              No Location data found for {locationFilterSelection.toLowerCase()}
            </Text>
          </Box>
        ) : null}
      </Status>
    </ReportingCard>
  );
};
