import React from 'react';
import { NextSeo } from 'next-seo';
import { useOrganization } from '~hooks';

export const Seo = ({ title, description = '' }) => {
  const { organization } = useOrganization();

  return (
    <NextSeo
      title={title}
      description={`${description} ${organization?.name} - Flicket`}
    />
  );
};
