import { camelCase } from "lodash";
import { stringify } from "qs";
import { apiUrl } from "~config";
import { CurrentOrganizationOutput, GraphFilterType } from "~graphql/sdk";
import { UserFragment } from "~context";
import { removeUndefined } from "./removeUndefined";
import { ToastType } from "react-toastify";
import showToast from "./showToast";
import { cleanTextOfSpecialCharsAndWhitespaceForExportFileName } from "./cleanTextOfSpecialCharsAndWhitespaceForExportFileName";

/**
 * utility helper to download a file by REST API, xlsx files will use filename on the backend, not this name
 *
 * @param   {string}  href  link where the file can be fetched
 * @param   {string}  name  name of the file (should include an extension)
 *
 * @return  {void}        void (button click)
 */
export const downloadFile = (href: string, name: string): void => {
  const anchor = document.createElement("a");

  anchor.href = href;
  anchor.target = "_blank";
  anchor.download = name;

  return anchor.click();
};

type DownloadSalesOverTimeReportParams = {
  organization: CurrentOrganizationOutput;
  startDate: string;
  endDate: string;
  channel?: string;
  user: UserFragment;
  sourceId?: string;
  source?: string;
  type: GraphFilterType;
  seasonId?: string;
  releaseId?: string;
};
export const downloadSalesOverTimeReport = ({
  organization,
  startDate,
  endDate,
  channel,
  user,
  sourceId,
  source,
  type,
  seasonId,
  releaseId,
}: DownloadSalesOverTimeReportParams): void => {
  let typeFormat: string | null = null;
  switch (type) {
    case GraphFilterType.AddOns:
      typeFormat = "add-ons";
      break;
    case GraphFilterType.Memberships:
      typeFormat = "memberships";
      break;
    case GraphFilterType.Tickets:
      typeFormat = "tickets";
      break;
    case GraphFilterType.Package:
      typeFormat = "package";
      break;
    case GraphFilterType.Overall:
      typeFormat = "overall";
      break;
  }
  const queryParams = {
    "flicket-org-id": organization?.id,
    ...(channel && { channel: camelCase(channel) }),
    source,
    createdAfter: startDate,
    createdBefore: endDate,
    userId: user?.id,
    sourceId: sourceId,
    type: typeFormat,
    seasonId,
    releaseId,
  };

  const query = stringify(queryParams);

  const url = `${apiUrl}/export/reporting/salesOverTime?${query}`;

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const now = new Date().toTimeString();
  const name = `report-sales-over-time-${now}.csv`;

  downloadFile(url, name);
};

export const downloadReport = async (
  orgId: string,
  path: string,
  filename: string,
  q: { [key: string]: string | undefined | string[] },
  fileFormat: "xlsx" | "pdf" | "csv",
  onLoading?: () => void,
  onDone?: () => void
): Promise<void> => {
  try {
    const query = stringify(
      removeUndefined({
        ["flicket-org-id"]: orgId,
        ...q,
      })
    ).toString();

    const url = `${path}?${query}`;

    onLoading?.();

    const res = await fetch(url, {
      credentials: "include",
      method: "GET",
    });

    if (!res.ok) {
      throw new Error(`HTTP status code: ${res.status}`);
    }

    const blob = await res.blob();

    downloadFile(
      window.URL.createObjectURL(blob),
      `${cleanTextOfSpecialCharsAndWhitespaceForExportFileName(
        filename
      )}.${fileFormat}`
    );
  } catch (err) {
    showToast("Failed to generate this report", ToastType.ERROR);
  } finally {
    onDone?.();
  }
};
