import {
  InsightsGenderBreakDownDocument,
  ReportType,
} from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";
import React from "react";
import { GenderBreakdown } from "~components/reports/insights/GenderBreakdown";
import { ReportingCard } from "~features/reports/reporting/components/dashboards/ReportingCard";
import { AudienceGoogleButton } from "~features/reports/reporting/components/dashboards/audience/audience.google";
import {
  BaseReportParams,
  narrowPointReportingFilterSource,
} from "~features/reports/reporting/types";
import { SystemProps } from "flicket-ui";
import { pick } from "@styled-system/props";

export const AudienceGender = ({
  params,
  ...props
}: SystemProps & BaseReportParams) => {
  const { data, error, isLoading } = useQuery(InsightsGenderBreakDownDocument, {
    ...params,
    source: narrowPointReportingFilterSource(params.source),
  });

  const hasData = Object.values(
    data?.insightsGenderBreakDown?.reportData ?? []
  ).some((item) => item > 0);

  if (isLoading || error || !hasData) {
    return <></>;
  }

  return (
    <ReportingCard
      title={`Gender ${
        data.insightsGenderBreakDown.reportType === ReportType.GoogleAnalytics
          ? `(via Google Analytics)`
          : ""
      }`}
      TopRightElement={<AudienceGoogleButton />}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      {...pick(props)}
    >
      <GenderBreakdown
        direction={"vertical"}
        data={data?.insightsGenderBreakDown}
      />
    </ReportingCard>
  );
};
