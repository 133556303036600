import { GraphQLError } from "graphql";
import { Divider } from "~components";
import { ScanVisitorsQuery } from "~graphql/sdk";
import { Card } from "../Card";
import { ScanBreakdown } from "./ScanBreakdown";

type ScanBreakdownCardProps = {
  isLoading: boolean;
  error: GraphQLError;
  scanVisitors: ScanVisitorsQuery["scanVisitors"];
};

export const ScanBreakdownCard = ({
  scanVisitors,
  error,
  isLoading,
}: ScanBreakdownCardProps) => {
  return (
    <Card title="Scanning breakdown" mb={4}>
      <ScanBreakdown
        scanVisitors={scanVisitors}
        isLoading={isLoading}
        error={error}
      />

      <Divider my={3} />
    </Card>
  );
};
