import React, { FC } from "react";

import { pick, omit } from "@styled-system/props";

import { SystemProps } from "flicket-ui";
import SkeletonComponent, { SkeletonProps } from "react-loading-skeleton";
import { css } from "~lib/css";

export const Skeleton: FC<SkeletonProps & SystemProps> = ({
  width,
  height,
  ...props
}) => {
  return (
    <SkeletonComponent
      width={width}
      height={height}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      {...omit(props)}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      css={css({ ...pick(props) })}
    />
  );
};
