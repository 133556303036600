import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { Box, Flex, Text } from "flicket-ui";
import { AnimatePresence, motion } from "framer-motion";
import { useId } from "~hooks";
import { getGCSFile } from "~lib";
import { css } from "~lib/css";
import { DeleteButton } from "./DeleteButton";
import UploadIcon from "./upload.svg";

interface ImageInputProps extends InputHTMLAttributes<HTMLElement> {
  title?: string;
  value?: any;
  fullWidthPreview?: boolean;
}

export const ImageInput = ({
  title,
  value,
  fullWidthPreview,
  ...inputProps
}: ImageInputProps) => {
  const id = useId();
  const [previewImage, setPreviewImage] = useState(null);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (value) {
      if (value instanceof File) {
        setPreviewImage(URL.createObjectURL(value));
      } else if (value?.fileName) {
        setPreviewImage(getGCSFile(value));
      } else {
        setPreviewImage(null);
      }
    }
  }, [value]);
  return (
    <>
      <Flex
        minHeight={208}
        borderRadius="sm"
        borderStyle="solid"
        borderWidth={1 as any}
        borderColor="N200"
        backgroundColor={"#F3F3F8" as any}
        variant="center"
        position="relative"
        background={
          fullWidthPreview &&
          previewImage &&
          `center / cover url(${previewImage})`
        }
      >
        <AnimatePresence>
          {previewImage && (
            <Box position="absolute" right={16} bottom={16}>
              <motion.div
                key="preview"
                transition={{ type: "spring", damping: 10, velocity: 800 }}
                animate={{ scale: [0, 1.1, 1] }}
                exit={{ scale: [1, 1.1, 0] }}
              >
                <DeleteButton
                  onClick={() =>
                    inputProps.onChange({
                      target: { ...inputRef?.current, value: null },
                    } as any)
                  }
                />
              </motion.div>
            </Box>
          )}
        </AnimatePresence>
        {previewImage ? (
          !fullWidthPreview && (
            <Flex variant="center">
              <img
                alt="preview"
                src={previewImage}
                css={css({ maxHeight: 200, maxWidth: 200 })}
              />
            </Flex>
          )
        ) : (
          <Flex mt={2} flexDir="column" alignItems="center">
            <input
              {...inputProps}
              onChange={(e) =>
                inputProps.onChange({
                  ...e,
                  target: { ...e.target, value: e?.target?.files?.[0] },
                } as any)
              }
              css={{ display: "none" }}
              id={String(id)}
              type="file"
              ref={inputRef}
            />
            <label
              css={css({
                backgroundColor: "P300",
                color: "white",
                fontSize: 2,
                px: 4,
                py: 2,
                borderRadius: "sm",
                fontWeight: "bold",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                ":hover": {
                  backgroundColor: "P200",
                },
              })}
              htmlFor={String(id)}
            >
              <UploadIcon css={{ display: "inline-block", marginRight: 8 }} />
              Upload Image
            </label>
            <Text mt={2} variant="heavy.M">
              Supported Files
            </Text>
            <Text variant="medium.M">SVG, PNG, JPG, JPEG</Text>
          </Flex>
        )}
      </Flex>
    </>
  );
};
