import { Box, Text, SystemProps } from "flicket-ui";
import { FC } from "react";
import { pick } from "@styled-system/props";

interface StatusLabelProps extends SystemProps {
  status: "idle" | "success" | "error";
  showStatusDot?: boolean;
}

const StatusLabel: FC<StatusLabelProps> = ({
  children,
  status,
  showStatusDot = true,
  ...props
}) => {
  return (
    <Box
      display="inline-flex"
      backgroundColor="N200"
      borderRadius="lg"
      alignItems="center"
      width="fit-content"
      alignSelf="center"
      px={"9px" as any}
      py="1/4"
      style={{
        textWrap: "nowrap",
      }}
      {...pick(props)}
    >
      {showStatusDot && <StatusDot mr="1/2" status={status} />}
      <Text display="inline-flex" variant="small">
        {children}
      </Text>
    </Box>
  );
};

function StatusDot({
  status,
  ...props
}: { status: StatusLabelProps["status"] } & SystemProps) {
  const color = (() => {
    switch (status) {
      case "success":
        return "success50";
      case "error":
        return "error";
      default:
        return "N200";
    }
  })();

  return (
    <Box
      display="inline-block"
      borderRadius="full"
      w="8px"
      h="8px"
      backgroundColor={color}
      {...pick(props)}
    />
  );
}

export default StatusLabel;

export { StatusDot };
