/* eslint-disable @typescript-eslint/no-unsafe-argument */
import Select, { components, OptionProps } from "react-select";
import { useTheme } from "styled-components";
import { allPackages, isAllPackages, PackageOptionType } from "../util";
import { CommonDisplay, defaultComponents, selectStyles } from "./common";
import { SearchItem } from "../SearchItem";
import { PRIMARY_NAVIGATION_KEYS } from "~features/reports/reporting/navigation/primary.config";
import { InternalState, isPackageSource, SourceSelection } from "../interfaces";

interface PackageSelectProps {
  internalState: InternalState;
  onChange: (option: SourceSelection) => void;
  modal?: boolean;
}

export const Option = (props: OptionProps<PackageOptionType, false>) => {
  return (
    <components.Option {...props}>
      {isAllPackages(props.data) ? (
        <SearchItem active={props.isFocused}>All packages</SearchItem>
      ) : (
        <SearchItem active={props.isFocused}>{props.data.name}</SearchItem>
      )}
    </components.Option>
  );
};

export function PackageDisplay(props: InternalState) {
  const { small, selectedSource, searchablePackages } = props;

  if (!isPackageSource(selectedSource)) {
    return null;
  }

  let title;

  if (selectedSource === "all-packages") {
    title = allPackages.label;
  } else {
    const foundValue = searchablePackages?.find(
      (e) => e.id === selectedSource.packageId
    );

    title = foundValue?.name;
  }

  return (
    <CommonDisplay
      small={small}
      title={title}
      sourceLabel={"Packages"}
      image={null}
    />
  );
}

export default function PackageSelect(props: PackageSelectProps) {
  const {
    internalState: { searchablePackages },
  } = props;

  const theme = useTheme();

  const options: PackageOptionType[] = [];

  options.push({
    type: PRIMARY_NAVIGATION_KEYS.PACKAGES,
    label: "All packages",
    id: "all-packages",
  });
  options.push(...searchablePackages);

  const onChange = (option: PackageOptionType) => {
    const selectedSource = isAllPackages(option)
      ? "all-packages"
      : { packageId: option.id };

    props.onChange(selectedSource);
  };

  return (
    <Select
      menuIsOpen
      autoFocus
      options={options}
      getOptionValue={(option: PackageOptionType) => {
        return option.id;
      }}
      getOptionLabel={(option: PackageOptionType) => {
        return isAllPackages(option) ? option.label : option.name;
      }}
      onChange={onChange}
      components={{ ...defaultComponents, Option }}
      styles={selectStyles(theme)}
      placeholder="Search packages"
    />
  );
}
