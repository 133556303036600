import styled from "styled-components";

import { ScrollContainer } from "~components";

export const Grid = styled(ScrollContainer)`
  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    overflow-x: unset;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-gap: 24px;

    padding-bottom: 0;

    &:after {
      content: unset;
    }
  }
`;
