import React from "react";
import { Flex, PrimaryButton, TertiaryButton } from "flicket-ui";
import { Modal } from "../Modal";

export interface Alert {
  header?: string;
  description?: string;
  okButtonText?: string;
  onOk?: (close: () => void) => void;
  onCancel?: (close: () => void) => void;
  isOpen: boolean;
  close: () => void;
}

export const Alert = (props: Alert) => {
  return (
    <Modal
      header={props.header}
      footer={
        <Flex flexDir="row-reverse">
          <PrimaryButton onClick={() => props.onOk(props.close)} bg="error">
            {props.okButtonText || "Proceed"}
          </PrimaryButton>
          <TertiaryButton
            style={{ fontSize: "14px" }}
            onClick={
              props.onCancel ? () => props.onCancel(props.close) : props.close
            }
            mr={3}
          >
            Cancel
          </TertiaryButton>
        </Flex>
      }
      isOpen={props.isOpen}
      close={props.onCancel || props.close}
    >
      {props.description}
    </Modal>
  );
};
