import React, { ButtonHTMLAttributes, HTMLAttributes } from "react";

import { SystemProps, Text, TertiaryButton, UnderlineButton } from "flicket-ui";
import { Icon } from "~components";
import { IconProps } from "../Icon/Icon";
import styled from "styled-components";

export const AddButton = ({ children, ...props }) => {
  return (
    <TertiaryButton type="button" width="auto" px={2} fontSize={2} {...props}>
      <Icon icon="add" fontSize={6} mr={1} />
      {children}
    </TertiaryButton>
  );
};

const StyledButton = styled(UnderlineButton)`
  background-color: transparent;
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.N800};
  transition: background-color 0.1s ease-out;

  &:hover,
  &:visited,
  &:focus {
    color: ${({ theme }) => theme.colors.N800} !important;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.N100};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.N200};
  }
`;

export const IconLinkButton = ({
  children,
  icon,
  ...props
}: {
  children: React.ReactNode;
  icon: IconProps["icon"];
} & ButtonHTMLAttributes<HTMLButtonElement> &
  SystemProps) => {
  return (
    <StyledButton type="button" width="auto" px={2} fontSize={2} {...props}>
      <Text variant="regular" display="flex" alignItems="center">
        <Icon icon={icon} fontSize={6} mr={1} />
        {children}
      </Text>
    </StyledButton>
  );
};
