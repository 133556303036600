import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { DateRange } from "../types";
import { removeUndefined } from "~lib/helpers/removeUndefined";
import Router, { useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { DEFAULT_DATES, OPTION_ALL } from "~features/reports/constants";
import useLayoutContext from "./useLayoutContext";
import useNavigation from "../navigation/useNavigation";
import { ZonedDate } from "@flicket/utils";
import { i18n } from "~lib/i18n";
import { PRIMARY_NAVIGATION_KEYS } from "../navigation/primary.config";

type Filter = {
  show: boolean;
  value?: string;
};

export enum FilterActionTypes {
  UPDATE_DATE,
  SHOW_FILTER,
  CLEAR_FILTER,
  SET_FILTERS,
  CLEAR_ALL_FILTER,
}

interface FilterState {
  dateRange: DateRange;
  filters: {
    releaseId: Filter;
    channel: Filter;
  };
}

type UpdateDateRangeAction = {
  type: FilterActionTypes.UPDATE_DATE;
  dateRange: DateRange;
};

type ShowFilterAction = {
  type: FilterActionTypes.SHOW_FILTER;
  filter: keyof FilterState["filters"];
};

type ClearFilterAction = {
  type: FilterActionTypes.CLEAR_FILTER;
  filter: keyof FilterState["filters"];
};

type SetFiltersAction = {
  type: FilterActionTypes.SET_FILTERS;
  filters: Partial<
    {
      [key in keyof FilterState["filters"]]: string;
    }
  >;
};

type ClearAllFilterAction = {
  type: FilterActionTypes.CLEAR_ALL_FILTER;
};

type FilterActions =
  | UpdateDateRangeAction
  | ShowFilterAction
  | ClearFilterAction
  | SetFiltersAction
  | ClearAllFilterAction;

interface FilterContext {
  dateRange: DateRange;
  filters: {
    releaseId: Filter;
    channel: Filter;
  };
  dispatch: Dispatch<FilterActions>;
  getFilterValue: (filter: keyof FilterState["filters"]) => string | undefined;
  getFilterShow: (filter: keyof FilterState["filters"]) => boolean;
  getSubmissionFilterValue: (
    filter: keyof FilterState["filters"]
  ) => string | undefined;
  modifiedFiltersCount: number;
  displayedFiltersCount: number;
  isCreating: boolean;
  isDefaultDateRange: boolean;
}

function reducer(state: FilterState, action: FilterActions): FilterState {
  const { type } = action;
  switch (type) {
    case FilterActionTypes.UPDATE_DATE:
      return { ...state, dateRange: action.dateRange };
    case FilterActionTypes.SHOW_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filter]: { show: true },
        },
      };
    case FilterActionTypes.CLEAR_FILTER: {
      const newQuery = removeUndefined({
        ...Router.query,
        [action.filter]: undefined,
      });
      void Router.replace(
        {
          query: newQuery,
        },
        undefined,
        { shallow: true }
      );
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filter]: { show: false },
        },
      };
    }
    case FilterActionTypes.SET_FILTERS: {
      const newQuery = removeUndefined({
        ...Router.query,
        ...action.filters,
      });
      void Router.replace(
        {
          query: newQuery,
        },
        undefined,
        { shallow: true }
      );

      const updatedFilters = { ...state.filters };
      for (const [filter, value] of Object.entries(action.filters)) {
        updatedFilters[filter] = { show: true, value };
      }

      return {
        ...state,
        filters: updatedFilters,
      };
    }
    case FilterActionTypes.CLEAR_ALL_FILTER: {
      return {
        ...state,
        filters: {
          channel: { show: false },
          releaseId: { show: false },
        },
      };
    }
    default: {
      const exhaustiveCheck: never = type;
      return exhaustiveCheck;
    }
  }
}

const getFilterState = (query: ParsedUrlQuery, key: keyof ParsedUrlQuery) => ({
  show: !!query[key],
  value: query[key] as string | undefined,
});

const getInitialState = (
  query: ParsedUrlQuery,
  defaultDateRange?: DateRange
): FilterState => {
  return {
    dateRange: defaultDateRange ?? DEFAULT_DATES,
    filters: {
      channel: getFilterState(query, "channel"),
      releaseId: getFilterState(query, "releaseId"),
    },
  };
};

export const FilterContext = createContext<FilterContext>(null);

export default function useFilterContext(): FilterContext {
  const context = useContext(FilterContext);

  return context;
}

export const FilterContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const context = useLayoutContext();
  const { selectedSource } = useNavigation();
  const router = useRouter();
  const { query } = router;
  const { defaultDateRange } = context;
  const seasonSource =
    context.type === PRIMARY_NAVIGATION_KEYS.SEASONS && context.seasonSource;

  const firstRender = useRef(true);

  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(query, defaultDateRange)
  );

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return; // Skip the first render because we only want to clear filters when the event changes
    }

    dispatch({
      type: FilterActionTypes.CLEAR_ALL_FILTER,
    });
    dispatch({
      type: FilterActionTypes.UPDATE_DATE,
      dateRange: defaultDateRange ?? DEFAULT_DATES,
    });
  }, [selectedSource, seasonSource]);

  // Update the date range since the default date range might not be loading on the first render
  useEffect(() => {
    if (defaultDateRange) {
      dispatch({
        type: FilterActionTypes.UPDATE_DATE,
        dateRange: defaultDateRange,
      });
    }
  }, [!!defaultDateRange]);

  const { dateRange, filters } = state;

  // we don't want to pass "all" as a value to the backend
  // hence we need to transform them into undefined
  function getSubmissionFilterValue(
    filter: keyof FilterState["filters"]
  ): string | undefined {
    const value = getFilterValue(filter);
    if (value === OPTION_ALL) {
      return undefined;
    }
    return value;
  }

  function getFilterShow(filter: keyof FilterState["filters"]) {
    return state.filters[filter].show;
  }

  function getFilterValue(filter: keyof FilterState["filters"]) {
    return state.filters[filter].value;
  }

  const modifiedFiltersCount = Object.values(filters).filter(
    (filter) => typeof filter.value === "string" && filter.value !== OPTION_ALL
  ).length;

  const displayedFiltersCount = Object.values(filters).filter(
    (filter) => filter.show === true
  ).length;

  const isCreating = Object.values(filters).some(
    (filter) => filter.show === true && filter.value === undefined
  );

  const isDefaultDateRange =
    ZonedDate.utils.isSameDay(
      dateRange.startDate,
      defaultDateRange?.startDate,
      i18n.timezone
    ) &&
    ZonedDate.utils.isSameDay(
      dateRange.endDate,
      defaultDateRange?.endDate,
      i18n.timezone
    );

  return (
    <FilterContext.Provider
      value={{
        dateRange,
        dispatch,
        getFilterShow,
        getFilterValue,
        getSubmissionFilterValue,
        modifiedFiltersCount,
        filters,
        displayedFiltersCount,
        isCreating,
        isDefaultDateRange,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
