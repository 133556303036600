import React, { FC } from "react";

import { Box, Flex, SystemProps, Text, formatPrice } from "flicket-ui";
import { Icon, Status } from "~components";
import {
  ScoutCampaignOverviewOutput,
  ReportingFilterSource,
  ScoutCommunicationQuery,
} from "~graphql/sdk";
import { Card, Grid } from "../";
import { IconName } from "~components/common/Icon/Icon";

interface Props {
  data: ScoutCampaignOverviewOutput;
  error: Error;
  scoutCommunication: ScoutCommunicationQuery["scoutCommunication"];
  title?: string;
  releaseName?: string;
}

type OverviewItem = {
  title: string;
  icon: IconName;
};

type ScoutOverviewItem = OverviewItem & {
  dataKey: keyof ScoutCampaignOverviewOutput;
};

const scoutOverviewItems: ScoutOverviewItem[] = [
  {
    title: "Campaign size",
    icon: "customer",
    dataKey: "campaignSize",
  },
  {
    title: "Conversion rate",
    icon: "customer",
    dataKey: "conversionRate",
  },
  {
    title: "Revenue generated",
    icon: "customer",
    dataKey: "totalRevenue",
  },
  {
    title: "Contact rate",
    icon: "customer",
    dataKey: "contactRate",
  },
  { title: "Tickets issued", icon: "my-tickets", dataKey: "ticketsSold" },
  { title: "Total tickets sold", icon: "my-tickets", dataKey: "ticketsPaid" },
  { title: "Total comps issued", icon: "my-tickets", dataKey: "ticketsComp" },
  {
    title: "Memberships issued",
    icon: "membership",
    dataKey: "membershipsSold",
  },
  { title: "Add-ons issued", icon: "add-cart", dataKey: "addonsSold" },
  { title: "Orders", icon: "orders", dataKey: "orders" },
  {
    title: "Email open rate",
    icon: "email2",
    dataKey: "emailOpenRate",
  },
  {
    title: "Email click rate",
    icon: "email2",
    dataKey: "emailClickRate",
  },
];

type OverviewBoxProps = ScoutOverviewItem & {
  data: ScoutCampaignOverviewOutput;
};

const OverviewBox = ({ icon, dataKey, title, data }: OverviewBoxProps) => (
  <Box
    bg="P100"
    borderRadius="sm"
    p={2}
    flex={1}
    key={dataKey}
    mr={{ _: "6/4", md: 0 }}
    minWidth={{ _: 260, md: 220 }}
  >
    <Flex alignItems="center" mb={2}>
      <Flex
        as="span"
        borderRadius="full"
        bg="P300"
        width={32}
        height={32}
        variant="center"
        mr={1}
      >
        <Icon icon={icon} color="white" fontSize={4} />
      </Flex>
      <Text fontWeight="extraBold" color="N500">
        {title}
      </Text>
    </Flex>
    <Text fontWeight="extraBold" fontSize={6} color="N600">
      {dataKey === "totalRevenue" || dataKey === "todayRevenue"
        ? formatPrice(data?.[dataKey])
        : dataKey === "conversionRate" ||
          dataKey === "contactRate" ||
          dataKey === "emailOpenRate" ||
          dataKey === "emailClickRate"
        ? (data?.[dataKey] * 100).toFixed(2) + "%"
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          data?.[dataKey]?.toLocaleString()}
    </Text>
  </Box>
);

export const ScoutCampignOverview: FC<Props & SystemProps> = ({
  data,
  error,
  scoutCommunication,
  title,
  releaseName,
  ...props
}) => {
  return (
    <Card
      title={`Campaign overview${
        scoutCommunication?.trackSource && title
          ? `- tracking ${
              scoutCommunication?.trackSource ===
              ReportingFilterSource.Overall.toLowerCase()
                ? "everything"
                : title
            } ${releaseName ? `- ${releaseName}` : ""}`
          : ""
      }`}
      pr={{ _: 0, md: 4 }}
      position="relative"
      {...props}
    >
      <Status loading={!error && !data} error={error}>
        <Grid>
          {scoutOverviewItems.map(({ title, icon, dataKey }) => {
            if (data?.[dataKey] !== 0) {
              return (
                <OverviewBox
                  icon={icon}
                  title={title}
                  key={dataKey}
                  dataKey={dataKey}
                  data={data}
                />
              );
            }
          })}
        </Grid>
      </Status>
    </Card>
  );
};

export default ScoutCampignOverview;
