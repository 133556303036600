import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";

const SENTRY_DSN: string =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (!SENTRY_DSN) {
  throw new Error("SENTRY_DSN is not set");
}

if (!process.env.SENTRY_ENV) {
  console.warn("SENTRY_ENV is not set. Defaulting to development");
} else if (process.env.SENTRY_ENV !== "production") {
  console.log(`SENTRY_ENV: ${process.env.SENTRY_ENV}`);
}

const getSampleRate = () => {
  if (!process.env.SENTRY_ENV || process.env.SENTRY_ENV === "development") {
    console.warn("Sentry env is not set or is development. Skipping sampling");
    return 0;
  }

  return 0.2;
};

Sentry.init({
  autoSessionTracking: true,
  dsn: SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control

  tracesSampleRate: getSampleRate(),

  integrations: [new BrowserTracing()],

  // Use sentry env variable here as we don't use NODE_ENV to indicate staging,
  // whereas we do want the separation between staging/production/development
  // in sentry
  environment: process.env.SENTRY_ENV ?? "development",
  release: process.env.SENTRY_RELEASE ?? "testrelease",

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
