import { domain } from "~config";
import { Organization } from "~graphql/sdk";

const protocol = process.env.NODE_ENV !== "production" ? "http" : "https";

export const getUrl = (
  organizationSlug: Organization["slug"],
  admin?: boolean | undefined
) => {
  const url = `${organizationSlug}.${domain}`;

  if (process.env.NODE_ENV !== "production") {
    if (admin) {
      return `${protocol}://${url}:3000`;
    }

    return `${protocol}://${url}:4000`;
  }

  if (admin) {
    return `${protocol}://admin.${url}`;
  }

  return `${protocol}://${url}`;
};
