import { useRouter } from "next/router";
import { EventQuery } from "~graphql/sdk";

import useEventMutations from "./useEventMutations";
import { useQuery } from "~hooks/useQuery";
import { EventDocument, ReleasesDocument } from "~graphql/typed-document-nodes";
import { getErrorMessage } from "~lib";

export const NEW_EVENT_ID = "new";

export type Event = EventQuery["event"];

export const useEvent = (eventId?: string) => {
  const { query } = useRouter();
  const id = eventId ?? (query?.id as string);

  const {
    data: eventData,
    error: eventError,
    mutate,
    isLoading: isLoadingEvent,
    ...methods
  } = useQuery(
    id && id !== NEW_EVENT_ID ? EventDocument : null,
    { id },
    {
      revalidateOnFocus: false,
    }
  );

  const event = eventData?.event;

  const {
    data: releasesData,
    error: releaseError,
    isLoading: isLoadingReleases,
  } = useQuery(
    event ? ReleasesDocument : null,
    { event: id },
    {
      revalidateOnFocus: false,
    }
  );

  const releases = releasesData?.releases;

  const { createEvent, updateEvent, duplicateEvent } = useEventMutations({
    eventId: id,
    mutate,
  });

  const error = eventError || releaseError;

  return {
    ...methods,
    isLoading: isLoadingEvent || isLoadingReleases,
    mutate,
    error,
    errorMessage: error ? getErrorMessage(error) : undefined,
    event,
    releases,
    createEvent,
    updateEvent,
    duplicateEvent,
  };
};
