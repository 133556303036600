import React, { ReactNode, HTMLProps, forwardRef } from 'react';
import ReactElement from 'react';
import { omit, pick } from '@styled-system/props';
import { Flex, SystemProps, InputWrapper } from 'flicket-ui';
import styled from 'styled-components';
import { Percentage } from 'recharts';

const Wrapper = styled(Flex)<{ isValid?: boolean; prefix?: string }>`
  position: relative;

  &:after {
    content: '${(p) => p.prefix || '%'}';
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 40px;
    height: 50px;
    background-color: #f5f5f6;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border: 1px solid ${(p) => p.theme.colors[p.isValid ? 'N200' : 'error']};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:focus-within {
    &:after {
      border-color: ${(p) => p.theme.colors[p.isValid ? 'N500' : 'error']};
    }
  }
`;

const StyledInput = styled.input<{ isValid?: boolean }>`
  padding: 12px 16px;
  padding-left: 50px;

  color: ${(p) => p.theme.colors.N800};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  letter-spacing: -0.165px;
  line-height: 150%;

  border: 1px solid ${(p) => p.theme.colors[p.isValid ? 'N200' : 'error']};

  border-radius: ${(p) => p.theme.radii.sm};

  box-shadow: ${(p) => p.theme.shadows.inner.sm};

  &:focus {
    border-color: ${(p) => p.theme.colors[p.isValid ? 'N500' : 'error']};
  }

  &::placeholder {
    color: ${(p) => p.theme.colors.N400};
  }
`;

type FieldComponent = Omit<
  HTMLProps<HTMLInputElement>,
  'color' | 'as' | 'ref' | 'width'
>;

interface InputProps extends FieldComponent {
  label?: string;
  error?: string;
  errors?: any;
  prefix?: string;
}

export const PercentageInput = forwardRef<
  HTMLInputElement,
  InputProps & SystemProps
>(({ label, name, error, errors, onChange, prefix, ...props }, ref) => {
  error = error || errors?.[name]?.message;

  return (
    <InputWrapper label={label} name={name} error={error} {...pick(props)}>
      <Wrapper
        flexDirection="column"
        width="100%"
        isValid={!error}
        prefix={prefix}
      >
        <StyledInput
          ref={ref}
          name={name}
          onChange={(e) => {
            if (onChange) {
              let newValue: string | Number = parseFloat(e.target.value);

              if (Number.isNaN(newValue)) {
                newValue = '';
              }

              if (newValue.valueOf() > 100) {
                newValue = 100;
              }

              if (newValue.valueOf() < 0) {
                newValue = 0;
              }

              onChange({
                ...e,
                target: { ...e.target, value: newValue} as any,
              });
            }
          }}
          type="number"
          min={0}
          step={0.01}
          isValid={!error}
          {...omit(props)}
        />
      </Wrapper>
    </InputWrapper>
  );
});
