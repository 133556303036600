import React, { FC, ReactNode } from "react";
import { Flex, PrimaryButton, system, SystemProps } from "flicket-ui";
import styled from "styled-components";
import { pick } from "@styled-system/props";

export const Grid = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 40px;

  ${system}
`;

export const ResponsiveGrid = ({
  children,
  ...props
}: SystemProps & { children: ReactNode }) => {
  return (
    <Grid
      width={1}
      gridTemplateColumns={{
        _: "repeat(auto-fit, minmax(300px, 1fr))",
        sm: "repeat(auto-fit, minmax(400px, 1fr))",
      }}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      {...pick(props)}
    >
      {children}
    </Grid>
  );
};

export const SubmitButton: FC<{ form: string }> = ({ children, form }) => (
  <PrimaryButton type="submit" form={form} lineHeight="normal">
    {children}
  </PrimaryButton>
);
