import {
  EventWhereInput,
  OrderByDirection,
  EventsListWithStatsQuery,
} from "~graphql/sdk";

import { SegmentedControlData } from "flicket-ui";

export type EventItem = EventsListWithStatsQuery["events"]["edges"][number]["node"];
export type EventsParams = EventWhereInput & { orderBy?: OrderByDirection };

export type SegmentedOption = SegmentedControlData & {
  params: EventsParams;
};

export type SegmentedOptionValue = typeof segementedOptions[number]["value"];

export enum EventStatus {
  Published = "Published",
  Draft = "Draft",
  Past = "Past",
}

export const segementedOptions: SegmentedOption[] = [
  {
    label: "Upcoming",
    value: "Upcoming",
    params: {
      startDate: new Date().toISOString(),
      endDate: undefined,
      orderBy: OrderByDirection.Asc,
      isActive: true,
    },
  },
  {
    label: "Draft",
    value: "Draft",
    params: {
      startDate: new Date().toISOString(),
      endDate: undefined,
      orderBy: OrderByDirection.Asc,
      isActive: false,
    },
  },
  {
    label: "Past",
    value: "Past",
    params: {
      startDate: undefined,
      endDate: new Date().toISOString(),
      orderBy: OrderByDirection.Desc,
    },
  },
  {
    label: "All",
    value: "All",
    params: {
      startDate: undefined,
      endDate: undefined,
      orderBy: OrderByDirection.Desc,
    },
  },
];

export function getEventStatus(event: EventItem): EventStatus {
  if (event.hasEventEnded) {
    return EventStatus.Past;
  }
  if (event.isActive) {
    return EventStatus.Published;
  }
  if (!event.isActive) {
    return EventStatus.Draft;
  }
}

export function getEventStatusLabel(status: EventStatus) {
  switch (status) {
    case EventStatus.Past:
      return "Event past";
    case EventStatus.Published:
      return "Published";
    case EventStatus.Draft:
      return "Draft";
    default:
      return "";
  }
}
