import React, { ReactNode } from "react";
import { Flex, system, SystemProps, Text } from "flicket-ui";
import styled from "styled-components";
import { pick } from "@styled-system/props";

type BadgeProps = SystemProps & {
  children?: ReactNode;
  badgeContent: ReactNode;
  visible?: boolean;
};

export const Badge = ({
  children,
  badgeContent,
  visible = true,
  ...props
}: BadgeProps) => (
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  <BadgeWrapper {...pick(props)}>
    {children}
    {visible && (
      <BadgeBackground>
        <Text variant="regular" fontSize={1} color="white">
          {badgeContent}
        </Text>
      </BadgeBackground>
    )}
  </BadgeWrapper>
);

const BadgeBackground = styled(Flex)`
  --badge-size: 20px;
  position: absolute;
  min-width: var(--badge-size);
  height: var(--badge-size);
  border-radius: ${(p) => p.theme.radii.full};
  background-color: ${(p) => p.theme.colors.N800};
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
`;

const BadgeWrapper = styled(Flex)`
  position: relative;
  height: fit-content;
  width: fit-content;
  ${system}
`;
