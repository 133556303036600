export const SECONDARY_NAVIGATION_INDEX = 1;

export type ReportingNavigationSection = "scheduledReporting" | "dashboards";

export enum SECONDARY_NAVIGATION_KEYS {
  OVERVIEW = "OVERVIEW",
  REPORTS = "REPORTS",
  FINANCIALS = "FINANCIALS",
  SALES_REPORT = "SALES_REPORT",
  CAPACITY = "CAPACITY",
  SCAN = "SCAN",
  AUDIENCE = "AUDIENCE",
  CODE = "CODE",
  MARKETING = "MARKETING",
  SCHEDULED_REPORTING = "SCHEDULED_REPORTING",
  POS = "POS",
}

export const secondaryNavigationConfig: {
  [Property in SECONDARY_NAVIGATION_KEYS]: {
    tabKey: SECONDARY_NAVIGATION_KEYS;
    slugSegment: string;
    section: ReportingNavigationSection;
  };
} = {
  [SECONDARY_NAVIGATION_KEYS.OVERVIEW]: {
    tabKey: SECONDARY_NAVIGATION_KEYS.OVERVIEW,
    slugSegment: "overview",
    section: "dashboards",
  },
  [SECONDARY_NAVIGATION_KEYS.REPORTS]: {
    tabKey: SECONDARY_NAVIGATION_KEYS.REPORTS,
    slugSegment: "reports",
    section: "dashboards",
  },
  [SECONDARY_NAVIGATION_KEYS.SALES_REPORT]: {
    tabKey: SECONDARY_NAVIGATION_KEYS.SALES_REPORT,
    slugSegment: "ticket-sales",
    section: "dashboards",
  },
  [SECONDARY_NAVIGATION_KEYS.FINANCIALS]: {
    tabKey: SECONDARY_NAVIGATION_KEYS.FINANCIALS,
    slugSegment: "financial",
    section: "dashboards",
  },
  [SECONDARY_NAVIGATION_KEYS.CAPACITY]: {
    tabKey: SECONDARY_NAVIGATION_KEYS.CAPACITY,
    slugSegment: "capacity",
    section: "dashboards",
  },
  [SECONDARY_NAVIGATION_KEYS.SCAN]: {
    tabKey: SECONDARY_NAVIGATION_KEYS.SCAN,
    slugSegment: "scan",
    section: "dashboards",
  },
  [SECONDARY_NAVIGATION_KEYS.AUDIENCE]: {
    tabKey: SECONDARY_NAVIGATION_KEYS.AUDIENCE,
    slugSegment: "audience",
    section: "dashboards",
  },
  [SECONDARY_NAVIGATION_KEYS.MARKETING]: {
    tabKey: SECONDARY_NAVIGATION_KEYS.MARKETING,
    slugSegment: "marketing",
    section: "dashboards",
  },
  [SECONDARY_NAVIGATION_KEYS.SCHEDULED_REPORTING]: {
    tabKey: SECONDARY_NAVIGATION_KEYS.SCHEDULED_REPORTING,
    slugSegment: "scheduled-reporting-sales",
    section: "scheduledReporting",
  },
  [SECONDARY_NAVIGATION_KEYS.CODE]: {
    tabKey: SECONDARY_NAVIGATION_KEYS.CODE,
    slugSegment: "code",
    section: "dashboards",
  },
  [SECONDARY_NAVIGATION_KEYS.POS]: {
    tabKey: SECONDARY_NAVIGATION_KEYS.POS,
    slugSegment: "pos",
    section: "dashboards",
  },
};
