import { Flex, system, SystemProps, Text } from "flicket-ui";
import styled from "styled-components";
import {
  earliestStartDate,
  greatestEndDate,
} from "~lib/helpers/dates/formatDateRange";
import { SearchEvent } from "~graphql/typed-document-nodes";
import React, { memo } from "react";
import formatDateRangeDayOnly from "./util";

const StyledSearchItem = styled(Flex)`
  padding: ${(p) => p.theme.space[1]}px ${(p) => p.theme.space[1]}px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  ${system}
`;

interface EventSearchItemProps {
  active: boolean;
  searchEvent: SearchEvent;
}

export const EventSearchItem = memo(function EventSearchItem(
  props: EventSearchItemProps
) {
  const { searchEvent, active } = props;

  const eventDate = formatDateRangeDayOnly({
    start: earliestStartDate(searchEvent.dates),
    end: greatestEndDate(searchEvent.dates),
  });

  return (
    <SearchItem
      active={active}
      postContent={
        <Text
          variant="small"
          textAlign={"right"}
          color="N600"
          minWidth="100px"
          mr={2}
        >
          {eventDate}
        </Text>
      }
    >
      {searchEvent.title}
    </SearchItem>
  );
});

interface SearchItemProps extends SystemProps {
  active: boolean;
  children: React.ReactNode;
  postContent?: React.ReactNode;
}
export const SearchItem = memo(function SearchItem(props: SearchItemProps) {
  const { active, children, postContent, ...rest } = props;
  return (
    <StyledSearchItem
      backgroundColor={active ? "N100" : "transparent"}
      {...rest}
    >
      <Text variant="regular" mr={1}>
        {children}
      </Text>
      {postContent}
    </StyledSearchItem>
  );
});
