import { showToast, getError } from "~lib/helpers";

export type State<T> = [error?: Error, data?: T];

const handlePromise = async <T>(
  promise: () => Promise<T>,
  toast?: { success?: string; errorMsg?: boolean | string }
): Promise<State<T>> =>
  promise()
    .then((result) => {
      if (toast?.success) {
        showToast(toast?.success, "success");
      }

      const res: State<T> = [undefined, result];
      return res;
    })
    .catch((error: Error) => {
      if (toast?.errorMsg) {
        const msg =
          typeof toast?.errorMsg === "string"
            ? toast?.errorMsg
            : getError(error, "graphQL");

        showToast(msg, "error");
      }
      return [error, undefined];
    });

const delay = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export async function delayedPromise<TData>(
  promise: () => Promise<TData>,
  ms = 800
): Promise<State<TData>> {
  return Promise.allSettled([promise(), delay(ms)]).then((data) => {
    if (data[0].status === "rejected") {
      console.error("error", data[0].reason);
      return [data[0].reason as Error, undefined];
    } else {
      return [undefined, data[0].value];
    }
  });
}

export default handlePromise;
