/* eslint-disable react/prop-types */
import { SystemProps, system } from "flicket-ui";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { countries } from "~forms/settings/venues/countries";
import { useOrganization } from "~hooks/useOrganization";
import { pick } from "@styled-system/props";
import { noop } from "lodash";
import { useOnClickOutside } from "usehooks-ts";
import { Coordinate } from "~graphql/sdk";
import { LocaleHelpers } from "locale-helpers";

// Imported as global styles in CSSReset.ts because the Google Maps API
// adds the dropdown in the DOM outside of this component.
// Reference: https://developers.google.com/maps/documentation/javascript/place-autocomplete#style-autocomplete
export const mapStyles = css`
  .pac-container {
    border-radius: 8px;
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: ${(p) => p.theme.shadows.container};

    &:after {
      // Hide 'powered by Google'
      background-image: none !important;
      height: 0px;
    }

    .pac-icon {
      display: none;
    }

    span {
      margin: 0;
      padding: 0;
    }

    // An item in the list of predictions supplied by the Autocomplete or SearchBox widget.
    .pac-item {
      font-family: ${(p) => p.theme.fonts.body};
      font-size: ${(p) => p.theme.fontSizes[2]};
      font-weight: ${(p) => p.theme.fontWeights.regular as string};
      color: ${(p) => p.theme.colors.N500};
      margin: 0 6px;
      border: none;
      padding: 10px 10px;
      line-height: 1.3;
      border-radius: 4px;
    }

    .pac-item:first-child {
      margin-top: 6px;
    }

    .pac-item:last-child {
      margin-bottom: 6px;
    }

    .pac-item:hover,
    .pac-item-selected {
      background: ${(p) => p.theme.colors.N100};
      cursor: pointer;
    }
    .pac-item-selected {
    }
    .pac-item-query {
      display: block;
      color: ${(p) => p.theme.colors.N800};
      font-size: ${(p) => p.theme.fontSizes[3]};
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .pac-matched {
      color: inherit;
      font-weight: inherit;
    }
  }
`;

const StyledMapDiv = styled.div<SystemProps>`
  display: block;
  min-width: 100%;
  max-width: 100%;
  height: 240px;
  border-radius: ${(p) => p.theme.radii.md};

  ${system}
`;

export type MapOnChangeValues = {
  latitude: number;
  longitude: number;
  name: string | undefined;
  formattedAddress: string;
  timezone: string;
  locale: string;
  addressKeys: Record<
    typeof googleTypeToDbAddress[keyof typeof googleTypeToDbAddress],
    string
  >;
};

interface MapProps {
  onChange: (values: MapOnChangeValues) => void;
  onLoad: () => void;
  latitude?: number;
  longitude?: number;
  searchInputRef: React.RefObject<HTMLInputElement>;
  onClickOutsideDropdown?: () => void;
}

const KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
const ZOOM_WHEN_VENUE_IS_SET = 17; // seems to be the common zoom.
const GOOGLE_MAP_BASE_URI = "maps.googleapis.com";
const mapSrc = `https://${GOOGLE_MAP_BASE_URI}/maps/api/js?key=${KEY}&libraries=places&callback=initGoogleMap`;

const googleTypeToDbAddress = {
  street_number: "address.line1",
  route: "address.line2",
  sublocality_level_1: "address.suburb",
  sublocality: "address.suburb",
  locality: "address.city",
  administrative_area_level_2: "address.suburb",
  administrative_area_level_1: "address.state",
  country: "address.country",
  postal_code: "address.postalCode",
} as const;

const googleTypeKeys = Object.keys(googleTypeToDbAddress);

const getGoogleMapsScriptElement = (src: string): HTMLScriptElement | null =>
  document.querySelector(`script[src="${src}"]`);

const generateEmptyAddress = () =>
  Object.values(googleTypeToDbAddress).reduce(
    (o, key) => ({ ...o, [key]: "" }),
    {}
  );

const convertGoogleAdressComponentForDB = (
  input: google.maps.GeocoderAddressComponent[]
) => {
  const address: Record<string, string> = generateEmptyAddress();
  input.forEach((inputObject, item) => {
    googleTypeKeys.forEach((keyObject) => {
      if (inputObject.types.includes(keyObject)) {
        address[googleTypeToDbAddress[keyObject]] =
          address[googleTypeToDbAddress[keyObject]] ?? "";
        const hasInfo = !!address[googleTypeToDbAddress[keyObject]];
        if (
          !address[googleTypeToDbAddress[keyObject]].includes(
            inputObject.long_name
          )
        ) {
          address[googleTypeToDbAddress[keyObject]] += `${hasInfo ? " " : ""}${
            inputObject.short_name
          }`;
        }
      }
    });
  });
  return address;
};

const validateLatLng = (lat: number | undefined, lng: number | undefined) => {
  // to deal with cases where the lat and lng are non valid numbers.
  // (-1.0,-1.0)
  // (1.0,1.0)
  return (
    lat && lng && !((lat == -1.0 && lng == -1.0) || (lat == 1.0 && lng == 1.0))
  );
};

const GoogleMap = memo<MapProps & SystemProps>(
  ({
    latitude,
    longitude,
    onChange,
    searchInputRef,
    onLoad,
    onClickOutsideDropdown,
    ...props
  }) => {
    const mapRef = useRef<HTMLDivElement>(null);
    const [googleMapLoaded, setGoogleMapLoaded] = useState<boolean>(false);
    const { organization } = useOrganization();

    useOnClickOutside(searchInputRef, () => onClickOutsideDropdown?.());

    // here we wait for all the dependencies before we load the map.
    useEffect(() => {
      if (
        typeof window !== "undefined" &&
        typeof window.google !== "undefined" &&
        mapRef.current &&
        organization &&
        searchInputRef.current &&
        googleMapLoaded
      ) {
        loadMap();
      }
    }, [mapRef.current, organization, searchInputRef.current, googleMapLoaded]);

    const loadMap = useCallback(() => {
      // if the customer doesnt have a country set then we will show the whole globe and set NZ as the center point.
      let defaultMapParams = {
        zoom: 1,
        disableDefaultUI: true,
        zoomControl: true,
        fullscreenControl: true,
        gestureHandling: "none" as google.maps.GestureHandlingOptions,
        center: {
          lat: countries.NZ.Latitude,
          lng: countries.NZ.Longitude,
        },
      };

      if (organization?.address?.country) {
        defaultMapParams = {
          ...defaultMapParams,
          zoom: 5,
          center: {
            lat: countries[organization.address.country].Latitude,
            lng: countries[organization.address.country].Longitude,
          },
        };
      }

      const hasVenueLatLng = validateLatLng(latitude, longitude);

      if (hasVenueLatLng) {
        defaultMapParams = {
          ...defaultMapParams,
          zoom: ZOOM_WHEN_VENUE_IS_SET,
          center: {
            lat: latitude,
            lng: longitude,
          },
        };
      }

      const newMap = new window.google.maps.Map(
        mapRef.current,
        defaultMapParams
      );
      const options: google.maps.places.AutocompleteOptions = {
        fields: ["formatted_address", "geometry", "name", "address_component"],
        strictBounds: false,
        types: [], // all types.
      };

      const input = document.getElementById(
        searchInputRef.current.id
      ) as HTMLInputElement;

      const autocomplete = new google.maps.places.Autocomplete(input, options);

      autocomplete.bindTo("bounds", newMap);

      const marker = new google.maps.Marker({
        map: newMap,
        anchorPoint: new google.maps.Point(0, -29), // so the anchor point doesnt cover the actual position.
      });

      // if the venue lat and lng have been provided we want to set the marker on load.
      if (hasVenueLatLng) {
        marker.setPosition(defaultMapParams.center);
        marker.setVisible(true);
      }

      autocomplete.addListener("place_changed", () => {
        void handlePlaceChanged();

        async function handlePlaceChanged() {
          marker.setVisible(false);

          const place = autocomplete.getPlace();

          if (!place.geometry || !place.geometry.location) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            return;
          }

          // If the place has a geometry, then present it on a map.
          if (place.geometry.viewport) {
            newMap.fitBounds(place.geometry.viewport);
          } else {
            newMap.setCenter(place.geometry.location);
            newMap.setZoom(ZOOM_WHEN_VENUE_IS_SET);
          }

          marker.setPosition(place.geometry.location);
          marker.setVisible(true);

          const address = convertGoogleAdressComponentForDB(
            place.address_components
          );
          if (!address["address.line1"]) {
            address["address.line1"] = place.name;
          }

          const latitude = place.geometry.location.lat();
          const longitude = place.geometry.location.lng();

          const timezone = await fetchTimezoneFromLocation({
            lat: latitude,
            lng: longitude,
          });

          const locale = getLocaleFromCountryCode(address["address.country"]);

          const values = {
            latitude: latitude,
            longitude: longitude,
            name: place.name,
            formattedAddress: searchInputRef.current.value,
            addressKeys: address,
            locale,
            timezone,
          };

          onChange(values);
        }
      });

      onLoad();
    }, [mapRef.current, organization, searchInputRef.current]);

    useEffect(() => {
      if (typeof window === "undefined") return;

      window.initGoogleMap = () => {
        setGoogleMapLoaded(true);
      };
      const existingScript = getGoogleMapsScriptElement(mapSrc);
      if (existingScript) {
        setGoogleMapLoaded(true);
      } else {
        const script = document.createElement("script");
        script.src = mapSrc;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      }
      return () => {
        // Set init function to no-op because the google script can still call this function
        // if the user tabs away from the page before the script has loaded.
        window.initGoogleMap = noop;
      };
    }, []);

    return <StyledMapDiv ref={mapRef} {...pick(props)} />;
  }
);

GoogleMap.displayName = "GoogleMap";

export default GoogleMap;

async function fetchTimezoneFromLocation(
  location: Coordinate
): Promise<string> {
  try {
    const key = process.env.GOOGLE_TIMEZONE_API_KEY;

    if (!key) {
      throw new Error("Missing Google Timezone API key");
    }

    // See - https://developers.google.com/maps/documentation/timezone/get-started
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${
        location?.lat
      },${location?.lng}&key=${key}&timestamp=${Date.now() / 1000}`
    );

    if (!response.ok) {
      throw new Error(
        `ResponseCode: ${response.status}, Response: ${response.statusText}`
      );
    }

    // Response shape:
    // {
    //   dstOffset: 0,
    //   rawOffset: -18000,
    //   status: 'OK',
    //   timeZoneId: 'America/Jamaica',
    //   timeZoneName: 'Eastern Standard Time'
    // }

    const json = (await response.json()) as { timeZoneId: string };

    return json.timeZoneId;
  } catch (error) {
    // How to handle no timezone error?
    console.error({
      message: `Failed to get timezone data`,
      err: error as Error,
    });
  }

  return null;
}

function getLocaleFromCountryCode(country = "NZ"): string {
  // TODO: figure out why custom.d.ts is not picking up this module.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const locale = LocaleHelpers.bestLocaleFor({
    language: "en",
    country,
  }) as string;

  return locale.replace("_", "-");
}
