import { Modal } from "~components";
import React, { useRef } from "react";

import "react-advanced-cropper/dist/style.css";
import { ImageCropper, ImageCropperProps } from "./ImageCropper";
import styled from "styled-components";
import {
  Flex,
  TertiaryButton,
  PrimaryButton,
  SystemProps,
  system,
  Stack,
  Box,
} from "flicket-ui";
import { CropperRef } from "react-advanced-cropper";
import CustomModal from "../CustomModal";

interface ImageCropperModalProps {
  isOpen: boolean;
  onClose: () => void;
  src: string;
  onCrop: (blob: Blob) => void;
  stencilProps?: ImageCropperProps["stencilProps"];
}

const StyledImageCropper = styled(ImageCropper)<SystemProps>`
  ${system}
`;

export default function ImageCropperModal(props: ImageCropperModalProps) {
  const { isOpen, onClose, src, onCrop, stencilProps = {} } = props;

  const cropperRef = useRef<CropperRef>(null);

  const onCropInternal = () => {
    if (cropperRef.current) {
      cropperRef.current.getCanvas()?.toBlob((blob) => {
        if (blob) {
          onCrop(blob);
        }
      });
    }
  };

  return (
    <CustomModal key={src} isOpen={isOpen} close={onClose}>
      <CustomModal.Header>Crop image</CustomModal.Header>
      <CustomModal.Content py={0} paddingLeft={0} paddingRight={0}>
        {/* <Box style={{ aspectRatio: stencilProps.aspectRatio.toString() }}> */}
        <StyledImageCropper
          ref={cropperRef}
          flex={1}
          src={src}
          stencilProps={stencilProps}
          extraFooterContent={
            <Box display={{ _: "none", sm: "block" }}>
              <Stack gap={1} justifyContent="flex-end">
                <TertiaryButton onClick={onClose}>Cancel</TertiaryButton>
                <PrimaryButton onClick={() => onCropInternal()}>
                  Save
                </PrimaryButton>
              </Stack>
            </Box>
          }
        />
        {/* </Box> */}
      </CustomModal.Content>

      <CustomModal.Footer display={{ _: "block", sm: "none" }}>
        <Stack gap={1} justifyContent="flex-end">
          <TertiaryButton onClick={onClose}>Cancel</TertiaryButton>
          <PrimaryButton onClick={() => onCropInternal()}>Save</PrimaryButton>
        </Stack>
      </CustomModal.Footer>
    </CustomModal>
  );
}
