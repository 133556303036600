import { Box, Divider, Stack, Text } from "flicket-ui";
import { forwardRef } from "react";
import Select, {
  components,
  ControlProps,
  GroupType,
  OptionProps,
  ValueContainerProps,
} from "react-select";
import { SearchItem } from "../SourceSwitcher/SearchItem";
import { CSSProperties, DefaultTheme, useTheme } from "styled-components";
import { Check, MagnifyingGlass } from "@phosphor-icons/react";
import { Icon } from "~components";

type FilterDropdownBoxProps = {
  options: GroupedOptions;
  isLoading?: boolean;
  title: string;
  onSelectChange: (value: string) => void;
  value: Option;
  placeholder: string;
};

export type GroupedOptions = (Option | GroupType<Option>)[];

type Option = {
  label: string;
  value: string;
};

const selectStyles = (theme: DefaultTheme) => ({
  menu: (baseStyle: CSSProperties): CSSProperties => ({
    ...baseStyle,
    position: "relative",
    boxShadow: "none",
    top: 0,
    flex: 1,
  }),
  option: (): CSSProperties => ({}),
  control: (
    baseStyles: CSSProperties,
    state: ControlProps<Option, false>
  ): CSSProperties | { [key: string]: CSSProperties } => ({
    ...baseStyles,
    border: `1px solid ${
      state.isFocused ? theme.colors.N500 : theme.colors.N200
    }`,
    "&:hover": {
      border: `1px solid ${theme.colors.N500}`,
    },
    boxShadow: "none",
  }),
  groupHeading: (baseStyle: CSSProperties): CSSProperties => ({
    ...baseStyle,
    padding: 0,
  }),
});

const formatGroupLabel = () => <Divider my={1} />;

export const FilterDropdownBox = forwardRef<
  HTMLElement,
  FilterDropdownBoxProps
>((props, ref) => {
  const {
    options,
    isLoading,
    title,
    onSelectChange,
    value,
    placeholder,
  } = props;
  const theme = useTheme();

  const Option = (props: OptionProps<Option, false>) => {
    return (
      <components.Option {...props}>
        <SearchItem
          active={props.isSelected || props.isFocused}
          postContent={props.isSelected && <Icon icon={<Check />} />}
        >
          {props.data.label}
        </SearchItem>
      </components.Option>
    );
  };

  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<Option, false>) => (
    <Stack alignItems={"center"} px={"6/4"}>
      <MagnifyingGlass size={24} weight="light" />
      <Text variant="regular">
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      </Text>
    </Stack>
  );

  const customFilter = (option: Option, searchText: string) => {
    // only search by label
    // by default, react-select uses both label and value to search the result
    // since the value of release is a uuid, search by value will return unexpected results
    if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box
      width={380}
      position={"absolute"}
      top={50}
      background={"white"}
      zIndex={100}
      borderRadius={"md"}
      padding={2}
      boxShadow={"0px 2px 10px 0px #00000026" as any}
      ref={ref}
    >
      <Text variant="header.XS" color="N600" mb={1}>
        {title}
      </Text>
      <Select
        menuIsOpen
        options={options}
        onChange={(filterOption: Option) => {
          onSelectChange(filterOption.value);
        }}
        components={{
          Option,
          DropdownIndicator: null,
          ValueContainer,
        }}
        styles={selectStyles(theme)}
        controlShouldRenderValue={false}
        tabSelectsValue={false}
        isClearable={false}
        backspaceRemovesValue={false}
        isLoading={isLoading}
        formatGroupLabel={formatGroupLabel}
        filterOption={customFilter}
        value={value}
        placeholder={placeholder}
      />
    </Box>
  );
});

FilterDropdownBox.displayName = "FilterDropdownBox";
