import { Box, SystemProps } from "flicket-ui";
import { pick } from "@styled-system/props";

type BarProps = SystemProps & {
  percentage: number;
  barColour?: SystemProps["backgroundColor"];
  border?: SystemProps["borderRadius"];
  backgroundColor?: SystemProps["backgroundColor"];
  barHeight?: string | number;
  boxHeight?: number;
  barStyle?: React.CSSProperties;
};

export const PercentBar = ({
  percentage,
  barColour = "N800",
  backgroundColor = "N100",
  border,
  barHeight = "100%",
  barStyle,
  boxHeight = 3,
  ...props
}: BarProps) => {
  return (
    <Box
      height={boxHeight}
      width="100%"
      backgroundColor={backgroundColor}
      {...pick(props)}
      borderRadius={border}
      position={"relative"}
    >
      <Box
        height={barHeight}
        width={`${percentage}%`}
        backgroundColor={barColour}
        borderRadius={border}
        style={barStyle}
      />
    </Box>
  );
};
