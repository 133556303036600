import { SlidersHorizontal } from "@phosphor-icons/react";
import { Text } from "flicket-ui";
import { css } from "styled-components";
import { Dropdown, Icon } from "~components";
import { WhiteButton } from "~components/common/WhiteButton";
import { useAddFilterOptions } from "~features/reports/reporting/hooks/useAddFilterOptions";
import useFilterContext from "~features/reports/reporting/hooks/useFiltersContext";

export const AddFilter = () => {
  const options = useAddFilterOptions();
  const { isCreating } = useFilterContext();

  if (options.length === 0 || isCreating) return null;

  return (
    <Dropdown
      options={options}
      DropdownButton={(props) => (
        <WhiteButton {...props} height={48}>
          <Icon icon={<SlidersHorizontal />} mr={1} />
          <Text variant="regular">Add filter</Text>
        </WhiteButton>
      )}
      optionStyle={css((p) => ({
        color: p.theme.colors.N800,
        "&:hover": {
          backgroundColor: p.theme.colors.hoverBackground6,
        },
        paddingLeft: "12px",
      }))}
      optionWrapperStyle={css((p) => ({
        borderBottom: `1px solid ${p.theme.colors.N200}`,
        ":last-child": {
          borderBottom: "1px solid white",
        },
        ":hover": {
          borderBottom: "1px solid white",
        },
        // hide bottom border when the next element is on hover
        "&:has(+ &:hover)": {
          borderBottom: "1px solid white",
        },
        fontSize: "16px",
      }))}
    />
  );
};
