import Select, { components, GroupType, OptionProps } from "react-select";
import { useTheme } from "styled-components";
import {
  allSeasons,
  groupSeasons,
  isAllSeasons,
  SeasonOptionType,
} from "../../util";
import {
  CommonDisplay,
  defaultComponents,
  renderGroup,
  selectStyles,
} from "../common";
import { SearchItem } from "../../SearchItem";
import { Skeleton } from "~components";
import { Box, Text } from "flicket-ui";
import { DATE_FORMAT } from "~lib/helpers/dates/formatDateRange";
import { formatDate, notEmptyPredicate } from "~lib";
import { ReactNode } from "react";
import {
  InternalState,
  isSeasonSource,
  isSpecificSeasonSource,
  SourceSelection,
} from "../../interfaces";
import { useQuery } from "~hooks/useQuery";
import { SeasonInformationDocument } from "~graphql/typed-document-nodes";

interface SeasonSelectProps {
  internalState: InternalState;
  onChange: (option: SourceSelection) => void;
}

export const Option = (props: OptionProps<SeasonOptionType, false>) => {
  return (
    <components.Option {...props}>
      {isAllSeasons(props.data) ? (
        <SearchItem active={props.isFocused}>All seasons</SearchItem>
      ) : (
        <SearchItem active={props.isFocused}>{props.data.name}</SearchItem>
      )}
    </components.Option>
  );
};

function Subtitle(props: InternalState) {
  const { searchIndexLoading, selectedSource } = props;

  const { data, isLoading } = useQuery(
    isSpecificSeasonSource(selectedSource) ? SeasonInformationDocument : null,
    isSpecificSeasonSource(selectedSource) && {
      seasonId: selectedSource.seasonId,
    }
  );

  if (searchIndexLoading || isLoading) {
    return <Skeleton height={15} maxWidth={"400px"} mt={"6/4"} />;
  }

  if (!isSpecificSeasonSource(selectedSource) || !data?.seasonInformation) {
    return null;
  }

  const date = [
    data.seasonInformation?.startDate,
    data.seasonInformation?.endDate,
  ]
    .filter(notEmptyPredicate)
    .map((d) => formatDate(d, DATE_FORMAT))
    .join(" - ");

  return (
    <Box mt={1}>
      {date && <Text variant="small">{date}</Text>}
      {data.seasonInformation?.location && (
        <Text variant="small">{data.seasonInformation?.location}</Text>
      )}
    </Box>
  );
}

export function SeasonDisplay(props: InternalState) {
  const {
    selectedSource,
    searchableSeasons,
    small,
    renderSeasonPostSubtitle,
  } = props;

  const { data, isLoading } = useQuery(
    isSpecificSeasonSource(selectedSource) ? SeasonInformationDocument : null,
    isSpecificSeasonSource(selectedSource) && {
      seasonId: selectedSource.seasonId,
    }
  );

  if (!isSeasonSource(selectedSource)) {
    return null;
  }

  let title: ReactNode;

  if (selectedSource === "all-seasons") {
    title = allSeasons.label;
  } else {
    const foundValue = searchableSeasons?.find(
      (e) => e.id === selectedSource.seasonId
    );

    title = data?.seasonInformation?.name ?? foundValue?.name;
  }

  return (
    <CommonDisplay
      title={title}
      small={small}
      loading={isLoading}
      subtitle={
        <>
          <Subtitle {...props} />
          {renderSeasonPostSubtitle?.()}
        </>
      }
      sourceLabel={"Seasons"}
      image={null}
    />
  );
}

export default function SeasonSelect(props: SeasonSelectProps) {
  const {
    internalState: { searchableSeasons },
  } = props;
  const theme = useTheme();

  const options: (SeasonOptionType | GroupType<SeasonOptionType>)[] = [];

  options.push(allSeasons);
  options.push(...groupSeasons(searchableSeasons));

  const onChange = (option: SeasonOptionType) => {
    const selectedSource = isAllSeasons(option)
      ? "all-seasons"
      : { seasonId: option.id };

    props.onChange(selectedSource);
  };

  return (
    <Select
      menuIsOpen
      autoFocus
      options={options}
      getOptionValue={(option: SeasonOptionType) => {
        return option.id;
      }}
      getOptionLabel={(option: SeasonOptionType) => {
        return isAllSeasons(option) ? option.label : option.name;
      }}
      formatGroupLabel={renderGroup}
      onChange={onChange}
      components={{ ...defaultComponents, Option }}
      styles={selectStyles(theme)}
      placeholder="Search seasons"
    />
  );
}
