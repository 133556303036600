import { Box, SystemProps } from "flicket-ui";
import { FC } from "react";
import { pick } from "@styled-system/props";
// TODO: Replace this eventually with a more extensible component
// from flicket-ui

interface PillProps extends SystemProps {
  labelProps?: SystemProps;
}

export const Pill: FC<PillProps> = ({
  children,
  labelProps = {},
  ...props
}) => {
  return (
    <Box
      display={"inline-flex"}
      background={"#efefef"}
      borderRadius="lg"
      flexDir={"row"}
      alignItems="center"
      paddingX={2}
      width="fit-content"
      as="span"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      {...pick(props)}
    >
      <Box
        as="span"
        display={"inline-flex"}
        cursor={"pointer"}
        position={"relative"}
        fontSize={"13px" as any}
        lineHeight={1.6 as any}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        {...pick(labelProps)}
      >
        {children}
      </Box>
    </Box>
  );
};
