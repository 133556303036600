import { useState } from "react";
import useSWR from "swr";

import {
  CreateOrderEventsQuery,
  EventsQuery,
  EventWhereInput,
  OrderByDirection,
  ParamsEventsQuery,
  Role,
} from "~graphql/sdk";
import { useSDK, useUser } from "~hooks";

export type Params = EventWhereInput & { orderBy: OrderByDirection };

type Events = EventsQuery["events"]["edges"][number]["node"][];
export type ParamsEvents = ParamsEventsQuery["events"]["edges"][number]["node"][];
type CreateOrderEvents = CreateOrderEventsQuery["events"]["edges"][number]["node"][];

const getQueryParams = (search: string, { orderBy, ...params }: Params) => {
  return {
    where: {
      q: search,
      isActive: null,
      ...params,
    },
    orderBy: { startDate: orderBy },
  };
};

export const useEvents = (
  initialParams: Partial<Params> = {},
  options?: { adminAccessFilter?: boolean },
  fetchType?: { eventsForParams?: boolean; createOrder?: boolean }
) => {
  const sdk = useSDK();
  const [search, setSearch] = useState<string>("");
  const { user } = useUser();
  const [params, setParams] = useState<Params>({
    startDate: new Date().toString(),
    orderBy: OrderByDirection.Asc,
    ...initialParams,
  });

  // If user is only scoped for certain events, we need to filter the events
  const eventAccessFilter = <T>(events: T): T => {
    if (
      !options?.adminAccessFilter ||
      !user.roles.includes(Role.EventReporting)
    ) {
      return events;
    }
  };

  const getEvents = (
    search: string,
    { orderBy, ...params }: Params
  ): (() => Promise<Events>) => {
    return async () =>
      sdk
        .events(getQueryParams(search, { orderBy, ...params }))
        .then((res) =>
          eventAccessFilter(res.events?.edges)?.map(({ node }) => node)
        );
  };

  const getParamsEvents = (
    search: string,
    { orderBy, ...params }: Params
  ): (() => Promise<ParamsEvents>) => {
    return async () =>
      sdk
        .paramsEvents(getQueryParams(search, { orderBy, ...params }))
        .then((res) =>
          eventAccessFilter(res.events?.edges)?.map(({ node }) => node)
        );
  };

  const getCreateOrderEvents = (
    search: string,
    { orderBy, ...params }: Params
  ): (() => Promise<CreateOrderEvents>) => {
    return async () =>
      sdk
        .createOrderEvents(getQueryParams(search, { orderBy, ...params }))
        .then((res) =>
          eventAccessFilter(res.events?.edges)?.map(({ node }) => node)
        );
  };

  const { data: events, error, ...methods } = useSWR<Events>(
    !fetchType && ["events", search, params],
    getEvents(search, params)
  );

  const { data: paramsEvents, error: paramsEventsError } = useSWR(
    fetchType?.eventsForParams && ["paramsEvents", search, params],
    getParamsEvents(search, params)
  );

  const { data: createOrderEvents, error: createOrderEventsError } = useSWR(
    fetchType?.createOrder && ["createOrderEvents", search, params],
    getCreateOrderEvents(search, params)
  );

  return {
    ...methods,
    error,
    events,
    paramsEvents,
    paramsEventsError,
    createOrderEvents,
    createOrderEventsError,
    setSearch,
    setParams,
  };
};
