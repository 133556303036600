import { CHANNEL_OPTIONS, OPTION_ALL } from "~features/reports/constants";
import useFilterContext from "./useFiltersContext";
import { OrderChannel } from "~graphql/sdk";

export const useChannelOptions = () => {
  const { getFilterValue } = useFilterContext();

  const channelState = getFilterValue("channel");

  const channelLabel = CHANNEL_OPTIONS.find(
    (option) => option.value === channelState
  )?.label;

  const channelGroupedOptions = [
    { label: "All channels", value: OPTION_ALL },
    {
      label: "",
      options: [
        { label: "Online", value: OrderChannel.Online },
        { label: "Back office", value: OrderChannel.BackOffice },
        { label: "POS", value: OrderChannel.Pos },
      ],
    },
  ];

  return {
    channelLabel,
    channelState,
    channelOptions: CHANNEL_OPTIONS,
    channelGroupedOptions,
  };
};
