import { Role } from "~graphql/sdk";
import { OrganizationFeatures } from "~lib/features";
import { useOrganization, useUser } from "~hooks/index";

export const useUsherAdmissionTicket = (): boolean => {
  const { user } = useUser();
  return (
    (user?.roles.includes(Role.Admin) ||
      user.roles.includes(Role.Superadmin)) &&
    useHasUsherTicketFeature()
  );
};

export const useHasUsherTicketFeature = (): boolean => {
  const { hasFeature } = useOrganization();
  return hasFeature(OrganizationFeatures.UsherComplimentary);
};
