import React from "react";
import { Box, Flex, Text, Price, theme, SystemProps } from "flicket-ui";
import { OrderQuery, TicketCoverPurchaseBehaviour } from "~graphql/sdk";
import { StatusTag } from "./Status";

type TicketCoverProps = {
  orderTicketCover: OrderQuery["order"]["orderTicketCover"];
};

export const TicketCover = ({ orderTicketCover }: TicketCoverProps) => {
  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="9fr 2fr 2fr 1fr"
        gridGap={3}
        mt={1}
        mb={2}
      >
        <Flex alignItems="center">
          <Text>Ticket Protection Cover</Text>
          {orderTicketCover.issuedAt ? (
            <StatusTag
              text="Issued"
              background={theme.colors.success}
              color="white"
              titleText={`Ticket Protection Cover issued on ${new Date(
                orderTicketCover.issuedAt
              ).toLocaleDateString()} `}
            />
          ) : (
            <>
              {orderTicketCover.retryCount >= 3 ? (
                <StatusTag
                  text="Error"
                  background={theme.colors.error}
                  color="white"
                  titleText={
                    "We couldn't issue Ticket Protection Cover for this order, contact support for more information"
                  }
                />
              ) : (
                <StatusTag
                  text="Pending"
                  background={theme.colors.warning}
                  color="white"
                  titleText={
                    "Ticket protection cover is being processed for this order"
                  }
                />
              )}
            </>
          )}
        </Flex>
        <Box gridColumn="4">
          {orderTicketCover.purchaseBehaviour ===
          TicketCoverPurchaseBehaviour.Included ? (
            <Text whiteSpace={"nowrap"}>Free</Text>
          ) : (
            <Price price={orderTicketCover.totalFeeAmount} />
          )}
        </Box>
      </Box>
    </>
  );
};
