import { createContext, useContext } from "react";

export const ModalContext = createContext<{
  close?: (...args: unknown[]) => unknown;
  small: boolean;
}>({ close: undefined, small: false });

export function useCustomModal() {
  const state = useContext(ModalContext);
  return state;
}
