export const cleanTextOfSpecialCharsAndWhitespaceForExportFileName = (
  input: string
): string => {
  // First, convert the text to lowercase
  let result = input.toLowerCase();
  // Then, use regex to remove special characters and replace whitespace with hyphens
  // This regex will:
  // 1. Replace any sequence of non-word characters (excluding whitespace) with nothing
  // 2. Replace any sequence of whitespace characters with a single hyphen
  result = result.replace(/[^\w\s]|[\s]+/g, (match) =>
    match.trim() ? "-" : ""
  );

  return result.substring(0, 30); // trim the length as xls file name can only be 31 chars
};
