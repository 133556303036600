import React, { FC } from "react";

import { theme } from "flicket-ui";
import { Status } from "~components";
import { getRandomColor } from "~lib";
import { FinancialGatewayGraphOutput } from "~graphql/sdk";
import { PieChart } from "../charts";
import { GraphQLError } from "graphql";

export const FinancialGateways: FC<{
  data: FinancialGatewayGraphOutput;
  error: GraphQLError;
  direction?: "horizontal" | "vertical";
  color?: string[];
}> = ({ data, error, direction, color }) => {
  const COLORS = color ?? [theme.colors.P300, theme.colors.S300, "#D3CC2C"];

  return (
    <Status loading={!error && !data} error={error}>
      <PieChart
        direction={direction}
        data={data?.integrations
          ?.filter((item) => item.transactions > 0)
          ?.sort((a, b) => b.transactions - a.transactions)
          ?.map((item, index) => ({
            label: item.name,
            value: item.transactions,
            color: COLORS[index] || getRandomColor(),
          }))}
        label={`Transaction${data?.transactions === 1 ? "" : "s"}`}
        total={data?.transactions?.toLocaleString()}
      />
    </Status>
  );
};
