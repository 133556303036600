import React from 'react';

import { Flex, Text } from 'flicket-ui';

import { Icon } from '~components';
import { OrderQuery } from '~graphql/sdk';

type ListItemProps = {
  isDisabled: boolean;
  onClick(): void;
  icon?: 'cross' | 'arrow-right';
} & OrderQuery['order']['lineItems']['edges'][number]['node'];

export const ListItem = ({
  id,
  name,
  onClick,
  isDisabled,
  icon = 'cross',
}: ListItemProps) => (
  <Flex
    {...(!isDisabled && { onClick })}
    key={id}
    justifyContent="space-between"
    alignItems="center"
    p="6/4"
    cursor="pointer"
    border="1px solid"
    borderColor="N300"
    boxShadow="none"
    borderRadius="sm"
    transition="box-shadow 0.3s"
    css={`
      .hover-icon {
        display: none;
        opacity: 0;

        transition: opacity 2;
      }

      &:hover {
        background: ${(p) => p.theme.colors.N100};
        box-shadow: 0px 9px 39px rgba(0, 0, 0, 0.13),
          0px 2.01027px 8.71116px rgba(0, 0, 0, 0.0774939),
          0px 0.598509px 2.59354px rgba(0, 0, 0, 0.0525061);

        .hover-icon {
          display: flex;
          opacity: 1;
        }
      }
    `}
  >
    <Text fontSize={3} lineHeight="high">
      {name}
    </Text>
    {!isDisabled && <Icon icon={icon} fontSize={3} className="hover-icon" />}
  </Flex>
);
