import { ReportingCard } from "~features/reports/reporting/components/dashboards/ReportingCard";
import { BoxGrid } from "~features/reports/postevent/components/common";
import Statistic, {
  statisticValue,
} from "~features/reports/postevent/components/Statistic";
import { useScreenSize } from "flicket-ui";
import { StatisticFragmentFragment } from "~graphql/sdk";

type reportStatisticsProps = {
  title: string;
  data: StatisticFragmentFragment[];
};

export const ReportStatistics = ({ title, data }: reportStatisticsProps) => {
  const screenSize = useScreenSize();

  return (
    <ReportingCard title={title}>
      <BoxGrid columns={screenSize.isTabletPortraitDown ? 1 : 3}>
        {data.map((stat: StatisticFragmentFragment) => (
          <Statistic
            key={stat.label}
            value={statisticValue(stat)}
            label={stat.label}
            tooltip={stat.tooltip}
          />
        ))}
      </BoxGrid>
    </ReportingCard>
  );
};
