import { Info, Warning, WarningCircle } from "@phosphor-icons/react";
import ReactTooltip from "react-tooltip";
import { Icon } from "~components";

export const TooltipIcon = ({
  description,
  variant = "info",
}: {
  description: string;
  variant: "info" | "warning" | "error";
}) => {
  const colour = (() => {
    if (variant === "info") {
      return undefined;
    }

    return variant;
  })();

  const icon = (() => {
    switch (variant) {
      case "info":
        return <Info size={16} />;
      case "warning":
        return <WarningCircle weight="fill" size={16} />;
      case "error":
        return <WarningCircle weight="fill" size={16} />;
    }
  })();

  return (
    <>
      <Icon
        data-tip={description}
        icon={icon}
        ml={1}
        fontSize={3}
        mr={1}
        color={colour}
      />
      <ReactTooltip
        effect="solid"
        css={{
          fontWeight: "normal",
        }}
        textColor={"black"}
        borderColor="gray"
        backgroundColor="lightgray"
        multiline={true}
      />
    </>
  );
};
