import { useEffect, useState } from "react";
import { NamingFieldOption } from "../types/NamingFieldOption";
import { useOrganization } from "~hooks";
import { OrganizationFeatures } from "~lib/features";

const predefinedOptions: { [label: string]: NamingFieldOption } = {
  "First name": { field: "firstName" },
  "Last name": { field: "lastName" },
  Company: { field: "companyName" },
  Gender: { field: "gender" },
  DOB: { field: "birthDate" },
  Phone: { field: "phoneNumber" },
  "Postal code": { field: "postalCode" },
  Email: { field: "email" },
  "License plate": { field: "licensePlate" },
  "Member since": { field: "memberSince" },
  "Membership number": { field: "membershipNumber" },
  Comments: { field: "comments" },
  "Dietary requirements": { field: "dietaryRequirements" },
  "Opt in to email marketing": {
    field: "usableForEmailMarketing",
    requiresFields: ["firstName", "lastName", "email"],
  },
  "Opt in to text marketing": {
    field: "usableForSMSMarketing",
    requiresFields: ["firstName", "lastName", "email"],
  },
  "Length of stay": { field: "lengthOfStay" },
  "Emergency name": { field: "emergencyName" },
  "Emergency contact number": { field: "emergencyContact" },
  "Relationship with emergency contact": {
    field: "emergencyContactRelationship",
  },
  "Residential address in Dunedin": { field: "dunedinResidentialAddress" },
  "Student ID": { field: "studentId" },
};

export const defaultNamingFields: NamingFieldOption[] = [
  { ...predefinedOptions["First name"], isRequired: true },
  { ...predefinedOptions["Last name"], isRequired: true },
  { ...predefinedOptions.Email, isRequired: false },
  { ...predefinedOptions["Postal code"], isRequired: false },
];

const toCamelisedFieldName = (input: string) => {
  return `custom_${input
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    .replace(/[^a-zA-Z0-9]/g, "")
    .replace(/^./, (str) => str.toLowerCase())}`;
};

export const useNamingFieldOptions = (namingFields: NamingFieldOption[]) => {
  const { hasFeature } = useOrganization();
  const [namingFieldOptions, setNamingFieldOptions] = useState({
    ...predefinedOptions,
  });

  useEffect(() => {
    if (hasFeature(OrganizationFeatures.VaccinePassport)) {
      setNamingFieldOptions({
        ...predefinedOptions,
        ...{ "Vaccine pass": { field: "vaccinePass" } },
      });
    }
  }, [hasFeature(OrganizationFeatures.VaccinePassport)]);

  useEffect(() => {
    if (!namingFields) return;

    setNamingFieldOptions((prev) => {
      namingFields.forEach((field) => {
        if (field?.label) {
          prev[field.label] = field;
        }
      });
      return { ...prev };
    });
  }, [namingFields]);

  const addCustomField = (label: string) => {
    const fieldName = toCamelisedFieldName(label);
    if (fieldName === "custom_") {
      return { error: "Field name must contain some letters or numbers" };
    }

    const fieldExists = Object.entries(namingFieldOptions).find(
      ([key, value]) => value.field === fieldName
    );
    if (fieldExists) {
      return { error: "Field already exists" };
    }

    const newField = {
      field: fieldName,
      label: label,
      isCustom: true,
      isRequired: false,
    };

    setNamingFieldOptions((prev) => {
      prev[label] = newField;
      return { ...prev };
    });
    return { newField };
  };

  const removeCustomField = (label: string) => {
    setNamingFieldOptions((prev) => {
      delete prev[label];
      return { ...prev };
    });
  };

  return {
    namingFieldOptions,
    defaultNamingFields,
    addCustomField,
    removeCustomField,
  };
};
