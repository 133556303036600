import { Box, Flex, Text } from "flicket-ui";
import { FC, ReactNode } from "react";

interface OrderItemProps {
  title?: string;
  subtitle?: string;
  promo?: ReactNode;
  numReleasedTickets?: number | undefined;
  numTransferredTickets?: number | undefined;
  numTransferPendingTickets?: number | undefined;
  referral?: string;
  numMembershipEvents?: number;
}

export const OrderItem: FC<OrderItemProps> = ({
  title,
  subtitle,
  promo,
  numReleasedTickets: hasReleasedTickets = 0,
  numTransferredTickets: hasTransferredTickets = 0,
  numTransferPendingTickets: hasTransferPendingTickets = 0,
  numMembershipEvents,
  referral,
}) => {
  return (
    <Flex alignItems="flex-start">
      <Box>
        {title && <Text lineHeight="normal">{title}</Text>}
        <Flex alignItems="baseline">
          <Text fontSize={1} lineHeight="high">
            {subtitle}
          </Text>
          {promo && (
            <Text
              fontWeight="demiBold"
              fontSize={1}
              lineHeight="high"
              color="N600"
              ml={1}
            >
              {promo}
            </Text>
          )}
          {referral && (
            <Text
              fontWeight="demiBold"
              fontSize={1}
              lineHeight="high"
              color="N600"
              ml={1}
            >
              {referral}
            </Text>
          )}
        </Flex>
        {hasReleasedTickets > 0 && (
          <Box w="fit-content">
            <Flex
              borderRadius="full"
              bg="N200"
              p="1/2"
              pr={1}
              alignItems="center"
              justifyContent="center"
              mt="1/4"
            >
              <Flex
                borderRadius="full"
                width={16}
                height={16}
                bg="warning50"
                mr="1/2"
              />
              <Text fontWeight="extraBold" fontSize={1}>
                <Text
                  fontSize={1}
                  fontWeight="demiBold"
                  lineHeight="high"
                  color="N600"
                  ml={1}
                >
                  Released {hasReleasedTickets} ticket(s)
                </Text>
              </Text>
            </Flex>
          </Box>
        )}
        {hasTransferPendingTickets > 0 && (
          <Box w="fit-content">
            <Flex
              borderRadius="full"
              bg="N200"
              p="1/2"
              pr={1}
              alignItems="center"
              justifyContent="center"
              mt="1/4"
            >
              <Flex
                borderRadius="full"
                width={16}
                height={16}
                bg="warning50"
                mr="1/2"
              />
              <Text fontWeight="extraBold" fontSize={1}>
                <Text
                  fontSize={1}
                  fontWeight="demiBold"
                  lineHeight="high"
                  color="N600"
                  ml={1}
                >
                  {hasTransferPendingTickets} ticket(s) pending transfer
                </Text>
              </Text>
            </Flex>
          </Box>
        )}
        {hasTransferredTickets > 0 && (
          <Box w="fit-content">
            <Flex
              borderRadius="full"
              bg="N200"
              p="1/2"
              pr={1}
              alignItems="center"
              justifyContent="center"
              mt="1/4"
            >
              <Flex
                borderRadius="full"
                width={16}
                height={16}
                bg="warning50"
                mr="1/2"
              />
              <Text fontWeight="extraBold" fontSize={1}>
                <Text
                  fontSize={1}
                  fontWeight="demiBold"
                  lineHeight="high"
                  color="N600"
                  ml={1}
                >
                  Transferred {hasTransferredTickets}
                  {numMembershipEvents
                    ? ` of ${numMembershipEvents} event`
                    : ""}{" "}
                  ticket(s)
                </Text>
              </Text>
            </Flex>
          </Box>
        )}
      </Box>
    </Flex>
  );
};
