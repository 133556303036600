import React, { createContext, ReactNode, useState } from "react";

export interface FormStateContextProps {
  isDirty?: boolean;
  isSubmitting?: boolean;
  setIsDirty?: (val: boolean) => void;
  setIsSubmitting?: (val: boolean) => void;
}

const FormStateContext = createContext<FormStateContextProps>({
  isDirty: false,
});

const FormStateProvider = ({ children }: { children: ReactNode }) => {
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <FormStateContext.Provider
      value={{ isDirty, setIsDirty, isSubmitting, setIsSubmitting }}
    >
      {children}
    </FormStateContext.Provider>
  );
};

export { FormStateProvider, FormStateContext };
