import { useState } from "react";
import { SearchShopifyProductsQuery } from "~graphql/typed-document-nodes";
import { useSDK } from "~hooks";

export const useSearchProducts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SearchShopifyProductsQuery | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const sdk = useSDK();
  const searchProducts = async (query: string): Promise<void> => {
    try {
      setIsLoading(true);
      const response = await sdk.searchShopifyProducts({
        search: query,
      });
      setData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError((error as unknown) as Error);
      setData(null);
    }
  };

  return {
    searchProducts,
    data,
    error,
    isLoading,
  };
};
