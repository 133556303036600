import { get } from "lodash";
import { DeepMap, FieldError } from "react-hook-form";

// Suppport for string name with periods like "startDateTime.date" and "startDateTime.time"
export function extractErrorMessageFromFormName(
  name: string,
  errors: DeepMap<Record<string, any>, FieldError>
) {
  return get(errors, `${name}.message`, "") as string;
}
