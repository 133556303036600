import { useIsMobile } from "~hooks";
import { Grid } from "~components/reports";
import { Icon, Status } from "~components";
import { Box, Container, Flex, Text } from "flicket-ui";
import React from "react";
import { useQuery } from "~hooks/useQuery";
import { ScanUsherTicketTypesDocument } from "~graphql/typed-document-nodes";
import { useHasUsherTicketFeature } from "~hooks/useUsherAdmissionTicket";

export const UsherScansWidget = ({ eventId }: { eventId: string }) => {
  const isMobile = useIsMobile();
  const showUsherTicketWidget = useHasUsherTicketFeature();
  const scanUsherTicketTypesQuery = useQuery(
    eventId && showUsherTicketWidget ? ScanUsherTicketTypesDocument : null,
    { eventId }
  );

  const hasAnyScans =
    scanUsherTicketTypesQuery?.data?.scanUsherTicketTypes.value !== 0;

  // Return null if there are no scans in the results
  if (!hasAnyScans || !showUsherTicketWidget) {
    return null;
  }

  return (
    <Status loading={scanUsherTicketTypesQuery?.isLoading} error={null}>
      {isMobile ? (
        <Container>
          <Box
            bg="P100"
            borderRadius="sm"
            p={2}
            flex={1}
            key={"Usher tickets"}
            mr={{ _: "6/4", md: 0 }}
            minWidth={{ _: 260, md: 220 }}
            mb={2}
          >
            <Flex alignItems="center" mb={2}>
              <Flex
                as="span"
                borderRadius="full"
                bg="P300"
                width={32}
                height={32}
                variant="center"
                mr={1}
              >
                <Icon icon={"trending_up"} color="white" fontSize={4} />
              </Flex>
              <Text fontWeight="extraBold" color="N500">
                {"Usher tickets"}
              </Text>
            </Flex>
            <Text fontWeight="extraBold" fontSize={6} color="N600">
              Scanned:{" "}
              {scanUsherTicketTypesQuery.data?.scanUsherTicketTypes.value}
            </Text>
          </Box>
        </Container>
      ) : (
        <Grid>
          <Box
            bg="P100"
            borderRadius="sm"
            p={2}
            flex={1}
            key={"Usher tickets"}
            mr={{ _: "6/4", md: 0 }}
            minWidth={{ _: 260, md: 220 }}
          >
            <Flex alignItems="center" mb={2}>
              <Flex
                as="span"
                borderRadius="full"
                bg="P300"
                width={32}
                height={32}
                variant="center"
                mr={1}
              >
                <Icon icon={"trending_up"} color="white" fontSize={4} />
              </Flex>
              <Text fontWeight="extraBold" color="N500">
                Usher tickets
              </Text>
            </Flex>
            <Text fontWeight="extraBold" fontSize={6} color="N600">
              Scanned:{" "}
              {scanUsherTicketTypesQuery.data?.scanUsherTicketTypes.value}
            </Text>
          </Box>
        </Grid>
      )}
    </Status>
  );
};
