import { X } from "@phosphor-icons/react";
import { Stack, Text, useScreenSize } from "flicket-ui";
import styled from "styled-components";
import HoverIcon from "~features/generalAdmissionEvent/components/common/HoverIcon";

const Button = styled.a`
  border: 1px solid ${(p) => p.theme.colors.N300};
  border-radius: ${(p) => p.theme.radii.xs};
  padding: 8px 12px;
  width: fit-content;
  cursor: pointer;
`;

export const StatisticTooltipContent = ({
  content,
  link,
}: {
  content: string;
  link?: string;
}) => {
  const { isPhoneLandscapeDown } = useScreenSize();

  return (
    <Stack
      direction="vertical"
      p={3}
      gap={2}
      backgroundColor={"white"}
      boxShadow={"sm"}
      borderRadius={"md"}
    >
      <Stack justifyContent={"space-between"}>
        <Text variant="header.S">What&lsquo;s this?</Text>
        {isPhoneLandscapeDown && (
          // the tooltip will close if you click anywhere on the screen on mobile, so this close button is just for styling
          <HoverIcon icon={<X size={20} />} color="N800" />
        )}
      </Stack>
      <Stack direction={"vertical"} gap={2}>
        <Text variant="regular" whiteSpace={"pre-wrap"}>
          {content}
        </Text>
        {link && (
          <Button href={link} target="_blank" rel="noopener noreferrer">
            <Text variant="header.XS" lineHeight={"normal"}>
              Learn more
            </Text>
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
