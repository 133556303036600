import { useEffect, useState, useContext } from "react";
import { ThemeContext } from "styled-components";

/**
 * @deprecated use useScreenSize instead.
 */
const useIsMobile = (breakpoint = "md") => {
  const theme = useContext(ThemeContext);
  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const checkIfMobile = () =>
      setIsMobile(
        window.innerWidth <
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          Number(theme.breakpoints[breakpoint].replace(/\D/g, ""))
      );

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);
    return () => window.removeEventListener("resize", checkIfMobile);
  }, [theme.breakpoints[breakpoint]]);

  return isMobile;
};

export default useIsMobile;
