import { formatPrice } from "flicket-ui";
import * as yup from "yup";
import { replaceValuesWithPaths } from "~features/generalAdmissionEvent/form/schema.helpers";
import { ObjectSchemaDefinition } from "~features/generalAdmissionEvent/form/schema.types";
import { OrderType } from "~graphql/sdk";
import {
  OrderRefundState,
  RefundGateway,
  RefundableState,
} from "~graphql/typed-document-nodes";

export type RefundFormValues = {
  isManual: boolean;
  notes: string;
  sendEmail: boolean;
  gateway?: RefundGateway;
  ticketIdsToRelease: string[];
  refundAmount: number;
};

export const buildInitialState = (
  refundableState: RefundableState,
  initialAmount: number
): RefundFormValues => {
  return {
    isManual:
      refundableState.refundState === OrderRefundState.ManualOnly
        ? true
        : false,
    notes: undefined,
    sendEmail: true,
    refundAmount: initialAmount,
    ticketIdsToRelease: [],
  };
};

export const refundUnavailable = (refundState: OrderRefundState): boolean =>
  refundState === OrderRefundState.None ||
  refundState === OrderRefundState.FullyRefunded;

export const buildRefundSchema = (
  refundableState: RefundableState,
  orderType: OrderType
) => {
  const totalRefundableAmount = refundableState.totalRefundableAmount;
  const gatewayRefundableAmount =
    refundableState.gatewayRefundableAmount?.refundableAmount ?? 0;

  const _refundUnavailable = refundUnavailable(refundableState.refundState);
  const initialState = buildInitialState(
    refundableState,
    orderType === OrderType.PaymentPlan ? gatewayRefundableAmount : 0
  );

  const schema = yup.object<ObjectSchemaDefinition<RefundFormValues>>().shape({
    isManual: yup
      .boolean()
      .default(initialState.isManual)
      .test("Is required", "", (value) => {
        if (refundableState.refundState === OrderRefundState.ManualOnly) {
          return value === true;
        }
        return true;
      }),
    notes: yup
      .string()
      .default(initialState.notes)
      .required(
        `${_refundUnavailable ? "Notes " : "Refund notes"} cannot be empty.`
      ),
    gateway: yup
      .mixed()
      .oneOf(Object.values(RefundGateway))
      .when("isManual", {
        is: true,
        then: yup.mixed().required(),
        otherwise: yup.mixed().transform(() => undefined),
      }),
    ticketIdsToRelease: yup
      .array(yup.string())
      .default(initialState.ticketIdsToRelease),
    sendEmail: yup.boolean().default(initialState.sendEmail),
    refundAmount: yup
      .number()
      .nullable()
      .transform((value: number) => {
        return typeof value === "number" && !Number.isNaN(value) ? value : 0;
      })
      .when("isManual", {
        is: true,
        then: yup
          .number()
          .max(
            totalRefundableAmount,
            `Refund amount must be less than or equal to ${formatPrice(
              totalRefundableAmount
            )}`
          ),
        otherwise: yup
          .number()
          .max(
            gatewayRefundableAmount,
            `Automatic refund amount must be less than or equal to ${formatPrice(
              gatewayRefundableAmount
            )}.`
          ),
      })
      .when("ticketIdsToRelease", {
        is: (value) => !value || value.length === 0,
        then: yup
          .number()
          .required()
          .moreThan(
            0,
            "Either release tickets or choose a refund amount greater than zero."
          ),
      })
      .default(initialState.refundAmount),
  });

  const defaultValues = schema.getDefault();
  const formNames = replaceValuesWithPaths(defaultValues);

  return {
    schema,
    defaultValues,
    formNames,
  };
};
