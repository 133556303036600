import { RichTextStyles, StylesChangeFn } from "../richTextStyleTypes";
import RichTextStylesForm from "./RichTextStylesForm";
import CustomModal from "~components/common/CustomModal";

interface EmailStylesModalProps {
  isOpen?: boolean;
  onClose: () => void;
  onChange: StylesChangeFn;
  richTextStyle: RichTextStyles;
}

function EmailStylesModal({
  isOpen,
  onClose,
  onChange,
  richTextStyle,
}: EmailStylesModalProps) {
  return (
    <CustomModal isOpen={isOpen} close={onClose}>
      <CustomModal.Header>Email styles</CustomModal.Header>
      <RichTextStylesForm
        onClose={onClose}
        onChange={onChange}
        richTextStyle={richTextStyle}
      />
    </CustomModal>
  );
}

export default EmailStylesModal;
