import { OrderQuery } from "~graphql/sdk";

type PartialOrder = Partial<OrderQuery["order"]>;

export const getPointsQuantityFromOrder = (order: PartialOrder) => {
  const item = order.lineItems.edges[0]?.node;
  return item?.quantity * item?.pointItem?.amount;
};

// Get the display name for points communication which includes the org
// brand name if different to the default. E.g "5 points (Fan Cash)"
// This helper checks if the Org is using the default brannd name "Points"
// and will substite it out. E.g "5 points (Points)" becomes "5 points".
export const getPointsDisplayLabel = (pointsBrandName: string) => {
  if (pointsBrandName?.toLowerCase() === "points") {
    return "points";
  }

  return "points" + (pointsBrandName ? ` (${pointsBrandName})` : "");
};
