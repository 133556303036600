import { Stack } from "flicket-ui";
import { Skeleton } from "~components";
import { DateRangeSelect } from "~features/reports/components/ReportOptionSelects";
import useLayoutContext from "~features/reports/reporting/hooks/useLayoutContext";
import { PRIMARY_NAVIGATION_KEYS } from "~features/reports/reporting/navigation/primary.config";

export function ModalLoadingState() {
  return (
    <Stack direction={"vertical"} gap={3}>
      <Stack direction={"vertical"}>
        <Skeleton height={15} width={"30%"} />
        <Skeleton height={30} />
      </Stack>
      <Stack direction={"vertical"}>
        <Skeleton height={15} width={"30%"} />
        <Skeleton height={30} />
      </Stack>
    </Stack>
  );
}

export function RenderDateRange({
  onChange,
  value,
  name,
}: {
  onChange: (value: [Date, Date]) => void;
  value: [string, string];
  name: string;
}) {
  const { defaultDateRange, isAllEvents, type } = useLayoutContext();
  const isOverall = type === PRIMARY_NAVIGATION_KEYS.OVERALL;
  const isOnsaleRange =
    value?.[0] && value?.[1]
      ? new Date(value[0]).getTime() === defaultDateRange.startDate.getTime() &&
        new Date(value[1]).getTime() === defaultDateRange.endDate.getTime()
      : false;

  return (
    <DateRangeSelect
      isOnsaleRange={isOnsaleRange && !isAllEvents && !isOverall}
      label="Date"
      name={name}
      value={
        value?.[0] && value?.[1]
          ? {
              startDate: new Date(value[0]),
              endDate: new Date(value[1]),
            }
          : undefined
      }
      onChange={({ startDate, endDate }) => onChange([startDate, endDate])}
    />
  );
}
