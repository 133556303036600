import { Status } from "~components";
import { ReportStatistics } from "~features/reports/reporting/components/dashboards/report.statistics";
import { StatisticFragmentFragment } from "~graphql/sdk";
import { useQuery } from "~hooks/useQuery";
import { AudienceReportOverviewDocument } from "~graphql/typed-document-nodes";
import { EventReportParams } from "~features/reports/reporting/types";

export const AudienceStatistics = ({ params }: EventReportParams) => {
  const { data, error, isLoading } = useQuery(
    AudienceReportOverviewDocument,
    params
  );

  if (isLoading || error) {
    return <Status error={error} loading={isLoading} />;
  }

  const formatData = Object.values(
    data.customerReportingOverview
  ) as StatisticFragmentFragment[];

  return <ReportStatistics title={"Audience insights"} data={formatData} />;
};
