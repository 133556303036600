import React from "react";

import { Flex, Text, TransparentButton } from "flicket-ui";

import { Select } from "~components";
import { Icon } from "~components/common/Icon";
import { LineItemType } from "~graphql/sdk";
import {
  EditModalLineItem,
  EditModalTicketType,
  EditModalZone,
} from "./EditModalContent";
import { ActivePromotion, SeatWithPromotion } from "~hooks/multibuy/types";
import { getMultibuyInfo } from "../getMultibuyInfo";

type EditListItemProps = {
  lineItem: EditModalLineItem;
  onChange: (
    lineItem: EditModalLineItem,
    update: Partial<EditModalLineItem>
  ) => void;
  addToRelease: (ticket: EditModalLineItem) => void;
  currentZone: EditModalZone;
  releaseLineItems: EditModalLineItem[];
  allTicketTypes: EditModalTicketType[];
  multiBuy: SeatWithPromotion | ActivePromotion;
};

interface SimpleTicketType {
  id: string;
  name?: string;
  price?: number;
  isActive?: boolean;
}

export const EditListItem = ({
  lineItem,
  onChange,
  releaseLineItems,
  addToRelease,
  currentZone,
  allTicketTypes,
  multiBuy,
}: EditListItemProps) => {
  const isToBeReleased = releaseLineItems.find((r) => r.id === lineItem.id);

  const ticketTypeId = lineItem?.membershipType?.id ?? lineItem.ticketType?.id;
  const ticketTypes = ((): SimpleTicketType[] | undefined => {
    if (currentZone?.ticketTypes) {
      return currentZone.ticketTypes.map((t) => ({
        id: t.id,
        name: t.name,
      }));
    } else if (currentZone?.membershipTypes) {
      return currentZone.membershipTypes.map((t) => ({
        id: t.membershipTypeId,
        isActive: t.isActive,
        price: t.price,
      }));
    }
    return undefined;
  })();

  const ticketTypeOptions = ticketTypes
    ?.filter((t) => t?.isActive)
    ?.map((t) => ({
      label: allTicketTypes?.find((type) => type.id === t?.id)?.name,
      value: t?.id,
    }));

  const { MultibuyPromoText } = getMultibuyInfo(multiBuy, lineItem);

  const handleChange = (value: string) => {
    const update = {
      [lineItem.membershipType ? "membershipType" : "ticketType"]: {
        id: value,
        name: allTicketTypes?.find((type) => type.id === value)?.name ?? "-",
      },
    };

    return onChange(lineItem, update);
  };

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      key={`key-${ticketTypeId}-${lineItem.id}`}
    >
      <Flex
        alignItems="center"
        textDecoration={isToBeReleased ? "line-through" : "none"}
        onClick={() => addToRelease(lineItem)}
        cursor="pointer"
      >
        <Text opacity={isToBeReleased ? ".6" : 1}>
          ({lineItem?.quantity}) {lineItem?.name}
          {MultibuyPromoText}
        </Text>
        <TransparentButton ml={2}>
          {isToBeReleased ? (
            <>
              <Icon icon="warning-circle" />
            </>
          ) : (
            <Icon icon="cross" />
          )}
        </TransparentButton>
      </Flex>
      {lineItem?.type !== LineItemType.Addon && currentZone?.isActive && (
        <Select
          disabled={isToBeReleased}
          onChange={handleChange}
          defaultValue={ticketTypeId}
          options={ticketTypeOptions}
          flexBasis="calc(100% - 75%)"
          ml={2}
          mr="1/4"
        />
      )}
    </Flex>
  );
};
