import React from 'react';

import { useSuperAdmin } from '~hooks';

import Error from '~pages/_error';

export const SuperAdminRoute = ({ children }) => {
  const { isSuperAdmin } = useSuperAdmin();

  return isSuperAdmin ? children : <Error statusCode={404} />;
};
