import { ReactNode, forwardRef, useRef } from "react";
import {
  CropperProps,
  FixedCropper,
  FixedCropperRef,
  FixedCropperSettings,
  ImageRestriction,
  StencilSize,
  mergeRefs,
} from "react-advanced-cropper";
import styled from "styled-components";
import Wrapper from "./Wrapper";

// Taken from https://advanced-cropper.github.io/react-advanced-cropper/docs/showcase#twitter

export type ImageCropperProps = Omit<
  CropperProps<FixedCropperSettings>,
  "stencilSize" | "transitions" | "imageRestriction"
>;

const StyledFixedCropper = styled(FixedCropper)`
  background: none;

  .cropper__overlay {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.9;
  }

  .advanced-cropper__background-wrapper {
    background-color: ${({ theme }) => theme.colors.white};
    cursor: move;
  }

  .cropper__overlay {
    border-radius: ${({ theme }) => theme.radii.sm};
  }
`;

export const ImageCropper = forwardRef(
  (props: ImageCropperProps & { extraFooterContent: ReactNode }, ref) => {
    const { stencilProps = {}, wrapperComponent, ...cropperProps } = props;

    const cropperRef = useRef<FixedCropperRef>(null);

    const WrapperComponent = wrapperComponent || Wrapper;

    // These likely do not work smoothly for all aspect ratios.
    const stencilSize: StencilSize = ({ boundary }) => {
      return {
        width: boundary.width,
        height: boundary.height,
      };
    };

    return (
      <StyledFixedCropper
        minWidth={150}
        minHeight={150}
        {...cropperProps}
        ref={mergeRefs([ref, cropperRef])}
        stencilProps={{
          previewClassName: "cropper__preview",
          overlayClassName: "cropper__overlay",
          boundingBoxClassName: "cropper__bounding-box",
          movable: false,
          scalable: false,
          lines: false,
          resizable: false,
          handlers: false,
          aspectRatio: 16 / 9,
          ...stencilProps,
        }}
        wrapperComponent={WrapperComponent}
        wrapperProps={{
          extraFooterContent: props.extraFooterContent,
        }}
        imageRestriction={ImageRestriction.stencil}
        stencilSize={stencilSize}
        transitions={false}
        backgroundClassName="cropper__background"
      />
    );
  }
);

ImageCropper.displayName = "ImageCropper";
