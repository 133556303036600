import { DropdownOption } from "~components";
import useLayoutContext from "./useLayoutContext";
import { ShareFat, ShareNetwork } from "@phosphor-icons/react";
import useNavigation from "../navigation/useNavigation";
import { PRIMARY_NAVIGATION_KEYS } from "../navigation/primary.config";
import useFilterContext, { FilterActionTypes } from "./useFiltersContext";
import { Text } from "flicket-ui";

export const useAddFilterOptions = () => {
  const context = useLayoutContext();
  const { getFilterShow, dispatch } = useFilterContext();
  const eventId =
    context.type === PRIMARY_NAVIGATION_KEYS.EVENTS &&
    context?.eventInformation?.id;

  const { activePrimaryTab } = useNavigation();
  const isEventTab = activePrimaryTab === PRIMARY_NAVIGATION_KEYS.EVENTS;

  const showReleaseFilter = getFilterShow("releaseId");
  const showChannelFilter = getFilterShow("channel");

  const shouldShowReleaseFilter = isEventTab && !showReleaseFilter && eventId;

  const shouldShowChannelFilter = !showChannelFilter;

  const options: DropdownOption[] = [
    shouldShowReleaseFilter && {
      label: "Release",
      labelComponent: <Text variant="regular">Release</Text>,
      icon: <ShareFat />,
      onClick: () => {
        dispatch({
          type: FilterActionTypes.SHOW_FILTER,
          filter: "releaseId",
        });
      },
    },
    shouldShowChannelFilter && {
      label: "Channel",
      labelComponent: <Text variant="regular">Channel</Text>,
      icon: <ShareNetwork />,
      onClick: () => {
        dispatch({
          type: FilterActionTypes.SHOW_FILTER,
          filter: "channel",
        });
      },
    },
  ].filter(Boolean);

  return options;
};
