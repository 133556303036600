import { Box, SystemProps } from "flicket-ui";
import { FC } from "react";
import { pick } from "@styled-system/props";

export const WarningBanner: FC<SystemProps> = ({ children, ...rest }) => (
  <Box
    backgroundColor={"P100"}
    borderRadius={"sm"}
    px={2}
    py={1}
    display="flex"
    justifyContent={"space-between"}
    fontSize={3}
    mb={6}
    {...pick(rest)}
  >
    {children}
  </Box>
);
