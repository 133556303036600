import { useSessionStorage } from "usehooks-ts";
import { OrganizationFeatures } from "~lib/features";

export type FeatureFlag = OrganizationFeatures;
export type SessionFeatureFlagType =
  | Record<FeatureFlag, boolean>
  | Record<never, never>;

export default function useSessionFeatureFlags() {
  const [value, setValue] = useSessionStorage<SessionFeatureFlagType>(
    "features",
    {}
  );

  function toggleFeatureFlag(feature: FeatureFlag, value: boolean) {
    setValue((state) => ({
      ...state,
      [feature]: value,
    }));
  }

  function addFeatureFlags(features: SessionFeatureFlagType) {
    setValue((state) => ({
      ...state,
      ...features,
    }));
  }

  return {
    featureFlags: value,
    addFeatureFlags,
    toggleFeatureFlag,
  };
}
