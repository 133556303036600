import { Box } from "flicket-ui";
import { ReactNode } from "react";
import { RenderElementProps } from "slate-react";
import { BoxElement } from "./interface/slate.interface";

type BoxProps = Pick<RenderElementProps, "attributes"> & {
  children: ReactNode;
  element: BoxElement;
};

export default function SlateBox(props: BoxProps) {
  const { children } = props;

  return (
    <Box
      position={"relative"}
      padding={4}
      border={"1px solid black"}
      {...props.attributes}
    >
      {children}
    </Box>
  );
}
