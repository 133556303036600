import { Editor, Range } from "slate";
import { wrapLink } from "./Link";

const createEmailLinkIfMatch = (
  editor: Editor,
  selection: Range,
  word: string
) => {
  // If the word doesn't look like an email address, abort
  if (!word.match(/^\S+@\S+\.\S+$/)) return;

  // If the entered text looks like an email address, create a mailto link
  const originalWordRange = {
    anchor: {
      path: selection.anchor.path,
      offset: selection.anchor.offset - word.length,
    },
    focus: {
      path: selection.anchor.path,
      offset: selection.anchor.offset,
    },
  };

  wrapLink(editor, {
    url: `mailto:${word}`,
    content: word,
    at: originalWordRange,
  });
};

export const withEmailLinks = (editor: Editor) => {
  const { insertText, insertBreak } = editor;

  editor.insertText = (text: string) => {
    const { selection } = editor;

    if (text === " " && selection?.anchor) {
      const wordBeforeCursor = getWordBeforeCursor(editor, selection);
      createEmailLinkIfMatch(editor, selection, wordBeforeCursor);
    }

    // Insert text as usual
    insertText(text);
  };

  editor.insertBreak = () => {
    const { selection } = editor;
    if (selection?.anchor) {
      const wordBeforeCursor = getWordBeforeCursor(editor, selection);
      createEmailLinkIfMatch(editor, selection, wordBeforeCursor);
    }

    // Insert a line break as usual
    insertBreak();
  };

  return editor;
};

const getWordBeforeCursor = (editor: Editor, selection: Range) => {
  const { anchor } = selection;
  const lineStart = Editor.start(editor, anchor.path);
  const range = { anchor, focus: lineStart };
  const text = Editor.string(editor, range);
  const index = text.lastIndexOf(" ", anchor.offset - 1);
  return text.slice(index + 1, anchor.offset);
};
