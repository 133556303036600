import { AddFilter } from "./AddFilter";
import { ReleaseFilter } from "./ReleaseFilter";
import { ChannelFilter } from "./ChannelFilter";
import { SlidersHorizontal, X } from "@phosphor-icons/react";
import { Badge, Icon } from "~components";
import { WhiteButton } from "~components/common/WhiteButton";
import { useState } from "react";
import CustomModal from "~components/common/CustomModal";
import { FilterModal } from "./FiltersModal";
import { Flex } from "flicket-ui";
import useFilterContext, {
  FilterActionTypes,
} from "~features/reports/reporting/hooks/useFiltersContext";
import { useRouter } from "next/router";
import { removeUndefined } from "~lib/helpers/removeUndefined";

export const MobileExtraFilters = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { modifiedFiltersCount } = useFilterContext();

  const hasBadge = modifiedFiltersCount > 0;

  return (
    <>
      <Flex width={"fit-content"}>
        <Badge badgeContent={modifiedFiltersCount} visible={hasBadge} flex={1}>
          <WhiteButton
            width={"fit-content"}
            height={48}
            p={2}
            hasBadge={hasBadge}
            onClick={() => setIsOpened(true)}
          >
            <Icon icon={<SlidersHorizontal />} fontSize={3} />
          </WhiteButton>
        </Badge>
      </Flex>

      <CustomModal isOpen={isOpened} close={() => setIsOpened(false)}>
        <FilterModal />
      </CustomModal>
    </>
  );
};

export const DesktopExtraFilters = () => {
  const { displayedFiltersCount, dispatch, isCreating } = useFilterContext();
  const router = useRouter();

  const showCancelAll = displayedFiltersCount > 0;

  return (
    <>
      <ReleaseFilter />
      <ChannelFilter />
      <AddFilter />
      {showCancelAll && !isCreating && (
        <Icon
          icon={<X color="N800" />}
          onClick={() => {
            const newQuery = removeUndefined({
              ...router.query,
              releaseId: undefined,
              channel: undefined,
            });
            void router.replace({
              pathname: router.pathname,
              query: newQuery,
            });
            dispatch({ type: FilterActionTypes.CLEAR_ALL_FILTER });
          }}
        />
      )}
    </>
  );
};
