import React, { FC } from "react";
import { Flex, SystemProps } from "flicket-ui";
import styled from "styled-components";

export const ScrollContainer = styled(Flex)`
  width: 100%;
  overflow-x: auto;
  padding-bottom: 16px;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.9)
    );
  }
`;

export const HorizontalScroll: FC<SystemProps> = ({ children, ...props }) => (
  <Flex width={1} position="relative">
    <ScrollContainer {...props}>{children}</ScrollContainer>
  </Flex>
);
