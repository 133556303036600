import { EventsListWithStatsQuery, ExtendedFile } from "~graphql/sdk";

import {
  Box,
  Flex,
  formatPrice,
  Link,
  Stack,
  SystemProps,
  Text,
} from "flicket-ui";
import NextImage from "next/future/image";
import { useRouter } from "next/router";
import { ToastType } from "react-toastify";
import styled, { css } from "styled-components";
import { useClipboard } from "use-clipboard-hook";
import { DropdownOption, DropdownWithIconButton, Skeleton } from "~components";
import { PercentBar } from "~components/common/PercentBar";
import useEventMutations from "~graphql/hooks/useEventMutations";
import { getImage, showToast } from "~lib/helpers";
import { hoverExcludingChildHover } from "~lib/helpers/styles";
import {
  EventItem,
  EventStatus,
  getEventStatus,
  getEventStatusLabel,
} from "./EventList.helpers";
import { useScreenSize } from "~hooks/useScreenSize";
import { CSSProperties, useRef } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { formatNumber } from "~lib/helpers/formateNumber";
import { KeyedMutator } from "swr";
import { useCanDuplicateEvent } from "~features/generalAdmissionEvent/hooks/useCanDuplicateEvent";
import SmallButton from "~features/generalAdmissionEvent/components/common/SmallButton";
import { StatusDot } from "~components/common/StatusLabel";
import { OrganizationFeatures } from "~lib/features";
import { useOrganization } from "~hooks";
import { formatEventDateRange } from "src/lib/helpers/dates/formatDate";

const LargeScreenGrid = styled(Box)`
  display: grid;
  grid-template-columns:
    240px minmax(200px, 25%) 10fr minmax(110px, auto) 10fr minmax(150px, auto)
    3fr minmax(90px, auto) 32px;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
`;

const StyledEventListItem = styled(Box)`
  position: relative;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    box-shadow: ${(p) => p.theme.shadows.sm};
    border-radius: ${(p) => p.theme.radii.md};
    padding: ${(p) => p.theme.space[2]}px;
  }

  &::before {
    content: "";
    position: absolute;
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    border-radius: ${(p) => p.theme.radii.md};
    transition: background-color 0.2s ease-out;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    ${hoverExcludingChildHover}::before {
      background-color: ${(p) => p.theme.colors.hoverBackground3};
    }
  }
`;

interface IEventListItem {
  event: EventItem;
  baseURL: string;
  mutate: KeyedMutator<EventsListWithStatsQuery>;
}

/**
 *
 * This wrapper function only renders the inner list item when it is visible to the viewport.
 * This significantly increases render performance of the page when there are many events.
 */
export function EventListItemWithIntersectionLoader(props: IEventListItem) {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, { freezeOnceVisible: true });
  const isVisible = !!entry?.isIntersecting;

  return (
    <Box minHeight={100} ref={ref}>
      {isVisible && (
        <EventListItem
          event={props.event}
          baseURL={props.baseURL}
          mutate={props.mutate}
        />
      )}
    </Box>
  );
}

function EventListItem({ event, baseURL, mutate }: IEventListItem) {
  const router = useRouter();
  const { isTabletLandscapeDown } = useScreenSize();
  const { hasFeature } = useOrganization();
  const hasV2Report = hasFeature(OrganizationFeatures.ReportingIA);

  const { updateEvent, duplicateEvent } = useEventMutations({
    eventId: event.id,
    mutate,
  });

  const canDuplicateEvent = useCanDuplicateEvent(event);

  const { copy } = useClipboard({
    onSuccess: () => showToast("Copied link to clipboard", ToastType.SUCCESS),
    onError: () => showToast("Could not copy link", ToastType.ERROR),
  });

  const useHomepageLayout = router.pathname === "/";

  const status = getEventStatus(event);
  const statusLabel = getEventStatusLabel(status);

  const showFaceValue = !hasFeature(
    OrganizationFeatures.TotalSalesInsteadOfFaceValue
  );

  const revenue = showFaceValue
    ? event?.totalTicketsFaceValue
    : event?.totalRevenue;

  const revenueLabel = showFaceValue ? "exc. fees" : "including fees";

  const previewURL = `${baseURL}/events/${event?.id}`;
  const salesReportURL = hasV2Report
    ? `reporting/events/ticket-sales?sourceSelection={"eventId":"${event?.id}"}`
    : `reports/sales?source=EVENT&sourceId=${event?.id}&referrer=/`;

  const dropdownOptions = (() => {
    const result: DropdownOption[] = [];

    const viewWebsite = () => {
      if ([EventStatus.Draft, EventStatus.Published].includes(status)) {
        result.push({
          label:
            status === EventStatus.Draft ? "Preview website" : "View website",
          href: previewURL,
          target: "_blank",
        });
      }
    };

    // only keep the view website option if the event list is on homepage
    if (!useHomepageLayout) {
      result.push({
        label: "Edit event",
        onClick: () => gotoEvent(),
      });

      result.push({
        label: "Sales report",
        href: salesReportURL,
        target: "_blank",
      });

      viewWebsite();

      if (canDuplicateEvent) {
        result.push({
          label: "Duplicate",
          onClick: async () => {
            await duplicateEvent();
          },
        });
      }

      if (status !== EventStatus.Past) {
        result.push({
          label: "Copy web link",
          onClick: () => copy(previewURL),
        });

        result.push({
          label: event?.isFeatured
            ? "Unfeature on homepage"
            : "Feature on homepage",
          onClick: async () => {
            await updateEvent({ isFeatured: !event?.isFeatured });
          },
        });
      }

      result.push({
        label: event?.hiddenFromPublic
          ? "Unhide from homepage"
          : "Hide from homepage",
        onClick: async () => {
          await updateEvent({ hiddenFromPublic: !event?.hiddenFromPublic });
        },
      });
    } else {
      viewWebsite();
    }

    return result;
  })();

  function gotoEvent() {
    void router.push({
      pathname: `/events/[id]`,
      query: {
        id: event.id,
      },
    });
  }

  const location = `${[event?.venue?.name, event?.venue?.address?.city || null]
    .filter((v) => v)
    .join(", ")}`;

  const IconButton = (
    <DropdownWithIconButton
      optionStyle={css((p) => ({
        color: p.theme.colors.N800,
        "&:hover": {
          backgroundColor: p.theme.colors.hoverBackground6,
        },
      }))}
      options={dropdownOptions}
      textAlign="left"
    />
  );

  const Title = (
    <Text as="h4" variant={useHomepageLayout ? "header.S" : "header.XS"} pr={2}>
      {event.title}
    </Text>
  );

  const LocationAndDate = (
    <Box>
      <Text variant={useHomepageLayout ? "regular" : "small"}>{location}</Text>
      <Text variant={useHomepageLayout ? "regular" : "small"}>
        {formatEventDateRange(event)}
      </Text>
    </Box>
  );

  const StatusBadge = (
    <Text
      variant={isTabletLandscapeDown ? "small" : "regular"}
      css={css({
        textWrap: "nowrap",
      })}
    >
      <StatusDot
        top={"-1px" as any}
        mr="1/2"
        status={status === EventStatus.Published ? "success" : "idle"}
      />
      {statusLabel}
    </Text>
  );

  const percentage = (() => {
    if (event.capacity) {
      return (event.soldTicketsCount / event.capacity) * 100;
    }

    if (
      typeof event.soldTicketsCount === "number" &&
      event.soldTicketsCount > 0
    ) {
      return 100;
    }

    return 0;
  })();

  const CapacityChart = (
    <PercentBar
      percentage={percentage}
      maxWidth={useHomepageLayout ? "auto" : { _: "auto", lg: "300px" }}
    />
  );

  const Attendees = (
    <Text
      variant={
        // small on the event page small device
        !useHomepageLayout && isTabletLandscapeDown ? "small" : "regular"
      }
    >
      {formatNumber(event.soldTicketsCount)} tickets sold
    </Text>
  );

  const SoldToday = (
    <Text variant="small">
      {formatNumber(event.soldTicketsCountToday)}{" "}
      {useHomepageLayout ? "" : "sold"} today
    </Text>
  );

  const SoldYesterday = (
    <Text variant="small">
      {formatNumber(event.soldTicketsCountYesterday)}{" "}
      {useHomepageLayout ? "" : "sold"} yesterday
    </Text>
  );

  const Separator = (
    <Text variant="small" display={{ _: "block", lg: "none" }}>
      &#x2022;
    </Text>
  );

  const Revenue = (
    <Text
      variant={
        // small on the event page small device
        !useHomepageLayout && isTabletLandscapeDown ? "small" : "regular"
      }
      textAlign={"right"}
    >
      {formatPrice(Number(revenue ?? 0))}
    </Text>
  );

  const EditEventButton = (
    <Flex
      as={Link}
      to="/events/[id]"
      linkAs={`/events/${event.id}`}
      flex={1}
      noHoverEffect
    >
      <SmallButton flex={1} height={48}>
        Edit event
      </SmallButton>
    </Flex>
  );

  const ViewReportButton = (
    <Flex
      as={Link}
      to={salesReportURL}
      linkAs={salesReportURL}
      flex={1}
      noHoverEffect
    >
      <SmallButton flex={1} height={48}>
        View report
      </SmallButton>
    </Flex>
  );

  function getLargeScreenLayout() {
    return (
      <LargeScreenGrid>
        <ImagePreview title={event.title} file={event.logo} />
        <Box position="absolute" right={"0px" as any} top={"-4px" as any}>
          {IconButton}
        </Box>
        <Stack direction="vertical" gap={0}>
          {Title}
          {LocationAndDate}
        </Stack>
        <ColumnSpacer />
        <Flex pt={"3px" as any}>{StatusBadge}</Flex>
        <ColumnSpacer />
        <Stack direction="vertical" gap={1} pt={"3px" as any}>
          <Stack direction="vertical" gap={1}>
            {Attendees}
            {/* {!!event.capacity && CapacityChart} */}
            {CapacityChart}
          </Stack>
        </Stack>
        <ColumnSpacer />
        <Stack
          direction="vertical"
          gap={0}
          textAlign={"right"}
          pt={"3px" as any}
        >
          {Revenue}
          <Text variant="small">{revenueLabel}</Text>
        </Stack>
      </LargeScreenGrid>
    );
  }

  function getScreenLayoutForHomepage() {
    return (
      <Stack direction={{ _: "vertical", md: "horizontal" }} gap={2}>
        <Box flex={1}>
          <ImagePreview title={event.title} file={event.logo} />
        </Box>
        <Box flex={1}>
          <Stack direction="vertical" gap={2} position={"relative"}>
            <Box position="absolute" right={"0px" as any} top={"-4px" as any}>
              {IconButton}
            </Box>
            <Stack direction="vertical" gap={"4px"}>
              {Title}
              {LocationAndDate}
            </Stack>
            <Stack direction="vertical" gap={1} width={1}>
              <Stack direction={"horizontal"} gap={0} alignItems={"baseline"}>
                {Attendees}&nbsp;•&nbsp;{SoldToday}&nbsp;•&nbsp;{SoldYesterday}
              </Stack>

              {CapacityChart}

              <Stack direction="horizontal" gap={0} alignItems={"baseline"}>
                {Revenue}&nbsp;
                <Text variant="small">{revenueLabel}</Text>
              </Stack>
            </Stack>
            <Stack direction={"horizontal"} gap={1} width={1}>
              {EditEventButton} {ViewReportButton}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    );
  }

  function getSmallAndMediumScreenLayout() {
    return (
      <Stack gap={2} direction={{ _: "vertical", md: "horizontal" }}>
        <Flex flexBasis={{ _: "none", md: "240px" }} flexGrow={1}>
          <ImagePreview title={event.title} file={event.logo} />
        </Flex>

        <Stack
          gap={1}
          direction={{ _: "vertical", lg: "horizontal" }}
          flex={{ _: 1, md: 60 }}
          position="relative"
          pr={{ _: 1, md: 5 }}
        >
          <Box position="absolute" right={"0px" as any} top={"-4px" as any}>
            {IconButton}
          </Box>

          <Stack direction="vertical" gap={1}>
            <Stack direction="vertical" gap={1}>
              {Title}
              {LocationAndDate}
            </Stack>

            {StatusBadge}

            <Stack direction="vertical" gap={1}>
              <Stack direction="vertical" gap={0} flex={0}>
                <Stack direction="horizontal" gap={1}>
                  {Attendees}
                  {Separator}
                  <Stack
                    direction="horizontal"
                    gap={"1/2"}
                    alignItems={"baseline"}
                  >
                    {Revenue}
                    <Text variant="small">{revenueLabel}</Text>
                  </Stack>
                </Stack>
              </Stack>

              {CapacityChart}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  const screenLayout = (() => {
    if (useHomepageLayout) {
      return getScreenLayoutForHomepage();
    } else {
      if (isTabletLandscapeDown) {
        return getSmallAndMediumScreenLayout();
      } else {
        return getLargeScreenLayout();
      }
    }
  })();

  const Wrapper = useHomepageLayout ? Box : StyledEventListItem;

  return (
    <Wrapper
      cursor={useHomepageLayout ? "auto" : "pointer"}
      onClick={() => (useHomepageLayout ? undefined : gotoEvent())}
    >
      {screenLayout}
    </Wrapper>
  );
}

export function ImagePreview({
  isLoading,
  title,
  file,
  innerProps,
  imageStyles,
}: {
  isLoading?: boolean;
  title?: string;
  file?: ExtendedFile;
  innerProps?: SystemProps;
  imageStyles?: CSSProperties;
}) {
  const src = getImage(file, "/static/blank_event_image.svg");
  return (
    // Needed diplay="grid" required for aspect ratio to size properly
    // https://stackoverflow.com/a/67606694
    <Box display="grid" width={1}>
      <Box
        borderRadius="md"
        backgroundColor="N100"
        overflow="hidden"
        position="relative"
        zIndex={1}
        css={css({
          aspectRatio: "5 / 2",
        })}
        {...innerProps}
      >
        {isLoading ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          <NextImage
            loading="lazy"
            src={src}
            alt={title}
            fill={true}
            style={{
              objectFit: "cover",
              objectPosition: "center",
              ...imageStyles,
            }}
            sizes="(min-width: 800px) 300px"
            quality={85}
          />
        )}
      </Box>
    </Box>
  );
}

function ColumnSpacer() {
  return <Box />;
}

export function Loader() {
  return useScreenSize().isTabletLandscapeDown ? (
    <SmallScreenLoader />
  ) : (
    <LargeScreenLoader />
  );
}

function LargeScreenLoader() {
  return (
    <LargeScreenGrid>
      <ImagePreview isLoading={true} />

      <Stack direction="vertical" gap={0}>
        <Skeleton height={18} width="50%" />
        <Skeleton height={15} width="70%" />
        <Skeleton height={15} width="80%" />
      </Stack>

      <ColumnSpacer />

      <Stack pt={"3px" as any}>
        <Skeleton height={18} width="70%" />
      </Stack>

      <ColumnSpacer />

      <Stack direction="vertical" gap={1} pt={"3px" as any}>
        <Skeleton height={18} width="70%" />
      </Stack>

      <ColumnSpacer />

      <Stack direction="vertical" gap={0} textAlign={"right"} pt={"3px" as any}>
        <Skeleton height={18} width="70%" />
      </Stack>
    </LargeScreenGrid>
  );
}

function SmallScreenLoader() {
  return (
    <Stack gap={2} direction={{ _: "vertical", md: "horizontal" }}>
      <Flex flexBasis={{ _: "none", md: "240px" }} flexGrow={1}>
        <ImagePreview isLoading={true} />
      </Flex>

      <Stack
        gap={1}
        direction={{ _: "vertical", lg: "horizontal" }}
        flex={{ _: 1, md: 60 }}
      >
        <Stack direction="vertical" gap={0}>
          <Skeleton height={18} width="40%" />
          <Skeleton height={15} width="50%" />
          <Skeleton height={15} width="55%" />
        </Stack>
      </Stack>
    </Stack>
  );
}
