import { FC, ReactNode } from "react";

import { Divider, Flex, SystemProps, Text } from "flicket-ui";
import { Dropdown, DropdownOption } from "~components";
import { pick } from "@styled-system/props";

export const Card: FC<
  {
    title: ReactNode;
    dropdown?: { title: string; options: DropdownOption[] };
  } & SystemProps
> = ({ title, dropdown, children, ...props }) => {
  return (
    <Flex
      flexDir="column"
      flexShrink={0}
      p={{ _: "6/4", md: 4 }}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      width={1}
      overflow="hidden"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      {...pick(props)}
    >
      <Flex justifyContent="space-between" alignItems="center" width={1}>
        {typeof title === "string" ? (
          <Text variant={"header.M"}>{title}</Text>
        ) : (
          title
        )}

        {dropdown && (
          <Dropdown options={dropdown.options} zIndex={10}>
            {dropdown.title}
          </Dropdown>
        )}
      </Flex>
      <Divider mb={{ _: "6/4", xs: 4 }} mt="6/4" />

      {children}
    </Flex>
  );
};
