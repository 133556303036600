import { Box, ErrorMessage, Flex, Input, TertiaryButton } from "flicket-ui";
import { useRef, useState } from "react";

export const AddCustomFieldForm = ({
  onSubmit,
}: {
  onSubmit: (fieldLabel: string) => string;
}) => {
  // TODO: move this to hook forms when extending for other field types
  // https://react-hook-form.com/api/usefieldarray/
  const input = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");
  const [showForm, setShowForm] = useState(false);

  const onAdd = () => {
    const error = onSubmit(input.current.value);
    if (!error) {
      input.current.value = "";
      setError("");
      setShowForm(false);
    } else {
      setError(error);
    }
  };

  return (
    <>
      {!showForm && (
        <TertiaryButton mr={2} onClick={() => setShowForm(true)}>
          Add a custom text field
        </TertiaryButton>
      )}

      <Box mb={2} display={showForm ? "block" : "none"}>
        <Flex>
          <Input
            name="fieldLabel"
            type="text"
            placeholder="Custom field label"
            autoFocus
            ref={input}
            mr={2}
          />
          <TertiaryButton
            lineHeight="normal"
            boxShadow="button"
            onClick={onAdd}
            width={"20%"}
          >
            Add field
          </TertiaryButton>
        </Flex>
        {error && <ErrorMessage my={1}>{error}</ErrorMessage>}
      </Box>
    </>
  );
};
