import { useIsV2Event } from "~features/generalAdmissionEvent/hooks/useIsV2Event";
import { EventKind, EventQuery, VenueSeating } from "~graphql/sdk";
import { EventItem } from "~features/EventList/EventList.helpers";

export function useCanDuplicateEvent(event: EventQuery["event"] | EventItem) {
  const isV2Event = useIsV2Event(!!event?.venue?.createdFromEvent);
  const disableDuplicateEvent =
    (!isV2Event && event?.venue.seating === VenueSeating.NonSeated) ||
    event?.eventKind === EventKind.Digital;
  return !disableDuplicateEvent;
}
