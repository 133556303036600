import { X } from "@phosphor-icons/react";
import { Stack, Text } from "flicket-ui";
import { Icon } from "~components";
import { IconName } from "~components/common/Icon/Icon";
import { WhiteButton } from "~components/common/WhiteButton";

export const AppliedFilter = ({
  onClick,
  textContent,
  icon,
  removeFilter,
  showRemoveIcon,
}: {
  onClick: () => void;
  textContent: string;
  icon: JSX.Element | IconName;
  removeFilter: () => void;
  showRemoveIcon: boolean;
}) => {
  return (
    <WhiteButton
      px={4}
      py={2}
      height={48}
      justifyContent={"center"}
      alignItems={"center"}
      onClick={onClick}
    >
      <Stack gap={1}>
        <Icon icon={icon} />
        <Text variant="regular">{textContent}</Text>
        {showRemoveIcon && (
          <Icon
            icon={<X color="N800" />}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              removeFilter();
            }}
          />
        )}
      </Stack>
    </WhiteButton>
  );
};
