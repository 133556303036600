import { useSlate } from "slate-react";
import { BlockButton, MarkButton } from "./Buttons";
import { GroupInsertOptions, RichtextEditorSelect } from "./Dropdown";
import { EditImageButton } from "./Image";
import { LinkButton } from "./Link";
import {
  Button,
  ButtonDivider,
  Toolbar as ToolbarComponent,
} from "./components";
import {
  selectedBannerImage,
  selectedButton,
  selectedImage,
  selectedMerchandise,
  selectedSummary,
  selectedVideo,
  isUnmovableSelected,
} from "./util";
import { SystemProps, Text } from "flicket-ui";
import {
  AlignCenterHorizontalSimple,
  AlignLeftSimple,
  AlignRightSimple,
  IconContext,
  ListBullets,
  ListNumbers,
  Palette,
  TextAlignCenter,
  TextAlignLeft,
  TextAlignRight,
  TextB,
  TextH,
  TextHOne,
  TextHThree,
  TextHTwo,
  TextItalic,
} from "@phosphor-icons/react";
import { ToolbarOption } from ".";
import { ReactNode, useState } from "react";
import RichTextStylesModal from "./richTextStyles/components/RichTextStylesModal";
import { Icon } from "../Icon";
import { MediaDeleteButton } from "./video";
import {
  RichTextStyles,
  StylesChangeFn,
} from "./richTextStyles/richTextStyleTypes";
import { DownToolbarButton, UpToolbarButton } from "./UpDown/UpDown";
import {
  DeleteMerchandiseToolbarButton,
  EditMerchandiseToolbarButton,
} from "./Merchandise";
import {
  DeleteButtonToolbarButton,
  EditButtonToolbarButton,
} from "./InsertButton";

interface ToolbarProps {
  selectEvents: boolean;
  insertDropdownOptions: GroupInsertOptions[];
  toolbarProps?: SystemProps;
  toolbarOptions: ToolbarOption[];
  toolbarImageOptions?: ToolbarOption[];
  toolbarVideoOptions?: ToolbarOption[];
  toolbarBannerOptions?: ToolbarOption[];
  toolbarMerchandiseOptions?: ToolbarOption[];
  toolbarSummaryOptions?: ToolbarOption[];
  toolbarButtonOptions?: ToolbarOption[];
  richTextStyle: RichTextStyles;
  onRichTextStyleChange?: StylesChangeFn;
}

export default function Toolbar(props: ToolbarProps) {
  const {
    selectEvents,
    insertDropdownOptions,
    toolbarOptions = [],
    toolbarImageOptions = [],
    toolbarVideoOptions = [],
    toolbarBannerOptions,
    toolbarMerchandiseOptions = [],
    toolbarSummaryOptions = [],
    toolbarButtonOptions = [],
    richTextStyle,
    onRichTextStyleChange,
  } = props;

  const editor = useSlate();
  const video = selectedVideo(editor);
  const image = selectedImage(editor);
  const banner = selectedBannerImage(editor);
  const summary = selectedSummary(editor);
  const merchandise = selectedMerchandise(editor);
  const button = selectedButton(editor);

  let optionsIterator = banner
    ? toolbarBannerOptions
    : image
    ? toolbarImageOptions
    : video
    ? toolbarVideoOptions
    : summary
    ? toolbarSummaryOptions
    : merchandise
    ? toolbarMerchandiseOptions
    : button
    ? toolbarButtonOptions
    : toolbarOptions;

  const _isUnmovableSelected = isUnmovableSelected(editor);

  if (_isUnmovableSelected) {
    optionsIterator = optionsIterator.filter(
      (option) => !["move-up", "move-down"].includes(option)
    );
  }

  // The below toolbar elements are ordered according to the
  // supplied toolbarOptions array.
  function renderToolbarOption(option: ToolbarOption, index: number) {
    switch (option) {
      case "divider":
        return <ButtonDivider key={`toolbar-${option}-${index}`} />;
      case "text-left":
        return (
          <BlockButton
            format="left"
            icon={<TextAlignLeft />}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "text-center":
        return (
          <BlockButton
            format="center"
            icon={<TextAlignCenter />}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "text-right":
        return (
          <BlockButton
            format="right"
            icon={<TextAlignRight />}
            key={`toolbar-${option}-${index}`}
          />
        );

      case "bold":
        return (
          <MarkButton
            format="bold"
            icon={<TextB />}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "italic":
        return (
          <MarkButton
            format="italic"
            icon={<TextItalic />}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "heading-one":
        return (
          <BlockButton
            format="heading-one"
            icon={<TextHOne />}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "heading-two":
        return (
          <BlockButton
            format="heading-two"
            icon={<TextHTwo />}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "heading-three":
        return (
          <BlockButton
            format="heading-three"
            icon={<TextHThree />}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "heading":
        return (
          <BlockButton
            format="heading-three"
            icon={<TextH />}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "numbered-list":
        return (
          <BlockButton
            format="numbered-list"
            icon={<ListNumbers />}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "bulleted-list":
        return (
          <BlockButton
            format="bulleted-list"
            icon={<ListBullets />}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "link":
        return <LinkButton key={`toolbar-${option}-${index}`} />;
      case "styles":
        return (
          <StylesButton
            onChange={onRichTextStyleChange}
            richTextStyle={richTextStyle}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "insert":
        if (insertDropdownOptions?.length > 0) {
          return (
            <RichtextEditorSelect
              selectEvents={selectEvents}
              insertOptions={insertDropdownOptions}
              key={`toolbar-${option}-${index}`}
            />
          );
        }
        return null;

      // Media related
      case "align-left":
        return (
          <BlockButton
            format="left"
            icon={<AlignLeftSimple />}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "align-center":
        return (
          <BlockButton
            format="center"
            icon={
              <AlignCenterHorizontalSimple key={`toolbar-${option}-${index}`} />
            }
          />
        );
      case "align-right":
        return (
          <BlockButton
            format="right"
            icon={<AlignRightSimple />}
            key={`toolbar-${option}-${index}`}
          />
        );
      case "media-small":
        return (
          <BlockButton
            format="small"
            text="Small"
            key={`toolbar-${option}-${index}`}
          />
        );
      case "media-medium":
        return (
          <BlockButton
            format="medium"
            text="Medium"
            key={`toolbar-${option}-${index}`}
          />
        );
      case "media-large":
        return (
          <BlockButton
            format="large"
            text="Large"
            key={`toolbar-${option}-${index}`}
          />
        );
      case "media-full":
        return (
          <BlockButton
            format="full"
            text="Full"
            key={`toolbar-${option}-${index}`}
          />
        );
      case "image-edit":
        if (image ?? banner) {
          return (
            <EditImageButton
              image={image ?? banner}
              isBanner={!!banner}
              key={`toolbar-${option}-${index}`}
            />
          );
        }
        return null;
      case "image-delete":
        return (
          <MediaDeleteButton
            label="Delete"
            key={`toolbar-${option}-${index}`}
          />
        );
      case "video-delete":
        return (
          <MediaDeleteButton
            label="Delete video"
            key={`toolbar-${option}-${index}`}
          />
        );
      case "move-up":
        return <UpToolbarButton key={`toolbar-${option}-${index}`} />;
      case "move-down":
        return <DownToolbarButton key={`toolbar-${option}-${index}`} />;
      case "merchandise-edit":
        if (merchandise) {
          return (
            <EditMerchandiseToolbarButton
              key={`toolbar-${option}-${index}`}
              merchandise={merchandise}
            />
          );
        }
        return null;
      case "merchandise-delete":
        return (
          <DeleteMerchandiseToolbarButton key={`toolbar-${option}-${index}`} />
        );
      case "button-edit":
        return (
          <EditButtonToolbarButton
            key={`toolbar-${option}-${index}`}
            buttonElement={button}
          />
        );
      case "button-delete":
        if (button.isRemovable !== false) {
          return (
            <DeleteButtonToolbarButton
              key={`toolbar-${option}-${index}`}
              buttonElement={button}
            />
          );
        }
        return null;
      default:
        return null;
    }
  }

  const toolbar: { option: ToolbarOption; node: ReactNode }[] = [];

  optionsIterator.forEach((option, index) => {
    // No divider as the first element
    if (option === "divider" && toolbar.length === 0) return;

    // No consecutive dividers
    if (
      option === "divider" &&
      toolbar[toolbar.length - 1].option === "divider"
    )
      return;

    const toolbarOption = renderToolbarOption(option, index);

    if (!toolbarOption) return;

    toolbar.push({ node: toolbarOption, option });
  });

  // No divider as the last element
  if (toolbar[toolbar.length - 1]?.option === "divider") {
    toolbar.pop();
  }

  return (
    <IconContext.Provider
      value={{
        size: 20,
        weight: "regular",
      }}
    >
      <ToolbarComponent
        flexWrap="wrap"
        px={1}
        py={"1/2"}
        minHeight="46px"
        {...props.toolbarProps}
      >
        {toolbar.map((el) => el.node)}
      </ToolbarComponent>
    </IconContext.Provider>
  );
}

function StylesButton({
  onChange,
  richTextStyle,
}: {
  onChange: StylesChangeFn;
  richTextStyle: RichTextStyles;
}) {
  const [stylesModalOpen, setStylesModalOpen] = useState(false);

  return (
    <>
      <Button h="30px" px={1} onClick={() => setStylesModalOpen(true)}>
        <Icon icon={<Palette />} mr="1/2" />
        <Text variant="regular" color="N800">
          Styles
        </Text>
      </Button>
      <RichTextStylesModal
        isOpen={stylesModalOpen}
        onClose={() => {
          setStylesModalOpen(false);
        }}
        richTextStyle={richTextStyle}
        onChange={onChange}
      />
    </>
  );
}
