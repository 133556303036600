import { omit, pick } from "@styled-system/props";
import { addDays, sub } from "date-fns";
import { Box, Flex, SystemProps, TransparentButton } from "flicket-ui";
import { ReactNode, useState } from "react";

import styled from "styled-components";

import { DatePicker, Icon, Select } from "~components";
import { ParamsMethods } from "~features/useParams/hooks/useParams";
import {
  PointReportingFilterSource,
  ReportingFilterSource,
  ReportingFinancialSalesBreakdownSource,
} from "~graphql/sdk";
import { i18n } from "~lib/i18n";

const Wrapper = styled(Flex)`
  width: calc(100% + 16px);
  margin-right: -16px;

  > * {
    margin-right: 16px;
    margin-bottom: 16px;
  }

  > *:not(.date-picker) {
    @media (min-width: ${(p) => p.theme.breakpoints.md}) {
      max-width: 160px;
    }
  }
`;

type RawParamsProps = {
  children?: ReactNode;
  enableDeleteDates?: boolean;
  savedChannel?: any;
  disabled?: boolean;
  savedSource?:
    | ReportingFilterSource
    | ReportingFinancialSalesBreakdownSource
    | PointReportingFilterSource;
  savedSourceId?: string;
  savedRelease?: string;
  enableStorage?: boolean;
  sourceOptions: { label: string; value: string }[];
  controlVisibility?: {
    dates?: boolean;
    channel?: boolean;
    release?: boolean;
  };
  useMobileLayout?: boolean;
} & Omit<ParamsMethods, "sourceOptions">;

type ParamsProps = RawParamsProps & SystemProps;

export const RawParams = ({
  exclude,
  eventOptions,
  membershipOptions,
  releaseOptions,
  params,
  setParams,
  channelOptions,
  sourceOptions,
  children = null,
  enableDeleteDates = false,
  savedChannel = undefined,
  disabled = false,
  savedSource = undefined,
  savedSourceId = undefined,
  savedRelease = undefined,
  enableStorage = false,
  controlVisibility = {},
  useMobileLayout = false,
}: RawParamsProps) => {
  const [channelValue, setChannelValue] = useState(
    savedChannel || params.channel
  );

  const [sourceValue, setSourceValue] = useState(savedSource || params.source);
  const [sourceIdValue, setSourceIdValue] = useState(
    savedSourceId || params.sourceId
  );
  const [releaseIdValue, setReleaseIdValue] = useState(
    savedRelease || params.releaseId
  );

  // if the object initates but is set as null then set it to the saved variable
  // the savedChannel state is being saved as a string in memory
  if (
    params.channel == null &&
    (savedChannel == "undefined" || savedChannel == "null")
  ) {
    params.channel = null;
  } else {
    params.channel = channelValue;
  }

  if (enableStorage) {
    params.source = sourceValue;
    params.sourceId = sourceIdValue;
    params.releaseId = releaseIdValue;
  }

  if (exclude?.point) {
    sourceOptions = sourceOptions.filter(
      (source) => source.label !== "Package"
    );
    if (params.source === PointReportingFilterSource.Package) {
      params.source = ReportingFilterSource.Overall;
    }
  }

  const renderReleaseSelect = () => {
    return (
      <Select
        disabled={disabled}
        name="release"
        placeholder="Select release"
        options={releaseOptions}
        minWidth={160}
        flex={1}
        value={
          enableStorage ? releaseIdValue : params.releaseId || params.release
        }
        onChange={(releaseId: string) => {
          setReleaseIdValue(releaseId);
          setParams({ ...params, releaseId, release: releaseId });
          if (enableStorage) {
            if (typeof releaseId === "string") {
              window.sessionStorage.setItem(
                "filterReleaseCriteria",
                JSON.stringify(releaseId)
              );
            } else {
              window.sessionStorage.removeItem("filterReleaseCriteria");
            }
          }
        }}
      />
    );
  };

  const renderChannelSelect = () => {
    return (
      <Select
        disabled={disabled}
        name="channel"
        placeholder="Select channel"
        minWidth={160}
        flex={1}
        options={channelOptions}
        value={channelValue ? channelValue : null}
        onChange={(channel) => {
          //the component loads as null, we dont want to update any values if that is the case
          //All channels has a value of undefined, which if not explicity defined will be treated as null
          if (channel != null || channel == undefined) {
            setChannelValue(channel);
            if (channel == undefined) {
              setParams({ ...params, channel: null });
            } else {
              setParams({ ...params, channel });
            }
          }
        }}
      />
    );
  };

  const renderDateSelect = () => {
    return (
      <Flex
        pl={{ md: "6/4" }}
        borderLeft={{ md: "1px" }}
        borderColor={{ md: "N300" }}
        flex={1}
        className="date-picker"
        alignItems="center"
      >
        <Box minWidth={230} maxWidth={{ md: 250 }} width={{ _: "100%" }}>
          <DatePicker
            disabled={disabled}
            name="dates"
            value={params.dates}
            placeholder="Select a date range"
            onChange={(dates) => {
              if (Array.isArray(dates)) {
                const startDate = new Date(dates[0]);
                const endDate = sub(addDays(new Date(dates[1]), 1), {
                  seconds: 1,
                });

                setParams({
                  ...params,
                  dates: [startDate, endDate],
                  startDate: startDate,
                  endDate: endDate,
                });
              }
            }}
            timezone={i18n.timezone}
            locale={i18n.locale}
            options={{
              dateFormat: "M j, Y",
              mode: "range",
              minDate: undefined,
            }}
          />
        </Box>

        {enableDeleteDates && params?.dates ? (
          <TransparentButton
            ml={2}
            onClick={() =>
              setParams({
                ...params,
                dates: null,
                startDate: null,
                endDate: null,
              })
            }
            width={25}
            height={25}
          >
            <Icon icon="cross" fontSize={3} />
          </TransparentButton>
        ) : null}
      </Flex>
    );
  };

  const renderSourceIdSelect = () => {
    return (
      <Select
        name="sourceId"
        options={
          params.source === ReportingFilterSource.Event
            ? eventOptions
            : membershipOptions
        }
        flex={useMobileLayout ? 70 : 1}
        minWidth={useMobileLayout ? "auto" : 160}
        maxWidth="660px !important"
        value={enableStorage ? sourceIdValue : params.sourceId}
        onChange={(sourceId: string) => {
          setSourceIdValue(sourceId);
          setReleaseIdValue("");
          setParams({ ...params, sourceId, releaseId: "", release: "" });
          if (enableStorage) {
            if (sourceId) {
              window.sessionStorage.setItem(
                "filterSourceIdCriteria",
                JSON.stringify(sourceId)
              );
            } else {
              window.sessionStorage.removeItem("filterSourceIdCriteria");
            }
            window.sessionStorage.removeItem("filterReleaseCriteria");
          }
        }}
        disabled={disabled}
      />
    );
  };

  const renderSourceSelect = () => {
    return (
      <Select
        name="source"
        options={sourceOptions}
        flex={useMobileLayout ? 30 : 1}
        mb={2}
        mr={useMobileLayout ? 1 : undefined}
        minWidth={useMobileLayout ? "auto" : 160}
        value={enableStorage ? sourceValue : params.source}
        onChange={(
          source: PointReportingFilterSource | ReportingFilterSource
        ) => {
          setSourceValue(source);
          setSourceIdValue(undefined);
          setReleaseIdValue("");
          setParams({
            ...params,
            source,
            sourceId: undefined,
            releaseId: "",
            release: "",
          });
          if (enableStorage) {
            window.sessionStorage.setItem(
              "filterSourceCriteria",
              JSON.stringify(source)
            );
            window.sessionStorage.removeItem("filterSourceIdCriteria");
            window.sessionStorage.removeItem("filterReleaseCriteria");
          }
        }}
        disabled={disabled}
      />
    );
  };

  return (
    <>
      {!exclude?.source && sourceOptions.length > 1 && renderSourceSelect()}

      {!exclude?.sourceId &&
        params.source !== ReportingFilterSource.Overall &&
        params.source !== PointReportingFilterSource.Package &&
        renderSourceIdSelect()}

      {!exclude?.release &&
        releaseOptions.length > 1 &&
        controlVisibility.release !== false &&
        renderReleaseSelect()}

      {!exclude?.channel &&
        controlVisibility.channel !== false &&
        renderChannelSelect()}

      {children}

      {!exclude?.dates &&
        controlVisibility.dates !== false &&
        renderDateSelect()}
    </>
  );
};

export const Params = (props: ParamsProps) => {
  return (
    <Flex
      width={1}
      justifyContent="space-between"
      overflowX="clip"
      alignItems="center"
      mb={[1, 3]}
      flexWrap="wrap"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      {...pick(props)}
    >
      <Wrapper flexWrap="wrap">
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
        <RawParams {...omit(props)} />
      </Wrapper>
    </Flex>
  );
};
