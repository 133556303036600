import { Box } from "flicket-ui";
import { ChangeEvent, CSSProperties, FC, useRef, useState } from "react";
import { components, GroupProps, IndicatorProps } from "react-select";
import { useSlate } from "slate-react";
import styled, { css, DefaultTheme, useTheme } from "styled-components";
import { useSDK } from "~hooks";
import { IconProps } from "../Icon/Icon";
import { Select } from "../Select";
import { insertDivider } from "./Divider";
import { fileSelectHandler } from "./Image";
import { imageModalAtom } from "./InsertImageModal/InsertImageModal";
import { useScreenSize } from "~hooks/useScreenSize";
import { BroadcastAudience, BroadcastTransactionalType } from "~graphql/sdk";
import { insertSummary } from "./Summary";
import { useAtom } from "jotai";
import { videoModalState } from "./InsertVideoModal";

import {
  CaretDown,
  HandTap,
  Image,
  ImageSquare,
  Link,
  Minus,
  ShoppingCartSimple,
  TextT,
} from "@phosphor-icons/react";
import { InsertSelectCss } from "~components/common/RichText/styled/DropDown.styled";
import { insertField } from "./CustomField";
import {
  FieldTextVariables,
  SuggestedLinkType,
} from "./interface/slate.interface";
import { insertImportantInfo } from "./insertImportantInfo";
import { merchandiseModalState } from "~components/common/RichText/InsertMerchandiseModal";
import { insertTicketsToSend } from "./TicketsToSendElement";
import { linkAndButtonModalAtom } from "./InsertModal";

const DropdownIndicator = (props: IndicatorProps<any, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown color="N800" size={16} />
    </components.DropdownIndicator>
  );
};

export type RichTextDropdownOption = {
  label: string;
  value: string;
  icon: IconProps["icon"];
  tooltip?: string;
  payload?: Record<string, unknown>;
};

export type SummaryType =
  | "auto-renewal-details"
  | "order-details"
  | "ticket-details";

export type GroupInsertOptions = {
  options: RichTextDropdownOption[];
};

export const transactionalInsertOptions = (
  transactionalType: BroadcastTransactionalType = BroadcastTransactionalType.Event,
  audience: BroadcastAudience = null,
  hasResaleTicketType = false
): GroupInsertOptions[] => [
  {
    options: [
      { label: "Banner image", value: "banner", icon: <Image /> },
      { label: "Image", value: "image", icon: <ImageSquare /> },
      { label: "Divider", value: "divider", icon: <Minus /> },
      { label: "Link", value: "link", icon: <Link /> },
    ],
  },
  {
    options:
      transactionalType === BroadcastTransactionalType.Event
        ? ([
            {
              label: "Link to their tickets",
              value: "link:access-your-tickets",
              icon: <HandTap />,
            },
            {
              label: "Link to event page",
              value: "link:event-information",
              icon: <HandTap />,
            },
            hasResaleTicketType
              ? {
                  label: "Link to resale page",
                  value: "link:event-ticket-resale",
                  icon: <HandTap />,
                }
              : null,
          ]
            .filter(Boolean)
            .filter(
              (item) =>
                !(
                  audience ===
                    BroadcastAudience.RegistrationWaitlistCustomers &&
                  item.value == "link:access-your-tickets"
                )
            ) as [])
        : ([
            {
              label: "Link to membership renewal",
              value: "link:membership-renewal",
              icon: <HandTap />,
            },
            {
              label: "Auto renewal details",
              value: "summary:auto-renewal-details",
              icon: <ShoppingCartSimple />,
            },
          ].filter(
            (item) =>
              audience === BroadcastAudience.AutomaticMembershipRenewal ||
              item.value !== "summary:auto-renewal-details"
          ) as []),
  },
  {
    options: [
      {
        label: "First name",
        value: FieldTextVariables.FIRST_NAME,
        icon: <TextT />,
      },
      {
        label: "Last name",
        value: FieldTextVariables.LAST_NAME,
        icon: <TextT />,
      },
    ],
  },
];

export const smsInsertOptions: GroupInsertOptions[] = [
  {
    options: [
      { label: "Link", value: "link", icon: <Link /> },
      {
        label: "Link to ticketing page",
        value: "link:event-membership",
        icon: <HandTap />,
      },
    ],
  },
];

export const marketingInsertOptions: GroupInsertOptions[] = [
  {
    options: [
      { label: "Banner image", value: "banner", icon: <Image /> },
      { label: "Image", value: "image", icon: <ImageSquare /> },
      { label: "Divider", value: "divider", icon: <Minus /> },
      { label: "Link", value: "link", icon: <Link /> },
      {
        label: "Merchandise links",
        value: "merchandise",
        icon: <ShoppingCartSimple />,
      },
    ],
  },
  {
    options: [
      {
        label: "Link to event page",
        value: "link:event-information",
        icon: <HandTap />,
      },
      {
        label: "Link to ticketing page",
        value: "link:event-tickets",
        icon: <HandTap />,
      },
      {
        label: "Link to registration form",
        value: "link:event-registration",
        icon: <HandTap />,
      },
    ],
  },
  {
    options: [
      {
        label: "First name",
        value: FieldTextVariables.FIRST_NAME,
        icon: <TextT />,
      },
      {
        label: "Last name",
        value: FieldTextVariables.LAST_NAME,
        icon: <TextT />,
      },
    ],
  },
];

export const StyledBox = styled(Box)<{ isFirst: boolean }>`
  position: relative;

  ${(props) =>
    !props.isFirst &&
    css`
      &::before {
        content: "";
        background: ${(props) => props.theme.colors.N200};
        position: absolute;
        left: 10%;
        top: 0;
        height: 1px;
        width: 80%;
      }
    `}
`;

export const defaultContent = (
  suggestedLink: SuggestedLinkType
): string | undefined => {
  if (!suggestedLink) return undefined;

  const map: { [key in SuggestedLinkType]: string } = {
    "access-your-tickets": "Access your tickets",
    "event-information": "Event information",
    "event-registration": "Register now",
    "event-tickets": "Event tickets",
    "event-ticket-resale": "Event tickets",
    "membership-renewal": "Renew your membership",
    "event-membership": "Link to ticketing page",
    "abandoned-cart-reengagement": "Buy tickets",
    "report-spam": "Report spam",
    unsubscribe: "Unsubscribe",
    "event-terms-and-conditions": "Terms and conditions",
    "membership-terms-and-conditions": "Terms and conditions",
    "registration-confirmation-share": "Share registration link",
    "guest-tickets": "Guest tickets",
    "exhibitor-login": "Exhibitor login",
    "exhibitor-attendee-scans": "Exhibitor attendee scans",
    "scanner-access-instruction": "",
  };

  return map[suggestedLink];
};

export const InsertSelectStyles = (theme: DefaultTheme, isMobile: boolean) => {
  return {
    group: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      paddingTop: "4px",
      paddingBottom: "4px",
    }),
    control: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      maxWidth: `${theme.space[15]}px`,
      height: `${theme.space[3]}px`,
      minHeight: `${theme.space[3]}px!important`,
      border: "none",
      alignContent: "center",
      boxShadow: "none!important",
    }),
    container: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      width: isMobile ? "100px" : "auto",
      alignSelf: "flex-start",
    }),
    menu: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      width: isMobile ? "200px" : "260px",
      marginTop: "12px",
      right: isMobile ? "-60px" : "-60px",
    }),
    singleValue: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: "normal",
      color: "#1A1A1A",
    }),
    dropdownIndicator: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      paddingLeft: 4,
    }),
    menuList: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: "normal",
      color: "#1A1A1A",
    }),
  };
};

export const RichtextEditorSelect: FC<{
  insertOptions?: GroupInsertOptions[];
  selectEvents?: boolean;
}> = ({ insertOptions = [], selectEvents = false }) => {
  const editor = useSlate();
  const sdk = useSDK();
  const theme = useTheme();
  const isMobile = useScreenSize().isPhonePortrait;
  const [_, setLinkAndButtonModalState] = useAtom(linkAndButtonModalAtom);
  const [isBanner, setIsBanner] = useState(false);
  const [, setImageModalState] = useAtom(imageModalAtom);
  const [, setVideoModalState] = useAtom(videoModalState);
  const [, setMerchandiseModalState] = useAtom(merchandiseModalState);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { Group } = components;

  const RichTextGroup = ({
    ...props
  }: GroupProps<RichTextDropdownOption, false>) => {
    // this is hard coded
    // props.options[0].value will use the value of the first option group
    // remove the divider for the first group
    let isFirst = false;
    if (props.options[0].value === insertOptions[0].options[0].value) {
      isFirst = true;
    }
    return (
      <StyledBox isFirst={isFirst}>
        <Group {...props} />
      </StyledBox>
    );
  };

  return (
    <>
      <Select
        width={"auto"}
        menuPortalTarget={document.body}
        options={insertOptions}
        onChange={(value: string) => {
          if (value === "divider") {
            insertDivider(editor);
          } else if (value === "tickets-to-send") {
            insertTicketsToSend(editor);
          } else if (value === "image") {
            setIsBanner(false);
            setImageModalState({
              isOpen: true,
              image: null,
              isBanner: false,
            });
          } else if (value === "video") {
            setVideoModalState({ isOpen: true, video: null });
          } else if (value === "banner") {
            setIsBanner(true);
            setImageModalState({
              isOpen: true,
              image: null,
              isBanner: true,
            });
          } else if (value === "merchandise") {
            setMerchandiseModalState({ isOpen: true, merchandise: null });
          } else if (value.startsWith("link")) {
            const suggestedLink = value.split(":")[1] as SuggestedLinkType;
            setLinkAndButtonModalState({
              isOpen: true,
              allowTogglingLinkOrButton: true,
              selectEvents,
              defaultValues: {
                displayText: defaultContent(suggestedLink),
                suggestedLink,
              },
            });
          } else if (value.startsWith("summary")) {
            insertSummary(editor, value.split(":")[1] as SummaryType);
          } else if (value === "important-info") {
            const flatOptions = insertOptions.flatMap((group) => group.options);
            const importantInfo = flatOptions.find(
              (option) => option.value === "important-info"
            )?.payload?.importantInfo;
            insertImportantInfo(editor, importantInfo);
          } else {
            insertField(editor, value as FieldTextVariables);
          }
        }}
        styles={InsertSelectStyles(theme, isMobile)}
        css={InsertSelectCss}
        isSearchable={false}
        value={{ label: "Insert", value: "" }}
        components={{ Group: RichTextGroup, DropdownIndicator }}
      />

      <input
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        id="upload-image"
        type="file"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const file =
            e.target.files && e.target.files.length > 0 && e.target.files[0];
          file &&
            fileSelectHandler(file, editor, sdk, isBanner).catch(console.error);
        }}
        ref={fileInputRef}
      />
    </>
  );
};
