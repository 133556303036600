import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import useSWR from "swr";

import { UserActionTypes, UserContext } from "~context";
import {
  getError,
  handlePromise,
  isSuperAdminDomain,
  showToast,
} from "~lib/helpers";

import {
  LoginInput,
  TwoFactorAuthenticationCodeInput,
  VerifyEmailInput,
  VerifyMagicLinkMutation,
} from "~graphql/sdk";
import { useOrganization } from "./useOrganization";
import { useReCaptchaSDK } from "./useReCaptcha";
import { useSDK } from "./useSDK";

const isVerifyEmailInput = (
  input: VerifyEmailInput | LoginInput
): input is VerifyEmailInput => !!Object.hasOwnProperty.call(input, "token");

const useUser = () => {
  const sdk = useSDK();
  const { recaptchaSdkGenerator } = useReCaptchaSDK();
  const router = useRouter();
  const { organization } = useOrganization();
  const {
    user,
    dispatch,
    isLoggedIn,
    hasLoaded,
    isTwoFactorAuthenticated,
    loggedInOrganizations,
    isSalesOutlet,
    isSubpromoter,
    isEventManager,
    isAdmin,
    userRoles,
  } = useContext(UserContext);

  const { data, mutate, error } = useSWR(
    isSuperAdminDomain()
      ? ["me", organization?.id]
      : organization?.id
      ? ["me", organization.id]
      : null,
    async () => sdk.me(),
    { shouldRetryOnError: false }
  );

  const {
    data: loggedInOrganizationsData,
    error: loggedInOrganizationsError,
  } = useSWR(
    organization && user ? ["loggedInOrganizations"] : null,
    async () => sdk.myLoggedInOrganizations(),
    { shouldRetryOnError: false }
  );

  useEffect(() => {
    if (!data && error) {
      dispatch({ type: UserActionTypes.UNAUTHENTICATE_USER });
      dispatch({ type: UserActionTypes.LOGGED_IN_ORGS });
    } else if (data && !error) {
      dispatch({ type: UserActionTypes.LOGIN, user: data?.me });
    }
  }, [error, data]);

  useEffect(() => {
    if (loggedInOrganizationsData && !loggedInOrganizationsError) {
      dispatch({
        type: UserActionTypes.LOGGED_IN_ORGS,
        loggedInOrganizations:
          loggedInOrganizationsData.myLoggedInOrganizations,
      });
    }
  }, [loggedInOrganizationsData, loggedInOrganizationsError, user]);

  async function login(
    input: VerifyEmailInput
  ): Promise<VerifyMagicLinkMutation>;
  async function login(input: LoginInput): Promise<boolean>;
  async function login(
    input: VerifyEmailInput | LoginInput
  ): Promise<boolean | VerifyMagicLinkMutation> {
    if (isVerifyEmailInput(input)) {
      // Use recaptcha
      const sdkFn = await recaptchaSdkGenerator("verifyMagicLink");
      const [error, res] = await handlePromise(async () =>
        sdkFn({ orgId: organization.id }).verifyMagicLink({ input })
      );

      if (error) {
        showToast(getError(error, "graphQL"), "error");
        return false;
      }

      await mutate();
      return res;
    } else {
      // Use recaptcha
      const sdkFn = await recaptchaSdkGenerator("login");
      const [error] = await handlePromise(async () =>
        sdkFn({ orgId: organization.id }).login({ input })
      );

      if (error) {
        showToast(getError(error, "graphQL"), "error");
        return false;
      }
    }

    await mutate();
    return true;
  }

  const logout = async () => {
    const [error] = await handlePromise(async () => sdk.logout());

    if (error) {
      return showToast(getError(error, "graphQL"), "error");
    }

    dispatch({ type: UserActionTypes.LOGOUT });
    dispatch({ type: UserActionTypes.LOGGED_IN_ORGS }); // Clear slugs
    void mutate(null, false);
    void router.push("/login");
  };

  const authenticateWithTwoFactor = async (
    input: TwoFactorAuthenticationCodeInput
  ) => {
    const [error] = await handlePromise(async () =>
      sdk.authenticateWithTwoFactor({ input })
    );

    if (error) {
      showToast(getError(error, "graphQL"), "error");
      return false;
    }

    dispatch({
      type: UserActionTypes.AUTHENTICATE_TWO_FACTOR,
    });

    showToast("Authenticated successfully", "success");

    void mutate();
    void router.push("/");
  };

  return {
    login,
    logout,
    dispatch,
    user,
    loggedInOrganizations,
    error,
    isLoggedIn,
    hasLoaded,
    mutateUser: mutate,
    authenticateWithTwoFactor,
    isTwoFactorAuthenticated,
    isSalesOutlet,
    isEventManager,
    isSubpromoter,
    isAdmin,
    userRoles,
  };
};

export default useUser;
