import { yupResolver } from "@hookform/resolvers";
import { Select } from "~components/common/Select";
import {
  Box,
  Divider,
  Flex,
  Price,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TransparentButton,
  formatPrice,
} from "flicket-ui";
import { Input } from "~components/common/Input";
import { AnimatePresence, motion } from "framer-motion";
import { orderBy } from "lodash";
import { ChangeEvent, FormEvent, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import CustomModal from "~components/common/CustomModal";
import { useCustomModal } from "~components/common/CustomModal/useCustomModal";
import { Icon } from "~components/common/Icon";
import {
  OrderQuery,
  OrderRefundState,
  OrderType,
  RefundGateway,
  TicketStatus,
} from "~graphql/typed-document-nodes";
import { useSDK } from "~hooks/useSDK";
import { getError } from "~lib";
import { delayedPromise } from "~lib/helpers/handlePromise";
import showToast from "~lib/helpers/showToast";
import RefundBanner from "./RefundBanner";
import {
  RefundFormValues,
  buildRefundSchema,
  refundUnavailable,
} from "./refundSchema";
import { PriceInput } from "~components/common/PriceInput";
import { FormPartialCheckbox } from "~features/generalAdmissionEvent/components/FormPartials/Collapse.form-partial";
import { Checkbox } from "~components";

interface RefundModalContentProps {
  order: OrderQuery["order"];
  onRefundOrTicketRelease?: () => Promise<void>;
}

const refundGatewayOptions = [
  { label: "Cash", value: RefundGateway.Cash },
  { label: "Credit card", value: RefundGateway.CreditCard },
  { label: "Eftpos", value: RefundGateway.Eftpos },
  { label: "Invoice", value: RefundGateway.Invoice },
  { label: "Other", value: RefundGateway.Other },
];

const REFUND_FORM_NANE = "refund-form";

function title(refundState: OrderRefundState): string {
  if (
    refundState === OrderRefundState.None ||
    refundState === OrderRefundState.FullyRefunded
  ) {
    return "Release seats";
  }

  return "Refund order";
}

function ctaLabel(
  refundState: OrderRefundState,
  refundAmount: number,
  ticketIdsToRelease: string[]
): string {
  if (
    refundState === OrderRefundState.None ||
    refundState === OrderRefundState.FullyRefunded
  ) {
    return "Release seats";
  }

  if (refundAmount > 0) {
    if (ticketIdsToRelease.length > 0) {
      return "Refund and release seats";
    }

    return "Refund";
  }

  if (ticketIdsToRelease.length > 0) {
    return "Release seats";
  }

  return "Refund";
}

const getFullPrice = (
  ticket: OrderQuery["order"]["tickets"][number],
  lineItems: OrderQuery["order"]["lineItems"]
): number => {
  const lineItemId = ticket.lineItem.id;
  const lineItem = lineItems.edges
    .flatMap((lineItem) => lineItem.node)
    .find(({ id }) => id === lineItemId);
  return lineItem.originalPrice;
};

export default function RefundModalContent(props: RefundModalContentProps) {
  const { order, onRefundOrTicketRelease } = props;

  const { close } = useCustomModal();
  const sdk = useSDK();

  const { refundableState } = order;

  const [formState, setFormState] = useState<"idle" | "loading">("idle");
  const [showReleaseTickets, setShowReleaseTickets] = useState(false);
  const { schema, formNames, defaultValues } = useMemo(
    () => buildRefundSchema(refundableState, order?.orderType),
    []
  );

  const maxRefundAmount = refundableState.totalRefundableAmount;

  const formMethods = useForm<RefundFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const {
    handleSubmit,
    watch,
    setValue,
    errors,
    register,
    control,
  } = formMethods;

  const isManual = watch("isManual");

  const onSubmit = async (values: RefundFormValues) => {
    if (values.ticketIdsToRelease.length > 0 && values.refundAmount === 0) {
      await releaseSeats(values);
    } else {
      await createRefund(values);
    }
  };

  const isHoldOrder = order?.status === "Hold";
  const ticketIdsToRelease = watch("ticketIdsToRelease");
  const refundAmount = watch("refundAmount");
  const atLeastOneTicketToBeReleased = ticketIdsToRelease.length > 0;
  const activeTickets = order?.tickets?.filter(
    (ticket) => TicketStatus.Active === ticket.status
  );

  const toggleTicketToBeReleased = (ticketId: string) => {
    if (ticketIdsToRelease.find((id) => ticketId === id)) {
      setValue(
        "ticketIdsToRelease",
        ticketIdsToRelease.filter((id) => id !== ticketId)
      );
    } else {
      setValue("ticketIdsToRelease", ticketIdsToRelease.concat([ticketId]));
    }
  };

  // Release seats is called when no refund is being applied. Otherwise
  const releaseSeats = async (formValues: RefundFormValues) => {
    setFormState("loading");

    const [error, data] = await delayedPromise(async () =>
      sdk.releaseSeats({
        input: {
          orderId: order.id,
          notes: formValues.notes,
          ticketIds: formValues.ticketIdsToRelease,
        },
      })
    );

    if (error || !data.releaseSeats) {
      showToast(
        error ? getError(error, "graphQL") : "Something went wrong!",
        "error"
      );
      setFormState("idle");
      return;
    }

    showToast("Seats have been released", "success");
    await onRefundOrTicketRelease?.();
    close();
  };

  const createRefund = async (formValues: RefundFormValues) => {
    setFormState("loading");

    const [error, data] = await delayedPromise(async () =>
      sdk.createRefund({
        input: {
          orderId: order.id,
          amount: formValues.refundAmount,
          notes: formValues.notes,
          isManual: formValues.isManual,
          sendEmail: formValues.sendEmail,
          gateway: formValues.gateway,
          ticketIds: formValues.ticketIdsToRelease,
        },
      })
    );

    if (error || !data.createRefund) {
      showToast(
        error ? getError(error, "graphQL") : "Something went wrong!",
        "error"
      );
      setFormState("idle");
      return;
    }

    showToast("Amount has been refunded", "success");
    await onRefundOrTicketRelease?.();
    close();
  };

  const _refundUnavailable = refundUnavailable(refundableState.refundState);

  const hasTicketsToRelease = order?.tickets?.some(
    ({ status }) => status === TicketStatus.Active
  );

  return (
    <>
      <CustomModal.Header>
        {title(refundableState.refundState)}
      </CustomModal.Header>
      <CustomModal.Content>
        <FormProvider {...formMethods}>
          <Box
            id={REFUND_FORM_NANE}
            as="form"
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              e.stopPropagation();
              void handleSubmit(onSubmit)(e);
            }}
          >
            <RefundBanner order={order} />

            {hasTicketsToRelease && (
              <Box mb={2}>
                <Flex flex={1} justifyContent="space-between">
                  <Checkbox
                    label="Release tickets"
                    checked={atLeastOneTicketToBeReleased}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (atLeastOneTicketToBeReleased) {
                        setValue("ticketIdsToRelease", []);
                      } else {
                        setValue(
                          "ticketIdsToRelease",
                          activeTickets.map((t) => t.id)
                        );
                      }
                    }}
                  />
                  <TransparentButton
                    onClick={() => setShowReleaseTickets(!showReleaseTickets)}
                    flex={1}
                    justifyContent="flex-end"
                  >
                    {ticketIdsToRelease.length > 0 && (
                      <Text fontSize={2} fontWeight="heavy">
                        ({ticketIdsToRelease.length} ticket
                        {ticketIdsToRelease.length > 1 && "s"} selected)
                      </Text>
                    )}
                    <Icon
                      icon="chevron-down"
                      ml="1/4"
                      css={`
                        transform: rotate(${showReleaseTickets ? 180 : 0}deg);
                        transition: transform ease-in-out 0.3s;
                      `}
                    />
                  </TransparentButton>
                </Flex>
                <AnimatePresence>
                  {showReleaseTickets && (
                    <motion.div
                      initial={{
                        height: 0,
                        opacity: 0,
                      }}
                      animate={{
                        height: "auto",
                        opacity: 1,
                      }}
                      exit={{
                        height: 0,
                        opacity: 0,
                      }}
                    >
                      <Divider my={2} />
                      <Stack flexDir="column" space={1}>
                        {orderBy(
                          activeTickets,
                          ["lineItem.type", "lineItem.name"],
                          ["desc", "asc"]
                        ).map((ticket) => (
                          <Flex
                            flex={1}
                            justifyContent="space-between"
                            key={ticket.id}
                          >
                            <Checkbox
                              label={`${ticket?.lineItem.name} | (#${ticket?.ticketNumber})`}
                              onChange={() => {
                                toggleTicketToBeReleased(ticket.id);
                              }}
                              cursor="pointer"
                              checked={Boolean(
                                ticketIdsToRelease.find(
                                  (id) => id === ticket.id
                                )
                              )}
                            />
                            <Price
                              price={
                                isHoldOrder
                                  ? getFullPrice(ticket, order.lineItems)
                                  : ticket.lineItem.price
                              }
                            />
                          </Flex>
                        ))}
                      </Stack>
                    </motion.div>
                  )}
                </AnimatePresence>
                <Divider my={2} />
              </Box>
            )}

            <>
              {!_refundUnavailable && (
                <>
                  <Box mb={2}>
                    <PriceInput
                      ref={register({ valueAsNumber: true })}
                      name={formNames.refundAmount}
                      label={`Refund amount`}
                      placeholder="0.00"
                      error={errors?.refundAmount?.message}
                      prefix={"$"}
                    />
                    <Text variant="regular" color="N600" mt={1}>
                      {`The maximum refundable amount is ${formatPrice(
                        maxRefundAmount
                      )}.${
                        refundableState.refundState ===
                          OrderRefundState.FullGateway && !isManual
                          ? ` This will be refunded through ${refundableState.gatewayRefundableAmount?.integrationName}.`
                          : ""
                      }`}
                    </Text>
                  </Box>

                  {/* Remove the checkbox if the only way a user can refund is manually */}
                  {order.refundableState.refundState !==
                  OrderRefundState.ManualOnly ? (
                    <FormPartialCheckbox
                      label={"Manual refund"}
                      name={formNames.isManual}
                    >
                      <Box>
                        <Controller
                          options={refundGatewayOptions}
                          as={Select}
                          control={control}
                          label={"Method"}
                          name="gateway"
                          error={errors?.gateway?.message}
                          menuPortalTarget={document.body}
                        />
                      </Box>
                    </FormPartialCheckbox>
                  ) : (
                    <Box mb={2}>
                      <Controller
                        options={refundGatewayOptions}
                        as={Select}
                        control={control}
                        label={"Method"}
                        name="gateway"
                        error={errors?.gateway?.message}
                        menuPortalTarget={document.body}
                      />
                    </Box>
                  )}
                </>
              )}

              {hasTicketsToRelease || !_refundUnavailable ? (
                <Controller
                  as={Input}
                  inputAs="textarea"
                  rows={3}
                  control={control}
                  name={formNames.notes}
                  label={_refundUnavailable ? "Notes" : "Refund notes"}
                  placeholder={_refundUnavailable ? "Notes" : "Refund notes"}
                  error={errors?.notes?.message}
                  defaultValue={defaultValues.notes}
                  mb={2}
                />
              ) : null}

              {!_refundUnavailable && (
                <FormPartialCheckbox
                  label={"Send email"}
                  description={
                    "Send the user an email notifying them the refund has been put through."
                  }
                  name={formNames.sendEmail}
                />
              )}
            </>
          </Box>
        </FormProvider>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Stack direction={"horizontal"} gap={2}>
          <SecondaryButton fontSize={2} onClick={close}>
            Cancel
          </SecondaryButton>
          {hasTicketsToRelease || !_refundUnavailable ? (
            <PrimaryButton
              fontSize={2}
              type="submit"
              isLoading={formState === "loading"}
              disabled={formState === "loading"}
              form={REFUND_FORM_NANE}
            >
              {ctaLabel(
                refundableState.refundState,
                refundAmount,
                ticketIdsToRelease
              )}
            </PrimaryButton>
          ) : null}
        </Stack>
      </CustomModal.Footer>
    </>
  );
}
