import { useState } from "react";
import { includes } from "lodash";
import { useRouter } from "next/router";

import { Seo, Status, Select, Layout, Skeleton } from "~components";
import {
  usePermissions,
  useUser,
  useSuperAdmin,
  useOrganization,
} from "~hooks";

import { NAV_ITEMS } from "~components/common/Navbar";
import { Card, OverviewSummary, EventSummaryCard } from "~components/reports";
import { OrderByDirection, Role } from "~graphql/sdk";
import { withAuth } from "~lib/helpers";
import { useQuery } from "~hooks/useQuery";
import { DashboardEventsDocument } from "~graphql/typed-document-nodes";
import { Box, Stack, TertiaryButton } from "flicket-ui";
import { useScreenSize } from "~hooks/useScreenSize";
import { OrganizationFeatures } from "~lib/features";
import { EventList, useEventsListFilter } from "~features/EventList/EventList";

const Home = () => {
  const router = useRouter();
  const { user, isLoggedIn, isEventManager } = useUser();
  const { hasPermissions, Permission } = usePermissions();
  const { isSuperAdmin, isSuperAdminDomain } = useSuperAdmin();
  const { hasFeature } = useOrganization();

  const showNewEventList = hasFeature(OrganizationFeatures.EventListHomepage);

  if (isSuperAdmin && isSuperAdminDomain) {
    router.replace("/accounts").catch((e) => console.error(e));
    return null;
  } else if (includes(user?.roles, Role.PosAdmin) && user?.roles.length === 1) {
    router.replace("/pos").catch((e) => console.error(e));
    return null;
  }

  if (!isLoggedIn) {
    router.replace("/login").catch((e) => console.error(e));
    return null;
  }

  if (!hasPermissions(Permission.ReportingFinancial) || isEventManager) {
    const firstRoute = NAV_ITEMS({ hasPermissions, user, hasFeature })[0];
    router.replace(firstRoute.url).catch((e) => console.error(e));
    return null;
  }

  return (
    <>
      <Seo title="Flicket | Dashboard" description="Flicket dashboard" />

      <Layout
        title="Home"
        description="Home page"
        bg={{ _: "white", md: "N100" }}
      >
        <Stack gap={4} direction="vertical" width={1}>
          <OverviewSummary />

          {showNewEventList && <NewEventsListCard />}

          {!showNewEventList && <OldEventsListCard />}
        </Stack>
      </Layout>
    </>
  );
};

export default withAuth(Home);

function NewEventsListCard() {
  const router = useRouter();

  const { events, isValidating, isLoading, mutate } = useEventsListFilter({
    startDate: new Date().toISOString(),
    endDate: undefined,
    isActive: true,
  });

  const eventCount = isLoading ? " " : `${events?.length} `;

  return (
    <Card
      title={
        isLoading ? (
          <Skeleton height={22} width={220} />
        ) : (
          `${eventCount}upcoming events`
        )
      }
      overflow="visible"
      mb={6}
      p={{ _: 0, md: 4 }}
      boxShadow={{ _: "none", md: "sm" }}
    >
      <Box
        onMouseOver={() => {
          void router.prefetch("/events");
        }}
      >
        <EventList
          events={events}
          isLoading={isLoading}
          isValidating={isValidating}
          mutate={mutate}
        />
        {!isLoading && (
          <TertiaryButton
            width={1}
            mt={3}
            onClick={() => {
              void router.push("/events");
            }}
          >
            View all events
          </TertiaryButton>
        )}
      </Box>
    </Card>
  );
}

function OldEventsListCard() {
  const isMobile = useScreenSize().isTabletPortraitDown;
  const [selectedEventId, setSelectedEventId] = useState("");

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const { data: eventsData, error: eventsError, isLoading } = useQuery(
    DashboardEventsDocument,
    {
      where: {
        startDate: today.toISOString(),
        endDate: undefined,
      },
      orderBy: { startDate: OrderByDirection.Asc },
    }
  );

  const events = eventsData?.events?.edges?.map(({ node }) => node);

  const selectedEvents =
    selectedEventId === ""
      ? events
      : events?.filter((event) => selectedEventId === event.id);

  const options = events?.map((event) => ({
    label: event.title,
    value: event.id,
  }));

  options?.unshift({ label: "All Events", value: "" });

  return (
    <Card
      title="Upcoming events"
      p={{ _: 0, md: 4 }}
      boxShadow={{ _: "none", md: "sm" }}
    >
      <Status
        loading={!eventsError && !events && !isLoading}
        error={eventsError}
      >
        {!isMobile &&
          events?.map((event) => (
            <EventSummaryCard key={event.id} event={event} />
          ))}

        {isMobile && events && (
          <Select
            pb={4}
            isMulti={false}
            name="event-selector"
            options={options}
            onChange={setSelectedEventId}
            value={selectedEventId}
            small
          />
        )}

        {isMobile &&
          selectedEvents &&
          selectedEvents.map((selectedEvent) => (
            <EventSummaryCard key={selectedEvent.id} event={selectedEvent} />
          ))}
      </Status>
    </Card>
  );
}
