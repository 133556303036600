import {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  ReactNode,
} from "react";
import { Box, Flex, system, SystemProps } from "flicket-ui";
import styled, { DefaultTheme } from "styled-components";

type BoxProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  SystemProps & {
    children?: ReactNode;
  };

export const Menu = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => (
    <Flex {...props} ref={ref}>
      {children}
    </Flex>
  )
);
Menu.displayName = "Menu";

const StyledToolbar = styled(Menu)`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 0 24px;
  background-color: #fff;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  z-index: 100;
  gap: 4px;

  // Allow for toolbar item access on smaller screens
  overflow-x: auto;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    height: 1px;
    width: 100%;
    background-color: ${(p) => p.theme.colors.N200};
  }
  ${system}
`;

export const Toolbar = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => (
    <StyledToolbar {...props} ref={ref}>
      {children}
    </StyledToolbar>
  )
);
Toolbar.displayName = "Toolbar";

export const ButtonDivider = styled(Flex)`
  width: 1px;
  background-color: ${(p) => p.theme.colors.N200};
  height: 22px;
  margin-left: 4px;
  margin-right: 4px;
`;

const fontStylesRegular = (theme: DefaultTheme) => `
  font-size: ${theme.fontSizes[3]};
  line-height: 1.5;
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.N800};
`;

export const EditorWrapper = styled(Box)<{
  isValid?: boolean;
  isEmail: boolean;
  backgroundColour?: string;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.backgroundColour ?? p.theme.colors.white};
  width: 100%;
  ${(p) => (p.isEmail ? `min-height: 450px;` : `height:350px;`)}
  border: 1px solid ${(p) => p.theme.colors[p.isValid ? "N200" : "error"]};
  border-radius: 8px;
  contain: none;

  &:focus-within {
    border-color: ${(p) => p.theme.colors[p.isValid ? "N600" : "error"]};
  }

  [data-slate-node="value"] {
    flex: 1;
  }

  .react-select__control {
    .react-select__dropdown-indicator svg {
      color: ${(p) => p.theme.colors.N500} !important;
    }

    .react-select__value-container {
      padding-top: ${(p) => (p.isEmail ? "0 !important" : "auto")};
    }
  }

  p,
  a,
  li {
    ${(p) => fontStylesRegular(p.theme)};
  }

  h1,
  h2,
  h3 {
    margin-bottom: ${(p) => p.theme.space[1]}px !important;
  }

  p,
  ul,
  ol {
    margin-bottom: ${(p) => p.theme.space["1/2"]}px !important;
  }

  ul li,
  ol li {
    margin-left: ${(p) => p.theme.space[4]}px;
  }

  ol li::marker {
    font-size: ${(p) => p.theme.fontSizes[2]};
  }

  a {
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }
  }

  ${system}
`;

type ButtonProps = DetailedHTMLProps<
  HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  SystemProps & {
    active?: boolean;
  };

const StyledButton = styled(Flex)`
  :hover {
    background: ${(p) => p.theme.colors.N100};
  }
  :active {
    background: ${(p) => p.theme.colors.N200};
  }
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ active, ...props }, ref) => (
    <StyledButton
      as="button"
      type="button"
      alignItems="center"
      justifyContent="center"
      ref={ref}
      backgroundColor={active ? "N100" : "transparent"}
      borderRadius={"xs"}
      color={"N800"}
      padding="1/2"
      {...props}
    />
  )
);
Button.displayName = "Button";
