import React from "react";
import { Flex, Price, Text, Stack } from "flicket-ui";
import { useNonSeatedMultiBuy, useSeatedMultibuy } from "~hooks";
import { OrderQuery, VenueSeating } from "~graphql/sdk";

type LineItem = OrderQuery["order"]["lineItems"]["edges"][number]["node"];

type PriceDifferenceProps = {
  originalItems: LineItem[];
  orderItems: LineItem[];
  transferItems: LineItem[];
  order: OrderQuery["order"];
};

/** abstraction build around multibuy hook to return the correct activePromotion  */
export const useGetMultibuy = (order, orderItems, transferItems) => {
  const promotions =
    order?.event?.multiBuyPromotions ?? order?.membership?.multiBuyPromotions;

  const { activePromotions: orderMultiBuyPromotions } = useNonSeatedMultiBuy(
    promotions,
    orderItems
  );
  const { activePromotions: orderSeatedMultiBuyPromotions } = useSeatedMultibuy(
    promotions,
    orderItems
  );

  const { activePromotions: transferMultiBuyPromotions } = useNonSeatedMultiBuy(
    promotions,
    transferItems
  );
  const {
    activePromotions: transferSeatedMultiBuyPromotions,
  } = useSeatedMultibuy(promotions, transferItems);

  const isNonSeated =
    (order?.event
      ? order?.event?.venue?.seating
      : order?.membership
      ? order?.membership?.venue?.seating
      : undefined) === VenueSeating.NonSeated;

  const orderPromotions = isNonSeated
    ? orderMultiBuyPromotions
    : orderSeatedMultiBuyPromotions;

  const transferPromotions = isNonSeated
    ? transferMultiBuyPromotions
    : transferSeatedMultiBuyPromotions;

  return {
    orderPromotions,
    transferPromotions,
  };
};

const getPrice = (lineItem: LineItem, promotion: any) => {
  const newPrice = lineItem?.originalPrice * lineItem.quantity;

  return newPrice;
};

const calculateTotals = (items: LineItem[], promotion) => {
  const old = items.reduce(
    (acc, curr) => (acc += curr?.price * curr?.quantity),
    0
  );

  const updated = items.reduce(
    (acc, curr) => (acc += getPrice(curr, promotion)),
    0
  );

  return {
    old,
    updated,
  };
};

export const PriceDifference = ({
  originalItems,
  orderItems,
  transferItems,
  order,
}: PriceDifferenceProps) => {
  const { transferPromotions, orderPromotions } = useGetMultibuy(
    order,
    orderItems,
    transferItems
  );

  const originalTotals = calculateTotals(originalItems, orderPromotions?.[0]);
  const orderTotals = calculateTotals(orderItems, orderPromotions?.[0]);
  const transferTotals = calculateTotals(
    transferItems,
    transferPromotions?.[0]
  );

  return (
    <Stack space={4}>
      <Flex flexBasis="calc(50% - 16px)" flexDirection="column">
        <Flex justifyContent="space-between">
          <Text fontWeight="extraBold" fontSize={3}>
            Original ticket total
          </Text>
          <Price
            fontSize={3}
            lineHeight="high"
            price={originalTotals.old}
            ml={2}
          />
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="extraBold" fontSize={3}>
            New ticket total
          </Text>
          <Price
            fontSize={3}
            lineHeight="high"
            price={orderTotals.updated}
            ml={2}
          />
        </Flex>
      </Flex>
      <Flex
        flexBasis="calc(50% - 16px)"
        flexDirection="column"
        alignSelf="space-between"
      >
        <Flex justifyContent="space-between">
          <Text fontWeight="extraBold" fontSize={3}>
            New order ticket total
          </Text>
          <Price
            fontSize={3}
            lineHeight="high"
            price={transferTotals.updated}
            ml={2}
          />
        </Flex>
      </Flex>
    </Stack>
  );
};
