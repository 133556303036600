import React, { useState, useEffect } from "react";

import { useDebounce } from "use-debounce";

import { Select } from "~components";
import { useSDK } from "~hooks";
import { handlePromise } from "~lib";
import { Role } from "~graphql/sdk";

type CustomerSearchProps = {
  handleChange: () => void;
  defaultOptions?: { label: string; value: string }[];
};

export const CustomerSearch = ({
  handleChange,
  defaultOptions = undefined,
}: CustomerSearchProps) => {
  const sdk = useSDK();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [q, setQ] = useState("");
  const [debouncedValue] = useDebounce(q, 300);

  const getCustomers = async (q, setOptions) => {
    setIsLoading(true);

    const { 1: result } = await handlePromise(async () =>
      sdk.users({
        where: {
          q,
          roles: [Role.User],
        },
      })
    );

    setOptions(
      result?.users?.edges?.map(({ node: { id, fullName } }) => ({
        label: fullName,
        value: id,
      }))
    );

    setIsLoading(false);
  };

  useEffect(() => {
    if (debouncedValue.length > 0) {
      getCustomers(debouncedValue, setOptions);
    } else if (options?.length > 0) {
      setOptions(defaultOptions);
    }
  }, [debouncedValue]);

  return (
    <Select
      my={2}
      label="Select a customer"
      placeholder="Select a customer"
      options={options}
      onChange={handleChange}
      isLoading={isLoading}
      onInputChange={(value, { action }) => {
        if (action === "input-change") {
          setQ(value);
        }
      }}
      isClearable
    />
  );
};
