import {
  IconContext,
  IconProps as PhosphorIconProps,
} from "@phosphor-icons/react";
import { Box, Stack, SystemProps, Text } from "flicket-ui";
import React from "react";
import styled from "styled-components";
import { useScreenSize } from "~hooks/useScreenSize";
import { Icon, IconProps, isIconName } from "../Icon/Icon";

const ICON_PROPS: PhosphorIconProps = {
  size: "22",
  weight: "regular",
};

const MobileGrid = styled(Box)`
  display: grid;
  grid-template-columns: 5fr 4fr;
  grid-template-rows: 1fr;
  gap: 4px;
`;

const DesktopGrid = styled(Box)`
  display: grid;
  grid-template-columns: 22px 1fr 1fr 130px;
  grid-template-rows: 1fr;
  align-items: center;
`;

const DesktopTitle = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export interface ReportingItemProps {
  title: string;
  subtitle?: string;
  icon?: IconProps["icon"];
  descriptionItems?: string[];
  displayValue?: string;
  displayValueSubtitle?: string;
  showDisplayValue?: boolean;
  showDisplayValueSubtitle?: boolean;
}

type ReportingItemIconProps = {
  icon: IconProps["icon"];
};

const ReportingItemIcon = ({
  icon,
  ...props
}: ReportingItemIconProps & SystemProps) => {
  if (!icon) return null;
  // if icon is string, assert it is IconName
  if (isIconName(icon)) {
    return <Icon icon={icon} fontSize={"22px" as any} />;
  }
  return (
    <IconContext.Provider value={ICON_PROPS}>
      <Icon icon={icon} gridColumn={1} {...props} />
    </IconContext.Provider>
  );
};

const DesktopItem = ({
  title,
  subtitle,
  descriptionItems,
  icon,
  displayValue,
  displayValueSubtitle,
  showDisplayValue = true,
  showDisplayValueSubtitle = true,
}: ReportingItemProps) => {
  return (
    <DesktopGrid gridGap={1}>
      <ReportingItemIcon
        icon={icon}
        alignSelf={descriptionItems?.length ? "flex-start" : "center"}
        mt={"1/4"}
      />
      <Stack direction={"vertical"} gridColumn={subtitle ? 2 : "2/4"}>
        <DesktopTitle>{title}</DesktopTitle>
        {descriptionItems?.length ? (
          <Text fontSize={2} color={"N600"}>
            {descriptionItems.join(" • ")}
          </Text>
        ) : null}
      </Stack>
      {subtitle && (
        <Text gridColumn={3} fontSize={2} color={"N600"} textAlign={"end"}>
          {subtitle}
        </Text>
      )}

      <Stack direction={"vertical"} gridColumn={4}>
        {showDisplayValue && displayValue && (
          <Text fontSize={2} textAlign={"end"}>
            {displayValue}
          </Text>
        )}
        {showDisplayValueSubtitle && displayValueSubtitle && (
          <Text fontSize={2} color={"N600"} textAlign={"right"}>
            {displayValueSubtitle}
          </Text>
        )}
      </Stack>
    </DesktopGrid>
  );
};

const MobileItem = ({
  title,
  subtitle,
  descriptionItems,
  displayValue,
  displayValueSubtitle,
  icon,
  showDisplayValue = true,
  showDisplayValueSubtitle = true,
}: ReportingItemProps) => {
  return (
    <MobileGrid>
      <Stack direction={"vertical"} gap={"1/2"}>
        <Text fontSize={3}>{title}</Text>
        {subtitle && (
          <Text fontSize={2} color={"N600"}>
            {subtitle}
          </Text>
        )}
        {descriptionItems && (
          <Text flex={1} fontSize={2} color={"N600"} mt={"1/4"}>
            {descriptionItems.join(" • ")}
          </Text>
        )}
      </Stack>
      <Stack direction={"vertical"} alignItems={"flex-end"}>
        <ReportingItemIcon icon={icon} />
        {showDisplayValue && displayValue && (
          <Text fontSize={2}>{displayValue}</Text>
        )}
        {showDisplayValueSubtitle && displayValueSubtitle && (
          <Text fontSize={2} color={"N600"} mt="1/4">
            {displayValueSubtitle}
          </Text>
        )}
      </Stack>
    </MobileGrid>
  );
};

export default function ReportingItem(props: ReportingItemProps) {
  const isMobile = useScreenSize().isPhonePortrait;
  return isMobile ? <MobileItem {...props} /> : <DesktopItem {...props} />;
}
