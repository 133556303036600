import React, { FC, useContext } from "react";
import { SystemProps } from "flicket-ui";
import styled, { DefaultTheme, ThemeContext } from "styled-components";
import Select from "react-select/creatable";
import { components, Props, Theme } from "react-select";
import { baseSelectStyles } from "./styles";

const DropdownIndicator = (props) => {
  return <components.DropdownIndicator {...props} />;
};

const StyledCreatableSelect = styled(Select)<
  SystemProps & { isInvalid?: boolean; styledTheme: DefaultTheme }
>(baseSelectStyles);

interface SelectProps extends Props {
  theme?: any;
}

export const CreatableSelect: FC<SelectProps> = (props) => {
  const styledTheme = useContext(ThemeContext); // react-select and styled-components both need a theme so it needs to be renamed
  return (
    <StyledCreatableSelect
      components={{ DropdownIndicator }}
      className="react-select"
      classNamePrefix="react-select"
      styledTheme={styledTheme}
      theme={(t: Theme) => ({
        ...t,
        colors: {
          ...t.colors,
          primary25: styledTheme.colors.N100,
          primary50: styledTheme.colors.N100,
          primary: styledTheme.colors.N100,
        },
      })}
      {...props}
    />
  );
};
