import { Info } from "@phosphor-icons/react";
import { Box, Flex, SystemProps, useScreenSize } from "flicket-ui";
import { useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";
import { Icon } from "~components";
import { StatisticTooltipFragment } from "~graphql/typed-document-nodes";
import CustomModal from "~components/common/CustomModal";
import { StatisticTooltipContent } from "./StatisticTooltipContent";

const StyledTooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
    max-width: 360px;
    padding: 0;
    background-color: transparent;
  }

  &.place-top {
    margin-top: 10px !important;
  }

  /* hide arrow */
  &::before,
  &::after {
    content: none !important;
  }
`;
const stringToHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

type StatisticTooltipProps = SystemProps & {
  tooltip?: StatisticTooltipFragment;
  id?: string;
};

export const StatisticTooltip = ({
  tooltip,
  id = "",
  ...props
}: StatisticTooltipProps) => {
  if (!tooltip) return null;
  const { content, link } = tooltip;
  const { isPhoneLandscapeDown } = useScreenSize();
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const uniqueId = stringToHash(content + id);
  useOnClickOutside(wrapperRef, () => {
    if (isPhoneLandscapeDown) {
      setIsOpen(false);
    }
  });

  return (
    <Flex ref={wrapperRef} {...props}>
      <Icon
        icon={<Info fontSize={"20px"} />}
        cursor={"pointer"}
        fontSize={5}
        data-for={`statistic-tooltip-${uniqueId}`}
        data-tip={content}
        onClick={() => setIsOpen(true)}
        color={"N600"}
        width={"20px"}
      />
      {isPhoneLandscapeDown ? (
        // mobile
        <CustomModal
          isOpen={isOpen}
          close={() => setIsOpen(false)}
          dialogOverlayProps={{ alignItems: "center" }}
          dialogContentProps={{ maxWidth: "360px" }}
          height={"fit-content"}
          borderRadius={"md"}
        >
          <StatisticTooltipContent content={content} link={link} />
        </CustomModal>
      ) : (
        // desktop
        <StyledTooltip
          id={`statistic-tooltip-${uniqueId}`}
          effect="solid"
          arrowColor="transparent"
          clickable={true}
          getContent={(dataTip) => (
            <Box backgroundColor={"transparent"}>
              <StatisticTooltipContent content={dataTip} link={link} />
              {/* Add a transparent box to ensure the tooltip doesn't hide 
              when the mouse moves over the gap between the icon and the tooltip. */}
              <Box height="10px" backgroundColor={"transparent"} />
            </Box>
          )}
        />
      )}
    </Flex>
  );
};
