import { useEffect, useMemo, useRef, useState } from "react";
import equal from "fast-deep-equal";

import { handlePromise } from "~lib/helpers";

const PAGE_SIZE = 50;

export interface FetcherProps {
  first: number;
  after?: string;
}

interface InfiniteLoaderProps<P> {
  fetcher: (props: FetcherProps) => any;
  isReady?: boolean;
  limit?: number;
  params?: P;
}

interface Page<T> {
  edges: PageNode<T>[];
  pageInfo: PageInfo;
  totalCount: number;
}

interface PageInfo {
  startCursor: string;
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

interface PageNode<T> {
  node: T;
}

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

// T is the type of actual data
// example: ScoutUsersQuery["scoutUsers"]["edges"][0]["node"]
// this type should be similar to all paginated data
// P is the type of parameter
// example: SocialMentionsQueryVariables
export const useInfiniteLoader = <T extends { id: string } = any, P = any>({
  fetcher,
  isReady = true,
  limit = PAGE_SIZE,
  params,
}: InfiniteLoaderProps<P>) => {
  const [error, setError] = useState<Error>(null);
  const [data, setData] = useState<Page<T>[]>(null);
  const [isNextPageLoading, setNextPageLoading] = useState(false);
  // useMemo use shallow compare, remove edges from data will not fire useMemo update
  // hence we need to add a forceUpdate to force updating
  const [forceUpdate, setForceUpdate] = useState(false);
  const previousParams = usePrevious(params);

  const previousPageData = useMemo(() => data?.[data?.length - 1]?.pageInfo, [
    data,
  ]);

  const loadNextPage = async (forceReset?: boolean) => {
    if (isNextPageLoading && !forceReset) {
      return;
    }

    if (
      !isReady ||
      (!forceReset && data?.length && !previousPageData?.hasNextPage)
    ) {
      return;
    }

    setNextPageLoading(true);

    const [resultError, result] = await handlePromise(() =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      fetcher({
        first: limit,
        after: forceReset ? undefined : previousPageData?.endCursor,
        ...params,
      } as FetcherProps)
    );

    if (resultError) {
      setError(resultError);
      setNextPageLoading(false);
      return;
    }

    setError(null);
    setData([...(forceReset ? [] : data || []), result as Page<T>]);
    setNextPageLoading(false);
    return result;
  };

  useEffect(() => {
    if (!equal(previousParams, params)) {
      loadNextPage(true).catch((e) => console.error(e));
    }
  }, [params]);

  const rows = useMemo(() => {
    return data
      ? data.flatMap((d) => d.edges.flatMap((e) => e.node))
      : Array<T>();
  }, [data, forceUpdate]);

  /**
   * This method is used to delete display data.
   * Since the useInfiniteLoader hook doesn't use swr, we can't use mutate().
   * This method receives the id of deleted item, then remove and force update the local data.
   * This method will not reload the data from api, so only use it after the delete data api succeeded.
   */

  const deleteLocalData = (ids: string[]): void => {
    data.forEach((d) => {
      d.edges = d.edges.filter((edge) => {
        return !ids.includes(edge.node.id);
      });
    });
    setData(data);
    setForceUpdate(!forceUpdate);
  };

  /**
   * This method is used to update display data.
   * Since the useInfiniteLoader hook doesn't use swr, we can't use mutate().
   * This method receives the updated item, then force update the local data.
   * This method will not reload the data from api, so only use it after the update data api succeeded.
   */
  const updateLocalData = (items: T[]): void => {
    data.forEach((d) => {
      d.edges.forEach((dd) => {
        const index = items.map((item) => item.id).indexOf(dd.node.id);
        if (index >= 0) {
          dd.node = items[index];
        }
      });
    });
    setData(data);
    setForceUpdate(!forceUpdate);
  };

  return {
    data,
    rows,
    error,
    hasNextPage: previousPageData?.hasNextPage,
    loadNextPage,
    deleteLocalData,
    updateLocalData,
  };
};
