import {
  PointReportingFilterSource,
  OrderChannel,
  ReportingFilterSource,
} from "~graphql/typed-document-nodes";

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export type BaseParams = {
  params: {
    sourceId: string;
    startDate: string;
    endDate: string;
    source: PointReportingFilterSource;
    channel?: OrderChannel;
  };
};

export interface MembershipReportParams extends BaseParams {
  params: BaseParams["params"];
}

export interface EventReportParams extends BaseParams {
  params: BaseParams["params"] & {
    releaseId?: string;
    release?: string;
  };
}
export interface SeasonReportParams extends BaseParams {
  params: BaseParams["params"] & {
    seasonId?: string;
  };
}

export interface OverallReportParams extends BaseParams {
  params: BaseParams["params"];
}

export interface PackageReportParams extends BaseParams {
  params: BaseParams["params"];
}

export type BaseReportParams =
  | EventReportParams
  | MembershipReportParams
  | SeasonReportParams
  | OverallReportParams
  | PackageReportParams;

export const narrowPointReportingFilterSource = (
  source: PointReportingFilterSource
): ReportingFilterSource => {
  switch (source) {
    case PointReportingFilterSource.Event:
      return ReportingFilterSource.Event;
    case PointReportingFilterSource.Membership:
      return ReportingFilterSource.Membership;
    case PointReportingFilterSource.Overall:
      return ReportingFilterSource.Overall;
    case PointReportingFilterSource.Package:
      throw new Error("Package source not supported");
    default:
      return ReportingFilterSource.Event;
  }
};
