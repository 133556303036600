import React from "react";

import { Flex, Text } from "flicket-ui";
import styled from "styled-components";

import { Icon } from "~components";
import { superAdminUrl } from "~config";
import { useSuperAdmin } from "~hooks";

const Wrapper = styled(Flex).attrs({
  bg: "N600",
  color: "white",
  py: 1,
  px: "6/4",
  zIndex: "overlay",
  position: "sticky",
  top: 0,
  maxHeight: "40px",
})`
  box-shadow: 0px 9px 39px rgba(0, 0, 0, 0.13),
    0px 2.01027px 8.71116px rgba(0, 0, 0, 0.0774939),
    0px 0.598509px 2.59354px rgba(0, 0, 0, 0.0525061);
`;

export const SuperAdminTopBar = () => {
  const { isSuperAdmin, isSuperAdminDomain, organization } = useSuperAdmin();

  return isSuperAdmin && !isSuperAdminDomain ? (
    <Wrapper>
      <Flex as="a" href={`${superAdminUrl}/accounts`} fontWeight="heavy">
        <Icon icon="chevron-left" fontSize={5} />
        Back to overview
      </Flex>

      <Text flex={1} textAlign="center">
        You are now managing the {organization?.name} account
      </Text>
    </Wrapper>
  ) : null;
};
