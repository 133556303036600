import { useEffect, useContext } from "react";
import { FormState } from "react-hook-form";
import { FormStateContext } from "~context";

export const useFormState = <T = unknown>(formState: Partial<FormState<T>>) => {
  const { isDirty, setIsDirty, isSubmitting, setIsSubmitting } = useContext(
    FormStateContext
  );

  useEffect(() => {
    if (!!formState.isDirty !== !!isDirty) {
      setIsDirty(formState.isDirty);
    }
  }, [formState.isDirty]);

  useEffect(() => {
    if (!!formState.isSubmitting !== !!isSubmitting) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState.isSubmitting]);

  return { isDirty, isSubmitting };
};
