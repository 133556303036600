import React, { createContext, FC, useState } from "react";
import { createPortal } from "react-dom";
import { Alert } from "~components";

type ManagerAlert = Omit<Alert, "isOpen" | "close">;

const AlertManagerContext = createContext<{
  currentAlert: Alert | null;
  showAlert: (config: ManagerAlert) => void;
}>(null);

const AlertManagerProvider: FC = ({ children }) => {
  const [alert, setAlert] = useState<Alert | null>();

  return (
    <AlertManagerContext.Provider
      value={{
        currentAlert: alert,
        showAlert: (config) =>
          setAlert({ isOpen: true, close: () => setAlert(null), ...config }),
      }}
    >
      {alert &&
        createPortal(<Alert {...alert} />, document.querySelector("body"))}
      {children}
    </AlertManagerContext.Provider>
  );
};

export { AlertManagerProvider, AlertManagerContext };
