import { InputHTMLAttributes, useRef, useState } from "react";
import { Box, Input, InputWrapper, Stack, SystemProps } from "flicket-ui";
import { SketchPicker } from "react-color";
import { useOnClickOutside } from "~hooks";
import { useFloating, flip, shift, autoUpdate } from "@floating-ui/react-dom";
export interface ColorInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "color" | "onChange"> {
  error?: string;
  onChange: (color: string) => void;
  hideHexDisplay?: boolean;
  colorPickerContainerProps?: SystemProps;
}

export const ColorInput = ({
  value,
  error,
  onChange,
  hideHexDisplay,
  colorPickerContainerProps,
  ...props
}: ColorInputProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { refs, floatingStyles } = useFloating({
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
    middleware: [
      flip(),
      shift({ padding: 5, crossAxis: true, mainAxis: true }),
    ],
  });
  useOnClickOutside(ref, () => setOpen(false));

  return (
    <InputWrapper error={error}>
      <Stack gap={2}>
        <Box ref={refs.setReference}>
          <Box
            cursor="pointer"
            borderRadius="md"
            height={48}
            width={48}
            onClick={() => setOpen(!open)}
            backgroundColor={value as any}
            css={`
              border: 1px solid ${(p) => p.theme.colors.N300};
            `}
          />
          {open && (
            <Box
              ref={(r: HTMLDivElement) => {
                refs.setFloating(r);
                ref.current = r;
              }}
              style={floatingStyles}
              zIndex={20001}
              {...colorPickerContainerProps}
            >
              <SketchPicker
                color={value as string}
                onChange={(e) => onChange(e.hex)}
                onChangeComplete={(e) => onChange(e.hex)}
              />
            </Box>
          )}
        </Box>
        <Input
          value={value}
          hidden={hideHexDisplay}
          onChange={(e) => {
            onChange(e.currentTarget.value);
          }}
          {...props}
        />
      </Stack>
    </InputWrapper>
  );
};
