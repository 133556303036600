import { useEffect, useRef, useState } from "react";
import { Editor, Element, NodeEntry, Range } from "slate";
import { useSelected, useSlate } from "slate-react";
import {
  ButtonElement,
  LinkElement,
  SummaryElement,
} from "../interface/slate.interface";

type PopoverElements = ButtonElement | LinkElement | SummaryElement;

export const usePopover = (
  nodeType: "button" | "link" | "summary" | "merchandise" | "image",
  disabled = false
) => {
  const editor = useSlate();
  const { selection } = editor;
  const selected = useSelected();

  const [selectedNode, setSelectedNode] = useState<NodeEntry<PopoverElements>>(
    undefined
  );

  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (disabled) return;

    if (selection && Range.isCollapsed(selection)) {
      const [selectedNode] = Editor.nodes<PopoverElements>(editor, {
        at: editor.selection,
        match: (n) => Element.isElement(n) && n.type === nodeType,
      });
      if (selectedNode && selected) {
        setSelectedNode(selectedNode);
      } else {
        setSelectedNode(undefined);
      }
    } else {
      setSelectedNode(undefined);
    }
  }, [editor.selection, !!selectedNode, selected]);

  return {
    popoverRef,
    selectedNode: selectedNode,
    isPopoverOpen: !!selectedNode,
  };
};
