import { useQuery } from "~hooks/useQuery";
import { HighestValueCustomersDocument } from "~graphql/typed-document-nodes";
import { ReportingCard } from "~features/reports/reporting/components/dashboards/ReportingCard";
import { formatPrice, Text } from "flicket-ui";
import React from "react";
import { List } from "~components/common/List/List";
import {
  BaseReportParams,
  narrowPointReportingFilterSource,
} from "~features/reports/reporting/types";

export const AudienceHighestValueCustomers = ({ params }: BaseReportParams) => {
  const { data, error, isLoading } = useQuery(HighestValueCustomersDocument, {
    ...params,
    source: narrowPointReportingFilterSource(params.source),
  });

  if (isLoading || error || data?.highestValueCustomers.length === 0) {
    return <></>;
  }

  return (
    <ReportingCard title={"Highest value customers"}>
      <List mb={2}>
        {data.highestValueCustomers.map((item) => (
          <List.ColumnItems key={item.userId} noTopBorder>
            <List.Column>
              <Text variant="regular">{item.name}</Text>
            </List.Column>
            <List.Column justifyContent={"flex-end"}>
              <Text variant="regular">{formatPrice(+item.value)}</Text>
            </List.Column>
          </List.ColumnItems>
        ))}
      </List>
    </ReportingCard>
  );
};
