import { Text } from "flicket-ui";
import { useMemo } from "react";
import { useOrganization } from "~hooks";
import { CommonDisplay } from "./common";
import { InternalState } from "../interfaces";

export function OverallDisplay(props: InternalState) {
  const {
    small,
    selectedSource,
    searchableEvents,
    searchableMemberships,
  } = props;
  const { organization } = useOrganization();

  if (selectedSource !== "overall") return null;

  const image = organization.branding?.logo ?? undefined;
  const title = organization.name;

  const subtitle = useMemo(() => {
    const subtitle = generateSubtitleText(props);
    return subtitle;
  }, [searchableEvents.length, searchableMemberships.length]);

  return (
    <CommonDisplay
      small={small}
      image={image}
      title={title}
      sourceLabel={"Overall"}
      subtitle={<Text variant="small">{subtitle}</Text>}
    />
  );
}

function generateSubtitleText(props: InternalState) {
  const eventCount = props.searchableEvents.length;
  const membershipCount = props.searchableMemberships.length;

  const countItems = [
    { name: "event", count: eventCount },
    { name: "membership", count: membershipCount },
  ].filter((item) => item.count > 0);

  if (countItems.length === 0) return "";

  if (countItems.length === 1) {
    const item = countItems[0];
    return `Includes ${item.count} ${item.name}${item.count > 1 ? "s" : ""}.`;
  }

  return `Includes ${countItems
    .map((item) => `${item.count} ${item.name}${item.count > 1 ? "s" : ""}`)
    .join(" and ")}.`;
}
