import { PrimaryButton, SecondaryButton, Stack } from "flicket-ui";
import { useState } from "react";
import { Select } from "~components";
import CustomModal from "~components/common/CustomModal";
import { useCustomModal } from "~components/common/CustomModal/useCustomModal";
import { OPTION_ALL } from "~features/reports/constants";
import { useChannelOptions } from "~features/reports/reporting/hooks/useChannelOptions";
import useFilterContext, {
  FilterActionTypes,
} from "~features/reports/reporting/hooks/useFiltersContext";
import { useReleaseFilterState } from "~features/reports/reporting/hooks/useReleaseOptions";

export const FilterModal = () => {
  const { releaseOptions, isLoading, releaseIdState } = useReleaseFilterState();
  const { channelOptions, channelState } = useChannelOptions();
  const { dispatch } = useFilterContext();

  const [releaseId, setReleaseId] = useState<string | null>(
    releaseIdState ?? OPTION_ALL
  );
  const [channel, setChannel] = useState<string | null>(
    channelState ?? OPTION_ALL
  );

  const { close } = useCustomModal();

  const onApply = () => {
    dispatch({
      type: FilterActionTypes.SET_FILTERS,
      filters: {
        releaseId,
        channel,
      },
    });
    close();
  };

  return (
    <>
      <CustomModal.Header>Filter</CustomModal.Header>
      <CustomModal.Content overflowY="visible">
        <Stack direction={"vertical"} gap={2}>
          <Select
            menuPortalTarget={document.body}
            options={releaseOptions}
            isLoading={isLoading}
            mb={1}
            value={releaseId}
            onChange={(value: string) => setReleaseId(value)}
            label="Releases"
          />
          <Select
            menuPortalTarget={document.body}
            options={channelOptions}
            mb={1}
            value={channel}
            onChange={(value: string) => setChannel(value)}
            label="Channels"
          />
        </Stack>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Stack
          direction="horizontal"
          justifyItems={"flex-end"}
          alignItems="center"
          gap={2}
        >
          <SecondaryButton onClick={close}>Cancel</SecondaryButton>
          <PrimaryButton onClick={onApply}>Apply</PrimaryButton>
        </Stack>
      </CustomModal.Footer>
    </>
  );
};
