import useSWR from "swr";
import { useSDK } from "~hooks";

export const useMembership = (id: string) => {
  const sdk = useSDK();

  const { data: membership, error, ...rest } = useSWR(
    id ? ["membership", id] : null,
    async (_, id) => sdk.membership({ id }).then((res) => res.membership)
  );
  return {
    ...rest,
    error,
    membership,
  };
};
