import styled from "styled-components";
import { Box, SecondaryButton } from "flicket-ui";

export const SecondaryButtonGray = styled(SecondaryButton)`
  border: 1px solid #cccccc;
  color: ${({ theme }) => theme.colors.N800};

  :hover:enabled {
    background: ${({ theme }) => theme.colors.N100};
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.xs}) {
    padding: 10px;
  }
`;

export const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 4.5fr 7fr 2.5fr;
  grid-template-rows: 2fr;
  grid-gap: 10px;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.lg}) {
    grid-template-columns: 2fr 7fr 2.5fr;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm}) {
    grid-template-columns: 0fr 7fr 2.5fr;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.xs}) {
    grid-template-columns: 100%;
  }
`;

export const SubGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 2.5fr);
  grid-template-rows: 2fr;
  grid-gap: 10px;
  justify-content: space-between;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.xs}) {
    grid-gap: 4px;
  }
`;
