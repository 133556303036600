import { Box, PrimaryButton, Stack, Text } from "flicket-ui";
import CustomModal from "~components/common/CustomModal";
import { LocationFilterTypes } from "~graphql/sdk";
import { DefaultDatesForPostEventReportDocument } from "~graphql/typed-document-nodes";
import { useOrganization } from "~hooks";
import { useQuery } from "~hooks/useQuery";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { ObjectSchemaDefinition } from "~features/generalAdmissionEvent/form/schema.types";
import { useFormWithSubmitState } from "~lib/helpers/form/useFormWithSubmitState";
import { Controller, FormProvider } from "react-hook-form";
import { getPrimaryFilter, getSecondaryFilter } from "~lib/i18n";
import { ModalLoadingState } from "~features/reports/reporting/components/dashboards/reports/exportModals/utils";
import { DEFAULT_DATES } from "~features/reports/constants";
import { DateRangeSelect } from "~features/reports/components/ReportOptionSelects";
import { SelectWithSelectState } from "~features/reports/reporting/components/SelectWithSelectedState";
import { SecondaryButtonGray } from "~features/broadcast/styled/MarketSMSForm.styled";
import { useCustomModal } from "~components/common/CustomModal/useCustomModal";

type LocationLevelItem = {
  label: string;
  value: LocationFilterTypes;
};

export type FormValues = {
  dates?: string[];
  locationLevel?: LocationFilterTypes;
};

const buildSchema = (
  locationFilters: LocationLevelItem[],
  defaultDates?: [string, string]
) => {
  const schema = yup.object<ObjectSchemaDefinition<FormValues>>().shape({
    dates: yup
      .array()
      .default(
        defaultDates
          ? [new Date(defaultDates[0]), new Date(defaultDates[1])]
          : [DEFAULT_DATES.startDate, DEFAULT_DATES.endDate]
      ),
    locationLevel: yup
      .mixed()
      .oneOf(locationFilters.map((f) => f.value))
      .default(locationFilters[0].value),
  });

  return { schema };
};

export function PostEventReportFilterForm(
  props: PostEventReportFilterFormProps
) {
  const { eventId } = props;

  const { data: defaultDatesData, isLoading } = useQuery(
    DefaultDatesForPostEventReportDocument,
    {
      eventId,
    }
  );

  const defaultDates = defaultDatesData?.defaultDatesForPostEventReport;

  return (
    <>
      <CustomModal.Header>
        <Text variant="header.M">Download event summary report</Text>
      </CustomModal.Header>
      {isLoading ? (
        <CustomModal.Content>
          <ModalLoadingState />
        </CustomModal.Content>
      ) : (
        <FormContent
          {...props}
          defaultDates={
            defaultDates?.startDate &&
            defaultDates?.endDate && [
              defaultDates.startDate,
              defaultDates.endDate,
            ]
          }
        />
      )}
    </>
  );
}

interface PostEventReportFilterFormProps {
  eventId: string;
  _onSubmit: (values: FormValues) => Promise<void>;
}

export const FormContent = ({
  _onSubmit,
  defaultDates,
}: PostEventReportFilterFormProps & {
  defaultDates?: [string, string];
}) => {
  const { organization } = useOrganization();
  const { close } = useCustomModal();
  const locationFilters: {
    label: string;
    value: LocationFilterTypes;
  }[] = [
    getPrimaryFilter(organization?.address?.country),
    getSecondaryFilter(organization?.address?.country),
    { label: "Country", value: LocationFilterTypes.Country },
  ].filter(Boolean);

  const { schema } = buildSchema(locationFilters, defaultDates);

  const methods = useFormWithSubmitState({
    onSubmit: _onSubmit,
    onError: console.error,
    resolver: yupResolver(schema),
    defaultValues: schema.getDefault(),
    shouldUnregister: true,
  });

  return (
    <>
      <CustomModal.Content overflowY="visible">
        <Box
          id="post-event-modal"
          as="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={methods.handleFormSubmit}
        >
          <FormProvider {...methods}>
            <Stack direction={"vertical"} gap={3}>
              <Controller
                control={methods.control}
                name="dates"
                render={({
                  name,
                  onChange,
                  value,
                }: {
                  onChange: (value: [Date, Date]) => void;
                  value: [string, string];
                  name: string;
                }) => (
                  <DateRangeSelect
                    label="Date"
                    name={name}
                    value={
                      value?.[0] && value?.[1]
                        ? {
                            startDate: new Date(value[0]),
                            endDate: new Date(value[1]),
                          }
                        : undefined
                    }
                    onChange={({ startDate, endDate }) =>
                      onChange([startDate, endDate])
                    }
                  />
                )}
              />
              <Controller
                control={methods.control}
                name={"locationLevel"}
                as={SelectWithSelectState}
                options={locationFilters}
                label="Show location demographic data by"
                menuPortalTarget={document.body}
              />
            </Stack>
          </FormProvider>
        </Box>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Stack
          gap={2}
          direction="horizontal"
          justifyItems={"flex-end"}
          alignItems="center"
        >
          <SecondaryButtonGray onClick={close}>Cancel</SecondaryButtonGray>
          <PrimaryButton
            type="submit"
            form="post-event-modal"
            isLoading={methods.isSubmitting}
          >
            Download
          </PrimaryButton>
        </Stack>
      </CustomModal.Footer>
    </>
  );
};
