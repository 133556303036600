import { isSameDay } from "date-fns";
import { formatDate } from "./formatDate";
import { Event } from "~graphql/sdk";
import { EN_DASH } from "~lib/constants/enDash";

export const DAY_MONTH_FORMAT = `d MMM`;
export const DATE_FORMAT = `${DAY_MONTH_FORMAT} yyy`;
const DATE_FORMAT_FULL = `${DATE_FORMAT}, h:mma`;

// Can't seem to find a way to get date-fns to format AM/PM as lowercase so this works for now.
export function formatToString(
  date: Date | string,
  format: string = DATE_FORMAT_FULL
) {
  return formatDate(date, format).replace("PM", " pm").replace("AM", " am");
}

/**
 * Output examples:
 * 5 Sep 2023, 9:05 am – 4:34 pm
 * 3 Sep 2023, 1:45 pm – 5 Sep 2023, 4:34 pm
 */
export default function formatDateRange({
  start,
  end,
}: {
  start?: Date | string;
  end?: Date | string;
}) {
  if (!start && !end) {
    throw new Error("Must provide at least one date to `formatDateRange`");
  }

  if (!end) {
    return formatToString(start);
  }

  if (!start) {
    return formatToString(end);
  }

  if (isSameDay(new Date(start), new Date(end))) {
    return (
      formatToString(start) + ` ${EN_DASH} ` + formatToString(end, "h:mma")
    );
  }
  return formatToString(start) + ` ${EN_DASH} ` + formatToString(end);
}

type EventDates = Omit<Event["dates"][0], "id">[];

export function formatEventDateRange(dates: EventDates) {
  return formatDateRange({
    start: earliestStartDate(dates),
    end: greatestEndDate(dates),
  });
}

export function greatestEndDate(eventDates: EventDates) {
  return eventDates.reduce((latest, { endDate }) => {
    if (endDate > latest) {
      return endDate;
    }
    return latest;
  }, eventDates[0].endDate);
}

export function earliestStartDate(eventDates: EventDates) {
  return eventDates.reduce((earliest, { startDate }) => {
    if (startDate < earliest) {
      return startDate;
    }
    return earliest;
  }, eventDates[0].startDate);
}
