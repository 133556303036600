import { useQuery } from "~hooks/useQuery";
import useLayoutContext from "./useLayoutContext";
import { ReleasesListUseParamasDocument } from "~graphql/typed-document-nodes";
import useFilterContext from "./useFiltersContext";
import { PRIMARY_NAVIGATION_KEYS } from "../navigation/primary.config";
import { OPTION_ALL } from "~features/reports/constants";

export const useReleaseOptions = (
  eventId?: string,
  { representAllAsNull } = { representAllAsNull: false }
) => {
  const { data, isLoading } = useQuery(
    eventId && ReleasesListUseParamasDocument,
    {
      event: eventId,
    }
  );

  const releases = data?.releases || [];

  const releaseGroupedOptions = [
    {
      label: "All releases",
      value: representAllAsNull ? null : OPTION_ALL,
    },
    {
      label: "",
      options: releases.map((release) => ({
        label: release.name,
        value: release.id,
      })),
    },
  ];

  const releaseOptions = [
    {
      label: "All releases",
      value: representAllAsNull ? null : OPTION_ALL,
    },
    ...releases.map((release) => ({
      label: release?.name,
      value: release?.id,
    })),
  ];

  return {
    releaseOptions,
    isLoading,
    releaseGroupedOptions,
  };
};

export const useReleaseFilterState = () => {
  const context = useLayoutContext();
  const eventId =
    context.type === PRIMARY_NAVIGATION_KEYS.EVENTS &&
    context.eventInformation?.id;
  const { getFilterValue } = useFilterContext();

  const {
    releaseGroupedOptions,
    isLoading,
    releaseOptions,
  } = useReleaseOptions(eventId);

  const releaseIdState = getFilterValue("releaseId");

  const currentReleaseName = (() => {
    if (releaseIdState) {
      if (releaseIdState === null) {
        return "All releases";
      } else {
        return (
          releaseOptions.find(
            (releaseOption) => releaseOption.value === releaseIdState
          )?.label ?? ""
        );
      }
    }
    return "";
  })();

  return {
    releaseGroupedOptions,
    isLoading,
    currentReleaseName,
    releaseIdState,
    releaseOptions,
  };
};
