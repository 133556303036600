import React, { createContext, ReactNode } from 'react';

import { CurrentOrganizationOutput } from '~graphql/sdk';
interface BaseContextProps {
  organization: CurrentOrganizationOutput;
  setOrganization: (org: any) => void;
}
interface Props {
  children: ReactNode;
  organization: CurrentOrganizationOutput;
  setOrganization: (org: any) => void;
}

const BaseContext = createContext<BaseContextProps | undefined>(undefined);

const BaseProvider = ({ children, organization, setOrganization }: Props) => (
  <BaseContext.Provider value={{ organization, setOrganization }}>
    {children}
  </BaseContext.Provider>
);

export { BaseProvider, BaseContext };
