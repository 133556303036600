import { useRouter } from "next/router";
import { useOrganization } from "~hooks/useOrganization";
import { OrganizationFeatures } from "~lib/features";
import { GAQueryParams } from "../types";

// V2 event setup is conditional on the query param and the feature flag.
// If a previously created event is being edited, it should be treated as a v2 event.
export function useIsV2Event(createdFromEvent: boolean) {
  const { hasFeature } = useOrganization();
  const router = useRouter();
  const query = router.query as GAQueryParams;
  return (
    createdFromEvent ||
    (query.type === "v2" && !hasFeature(OrganizationFeatures.LegacyGASetup))
  );
}
