import { useQuery } from "~hooks/useQuery";
import {
  GoogleAnalyticsReportDocument,
  GoogleAnalyticsReportingFilterInput,
} from "~graphql/typed-document-nodes";
import React from "react";
import { ReportingCard } from "~features/reports/reporting/components/dashboards/ReportingCard";
import { COLORS } from "~features/reports/reporting/components/dashboards/audience/audience.index";
import { AudienceGoogleButton } from "~features/reports/reporting/components/dashboards/audience/audience.google";
import { SystemProps } from "flicket-ui";
import { pick } from "@styled-system/props";
import { DeviceBreakdown } from "~components/reports/insights/DeviceBreakdown";

export const AudienceDevice = ({
  params,
  ...props
}: SystemProps & {
  params: GoogleAnalyticsReportingFilterInput;
}) => {
  const { data, error, isLoading } = useQuery(GoogleAnalyticsReportDocument, {
    input: params,
  });

  const hasData = data?.googleAnalyticsReport.length > 0;

  if (isLoading || error || !hasData) {
    return <></>;
  }
  return (
    <ReportingCard
      title="Device"
      TopRightElement={<AudienceGoogleButton />}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      {...pick(props)}
    >
      <DeviceBreakdown data={data.googleAnalyticsReport} colors={COLORS} />
    </ReportingCard>
  );
};
