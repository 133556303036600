import React, { FC, ReactNode } from 'react';
import { Box, SystemProps, Img, Flex, Container } from 'flicket-ui';
import styled from 'styled-components';

const StyledLayout = styled(Box)`
  position: relative;

  background-color: ${(p) => p.theme.colors.N100};
`;

const Content = styled(Box)`
  position: relative;

  padding-top: 80px;

  z-index: ${(p) => p.theme.zIndices.docked};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    padding-top: 0;
  }
`;

interface LayoutProps extends SystemProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children, ...props }) => {
  return (
    <StyledLayout {...props}>
      <Content>
        <Flex variant="center">
          <Img
            src="/static/flicket.png"
            maxW="96px"
            my={{ default: 5, md: 0 }}
          />
        </Flex>

        <Container py={5} px={3} width={{ default: '100%', sm: 'fit-content' }}>
          <Flex
            flexDir="column"
            variant="center"
            py={{ default: 5, md: 0 }}
            px={{ default: 3, md: 0 }}
          >
            {children}
          </Flex>
        </Container>
      </Content>
    </StyledLayout>
  );
};

export default Layout;
