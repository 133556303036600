import React, { FC } from "react";
import { Flex, Box, SystemProps } from "flicket-ui";
import styled from "styled-components";

import { Logo } from "../Logo";

export const Wrapper = styled(Flex)`
  .logo {
    animation: pulse 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
`;

export type LoaderProps = SystemProps;

export const Loader: FC<LoaderProps> = ({ ...props }) => {
  return (
    <Wrapper
      flex="1"
      w="100%"
      variant="center"
      flexDirection="column"
      position="absolute"
      overflow="hidden"
      bg="N100"
      minH="100vh"
      {...props}
    >
      <Box zIndex={1} mb={"-27px" as any} height={"18px" as any}>
        <Logo className="logo" />
      </Box>
    </Wrapper>
  );
};
