const apiUrl = process.env.API_URL || "http://localhost:3001/api";

const nonProductionDomainWhitelist = [
  "api.staging.flicket.",
  "api.flicket-preview.",
  "localhost",
];

export const domainAllowsSessionFeatureFlags = nonProductionDomainWhitelist.some(
  (domain) => apiUrl.includes(domain)
);

export default apiUrl;
