import { yupResolver } from "@hookform/resolvers";
import {
  Box,
  Flex,
  PrimaryButton,
  Stack,
  SystemProps,
  Text,
  UnderlineButton,
} from "flicket-ui";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { ColorInput, Divider } from "~components";
import { useOrganization } from "~hooks";
import { useScreenSize } from "~hooks/useScreenSize";
import { getOrgDefaultStyle } from "../hooks/useRichTextStyle";
import {
  richTextStyleOptions,
  RichTextStyles,
  richTextStylesSchema,
  StylesChangeFn,
} from "../richTextStyleTypes";
import CustomModal from "~components/common/CustomModal";
import { FormEvent } from "react";
import { useFormWithSubmitState } from "~lib/helpers/form/useFormWithSubmitState";

interface RichTextStylesFormProps {
  onClose?: () => void;
  richTextStyle: RichTextStyles;
  onChange: StylesChangeFn;
}

const FORM_NAME = "email-styles-form";

const ColourPickerInputRow = ({
  name,
  label,
}: {
  name: string;
  label: string;
}) => {
  const { control, errors } = useFormContext();
  const isMobile = useScreenSize().isPhonePortrait;

  // Fix colour picker in mobile view to not be obscured
  // or obscure colour select boxes.
  const mobilePickerProps: SystemProps = {
    position: "fixed",
    top: "20vh",
    left: "0",
  };

  return (
    <Stack alignItems={"center"} gap={2}>
      <Text variant="regular" flex={5}>
        {label}
      </Text>
      <Controller
        control={control}
        name={name}
        render={(props) => (
          <Box>
            <ColorInput
              error={errors?.[name]?.message}
              {...props}
              hideHexDisplay={isMobile}
              colorPickerContainerProps={isMobile ? mobilePickerProps : {}}
            />
          </Box>
        )}
      />
    </Stack>
  );
};

function RichTextStylesForm(props: RichTextStylesFormProps) {
  const { onClose, onChange, richTextStyle } = props;
  const { organization } = useOrganization();

  const onSubmit = (richTextValues: RichTextStyles) => {
    onChange(richTextValues);
    onClose?.();
  };

  const methods = useFormWithSubmitState({
    onSubmit,
    onError: console.error,
    defaultValues: {
      ...richTextStyleOptions,
      ...richTextStyle,
    },
    resolver: yupResolver(richTextStylesSchema),
  });

  const { reset, handleFormSubmit } = methods;

  return (
    <>
      <CustomModal.Content>
        <FormProvider {...methods}>
          <Box
            as="form"
            id={FORM_NAME}
            onSubmit={(e: FormEvent) => {
              e.stopPropagation();
              void handleFormSubmit(e);
            }}
          >
            <Stack direction={"vertical"} gap={2}>
              {Object.entries(richTextStyleOptions).map(
                ([key, label], index) => (
                  <>
                    <ColourPickerInputRow
                      key={`emailStyle-${index}`}
                      name={key}
                      label={label}
                    />
                    {index % 2 === 1 &&
                      index !==
                        Object.entries(richTextStyleOptions).length - 1 && (
                        <Divider marginY={2} />
                      )}
                  </>
                )
              )}
            </Stack>
          </Box>
        </FormProvider>
      </CustomModal.Content>

      <CustomModal.Footer>
        <Flex
          alignItems="center"
          justifyContent={"space-between"}
          width={1}
          position={"relative"}
        >
          <UnderlineButton
            onClick={() =>
              reset(getOrgDefaultStyle(organization?.branding?.primaryColor))
            }
            color="N800"
          >
            <Text variant="regular">Reset</Text>
          </UnderlineButton>
          <PrimaryButton type="submit" form={FORM_NAME} width={"auto"}>
            Apply
          </PrimaryButton>
        </Flex>
      </CustomModal.Footer>
    </>
  );
}

export default RichTextStylesForm;
