import { Stack, Text } from "flicket-ui";
import { GraphQLError } from "graphql";
import { Collapsible, Status } from "~components";
import { List } from "~components/common/List/List";
import ReportingCategory from "~components/common/ReportingCategory";
import ReportingItem from "~components/common/ReportingItem";
import { ScanVisitorsQuery } from "~graphql/sdk";

type ScanBreakdownProps = {
  isLoading: boolean;
  error: GraphQLError;
  scanVisitors: ScanVisitorsQuery["scanVisitors"];
  collapseOpen?: boolean;
};

export const ScanBreakdown = ({
  scanVisitors,
  isLoading,
  error,
  collapseOpen = false,
}: ScanBreakdownProps) => {
  return (
    <Status loading={isLoading} error={error}>
      {scanVisitors?.length > 0 ? (
        <Stack direction={"vertical"}>
          {scanVisitors.length === 1 ? (
            <>
              <ReportingCategory {...scanVisitors[0].category} mb={1} ml={1} />
              <List>
                {scanVisitors[0].items.map((item, itemIndex) => (
                  <List.Item key={`reporting-item-${itemIndex}`} pl={2}>
                    <ReportingItem icon={"gate"} {...item} />
                  </List.Item>
                ))}
              </List>
            </>
          ) : (
            scanVisitors.map(({ category, items }, index) => (
              <Collapsible
                active={collapseOpen}
                key={`reporting-category-${index}`}
                title={<ReportingCategory {...category} />}
              >
                <List>
                  {items.map((item, itemIndex) => (
                    <List.Item key={`reporting-item-${itemIndex}`} pl={4}>
                      <ReportingItem icon={"gate"} {...item} />
                    </List.Item>
                  ))}
                </List>
              </Collapsible>
            ))
          )}
        </Stack>
      ) : (
        <Text>Nothing scanned yet.</Text>
      )}
    </Status>
  );
};
