import React from "react";

import { system, SystemProps } from "flicket-ui";
import styled from "styled-components";

const StyledImg = styled.img`
  height: 100%;

  ${system}
`;

export const Logo: React.FC<
  { className?: string; fileName?: string } & SystemProps
> = ({ fileName = "flicket", ...props }) => {
  return (
    <StyledImg alt="flicket" src={`/static/flicket-logo.svg`} {...props} />
  );
};
