import { FormEvent, useState } from "react";
import { SubmitHandler, UseFormOptions, useForm } from "react-hook-form";

export function useFormWithSubmitState<TFieldValues>({
  onSubmit,
  onError,
  ...formConfig
}: {
  onSubmit:
    | ((values: TFieldValues) => Promise<void>)
    | ((values: TFieldValues) => void);
  onError: () => void;
} & UseFormOptions<TFieldValues>) {
  const [isSubmittingToServer, setIsSubmitToServer] = useState(false);
  const form = useForm<TFieldValues>(formConfig);
  const isSubmitting = isSubmittingToServer || form.formState.isSubmitting;

  async function beginSubmitToServer(values: TFieldValues, event: FormEvent) {
    event.stopPropagation();
    setIsSubmitToServer(true);

    try {
      await onSubmit(values);
    } catch (error) {
      console.error(error);
      setIsSubmitToServer(false);
    }
  }

  return {
    ...form,
    isSubmitting,
    handleFormSubmit: form.handleSubmit(
      beginSubmitToServer as SubmitHandler<TFieldValues>,
      onError
    ),
  };
}
