import { useRouter } from "next/router";
import { omit } from "lodash";
import {
  SECONDARY_NAVIGATION_INDEX,
  SECONDARY_NAVIGATION_KEYS,
  secondaryNavigationConfig,
} from "./secondary.config";
import {
  PRIMARY_NAVIGATION_KEYS,
  primaryNavigationConfig,
} from "./primary.config";
import { removeUndefined } from "~lib/helpers/removeUndefined";
import { useMemo } from "react";

import {
  isEventSource,
  isMembershipSource,
  isSeasonSource,
  isPackageSource,
  SourceSelection,
} from "../components/SourceSwitcher/interfaces";

export default function useNavigation() {
  const router = useRouter();
  const primaryNavSlug = router.query?.slug?.[0];

  const _selectedSource = (router.query.sourceSelection
    ? JSON.parse(decodeURIComponent(router.query.sourceSelection as string))
    : "default") as SourceSelection;

  // Memoize the selected source to prevent extra re-renders if the source didn't actually change.
  const selectedSource = useMemo(() => _selectedSource, [
    typeof _selectedSource === "string"
      ? _selectedSource
      : "membershipId" in _selectedSource
      ? _selectedSource.membershipId
      : "eventId" in _selectedSource
      ? _selectedSource.eventId
      : "seasonId" in _selectedSource
      ? _selectedSource.seasonId
      : "packageId" in _selectedSource
      ? _selectedSource.packageId
      : "overall",
  ]);

  /* --------------------------- Primary Navigation --------------------------- */
  const primaryRoute = Object.values(primaryNavigationConfig).find(
    (route) => route.slugSegment === primaryNavSlug
  );

  /* -------------------------- Secondary Navigation -------------------------- */
  const secondaryNavSlug = router.query?.slug?.[SECONDARY_NAVIGATION_INDEX];
  const activeSecondaryTab = Object.values(secondaryNavigationConfig).find(
    (conf) => conf.slugSegment === secondaryNavSlug
  );

  const secondaryNavigateTo = (key: SECONDARY_NAVIGATION_KEYS) => {
    const slugSegment = secondaryNavigationConfig[key].slugSegment;

    void router
      .push({
        pathname: `/reporting/${primaryNavSlug}/${slugSegment}`,
        query: omit(router.query, "slug"),
      })
      .then(() => {
        document.getElementById("layout-content").scrollTo(0, 0);
      });
  };

  /* ---------------------------- Set Selected Source --------------------------- */
  const setSelectedSource = (sourceSelection?: SourceSelection) => {
    void router.replace(getSourceNavigationUrl(sourceSelection));
  };

  const getSourceNavigationUrl = (sourceSelection: SourceSelection) => {
    let primarySlugSegment: string;
    if (sourceSelection === "overall") {
      primarySlugSegment =
        primaryNavigationConfig[PRIMARY_NAVIGATION_KEYS.OVERALL].slugSegment;
    } else if (isEventSource(sourceSelection)) {
      primarySlugSegment =
        primaryNavigationConfig[PRIMARY_NAVIGATION_KEYS.EVENTS].slugSegment;
    } else if (isMembershipSource(sourceSelection)) {
      primarySlugSegment =
        primaryNavigationConfig[PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS]
          .slugSegment;
    } else if (isSeasonSource(sourceSelection)) {
      primarySlugSegment =
        primaryNavigationConfig[PRIMARY_NAVIGATION_KEYS.SEASONS].slugSegment;
    } else if (isPackageSource(sourceSelection)) {
      primarySlugSegment =
        primaryNavigationConfig[PRIMARY_NAVIGATION_KEYS.PACKAGES].slugSegment;
    } else {
      primarySlugSegment =
        primaryNavigationConfig[PRIMARY_NAVIGATION_KEYS.EVENTS].slugSegment;
    }

    const pathname = `/reporting/${primarySlugSegment}/${secondaryNavSlug}`;

    const query = removeUndefined({
      ...router.query,
      sourceSelection: sourceSelection
        ? encodeURIComponent(JSON.stringify(sourceSelection))
        : undefined,
      releaseId: undefined,
      channel: undefined,
    });

    return {
      pathname,
      query,
    };
  };

  /* ------------------------------ Return value ------------------------------ */
  // TODO: Ideally these could be intercepted before this point so that there is ALWAYS a primary and secondary route and we
  // don't need the optional checks.
  return {
    selectedSource,
    setSelectedSource,
    getSourceNavigationUrl,
    activePrimaryTab: primaryRoute?.tabKey,
    activeSecondaryTab: activeSecondaryTab?.tabKey,
    secondaryNavigateTo,
  };
}
