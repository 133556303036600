import React from "react";

import { Status, Divider } from "~components";
import { useScanReports } from "~features/reports/hooks/useScanReports";
import { Card } from "~components/reports";

import { ScansChart } from "./ScansChart";
import { ScansOverview } from "./ScansOverview";
import { ScanBreakdownCard } from "./ScanBreakdownCard";

type ScansProps = {
  sourceId: string;
};

export const Scans = ({ sourceId }: ScansProps) => {
  const { scanTicketTypes, scansOverTime, scanVisitors } = useScanReports({
    eventId: sourceId,
  });

  return (
    <>
      <ScansOverview
        data={scanTicketTypes.data?.scanTicketTypes}
        error={scanTicketTypes.error}
        eventId={sourceId}
      />

      <ScanBreakdownCard
        error={scanVisitors.error}
        isLoading={scanVisitors.isLoading}
        scanVisitors={scanVisitors.data?.scanVisitors}
      />

      <Card title="Scanned visitors over time" mb={4}>
        <Status loading={scansOverTime.isLoading} error={scansOverTime.error}>
          {scansOverTime.data?.scanVisitorsOverTime?.length > 0 ? (
            <ScansChart data={scansOverTime.data?.scanVisitorsOverTime} />
          ) : (
            "Nothing scanned yet."
          )}
        </Status>

        <Divider my={3} />
      </Card>
    </>
  );
};

export default Scans;
