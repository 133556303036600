import { ReactNode } from "react";
import { useReportDataMethods } from "~features/reports/hooks/useReportData";
import { addDays, isSameDay, sub } from "date-fns";
import { DatePicker, Select, SelectProps } from "~components";
import { SearchParamsProps } from "../types";
import { pick } from "@styled-system/props";
import {
  OrderChannel,
  PointReportingFilterSource,
  ReportingFilterSource,
} from "~graphql/sdk";
import { OPTION_ALL } from "../constants";
import { Box, Flex, SystemProps } from "flicket-ui";
import { CalendarBlank } from "@phosphor-icons/react";
import useFilterContext, {
  FilterActionTypes,
} from "../reporting/hooks/useFiltersContext";
import { DateRange } from "../reporting/types";
import { EN_DASH } from "~lib/constants/enDash";
import useLayoutContext from "../reporting/hooks/useLayoutContext";
import { i18n } from "~lib/i18n";
import { ZonedDate } from "@flicket/utils";
import { PRIMARY_NAVIGATION_KEYS } from "../reporting/navigation/primary.config";

interface RawParamsProps extends useReportDataMethods {
  children?: ReactNode;
  enableDeleteDates?: boolean;
  disabled?: boolean;
  screenVisibility?: {
    dates?: boolean;
    channel?: boolean;
    release?: boolean;
  };
  useMobileLayout?: boolean;
}

export const SourceSelect = ({
  sourceOptions,
  useMobileLayout,
  selectedSource,
  setParams,
  disabled,
  exclude,
  ...props
}: Pick<
  RawParamsProps,
  | "sourceOptions"
  | "useMobileLayout"
  | "selectedSource"
  | "setParams"
  | "disabled"
  | "exclude"
> &
  SelectProps) => {
  if (!exclude?.source && sourceOptions.length > 0) {
    return (
      <Select
        name="source"
        options={sourceOptions}
        flex={useMobileLayout ? 30 : 1}
        mb={2}
        minWidth={useMobileLayout ? "auto" : 160}
        value={selectedSource}
        onChange={(source: SearchParamsProps["source"]) => {
          setParams({
            source,
            seasonId: undefined,
            sourceId: undefined,
            releaseId: undefined,
            channel: undefined,
            startDate: undefined,
            endDate: undefined,
          });
        }}
        disabled={disabled}
        {...props}
      />
    );
  }

  return null;
};

export const SeasonIdSelect = ({
  isLoading,
  useMobileLayout,
  seasonOptions,
  setParams,
  disabled,
  selectedSeasonId,
  exclude,
  selectedSource,
  ...props
}: Pick<
  RawParamsProps,
  | "isLoading"
  | "useMobileLayout"
  | "seasonOptions"
  | "setParams"
  | "disabled"
  | "selectedSeasonId"
  | "exclude"
  | "selectedSource"
> &
  SelectProps) => {
  if (
    !exclude?.seasonId &&
    selectedSource !== ReportingFilterSource.Overall &&
    selectedSource !== PointReportingFilterSource.Package &&
    seasonOptions.length > 0
  ) {
    return (
      <Select
        name="seasonId"
        placeholder={isLoading ? "Loading..." : "Select a season"}
        options={seasonOptions}
        flex={useMobileLayout ? 70 : 1}
        minWidth={useMobileLayout ? "auto" : 160}
        maxWidth="660px !important"
        value={selectedSeasonId}
        onChange={(seasonId: string) => {
          setParams({
            seasonId,
            sourceId: undefined,
            releaseId: undefined,
            startDate: undefined,
            endDate: undefined,
          });
        }}
        disabled={disabled}
        {...props}
      />
    );
  }
  return null;
};

export const SourceIdSelect = ({
  isLoading,
  useMobileLayout,
  eventOptions,
  membershipOptions,
  setParams,
  disabled,
  selectedSourceId,
  exclude,
  selectedSource,
  ...props
}: Pick<
  RawParamsProps,
  | "isLoading"
  | "useMobileLayout"
  | "eventOptions"
  | "membershipOptions"
  | "setParams"
  | "disabled"
  | "selectedSourceId"
  | "exclude"
  | "selectedSource"
> &
  SelectProps) => {
  const options =
    selectedSource === ReportingFilterSource.Event
      ? eventOptions
      : membershipOptions;

  if (
    !exclude?.sourceId &&
    selectedSource !== ReportingFilterSource.Overall &&
    selectedSource !== PointReportingFilterSource.Package
  ) {
    return (
      <Select
        name="sourceId"
        placeholder={isLoading ? "Loading..." : "Select"}
        options={options}
        flex={useMobileLayout ? 70 : 1}
        minWidth={useMobileLayout ? "auto" : 160}
        maxWidth="660px !important"
        value={selectedSourceId}
        onChange={(sourceId: string) => {
          setParams({
            sourceId,
            releaseId: undefined,
            startDate: undefined,
            endDate: undefined,
          });
        }}
        disabled={disabled}
        {...props}
      />
    );
  }

  return null;
};

export const ReleaseSelect = ({
  screenVisibility = {},
  releaseOptions,
  selectedReleaseId,
  setParams,
  disabled,
  selectedSourceId,
  exclude,
  selectedSource,
  ...props
}: Pick<
  RawParamsProps,
  | "screenVisibility"
  | "releaseOptions"
  | "selectedReleaseId"
  | "setParams"
  | "disabled"
  | "selectedSourceId"
  | "exclude"
  | "selectedSource"
> &
  SelectProps) => {
  if (
    !exclude?.release &&
    selectedSource === ReportingFilterSource.Event &&
    selectedSourceId !== OPTION_ALL &&
    screenVisibility.release !== false
  ) {
    return (
      <Select
        disabled={disabled}
        name="release"
        placeholder="Select release"
        options={releaseOptions}
        minWidth={160}
        flex={1}
        value={selectedReleaseId}
        onChange={(releaseId: string) => {
          setParams({
            releaseId,
          });
        }}
        {...props}
      />
    );
  }

  return null;
};

export const ChannelSelect = ({
  screenVisibility = {},
  channelOptions,
  selectedChannel,
  setParams,
  disabled,
  exclude,
  ...props
}: Pick<
  RawParamsProps,
  | "screenVisibility"
  | "channelOptions"
  | "selectedChannel"
  | "setParams"
  | "disabled"
  | "exclude"
> &
  SelectProps) => {
  if (!exclude?.channel && screenVisibility.channel !== false) {
    return (
      <Select
        disabled={disabled}
        name="channel"
        placeholder="Select channel"
        minWidth={160}
        flex={1}
        options={channelOptions}
        value={selectedChannel}
        onChange={(channel: OrderChannel) => {
          setParams({
            channel,
          });
        }}
        {...props}
      />
    );
  }

  return null;
};

export const DateSelect = ({
  screenVisibility = {},
  setParams,
  disabled,
  exclude,
  params,
  clearDates,
  enableDeleteDates,
  boxProps,
  ...props
}: Pick<
  RawParamsProps,
  | "screenVisibility"
  | "setParams"
  | "disabled"
  | "exclude"
  | "params"
  | "clearDates"
  | "enableDeleteDates"
> &
  SystemProps & {
    boxProps?: SystemProps;
  }) => {
  if (!exclude?.dates && screenVisibility.dates !== false) {
    const hasDates = params.startDate && params.endDate;

    return (
      <Flex flex={1} className="date-picker" alignItems="center" {...props}>
        <Box
          minWidth={300}
          maxWidth={{ md: 300 }}
          width={{ _: "100%" }}
          {...boxProps}
        >
          <DatePicker
            disabled={disabled}
            name="dates"
            iconLeft={true}
            icon={<CalendarBlank />}
            value={hasDates ? [params.startDate, params.endDate] : undefined}
            timezone={i18n.timezone}
            locale={i18n.locale}
            placeholder="Select a date range"
            allowClear={enableDeleteDates}
            onChange={dateRangeOnChange((dateRange) => {
              if (!dateRange) {
                clearDates();
                return;
              }
              setParams(dateRange);
            })}
            options={{
              mode: "range",
              minDate: undefined,
            }}
          />
        </Box>
      </Flex>
    );
  }

  return null;
};

export function FilterConnectedDateRangeSelect(props: SystemProps) {
  const { dateRange, dispatch, isDefaultDateRange } = useFilterContext();
  const { isAllEvents, type } = useLayoutContext();
  const isOverall = type === PRIMARY_NAVIGATION_KEYS.OVERALL;

  const onChange = (dateRange: DateRange | undefined) => {
    if (dateRange === undefined) {
      dispatch({
        type: FilterActionTypes.UPDATE_DATE,
        dateRange: {
          startDate: undefined,
          endDate: undefined,
        },
      });
    } else {
      dispatch({
        type: FilterActionTypes.UPDATE_DATE,
        dateRange,
      });
    }
  };

  return (
    <DateRangeSelect
      {...props}
      value={dateRange}
      isOnsaleRange={isDefaultDateRange && !isAllEvents && !isOverall}
      onChange={onChange}
      showCaretDownIcon
    />
  );
}

interface DateRangeSelectProps extends SystemProps {
  value?: DateRange;
  onChange: (dates: DateRange | undefined) => void;
  isOnsaleRange?: boolean;
  label?: string;
  name?: string;
  showCaretDownIcon?: boolean;
}

function dateRangeOnChange(onChange: (dateRange: DateRange) => void) {
  return function (dates: string | string[]): void {
    if (Array.isArray(dates)) {
      const startDate = new Date(dates[0]);
      const endDate = new Date(dates[1]);

      /**
       * Why do we add one day to the end date?
       * The date picker choose the start of the day as the time so when you choose say the 1st - 2nd, that
       * range is 24 hours: the 1st at 00:00:00 until the second at 00:00:00.
       * What we want is for it to be inclusive so we must add 24 hours so that it is the "end" of the second.
       * We can't use "endOfDay" here because this is in the browsers timezone.
       * */
      onChange({
        startDate,
        endDate: sub(addDays(endDate, 1), { seconds: 1 }),
      });

      return;
    }

    if (dates === undefined) {
      onChange(undefined);
    }
  };
}

export function DateRangeSelect(props: DateRangeSelectProps) {
  const {
    value,
    onChange,
    isOnsaleRange,
    label,
    name,
    showCaretDownIcon,
  } = props;

  const hasDates = value?.startDate && value?.endDate;

  return (
    <DatePicker
      name={name ?? "dates"}
      label={label}
      iconLeft={true}
      icon={<CalendarBlank />}
      value={hasDates ? [value.startDate, value.endDate] : undefined}
      placeholder="Select a date range"
      onChange={dateRangeOnChange(onChange)}
      options={{
        mode: "range",
        minDate: undefined,
        formatDate: (date) => {
          if (
            isOnsaleRange &&
            isSameDay(
              date,
              ZonedDate.utils.toZonedTime(value.startDate, i18n.timezone)
            )
          ) {
            return `On sale ${EN_DASH} ${ZonedDate.format(date, "date", {
              timeZone: ZonedDate.utils.getLocalTimezone(),
              locale: i18n.locale,
            })}`;
          }
          return ZonedDate.format(date, "date", {
            timeZone: ZonedDate.utils.getLocalTimezone(),
            locale: i18n.locale,
          });
        },
      }}
      showCaretDownIcon={showCaretDownIcon}
      timezone={i18n.timezone}
      locale={i18n.locale}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      {...pick(props)}
    />
  );
}
