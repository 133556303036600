import React, { ChangeEvent, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers";
import {
  Box,
  Text,
  PrimaryButton,
  Flex,
  TertiaryButton,
  TransparentButton,
} from "flicket-ui";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { lowerCase } from "lodash";
import {
  FormSection,
  ToggleWrapper,
  ToggleLabel,
  Icon,
  Checkbox,
} from "~components";
import { NamingFieldsModalProps } from ".";
import {
  defaultNamingFields,
  useNamingFieldOptions,
} from "./hooks/useNamingFieldOptions";
import { NamingFieldOption } from "./types/NamingFieldOption";
import * as yup from "yup";
import { AddCustomFieldForm } from "./AddCustomFieldForm";
import { NamingFieldsTag } from "./NamingFieldsTag";
import CustomModal from "../CustomModal/CustomModal";

export const NamingFieldsModalContent = ({
  onSubmit,
  namingFields,
}: NamingFieldsModalProps) => {
  const {
    namingFieldOptions,
    addCustomField,
    removeCustomField,
  } = useNamingFieldOptions(namingFields);

  const schema = yup.object().shape({
    namingFields: yup
      .array()
      .of(
        yup.object().shape({
          field: yup.string().required(),
          isRequired: yup.boolean(),
        })
      )
      .test("requiredBy", "", function (value: NamingFieldOption[]) {
        const notRequiredFields = value
          ?.filter((field) => !field.isRequired)
          .map((f) => f.field);

        const errors: { label: string; requiredBy: string }[] = [];
        for (const field of notRequiredFields) {
          const option = Object.entries(namingFieldOptions)
            ?.filter(([, data]) =>
              value.map((f) => f.field).includes(data.field)
            )
            .find(([, nfo]) => nfo.requiresFields?.includes(field));
          if (!option) continue;

          errors.push({
            label: field,
            requiredBy: option[1].field,
          });
        }

        return errors.length > 0
          ? this.createError({
              message: errors
                .map(
                  (error) =>
                    `Field "${lowerCase(
                      error.label
                    )}" needs to be marked as required to use "${lowerCase(
                      error.requiredBy
                    )}"`
                )
                .join("\n"),
            })
          : true;
      }),
  });

  const {
    control,
    handleSubmit,
    register,
    formState,
    reset,
    errors,
  } = useForm<{ namingFields: NamingFieldOption[] }>({
    defaultValues: { namingFields },
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const { fields, append, remove } = useFieldArray<NamingFieldOption>({
    control,
    name: "namingFields",
  });

  useEffect(() => {
    reset({ namingFields });
  }, [namingFields]);

  const generateLabel = (label: string) => {
    const option = namingFieldOptions[label];
    if (!option) return label;

    // If the field the label relates to is required by another field (thas is present in fields),
    // add that label into array and dislay next to the label
    const requiredByOptions = Object.entries(namingFieldOptions)
      ?.filter(([, data]) => fields.map((f) => f.field).includes(data.field))
      ?.filter(([, data]) => data.requiresFields?.includes(option.field));

    if (requiredByOptions.length === 0) return label;

    return `${label} (required by ${requiredByOptions
      .map((o) => `'${lowerCase(o[1].field)}'`)
      .join(", ")})`;
  };

  const addField = (customFieldLabel: string) => {
    const customLabel = customFieldLabel;
    if (!customLabel) {
      return "Field cannot be empty";
    }

    const result = addCustomField(customLabel);
    if (result.newField) {
      append(result.newField);
    } else {
      return result.error;
    }
  };

  const removeField = (customFieldLabel: string) => {
    const fieldIndex = fields.findIndex((f) => f.field === customFieldLabel);
    remove(fieldIndex);

    removeCustomField(customFieldLabel);
  };

  return (
    <>
      <CustomModal.Header>Set attendee fields</CustomModal.Header>
      <CustomModal.Content>
        <Box
          as="form"
          id="naming-fields-form"
          //  eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit, console.error)}
          noValidate
        >
          <FormSection mb={2} error={(errors?.namingFields as any)?.message}>
            <Flex flexDirection="column" flex={1}>
              <Text color="N600" fontSize={3} fontWeight="extraBold" mb={1}>
                Predefined fields
              </Text>

              <Flex mb={4} flexWrap="wrap">
                {Object.entries(namingFieldOptions)
                  .filter((f) => !f[1].isCustom)
                  .map(([label, { field, requiresFields }]) => (
                    <ToggleWrapper key={field} mr="6/4" mb="6/4">
                      <input
                        key={field}
                        type="checkbox"
                        onChange={(e) =>
                          e.target.checked
                            ? append([
                                { field, isRequired: false },
                                ...(requiresFields
                                  ?.filter(
                                    (f) =>
                                      !fields.map((f) => f.field).includes(f)
                                  )
                                  .map(
                                    (f): NamingFieldOption => ({
                                      field: f,
                                      isRequired: true,
                                    })
                                  ) ?? []),
                              ])
                            : remove(fields.findIndex((f) => f.field === field))
                        }
                        checked={!!fields.find((f) => f.field === field)}
                      />
                      <ToggleLabel>{label}</ToggleLabel>
                    </ToggleWrapper>
                  ))}
              </Flex>

              <Box mb={4}>
                <Text color="N600" fontSize={3} fontWeight="extraBold">
                  Custom fields
                </Text>
                <Flex flexWrap="wrap" mb={2}>
                  {Object.entries(namingFieldOptions)
                    .filter((f) => f[1].isCustom)
                    .map(([label, { field }]) => (
                      <NamingFieldsTag bg="P200" key={field}>
                        <Text>{label}</Text>
                        <TransparentButton
                          type="button"
                          onClick={() => {
                            removeField(label);
                          }}
                          ml={1}
                        >
                          <Icon icon="remove" fontSize={2} />
                        </TransparentButton>
                      </NamingFieldsTag>
                    ))}
                </Flex>
                <AddCustomFieldForm onSubmit={addField} />
              </Box>

              {!!fields?.length && (
                <>
                  <Text color="N600" fontSize={3} fontWeight="extraBold">
                    Required fields
                  </Text>
                  <Text color="N600" fontSize={3} mb={3}>
                    Specify which fields are mandatory for the user to fill in
                  </Text>

                  {fields.map((field, i) => (
                    <div key={field.id}>
                      <input
                        name={`namingFields.${i}.field`}
                        value={field.field}
                        ref={register}
                        defaultValue={field.field}
                        hidden
                      />
                      <Controller
                        control={control}
                        name={`namingFields.${i}.isRequired`}
                        defaultValue={!!field.isRequired}
                        render={({ onChange, value, ...props }) => (
                          <Checkbox
                            {...props}
                            label={generateLabel(
                              Object.entries(namingFieldOptions).find(
                                ([, { field: opt }]) => opt === field.field
                              )?.[0]
                            )}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              onChange(e.target.checked)
                            }
                            mb={2}
                            fontWeight="extraBold"
                            checked={!!value}
                          />
                        )}
                      />
                    </div>
                  ))}
                </>
              )}
            </Flex>
          </FormSection>
        </Box>
      </CustomModal.Content>
      <CustomModal.Footer>
        <TertiaryButton
          mr={2}
          onClick={() => reset({ namingFields: defaultNamingFields })}
        >
          Reset fields
        </TertiaryButton>
        <PrimaryButton
          type="submit"
          form="naming-fields-form"
          px={5}
          isLoading={formState.isSubmitting}
        >
          Save changes
        </PrimaryButton>
      </CustomModal.Footer>
    </>
  );
};
