import { useRouter } from "next/router";
import { createContext, ReactNode, useContext } from "react";
import {
  EventDocument,
  MembershipDocument,
  PointItemDocument,
} from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";
import { getErrorMessage } from "~lib";

export type EmailDataContextType = "event" | "membership" | "point";

export interface IEmailDataContext {
  type: EmailDataContextType;
  id?: string;
  name?: string;
  isLoading?: boolean;
  errorMessage?: string;
  importantInfo?: string;
  packageName?: string;
  pointName?: string;
  ticketCoverEnabled?: boolean;
}

export const EmailDataContext = createContext<IEmailDataContext>(null);

export default function useEmailDataContext(): IEmailDataContext {
  const context = useContext(EmailDataContext);

  return context;
}

export const EmailDataContextProvider = ({
  children,
  type,
}: {
  children: ReactNode;
  type: EmailDataContextType;
}) => {
  const router = useRouter();
  const id = router.query.id as string;

  const queryMap = {
    event: {
      document: EventDocument,
      key: "event",
      fields: {
        name: "title",
        importantInfo: "importantNotice",
      },
    },
    membership: {
      document: MembershipDocument,
      key: "membership",
      fields: { name: "name", importantInfo: "importantNotice" },
    },
    point: {
      document: PointItemDocument,
      key: "pointItem",
      fields: { name: "name", importantInfo: null, point: "point" },
    },
  };

  const { document, key, fields } = queryMap[type] || {};

  const { data, isLoading, error } = useQuery(document, { id });

  const contextValue: IEmailDataContext = {
    type,
    id,
    name: data?.[key]?.[fields.name],
    isLoading,
    errorMessage: error ? getErrorMessage(error) : undefined,
    importantInfo: fields.importantInfo
      ? data?.[key]?.[fields.importantInfo]
      : undefined,
    ticketCoverEnabled: data?.[key]?.ticketCover?.isEnabled === true,
    packageName: data?.[key]?.name,
    pointName: data?.[key]?.point?.name,
  };

  return (
    <EmailDataContext.Provider value={contextValue}>
      {children}
    </EmailDataContext.Provider>
  );
};
