import { Role } from "~graphql/sdk";
import { isSuperAdminDomain } from "~lib/helpers";

import { useOrganization } from "./useOrganization";
import useUser from "./useUser";

export const useSuperAdmin = () => {
  const { user } = useUser();
  const { organization } = useOrganization();

  return {
    organization,
    isSuperAdmin: user?.roles?.includes(Role.Superadmin),
    isSuperAdminDomain: isSuperAdminDomain(),
  };
};
