import React, { FC } from "react";
import { getRandomColor } from "~lib";
import { GenderBreakDownOutput } from "~graphql/sdk";
import { PieChart } from "../charts";
import { REPORT_COLOURS } from "../common/reportColours";
import { capitalize } from "lodash";

export const GenderBreakdown: FC<{
  data: GenderBreakDownOutput;
  direction?: "horizontal" | "vertical";
}> = ({ data, direction }) => {
  const COLORS = [
    REPORT_COLOURS.teal,
    REPORT_COLOURS.tangerine,
    REPORT_COLOURS.pink,
  ];

  const chartData = Object.entries(data.reportData)
    .map(([label, value], index) => ({
      label: capitalize(label),
      value: value as number,
      color: COLORS[index] || getRandomColor(),
    }))
    .filter(({ value }) => value > 0);

  return <PieChart direction={direction} data={chartData} />;
};
