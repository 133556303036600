import { LocationFilterTypes } from "~graphql/typed-document-nodes";
import { CurrentOrganizationOutput, Organization } from "~graphql/sdk";

export const i18n = {
  timezone: "Pacific/Auckland",
  locale: "en-NZ",
};

export const getSecondaryFilter = (country: string) => {
  if (country === "NZ") {
    return { label: "Region", value: LocationFilterTypes.Region };
  } else if (country === "US" || country === "AU") {
    return { label: "State", value: LocationFilterTypes.State };
  }
  return null;
};

export const getPrimaryFilter = (country: string) => {
  if (country === "US") {
    return { label: "City", value: LocationFilterTypes.Locality };
  } else {
    return { label: "City", value: LocationFilterTypes.District };
  }
};

export const getLocationFiltersForCountry = (
  organization?: Organization | CurrentOrganizationOutput
) => {
  const locationFilters: {
    label: string;
    value: LocationFilterTypes;
  }[] = [
    getPrimaryFilter(organization?.address?.country),
    getSecondaryFilter(organization?.address?.country),
    { label: "Country", value: LocationFilterTypes.Country },
  ].filter(Boolean);
  return locationFilters;
};

export const canShowLocationFilters = (country: string): boolean => {
  return country === "NZ" || country === "US" || country === "AU";
};
