import { Price, Text } from "flicket-ui";
import { OrderQuery } from "~graphql/sdk";
import { ActivePromotion, SeatWithPromotion } from "~hooks/multibuy/types";

export const getMultibuyInfo = (
  multiBuy: SeatWithPromotion | ActivePromotion,
  item: OrderQuery["order"]["lineItems"]["edges"][number]["node"]
) => {
  const hasTypeGetting =
    multiBuy && "type" in multiBuy && multiBuy.type === "getting";
  const hasGiftedQty =
    multiBuy && "giftedQty" in multiBuy && multiBuy.giftedQty > 0;
  const hasAppliedMultibuy = hasTypeGetting || hasGiftedQty;
  const giftedQty = hasGiftedQty ? multiBuy.giftedQty : 1;
  const multibuyDiscountedPrice =
    hasAppliedMultibuy &&
    (item.originalPrice - multiBuy.promotion.price) * giftedQty;

  const MultibuyPromoText = hasAppliedMultibuy && (
    <Text ml="1/4">
      Multibuy promotion applied, you saved{" "}
      <Price price={multibuyDiscountedPrice} />
    </Text>
  );

  return {
    hasAppliedMultibuy,
    giftedQty,
    multibuyDiscountedPrice,
    MultibuyPromoText,
  };
};
