/* eslint-disable @typescript-eslint/no-unsafe-argument */
import Select, { components, GroupType, OptionProps } from "react-select";
import { useTheme } from "styled-components";
import {
  allMemberships,
  groupMemberships,
  isAllMemberships,
  MembershipOptionType,
} from "../../util";
import {
  CommonDisplay,
  defaultComponents,
  renderGroup,
  selectStyles,
} from "../common";
import { SearchItem } from "../../SearchItem";
import { Skeleton } from "~components";
import { Box, Text } from "flicket-ui";
import { DATE_FORMAT } from "~lib/helpers/dates/formatDateRange";
import { formatDate, notEmptyPredicate } from "~lib";
import {
  InternalState,
  isMembershipSource,
  isSpecificMembershipSource,
  SourceSelection,
} from "../../interfaces";
import { useQuery } from "~hooks/useQuery";
import { MembershipInformationDocument } from "~graphql/typed-document-nodes";

interface MembershipSelectProps {
  internalState: InternalState;
  onChange: (option: SourceSelection) => void;
}

export const Option = (props: OptionProps<MembershipOptionType, false>) => {
  return (
    <components.Option {...props}>
      {isAllMemberships(props.data) ? (
        <SearchItem active={props.isFocused}>All memberships</SearchItem>
      ) : (
        <SearchItem active={props.isFocused}>{props.data.name}</SearchItem>
      )}
    </components.Option>
  );
};

function Subtitle(props: InternalState) {
  const { selectedSource, searchIndexLoading, small } = props;

  const { data, isLoading } = useQuery(
    isSpecificMembershipSource(selectedSource)
      ? MembershipInformationDocument
      : null,
    isSpecificMembershipSource(selectedSource) && {
      membershipId: selectedSource.membershipId,
    }
  );

  if (isLoading || searchIndexLoading) {
    return <Skeleton height={15} maxWidth={"400px"} mt={"6/4"} />;
  }

  if (!isMembershipSource(selectedSource) || !data?.membershipInformation) {
    return null;
  }

  const date = [
    data.membershipInformation?.startDate,
    data.membershipInformation?.endDate,
  ]
    .filter(notEmptyPredicate)
    .map((d) => formatDate(d, DATE_FORMAT))
    .join(" - ");

  return (
    <Box mt={1}>
      {date && (
        <Text variant="small" as={small ? "span" : "div"}>
          {date}
        </Text>
      )}
      {data.membershipInformation?.location && (
        <Text variant="small" as="span">
          {small
            ? ` • ${data.membershipInformation.location}`
            : data.membershipInformation.location}
        </Text>
      )}
    </Box>
  );
}

export function MembershipDisplay(props: InternalState) {
  const { small, selectedSource, searchableMemberships } = props;

  const { data, isLoading } = useQuery(
    isSpecificMembershipSource(selectedSource)
      ? MembershipInformationDocument
      : null,
    isSpecificMembershipSource(selectedSource) && {
      membershipId: selectedSource.membershipId,
    }
  );

  if (!isMembershipSource(selectedSource)) {
    return null;
  }

  let image = undefined;
  let title;

  if (selectedSource === "all-memberships") {
    image = null;
    title = allMemberships.label;
  } else {
    const foundValue = searchableMemberships?.find(
      (e) => e.id === selectedSource.membershipId
    );

    title = data?.membershipInformation?.name ?? foundValue?.name;
    image = data?.membershipInformation?.banner ?? undefined;
  }

  return (
    <CommonDisplay
      image={image}
      title={title}
      small={small}
      subtitle={<Subtitle {...props} />}
      sourceLabel={"Memberships"}
      loading={isLoading}
    />
  );
}

export default function MembershipSelect(props: MembershipSelectProps) {
  const {
    internalState: { searchableMemberships, hideAllMembershipsOption },
  } = props;
  const theme = useTheme();

  const options: (
    | MembershipOptionType
    | GroupType<MembershipOptionType>
  )[] = [];

  if (!hideAllMembershipsOption) {
    options.push(allMemberships);
  }

  options.push(...groupMemberships(searchableMemberships));

  const onChange = (option: MembershipOptionType) => {
    const selectedSource = isAllMemberships(option)
      ? "all-memberships"
      : { membershipId: option.id };

    props.onChange(selectedSource);
  };

  return (
    <Select
      menuIsOpen
      autoFocus
      options={options}
      getOptionValue={(option: MembershipOptionType) => {
        return option.id;
      }}
      getOptionLabel={(option: MembershipOptionType) => {
        return isAllMemberships(option) ? option.label : option.name;
      }}
      formatGroupLabel={renderGroup}
      onChange={onChange}
      components={{ ...defaultComponents, Option }}
      styles={selectStyles(theme)}
      placeholder="Search memberships"
    />
  );
}
