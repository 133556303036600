import { Stack, Text, useScreenSize } from "flicket-ui";
import { Icon } from "~components";
import { Eye } from "@phosphor-icons/react";
import React from "react";

export const AudienceGoogleButton = () => {
  const { isTabletPortraitDown } = useScreenSize();
  const onClick = () => {
    console.info("Open Audience Google");
  };
  return (
    <Stack gap={1}>
      {isTabletPortraitDown && (
        <Icon display={"none"} icon={<Eye />} fontSize={4} color="N800" />
      )}
      {!isTabletPortraitDown && (
        <Text
          display={"none"}
          variant="regular"
          textDecoration={"underline"}
          cursor="pointer"
          onClick={onClick}
        >
          View Google Analytics
        </Text>
      )}
    </Stack>
  );
};
