import React from "react";

import { Flex, Text, PrimaryButton } from "flicket-ui";

const getError = ({ res, err }) => {
  let statusCode = 404;

  if (res) {
    statusCode = res?.statusCode || err?.statusCode || 500;
  }

  return { statusCode };
};

const getContent = (statusCode: number) => {
  let content = "An unexpected error ocurred.";

  if (statusCode === 404)
    content = "We could not find the page you were looking for."; // not found

  if (statusCode === 500)
    content = "Our server had some trouble processing this request."; // internal

  if (statusCode === 401) content = "You are not authorized to be here."; // unAuthorized

  return content;
};

const Error = ({ statusCode }: { statusCode: number }) => {
  return (
    <Flex
      flex={1}
      height="100vh"
      bg="N100"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Flex
        alignItems="center"
        flexDir="column"
        justifyContent="center"
        w="100%"
        maxW="480px"
        bg="white"
        px={{ _: 2, md: 4 }}
        py={4}
        borderRadius="lg"
        boxShadow="container"
      >
        <Text
          fontWeight="demiBold"
          textAlign="center"
          fontSize={6}
          mb={{ _: 0, md: 1 }}
        >
          {statusCode}
        </Text>
        <Text textAlign="center">{getContent(statusCode)}</Text>
        <PrimaryButton to="/" mt={{ _: 2, lg: 3 }}>
          Back to home
        </PrimaryButton>
      </Flex>
    </Flex>
  );
};

Error.getInitialProps = ({ res, err }) => getError({ res, err });

export default Error;
