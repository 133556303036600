import { ShopifyProductDto } from "~graphql/typed-document-nodes";
import Select, {
  CommonProps,
  components,
  ValueContainerProps,
} from "react-select";
import { Plus, X } from "@phosphor-icons/react";
import React, {
  CSSProperties,
  FC,
  JSXElementConstructor,
  ReactElement,
  useMemo,
  useState,
} from "react";
import { useSearchProducts } from "~forms/settings/integrations/shopify/useSearchProducts";
import { Box, InputWrapper, Stack, Text } from "flicket-ui";
import NextImage from "next/future/image";
import { CustomOptionProps } from "~forms/settings/integrations/shopify/components/ProductSelect";
import styled from "styled-components";
import debounce from "lodash/debounce";
import { SelectOption } from "../interface/slate.interface";

interface MerchandiseItemSelectProps {
  products: ShopifyProductDto[];
  setCurrentValue: (select: SelectOption[]) => void;
  currentValue: SelectOption[];
}
const StyledInputWrapper = styled(InputWrapper)`
  z-index: 1000;
`;

const customStyles = {
  control: (base: any) => ({
    ...base,
    flexDirection: "row-reverse",
  }),
  multiValue: (base: CSSProperties): CSSProperties => ({
    ...base,
    display: "none",
  }),
  ///
  loadingIndicator: (base: CSSProperties): CSSProperties => ({
    ...base,
    position: "absolute",
    right: 0,
  }),
  valueContainer: (base: CSSProperties): CSSProperties => ({
    ...base,
    padding: "2px 0px",
  }),
};
const DropdownIndicator = (
  props: JSX.IntrinsicAttributes &
    CommonProps<any, boolean> & {
      children: ReactElement<any, string | JSXElementConstructor<any>>;
      innerProps: any;
      isFocused: boolean;
      isRtl: boolean;
      isDisabled: boolean;
    }
) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <Plus color={"N800"} />
    </components.DropdownIndicator>
  );

const ValueContainer = (
  props: JSX.IntrinsicAttributes &
    ValueContainerProps<any, boolean> & {
      children: ReactElement<any, string | JSXElementConstructor<any>>;
      innerProps: any;
      isFocused: boolean;
      isRtl: boolean;
      isDisabled: boolean;
    }
) => (
  <components.ValueContainer {...props}>
    {!props.selectProps.isFocused && (
      <components.Placeholder
        {...props}
        isFocused={props.selectProps.isFocused}
      >
        {props.selectProps.placeholder}
      </components.Placeholder>
    )}
    {React.Children.map(props.children, (child) =>
      child && child.type !== components.Placeholder ? child : null
    )}
  </components.ValueContainer>
);

const ImageContainer = styled(Box)`
  width: 64px;
  height: 64px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 4px;
`;
const SelectItem = ({
  data,
  clearable,
  onClear,
  ...props
}: {
  data: SelectOption;
  clearable?: boolean;
  onClear?: (id: string) => void;
  [key: string]: any;
}) => {
  return (
    <Stack {...props} direction="horizontal" gap={2} alignItems="center">
      <ImageContainer onClick={() => window.open(data.url, "_blank")}>
        {data.image ? (
          <NextImage
            src={data.image}
            alt={data.value}
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            fill
            loading="lazy"
          />
        ) : (
          <></>
        )}
      </ImageContainer>
      <Stack direction="vertical" gap={1}>
        <Text variant="regular">{data.label}</Text>
        {data.price && <Text variant="small">${data.price}</Text>}
      </Stack>

      {clearable && (
        <Box
          onClick={() => onClear(data.value)}
          alignItems={"end"}
          marginLeft={"auto" as any}
          cursor={"pointer"}
        >
          <X />
        </Box>
      )}
    </Stack>
  );
};
const Option: FC<CustomOptionProps> = ({
  innerProps,
  innerRef,
  data,
  isFocused,
  isSelected,
}) => {
  return (
    <Box
      {...innerProps}
      px={2}
      py={1}
      mb={1}
      mt={1}
      backgroundColor={isSelected ? "P100" : isFocused ? "P100" : "transparent"}
      cursor="pointer"
      ref={innerRef}
    >
      <SelectItem data={data} />
    </Box>
  );
};

export const MerchandiseItemSelect = ({
  products,
  currentValue,
  setCurrentValue,
}: MerchandiseItemSelectProps) => {
  // Keep track of current value for options
  const [focused, setFocused] = useState(false);

  const {
    searchProducts,
    data: productSearchResults,
    isLoading: productsIsLoading,
  } = useSearchProducts();

  const options: ShopifyProductDto[] = useMemo(() => {
    // There are 3 different sources for Product data that need to be merged:
    // 1. The search results from the searchProducts query
    // 2. The Products that are already saved to the event
    // 3. The current new values of the input
    const productData: Record<string, ShopifyProductDto> = {};

    // Search results
    productSearchResults?.searchShopifyProducts?.products?.forEach(
      (product) => {
        productData[product.id] = {
          id: product.id,
          name: product.name,
          imageUrl: product.imageUrl,
          price: product.price,
          url: product.url,
        };
      }
    );

    // Saved Products
    products?.forEach((product) => {
      productData[product.id] = {
        id: product.id,
        name: product.name,
        imageUrl: product.imageUrl,
        price: product.price,
        url: product.url,
      };
    });

    return Object.values(productData);
  }, [productSearchResults, products]);

  const onChange = (selectedOption: any) => {
    setCurrentValue(selectedOption);
  };

  const handleProductSearch = debounce(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      const searchString = (event.target as HTMLInputElement).value;
      await searchProducts(searchString);
    },
    1000
  );

  const handleFocus = () => {
    setFocused(true);
    void searchProducts("");
  };

  return (
    <Box>
      {currentValue?.map((product) => (
        <SelectItem
          mt={1}
          mb={1}
          data={product}
          key={product.value}
          clearable={true}
          onClear={(id) => {
            setCurrentValue(currentValue.filter((v) => v.value !== id));
          }}
        />
      ))}
      <StyledInputWrapper>
        <Select
          isFocused={focused}
          onFocus={handleFocus}
          onBlur={() => setFocused(false)}
          onKeyDown={handleProductSearch}
          value={currentValue}
          placeholder={"Add products"}
          onChange={onChange}
          isLoading={productsIsLoading}
          options={options?.map((o) => ({
            label: o.name,
            value: o.id,
            image: o.imageUrl,
            price: o.price,
            url: o.url,
          }))}
          isMulti={true}
          isClearable={false}
          styles={customStyles}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
            ValueContainer,
          }}
        />
      </StyledInputWrapper>
    </Box>
  );
};
