import React, { FC } from "react";
import { Box, theme } from "flicket-ui";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  YAxisProps,
} from "recharts";

import { TimeOfDayBreakdownQuery } from "~graphql/sdk";
import { useScreenSize } from "~hooks/useScreenSize";

export const TimeOfDayBreakdown: FC<{
  data?: TimeOfDayBreakdownQuery["timeOfDayBreakdown"];
  colour?: string;
  fontSize?: number;
}> = ({ data, colour, fontSize }) => {
  const isMobile = useScreenSize().isPhonePortrait;
  colour = colour ?? theme.colors.P200;
  fontSize = fontSize ?? 14;
  const options = {
    chart: {
      margin: {
        top: 20,
        right: 0,
        left: 0,
      },
    },
    x: {
      axisLine: false,
      tickLine: false,
      interval: 0,
      tick: {
        fontSize: fontSize,
        color: theme.colors.N500,
      },
      hide: isMobile,
    },
    y: {
      axisLine: false,
      tickLine: false,
      tick: { fontSize: 10, color: theme.colors.N500 },
      padding: { left: 0, top: 0, right: 0, bottom: 0 },
      allowDecimals: false,
      label: {
        value: "Orders",
        position: "insideLeft",
        angle: -90,
        offset: 15,
      },
    } as YAxisProps,
    grid: {
      strokeDasharray: "0",
      vertical: false,
      stroke: theme.colors.N200,
    },
    tooltip: {
      formatter: (value: number) => [value, "orders"],
    },
  };

  return (
    <Box height="300px" mb={4}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} {...options.chart}>
          <CartesianGrid {...options.grid} />
          <XAxis dataKey="range" {...options.x} />
          <YAxis {...options.y} />
          <Tooltip {...options.tooltip} />
          <Bar dataKey="value" fill={colour as any} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
