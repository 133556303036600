import { css } from "styled-components";

export const InsertSelectCss = css`
  .react-select__control {
    padding: 0px 0 0px 12px;
    height: 28px;
    border-radius: 4px;
  }

  .react-select__value-container {
    padding: 0px;
  }
`;

export const InsertSMSSelectCss = css`
  ${InsertSelectCss}

  .react-select__control {
    padding: 0px;
  }
`;
