import { Box, Stack } from "flicket-ui";
import styled from "styled-components";

export const BoxGrid = styled(Box)<{ columns?: number }>`
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-columns: repeat(${(p) => p.columns ?? 3}, 1fr);
`;

export const AvoidBreakBox = styled(Box)`
  break-inside: avoid;
`;

export const AvoidBreakStack = styled(Stack)`
  break-inside: avoid;
`;
