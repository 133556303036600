import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { TicketsToSendElement } from "./interface/slate.interface";
import { topOfDocumentPoint } from "./util";
import { StyledCustomFieldElement } from "./CustomField";

/**
 * Difference between this and FieldTextVariables.TICKETS_TO_SEND:
 * This element "expands" to include a clickable link to the event informaiton.
 * The Custom Field variant is text only. For usage in the subject line for example.
 */

export const withTicketsToSend = (editor: Editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "tickets-to-send" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "tickets-to-send" ? true : isVoid(element);
  };

  return editor;
};

export const insertTicketsToSend = (editor: Editor) => {
  let blurSelection = editor.blurSelection;
  if (!blurSelection) {
    blurSelection = {
      anchor: topOfDocumentPoint,
      focus: topOfDocumentPoint,
    };
  }
  const field: TicketsToSendElement = {
    type: "tickets-to-send",
    children: [{ text: "" }],
  };
  Transforms.insertNodes(editor, field, {
    at: blurSelection,
  });

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

export const TicketsToSend = () => (
  <StyledCustomFieldElement contentEditable={false}>
    Tickets to send
  </StyledCustomFieldElement>
);
