import { GraphQLClient } from "graphql-request";
import { GetStaticProps } from "next";

import { graphUrl } from "~config";
import { getSdk, SdkFunctionWrapper } from "~graphql/sdk";

export const client = (
  orgId = undefined,
  host = undefined,
  recaptchaToken = undefined
) =>
  new GraphQLClient(graphUrl, {
    credentials: "include",
    headers: {
      //TO DO: fix - late night solution for a bug
      ...{ "Flicket-Environment": "admin" },
      ...(orgId
        ? {
            "Flicket-Org-Id": orgId,
          }
        : {}),
      ...(host
        ? {
            origin: host,
          }
        : {}),
      ...(recaptchaToken ? { "g-recaptcha-response": recaptchaToken } : {}),
    },
  });

export interface SDKOptions {
  orgId?: string;
  host?: string;
  key?: string;
  value?: string;
  recaptchaToken?: string;
  wrapper?: SdkFunctionWrapper;
}

export type SDK = ReturnType<typeof sdk>;
export type SDKMethod = keyof SDK;
export type SDKGeneratorFn = (
  method?: SDKMethod
) => Promise<
  (options: Omit<SDKOptions, "recaptchaToken">) => ReturnType<typeof sdk>
>;
export type SDKResult<T extends keyof SDK> = Awaited<
  Promise<PromiseLike<ReturnType<SDK[T]>>>
>;

export const sdk = ({
  orgId,
  host,
  key,
  value,
  recaptchaToken,
  wrapper,
}: SDKOptions = {}) => {
  if (key && value) {
    return getSdk(
      client(orgId, host, recaptchaToken).setHeader(key, value),
      wrapper
    );
  }
  return getSdk(client(orgId, host, recaptchaToken), wrapper);
};

export const getStaticPropsWithSDK = async (
  func: (sdk: ReturnType<typeof getSdk>) => ReturnType<GetStaticProps>
): Promise<ReturnType<GetStaticProps>> => {
  const staticSDK = getSdk(client());
  return func(staticSDK);
};
