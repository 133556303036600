import React, { useState } from "react";

import { Box, Flex, Text, Price, UnderlineButton } from "flicket-ui";
import groupBy from "lodash/groupBy";
import map from "lodash/map";

import {
  CurrentOrganizationQuery,
  LineItemType,
  OrderQuery,
} from "~graphql/sdk";
import { OrderItem } from "./OrderItem";
import { useOrganization } from "~hooks";

type TicketFeeProps = {
  lineItems: OrderQuery["order"]["lineItems"]["edges"][number]["node"][];
};

const renderDetails = (
  lineItems: TicketFeeProps["lineItems"],
  organization: CurrentOrganizationQuery["currentOrganization"]
) => {
  const groupedByFee = groupBy(
    lineItems,
    (lineItem) =>
      lineItem?.membershipType?.membership?.name ||
      lineItem?.ticketType?.name ||
      (lineItem.type === LineItemType.Addon && lineItem.name)
  );

  /** @note prefering lodash map over Object.values(groupedByFee) as the key gives us info we need (and we already include lodash for the group) */
  return map(groupedByFee, (ticketGroup, key) => (
    <Box display="grid" gridTemplateColumns="9fr 2fr 2fr 1fr" gridGap={3}>
      <Flex
        flexDir="column"
        justifyContent="space-between"
        w="100%"
        key={ticketGroup[0].id}
        mb="6/4"
      >
        <Text
          fontSize={4}
          lineHeight="normal"
          fontWeight="demiBold"
          color="N800"
          textTransform="capitalize"
          mb="1/2"
        >
          {key}
        </Text>
        {ticketGroup[0].ticketFeeAmount > 0 && (
          <Flex alignItems="flex-end" mt="1/4">
            <Text
              color="N600"
              fontSize={1}
              lineHeight={"1.2" as any}
              whiteSpace="nowrap"
              mr="1/4"
            >
              {ticketGroup[0].quantity > 1
                ? ticketGroup[0].quantity
                : ticketGroup.length}
              x
            </Text>
            <Price
              color="N800"
              fontSize={3}
              price={ticketGroup[0].ticketFeeAmount}
            />
            <Text
              color="N600"
              fontSize={1}
              lineHeight={"1.2" as any}
              whiteSpace="nowrap"
              mr="1/4"
              ml="1/4"
            >
              - Per Ticket Booking Fee
            </Text>
          </Flex>
        )}
        {ticketGroup[0].stadiumLevyFee > 0 && (
          <Flex alignItems="flex-end" mt="1/4">
            <Text
              color="N600"
              fontSize={1}
              lineHeight={"1.2" as any}
              whiteSpace="nowrap"
              mr="1/4"
            >
              {ticketGroup[0].quantity > 1
                ? ticketGroup[0].quantity
                : ticketGroup.length}
              x
            </Text>
            <Price
              color="N800"
              fontSize={3}
              lineHeight="normal"
              price={ticketGroup[0].stadiumLevyFee}
            />
            <Text
              color="N600"
              fontSize={1}
              lineHeight={"1.2" as any}
              whiteSpace="nowrap"
              mr="1/4"
              ml="1/4"
            >
              - Stadium Levy Fee
            </Text>
          </Flex>
        )}
        {ticketGroup[0].customTaxAmount > 0 && (
          <Flex alignItems="flex-end" mt="1/4">
            <Text
              color="N600"
              fontSize={1}
              lineHeight={"1.2" as any}
              whiteSpace="nowrap"
              mr="1/4"
            >
              {ticketGroup[0].quantity > 1
                ? ticketGroup[0].quantity
                : ticketGroup.length}
              x
            </Text>
            <Price
              color="N800"
              fontSize={3}
              lineHeight="normal"
              price={ticketGroup[0].customTaxAmount}
            />
            <Text
              color="N600"
              fontSize={1}
              lineHeight={"1.2" as any}
              whiteSpace="nowrap"
              mr="1/4"
              ml="1/4"
            >
              - {organization?.customTaxName ?? "Additional tax"}
            </Text>
          </Flex>
        )}
      </Flex>
      <Box gridColumn="4">
        <Price
          fontSize={4}
          price={
            (ticketGroup[0].ticketFeeAmount +
              (ticketGroup[0].stadiumLevyFee
                ? ticketGroup[0].stadiumLevyFee
                : 0) +
              (ticketGroup[0].customTaxAmount ?? 0)) *
            (ticketGroup[0].quantity > 1
              ? ticketGroup[0].quantity
              : ticketGroup.length)
          }
        />
      </Box>
    </Box>
  ));
};

export const TicketFees = ({ lineItems }: TicketFeeProps) => {
  const { organization } = useOrganization();
  const [showDetails, setShowDetails] = useState(false);
  const sum = lineItems?.reduce(
    (acc, curr) =>
      acc +
      (curr.ticketFeeAmount +
        (curr.stadiumLevyFee ? curr.stadiumLevyFee : 0) +
        (curr.customTaxAmount ?? 0)) *
        (curr?.quantity ?? 1),
    0
  );

  // We shouldn't need the dropdown details if no service details
  const hideServiceFeeDetails = sum <= 0;
  if (hideServiceFeeDetails) return null;

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="9fr 2fr 2fr 1fr"
        gridGap={3}
        my={1}
      >
        <Flex alignItems="center" mb={showDetails ? 2 : 1}>
          <Text>Ticket fees</Text>
          <UnderlineButton
            lineHeight="normal"
            fontWeight="regular"
            color="N600"
            ml={1}
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? "Hide" : "Show"} details
          </UnderlineButton>
        </Flex>
        <OrderItem />
        {!showDetails && (
          <Box gridColumn="4">
            <Price price={sum} />
          </Box>
        )}
      </Box>
      {showDetails && renderDetails(lineItems, organization)}
    </>
  );
};
