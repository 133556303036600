import { Flex, system, SystemProps } from "flicket-ui";
import styled from "styled-components";

export const StyledPopover = styled(Flex)<SystemProps>`
  position: absolute;
  z-index: 1300;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  padding: 8px 8px;
  align-items: center;
  font-size: 14px;

  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 8px);

  ${system}
`;
