import {
  BuyType,
  GetType,
  Promotion,
  Seat,
  SeatedActivePromotion,
  SeatWithPromotion,
} from "./types";

export const useSeatedMultibuy = (
  promotions: Promotion[],
  seats: Seat[]
): { activePromotions: SeatedActivePromotion["seats"] } => {
  const activePromotions: SeatedActivePromotion[] = promotions
    ?.filter((promotion) => promotion.enabled)
    ?.reduce((acc: SeatedActivePromotion[], curr) => {
      let getType: GetType, buyType: BuyType;
      if ("getTicketType" in curr) {
        getType = curr?.getTicketType;
        buyType = curr?.buyTicketType;
      } else if ("getMembershipType" in curr) {
        getType = curr?.getMembershipType;
        buyType = curr?.buyMembershipType;
      }

      if (buyType && getType) {
        const buySeats = seats?.filter(
          ({ ticketType, membershipType }) =>
            (ticketType?.id || membershipType?.id) === buyType?.id
        );

        const getSeats = seats?.filter(
          ({ ticketType, membershipType }) =>
            (ticketType?.id || membershipType?.id) === getType?.id
        );

        const buyTotal = buySeats?.length;
        const buySeatQty = Math.floor(buyTotal / curr.buyQuantity);

        const getTotal = getSeats?.length;

        const giftedQty =
          buySeatQty * curr.getQuantity <= getTotal
            ? buySeatQty * curr.getQuantity
            : getTotal;

        if (getSeats && buySeats && buySeats?.length >= curr?.buyQuantity) {
          const seatsWithPromotion: SeatWithPromotion[] = [
            ...buySeats.map((buy) => ({
              promotion: curr,
              ...buy,
              type: "buying" as const,
            })),
            // sort by price, so we can add discount text to the discount seats
            ...getSeats
              .sort((a, b) => a.price - b.price)
              .slice(0, giftedQty)
              .map((get) => ({
                promotion: curr,
                ...get,
                type: "getting" as const,
              })),
          ];

          return [
            ...acc,
            {
              ...curr,
              seats: seatsWithPromotion,
              isActive: getSeats?.length > 0,
            },
          ];
        }
      }

      return [
        ...acc,
        {
          ...curr,
          isActive: false,
        },
      ];
    }, []);

  /** @note we'll return the first promotion that is active **/
  return {
    activePromotions:
      activePromotions?.find((promo) => promo?.isActive)?.seats ?? [],
  };
};
