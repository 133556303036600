import { Icon } from "~components";
import { Button } from "../components";
import { PencilSimple, Trash } from "@phosphor-icons/react";
import { useAtom } from "jotai";
import { merchandiseModalState } from "~components/common/RichText/InsertMerchandiseModal";
import { MerchandiseElement } from "~components/common/RichText/interface/slate.interface";
import { useSlate } from "slate-react";
import { UpDownPopoverButtons } from "../UpDown/UpDown";

export const MerchandisePopover = ({
  merchandiseElement,
}: {
  merchandiseElement: MerchandiseElement;
}) => {
  const editor = useSlate();
  const [, setMerchandiseModalState] = useAtom(merchandiseModalState);
  return (
    <>
      <UpDownPopoverButtons />
      <Button
        w="30px"
        h="30px"
        onClick={() => {
          setMerchandiseModalState({
            isOpen: true,
            merchandise: merchandiseElement,
          });
        }}
      >
        <Icon icon={<PencilSimple size={18} />} />
      </Button>

      <Button
        w="30px"
        h="30px"
        onClick={() => {
          // there is a space character before the merchandise element
          editor.deleteBackward("character");
        }}
      >
        <Icon icon={<Trash size={18} />} />
      </Button>
    </>
  );
};
