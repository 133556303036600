import React, { FC } from 'react';
import { Box, Text, Flex, TertiaryButton } from 'flicket-ui';
import { includes } from 'lodash';
import styled from 'styled-components';

import { LineItemType, TicketStatus } from '~graphql/sdk';
import { formatSeatLocation } from '~lib/helpers';

const ListItem = styled(Flex)`
  list-style-type: none;
  justify-content: space-between;
`;

interface Props {
  id: string;
  name?: string;
  type?: string;
  seatZone?: string;
  seatSection?: string;
  seatTags?: string[];
  seatLabel?: string;
  firstName?: string;
  lastName?: string;
  isNamed?: boolean;
  fullName?: string;
  status?: any;
  lineItem?: any;
  onSyncTicket?: any;
  shouldBeNamed?: any;
}

export const LineItem: FC<Props> = ({
  id,
  name,
  type,
  seatZone,
  seatSection,
  seatTags,
  status,
  seatLabel,
  isNamed,
  firstName,
  lastName,
  fullName,
  lineItem,
  onSyncTicket,
  shouldBeNamed
}) => {
  return (
    <ListItem
      border="1px"
      borderColor="N300"
      borderRadius="sm"
      p="6/4"
      mb={3}
      as="li"
      {...(includes(
        [TicketStatus.Refunded, TicketStatus.Released],
        status || lineItem.status,
      ) && {
        textDecoration: 'line-through',
        opacity: '.6',
      })}
    >
      <Box>
        <Text
          fontSize={4}
          fontWeight="heavy"
          color="N800"
          borderRight="1px"
          borderColor="N800"
          pr="6/4"
          mr="6/4"
          as="span"
        >
          {name || lineItem?.name}
        </Text>
        <Text fontSize={4} fontWeight="heavy" color="N400" as="span">
          {type === LineItemType.Ticket && shouldBeNamed
            ? isNamed && (firstName || lastName)
              ? `${firstName} ${lastName}`
              : 'Not named yet'
            : fullName || ''}
        </Text>

        {type === LineItemType.Ticket && (
          <Text fontSize={1} display="block">
            {formatSeatLocation(seatZone, seatSection, seatLabel)}
          </Text>
        )}
      </Box>

      {onSyncTicket && (
        <TertiaryButton onClick={() => onSyncTicket(id)}>
          Sync with SeatsIO
        </TertiaryButton>
      )}
    </ListItem>
  );
};
