import { useState } from "react";
import useSWR from "swr";
import { AutomationFragmentFragment } from "~graphql/sdk";
import { useSDK } from "~hooks";
import { getError, showToast } from "~lib";

type Params = {
  isActive?: boolean;
};

export const useAutomations = (initialParams: Params = { isActive: true }) => {
  const sdk = useSDK();
  const [params, setParams] = useState<Params>({
    ...initialParams,
  });

  const getAutomations = async () => {
    return sdk
      .automations({ input: { isActive: params.isActive } })
      .then((res) => res.automations);
  };

  const { data, error, mutate, ...methods } = useSWR(
    ["automations", params?.isActive],
    getAutomations,
    {
      shouldRetryOnError: false,
    }
  );

  const updateAutomation = async (
    row: AutomationFragmentFragment
  ): Promise<void> => {
    try {
      await sdk.updateAutomation({
        id: row.id,
        input: { isActive: !row.isActive },
      });

      showToast("Automation updated", "success");
    } catch (e) {
      showToast(getError(e, "graphQL"), "error");
    }
  };

  return {
    ...methods,
    error,
    data,
    mutate,
    setParams,
    updateAutomation,
  };
};
