import React, {
  ComponentProps,
  createContext,
  FC,
  InputHTMLAttributes,
} from 'react';
import { Stack } from 'flicket-ui';

type Layout = 'vertical' | 'horizontal';

export const RadioContext = createContext<
  InputHTMLAttributes<HTMLInputElement> & { layout?: Layout }
>(null);

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  layout?: Layout;
  space?: ComponentProps<typeof Stack>['space'];
}

export const RadioGroup: FC<RadioProps> = ({
  layout = 'vertical',
  space = 0,
  children,
  ...props
}) => {
  return (
    <RadioContext.Provider value={{ ...props, layout }}>
      <Stack
        space={space}
        flexDirection={layout === 'vertical' ? 'column' : 'row'}
      >
        {children}
      </Stack>
    </RadioContext.Provider>
  );
};
