import { Text, formatPrice } from "flicket-ui";
import { WarningBanner } from "~features/competitions/components/WarningBanner";
import { OrderQuery, OrderRefundState } from "~graphql/typed-document-nodes";

interface RefundBannerProps {
  order: OrderQuery["order"];
}

export default function RefundBanner(props: RefundBannerProps) {
  const { order } = props;
  const { refundableState } = order;

  if (refundableState.refundState === OrderRefundState.None) {
    return (
      <WarningBanner mb={3}>
        <Text variant="regular">This order cannot be refunded.</Text>
      </WarningBanner>
    );
  }

  if (refundableState.refundState === OrderRefundState.FullyRefunded) {
    return (
      <WarningBanner mb={3}>
        <Text variant="regular">
          {`This order has been fully refunded (${formatPrice(
            order.refundedAmount
          )}).`}
        </Text>
      </WarningBanner>
    );
  }

  if (refundableState.refundState === OrderRefundState.ManualOnly) {
    return (
      <WarningBanner mb={3}>
        <Text variant="regular">
          {`This order can only be refunded manually.${
            typeof refundableState.gatewayRefundableAmount?.refundedAmount ===
            "number"
              ? ` The maximum of ${formatPrice(
                  refundableState.gatewayRefundableAmount?.refundedAmount
                )} has already been refunded through ${
                  refundableState.gatewayRefundableAmount.integrationName
                }.`
              : ""
          }`}
        </Text>
      </WarningBanner>
    );
  }

  if (refundableState.refundState === OrderRefundState.PartialGateway) {
    return (
      <WarningBanner mb={3}>
        <Text variant="regular">
          {`${
            order?.refundedAmount > 0
              ? `This order has been refunded ${formatPrice(
                  order.refundedAmount
                )}. `
              : ""
          }Only ${formatPrice(
            refundableState.gatewayRefundableAmount?.refundableAmount
          )} out of the maximum refundable amount (${formatPrice(
            refundableState.totalRefundableAmount
          )}) can be refunded through ${
            refundableState.gatewayRefundableAmount.integrationName
          }. The rest must be put through as a manual refund.`}
        </Text>
      </WarningBanner>
    );
  }

  return null;
}
