import {
  PointReportingFilterSource,
  ScanReportOverviewDocument,
  ScanTicketTypesDocument,
  ScanVisitorsDocument,
  ScanVisitorsOverTimeDocument,
} from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";

interface Input {
  eventId: string;
  queries?: {
    scanVisitors?: boolean;
    scanTicketTypes?: boolean;
    scansOverTime?: boolean;
  };
}

export const useScanReports = ({
  queries: {
    scanVisitors = true,
    scanTicketTypes = true,
    scansOverTime = true,
  } = {},
  eventId,
}: Input) => {
  const scanVisitorsQuery = useQuery(
    eventId && scanVisitors ? ScanVisitorsDocument : null,
    {
      eventId,
    }
  );

  const scansOverTimeQuery = useQuery(
    eventId && scansOverTime ? ScanVisitorsOverTimeDocument : null,
    {
      eventId,
    }
  );

  const scanTicketTypesQuery = useQuery(
    eventId && scanTicketTypes ? ScanTicketTypesDocument : null,
    {
      eventId,
    }
  );

  const scanStaticsQuery = useQuery(ScanReportOverviewDocument, {
    eventId,
    source: PointReportingFilterSource.Event,
  });

  return {
    scanVisitors: scanVisitorsQuery,
    scansOverTime: scansOverTimeQuery,
    scanTicketTypes: scanTicketTypesQuery,
    scanStatics: scanStaticsQuery,
  };
};
