import React, { ReactNode, forwardRef, MouseEventHandler } from "react";
import { pick, omit } from "@styled-system/props";
import styled, { css } from "styled-components";
import { SystemProps, Text, system, Box, Stack, Flex } from "flicket-ui";
import { CaretRight, IconContext, IconProps } from "@phosphor-icons/react";

const ICON_SIZE: IconProps["size"] = "24";
const ICON_WEIGHT: IconProps["weight"] = "regular";

interface BaseProps extends SystemProps {
  children: ReactNode;
  className?: string;
}

const dividerCss = `
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--divider-color);
  z-index: 1;
`;

const negativeMargin = css`
  margin-left: -${(p) => p.theme.space[1]}px;
  margin-right: -${(p) => p.theme.space[1]}px;
  padding-left: ${(p) => p.theme.space[1]}px;
  padding-right: ${(p) => p.theme.space[1]}px;
`;

const StyledItem = styled(Text).attrs(() => ({
  variant: "regular",
  as: "li",
}))<{
  $smallPadding?: boolean;
  $noTopBorder?: boolean;
  $noBottomBorder?: boolean;
}>`
  --divider-color: ${(p) => p.theme.colors.N200};

  display: flex;
  align-items: center;
  position: relative;
  padding: ${(p) => (p.$smallPadding ? p.theme.space[1] : p.theme.space[2])}px 0;

  margin-top: -1px;

  ${(p) =>
    p.$noTopBorder
      ? null
      : `
      &:first-of-type::before {
        ${dividerCss}
        top: 0;
      }

      &:has(:focus-visible):before {
        display: none;
      }
  `}

  ${(p) =>
    p.$noBottomBorder
      ? null
      : `
      &::after {
        ${dividerCss}
        bottom: 0;
      }
  `}

  &:has(+ li > button:focus-visible):after {
    display: none;
  }

  &:has(:focus-visible):after {
    display: none;
  }

  && {
    ${system}
  }
`;

export const List = ({ children, ...props }: BaseProps) => {
  return (
    <IconContext.Provider
      value={{
        size: ICON_SIZE,
        weight: ICON_WEIGHT,
      }}
    >
      <Stack
        gap={0}
        direction="vertical"
        as="ul"
        className={props.className}
        {...pick(props)}
      >
        {children}
      </Stack>
    </IconContext.Provider>
  );
};

interface BaseItemProps extends BaseProps {
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  smallPadding?: boolean;
  noTopBorder?: boolean;
  noBottomBorder?: boolean;
  InternalWrapper?: (props: BaseProps) => JSX.Element;
}

const BaseItem = forwardRef<HTMLDivElement, BaseItemProps>((props, ref) => {
  const {
    leadingIcon,
    trailingIcon,
    smallPadding,
    noTopBorder,
    noBottomBorder,
    InternalWrapper,
    children,
    ...rest
  } = props;

  const Wrapper = InternalWrapper ?? React.Fragment;

  return (
    <StyledItem
      ref={ref}
      {...rest}
      $smallPadding={smallPadding}
      $noTopBorder={noTopBorder}
      $noBottomBorder={noBottomBorder}
    >
      <Wrapper>
        <Stack flex={1} alignItems={"center"} gap={1} width="100%">
          {leadingIcon}
          <Box flex="1" width="100%">
            {children}
          </Box>
          {trailingIcon}
        </Stack>
      </Wrapper>
    </StyledItem>
  );
});

BaseItem.displayName = "BaseItem";

interface ButtonItemProps extends Omit<BaseItemProps, "InternalWrapper"> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const StyledButtonItem = styled(Box)<{ $smallPadding?: boolean }>`
  --hover-color: ${(p) => p.theme.colors.N100};
  --focus-color: ${(p) => p.theme.colors.N800};

  flex: 1;
  padding: ${(p) => (p.$smallPadding ? p.theme.space[1] : p.theme.space[2])}px 0;
  border-radius: ${(p) => p.theme.radii.md};

  ${negativeMargin}

  z-index: 2;

  &:hover {
    background: var(--hover-color);
  }

  &&:focus-visible {
    outline: 2px solid var(--focus-color);
    z-index: 3;
  }

  && {
    ${system}
  }
`;

const ButtonItem = forwardRef<HTMLDivElement, ButtonItemProps>((props, ref) => {
  const { children, onClick, smallPadding, ...rest } = props;

  return (
    <BaseItem
      ref={ref}
      padding={0}
      mx={0}
      InternalWrapper={({ children }) => {
        return (
          <StyledButtonItem
            as="button"
            type="button"
            onClick={onClick}
            $smallPadding={smallPadding}
            {...pick(rest)}
          >
            {children}
          </StyledButtonItem>
        );
      }}
      {...omit(rest)}
    >
      {children}
    </BaseItem>
  );
});

ButtonItem.displayName = "ButtonItem";

function ArrowItem(props: ButtonItemProps) {
  const { ...rest } = props;

  return <ButtonItem trailingIcon={<CaretRight />} {...rest} />;
}

const ColumnItems = forwardRef<
  HTMLDivElement,
  BaseItemProps &
    SystemProps & {
      onClick?: MouseEventHandler<HTMLElement>;
      noTopBorder?: boolean;
      noBottomBorder?: boolean;
      smallPadding?: boolean;
      children: ReactNode;
      gap?: number;
    }
>((props, ref) => {
  const {
    children,
    noTopBorder,
    noBottomBorder,
    smallPadding,
    gap,
    ...rest
  } = props;

  return (
    <StyledItem
      as="ul"
      my={0}
      ref={ref}
      css={{ gap: gap ?? 16 }}
      width={1}
      alignItems="center"
      $noTopBorder={noTopBorder}
      $noBottomBorder={noBottomBorder}
      $smallPadding={smallPadding}
      {...rest}
    >
      {children}
    </StyledItem>
  );
});

ColumnItems.displayName = "ColumnItems";

const Column = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
} & SystemProps) => {
  return (
    <Box
      display="flex"
      flexGrow={1}
      alignItems="center"
      className={className}
      {...pick(props)}
    >
      {children}
    </Box>
  );
};

List.BaseItem = BaseItem;
List.Item = ButtonItem;
List.ArrowItem = ArrowItem;
List.ColumnItems = ColumnItems;
List.Column = Column;
