import React, { ComponentProps } from "react";
import { Box } from "flicket-ui";
import { motion, SVGMotionProps } from "framer-motion";

const Path = (props: SVGMotionProps<never>) => (
  <motion.path
    d="M15 20 L25 20"
    fill="transparent"
    strokeWidth="2"
    stroke="white"
    strokeLinecap="round"
    {...props}
  />
);

export const DeleteButton = (props: ComponentProps<typeof Box>) => {
  return (
    <Box cursor="pointer" {...props}>
      <motion.svg
        whileHover="hover"
        width="50"
        height="50"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0" y="0" width="40" height="40" rx="50%" fill="white" />
        <rect x="10" y="10" width="20" height="20" rx="10" fill="#5B576A" />
        <Path
          variants={{
            hover: {
              d: "M16.47 23.53 L23.53 16.47",
            },
          }}
        />
        <Path
          variants={{
            hover: {
              d: "M16.47 16.47 L23.53 23.53",
            },
          }}
        />
      </motion.svg>
    </Box>
  );
};
