import { Text } from "flicket-ui";
import { ReactNode } from "react";
import { RenderElementProps } from "slate-react";
import { ParagraphElement } from "./interface/slate.interface";

type ParagraphProps = Pick<RenderElementProps, "attributes"> & {
  children: ReactNode;
  element: ParagraphElement;
  color?: string;
  isEmail: boolean;
};

export default function SlateParagraph(props: ParagraphProps) {
  const { children, element, color: _color, attributes, isEmail } = props;

  let color = _color;
  let fontSize: undefined | string;
  if (element.variant === "secondary") {
    color = "#666666";
    fontSize = "14px";
  }

  return (
    <Text
      as="p"
      variant="regular"
      mb={isEmail ? undefined : 1}
      style={{
        textAlign: element.align,
        color,
        fontSize,
      }}
      {...attributes}
    >
      {children}
    </Text>
  );
}
