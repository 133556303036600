const formatter = Intl.NumberFormat(undefined);

export function formatNumber(number: number | string) {
  if (number === "" || number == null) return "";

  const asNumber = Number(number);

  if (Number.isNaN(asNumber)) return "";

  return formatter.format(asNumber);
}
