import { hotjar } from "react-hotjar";
import { MeQuery } from "~graphql/sdk";
import posthogJS from "posthog-js";

const isPosthogUserIdentified = (): boolean => {
  const distinctId = posthogJS.get_distinct_id();
  const userProps = posthogJS.get_property("$user_id") as string | undefined;
  // Check if user has a distinct ID and user properties set
  return Boolean(distinctId && userProps);
};

const login = ({ id, ...user }: MeQuery["me"]) => {
  if (hotjar.initialized()) {
    hotjar.identify(id, user);
  }
  if (!isPosthogUserIdentified()) {
    posthogJS.identify(id, {
      email: user.email,
    });
  }
};

const logout = () => {
  if (hotjar.initialized()) {
    hotjar.identify(null, {});
  }
  posthogJS.reset();
};

export default {
  login,
  logout,
};
