export function addProtocolToURL(url?: string) {
  if (url) {
    if (url.startsWith("mailto:")) {
      return url;
    }

    url = encodeURI(url);
    if (url.indexOf("://") === -1) {
      url = `https://${url}`;
    }
  }
  return url;
}
