import React from "react";
import { omit, startCase } from "lodash";
import { Text, Flex, SystemProps } from "flicket-ui";

export const Status = ({ status }) => {
  const STATUS_COLORS = {
    Completed: "success",
    Paid: "success",
    Success: "success",
    Refunded: "success50",
    PartPaid: "success25",
    PartiallyRefunded: "warning50",
    Waiting: "warning",
    Pending: "warning",
    Unknown: "warning",
    Hold: "warning50",
    Expired: "N300",
    Error: "error",
    Failure: "error",
    Canceled: "error",
    Printed: "success",
    NotPrinted: "error",
  };

  return (
    <Flex borderRadius="full" bg="N200" p="1/2" pr={1} alignItems="center">
      <Flex
        borderRadius="full"
        width={16}
        height={16}
        bg={STATUS_COLORS[status]}
        mr="1/2"
      />
      <Text fontWeight="extraBold" fontSize={1} whiteSpace="nowrap">
        {startCase(status)}
      </Text>
    </Flex>
  );
};

type StatusTagProps = {
  text: string;
  titleText: string;
};
export const StatusTag = ({
  text,
  titleText,
  ...props
}: StatusTagProps & SystemProps) => {
  return (
    <Text
      fontSize={1}
      borderRadius={"sm"}
      px={1}
      py={"1/4"}
      mx={1}
      fontWeight={"extraBold"}
      title={titleText}
      height={"fit-content"}
      {...omit(props)}
    >
      {text}
    </Text>
  );
};
